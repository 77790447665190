import { AxiosResponse } from 'axios';
import { Model, Store } from 'json-api-models';
import { useState, useEffect } from 'react';

import Icon from 'components/Icon';
import BlastMessageContent from 'components/IntegratedInbox/Components/BlastMessageContent';
import { IntegratedInboxCampaign } from 'components/IntegratedInbox/types';
import Modal from 'components/Modals/Modal';
import { StatusCode } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';

import Styled from './BlastMessageModal.style';

type CreateBlastRequest = {
	message: string;
	influencers: string[];
	campaign?: string;
};

/**
 * Blast message is sending the same message to multiple selected influencers.
 * So it is for campaign managers not for influencers.
 *
 * @todo test and change createBlastMessage function
 */
const BlastMessageModal = (props: {
	open: boolean;
	onClose: () => void;
	selectedCampaign: IntegratedInboxCampaign | null;
	selectedCampaignInfluencers: Model[];
	selectedCampaignConversations: Model[] | null;
	getConversationMessages: (conversationId: string) => Promise<AxiosResponse>;
}) => {
	const [joinedInfluencers, setJoinedInfluencers] = useState<Model[]>([]);
	const [notJoinedInfluencers, setNotJoinedInfluencers] = useState<Model[]>([]);
	const [selectedInfluencers, setSelectedInfluencers] = useState<Model[]>([]);
	const [conversation, setConversation] = useState<Model | null>(null);
	const [messages, setMessages] = useState<Model[]>([]);
	const [tempUserMessageValue, setTempUserMessageValue] = useState<string>('');

	const messageStore = new Store();
	const Client = createClient();

	/**
	 * This sets selectedInfluencers state depending on type parameter.
	 * type parameter's type is string but it should be one of the following. 'joined', 'not-joined', 'all', 'single' and 'remove'
	 */
	const selectInfluencerHandler = (type: string, targetInfluencer?: Model) => {
		switch (type) {
			case 'joined':
				setSelectedInfluencers(joinedInfluencers);
				break;
			case 'not-joined':
				setSelectedInfluencers(notJoinedInfluencers);
				break;
			case 'all':
				setSelectedInfluencers([...joinedInfluencers, ...notJoinedInfluencers]);
				break;
			case 'single':
				if (targetInfluencer) {
					setSelectedInfluencers((prev) => {
						if (prev.some((prevCampaignInstagramOwner) => prevCampaignInstagramOwner.id === targetInfluencer.id)) {
							return prev;
						} else {
							return [...prev, targetInfluencer];
						}
					});
				}
				break;
			case 'remove':
				if (targetInfluencer) {
					setSelectedInfluencers((prev) => {
						return prev.filter((campaignInstagramOwner) => campaignInstagramOwner.id !== targetInfluencer.id);
					});
				}
				break;
			default:
				break;
		}
	};

	const fetchMessages = (conversationId: string) => {
		props.getConversationMessages(conversationId).then((res) => {
			if (res) {
				messageStore.sync(res);
				setTempUserMessageValue('');
				setMessages(messageStore.findAll('conversationMessage'));
			}
		});
	};

	/**
	 * temp createBlastConversation
	 * It will create a conversation or/and send a message to each influencer
	 */
	const createBlastConversation = async (message: string) => {
		if (!props.selectedCampaign || selectedInfluencers.length === 0 || 0 === message.trim().length) {
			return false;
		}

		const body: CreateBlastRequest = {
			message,
			campaign: props.selectedCampaign.id,
			influencers: selectedInfluencers.map(({ influencer }) => influencer.id),
		};

		await Client.post('/conversations/blasts', body);

		return true;
	};

	const sendMessage = async (conversationId: string, message: string) => {
		setTempUserMessageValue(message);
		const data: { [key: string]: string } = {
			message: message,
		};

		return await Client.post(`/conversations/${conversationId}`, data).then((res) => {
			if (res.status === StatusCode.OK) {
				fetchMessages(conversationId);
				return true;
			} else {
				return false;
			}
		});
	};

	const modalCloseHandler = () => {
		setJoinedInfluencers([]);
		setNotJoinedInfluencers([]);
		setSelectedInfluencers([]);
		setConversation(null);
		setMessages([]);
		setTempUserMessageValue('');
		props.onClose();
	};

	useEffect(() => {
		if (conversation) {
			fetchMessages(conversation.id);
		} else {
			setMessages([]);
		}
	}, [conversation]);

	useEffect(() => {
		if (props.selectedCampaignConversations && selectedInfluencers.length === 1) {
			const targetConversation = props.selectedCampaignConversations.find(
				(selectedCampaignConversation) => selectedCampaignConversation.influencer.id === selectedInfluencers[0].influencer.id,
			);
			if (targetConversation) {
				setConversation(targetConversation);
			} else {
				setConversation(null);
			}
		} else {
			setConversation(null);
		}
	}, [selectedInfluencers]);

	useEffect(() => {
		if (props.selectedCampaignInfluencers.length > 0) {
			const validInfluencers = props.selectedCampaignInfluencers.filter((campaignInstagramOwner) => campaignInstagramOwner.invite !== null);
			setJoinedInfluencers(validInfluencers.filter((campaignInstagramOwner) => campaignInstagramOwner.joined));
			setNotJoinedInfluencers(validInfluencers.filter((campaignInstagramOwner) => !campaignInstagramOwner.joined));
		} else {
			setJoinedInfluencers([]);
			setNotJoinedInfluencers([]);
		}
	}, [props.selectedCampaignInfluencers]);

	return (
		<Modal open={props.open} handleClose={modalCloseHandler} size='sm' data-testid='blast-modal'>
			<Styled.ModalHeader>
				<h4>Blast message - {props.selectedCampaign?.name}</h4>
				<button onClick={modalCloseHandler}>
					<Icon name='cross' size='18' />
				</button>
			</Styled.ModalHeader>
			<Modal.Body>
				<Styled.Wrapper>
					<BlastMessageContent
						campaign={props.selectedCampaign}
						campaignInstagramOwners={props.selectedCampaignInfluencers.filter((campaignInstagramOwner) => campaignInstagramOwner.invite !== null)}
						selectedInfluencers={selectedInfluencers}
						onSelectInfluencer={selectInfluencerHandler}
						conversation={conversation}
						messages={messages}
						tempValue={tempUserMessageValue}
						onSend={sendMessage}
						onStartBlast={createBlastConversation}
						onCloseModal={props.onClose}
					/>
				</Styled.Wrapper>
			</Modal.Body>
		</Modal>
	);
};

export default BlastMessageModal;
