export const FEATURE_FLAG_DATA_LIBRARY = 'DataLibrary';
export const FEATURE_FLAG_DATA_LIBRARY_DASHBOARD = 'DataLibraryDashboard';
export const FEATURE_FLAG_DATA_LIBRARY_REACH = 'DataLibraryReach';
export const FEATURE_FLAG_DATA_LIBRARY_IMPRESSIONS = 'DataLibraryImpressions';
export const FEATURE_FLAG_DATA_LIBRARY_TRAFFIC = 'DataLibraryTraffic';
export const FEATURE_FLAG_DATA_LIBRARY_AUDIENCE = 'DataLibraryAudience';
export const FEATURE_FLAG_DATA_LIBRARY_BUDGET = 'DataLibraryBudget';
export const FEATURE_FLAG_DATA_LIBRARY_ENGAGEMENT = 'DataLibraryEngagement';
export const FEATURE_FLAG_DATA_LIBRARY_CARD_PIN_BUTTON = 'DataLibraryCardPinButton';
export const FEATURE_FLAG_DATA_LIBRARY_CARD_MORE_BUTTON = 'DataLibraryCardMoreButton';
export const FEATURE_FLAG_DATA_LIBRARY_CARD_CAMPAIGN_BUTTON = 'DataLibraryCardCampaignButton';
export const FEATURE_FLAG_DOWNLOAD_DASHBOARD_CSV = 'DataLibraryCsvDownload';

// # temp feature for data-library
export const FEATURE_FLAG_APP_FAKE_SALES_DATA = 'FakeSalesData';

export const FEATURE_FLAG_DISCOVERY_LISTS_VIEW = 'DiscoveryListsView';
export const FEATURE_FLAG_DISCOVERY_LOVE_ICON = 'DiscoveryInfluencerItemLoveIcon';

export const FEATURE_FLAG_NEW_DATA_LIBRARY = 'NewDataLibrary';
export const FEATURE_FLAG_DASHBOARD_PREVIEW = 'DashboardPreview';

export const FEATURE_FLAG_INTEGRATED_INBOX = 'IntegratedInbox';
export const FEATURE_FLAG_NEW_INTEGRATED_INBOX = 'integratedInbox';
export const FEATURE_FLAG_NEW_INFLUENCER_INBOX = 'NewInfluencerIntegratedInbox';
export const FEATURE_FLAG_INBOX_UPLOAD_ATTATCHMENTS = 'UploadAttachmentsInbox';
export const FEATURE_FLAG_NOTIFICATIONS = 'Notifications';

export const FEATURE_FLAG_INFLUENCER_ASSIGNMENTS = 'InfluencerAssignments';
export const FEATURE_FLAG_INFLUENCER_CONTENT_MANAGEMENT = 'InfluencerContentManagement';
export const FEATURE_FLAG_USER_CONTENT_MANAGEMENT = 'UserContentManagement';

export const FEATURE_FLAG_FACEBOOK_AUTH = 'FacebookAuth';
export const FEATURE_COLLABS_AUTH = 'CollabsAPIAuth';

export const FEATURE_FLAG_DOWNLOAD_MEDIA = 'DownloadImage';

export const FEATURE_FLAG_NEW_INFLUENCER_DASHBOARD = 'NewInfluencerDashboard';
export const FEATURE_FLAG_AFFILIATE_MARKETPLACE = 'AffiliateMarketplace';
export const FEATURE_FLAG_SHOW_AFFILIATE_MARKETPLACE_LINK = 'ShowAffiliateMarketplace';

export const FEATURE_FLAG_DISCOVERY_MESSAGE = 'DiscoveryMessage';

export const FEATURE_FLAG_TIKTOK_INTEGRATION = 'TikTokIntegration';
export const FEATURE_FLAG_MULTIPLE_ACCOUNTS = 'MultipleAccounts';
export const FEATURE_FLAG_NEW_FRAME_SELECTOR = 'NewFrameSelector';

export const FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS = 'TikTokConnectorInSettings';
export const FEATURE_FLAG_ONBOARDING_VIEW = 'OnboardingView';
export const FEATURE_FLAG_NEW_CREATE_CAMPAIGN = 'NewCreateCampaign';

export const FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK = 'SignupRequireNetwork';

export const FEATURE_BRIEF_PAGE_IMAGE_CROP = 'BriefPageImageCrop';
export const FEATURE_FLAG_NEW_BRIEF_PAGE = 'NewBrief';

export const FEATURE_FLAG_FORCE_CONNECT = 'ForceConnect';

export const FEATURE_FLAG_CAMPAIGN_SIDEDRAWER = 'CampaignSideDrawer';
export const FEATURE_FLAG_SEGMENTS = 'Segments';

export const FEATURE_FLAG_MEDIA_AGENCY_FEE = 'MediaAgencyFee';

export const FEATURE_FLAG_CAMPAIGN_BUDGET = 'CampaignBudget';
