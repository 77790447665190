import { AssignmentModel, BrandModel, CampaignModel, ClientModel, CountryModel, InfluencerModel } from 'api-models';
import { ListActiveCountriesQuery, ListAssignmentsQuery, ListBrandsQuery, ListCampaignsQuery, ListInfluencersQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import AssignmentManager from 'services/ApiManager/Assignment.manager';
import BrandManager from 'services/ApiManager/Brand.manager';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import ClientManager from 'services/ApiManager/Client.manager';
import InfluencerManager from 'services/ApiManager/Influencer.manager';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getAssignmentOptions = (manager: AssignmentManager, filter: DashboardFilters): { result: AssignmentModel[] | undefined; error?: Error } => {
	let qb = RequestQueryBuilder.create<ListAssignmentsQuery>();

	if (filter.clients && filter.clients.length > 0) {
		qb = qb.withFilter('clients', filter.clients.slice().sort().join(','));
	}
	if (filter.brands && filter.brands.length > 0) {
		qb = qb.withFilter('brands', filter.brands.slice().sort().join(','));
	}
	if (filter.campaigns && filter.campaigns.length > 0) {
		qb = qb.withFilter('campaigns', filter.campaigns.slice().sort().join(','));
	}

	const { result, error } = manager.listAssignments(qb);

	return { result, error };
};

export const getCampaignOptions = (manager: CampaignManager, filter: DashboardFilters): { result: CampaignModel[] | undefined; error?: Error } => {
	let qb = RequestQueryBuilder.create<ListCampaignsQuery>();

	if (filter.clients && filter.clients.length > 0) {
		qb = qb.withFilter('clients', filter.clients.slice().sort().join(','));
	}
	if (filter.brands && filter.brands.length > 0) {
		qb = qb.withFilter('brands', filter.brands.slice().sort().join(','));
	}

	const { result, error } = manager.listCampaigns(qb);

	return { result, error };
};

export const getClientOptions = (manager: ClientManager, _filter: DashboardFilters): { result: ClientModel[] | undefined; error?: Error } => {
	const { result, error } = manager.listClients();

	return { result, error };
};

export const getBrandOptions = (manager: BrandManager, filter: DashboardFilters): { result: BrandModel[] | undefined; error?: Error } => {
	let qb = RequestQueryBuilder.create<ListBrandsQuery>();

	if (filter.clients && filter.clients.length > 0) {
		qb = qb.withFilter('clients', filter.clients.slice().sort().join(','));
	}

	const { result, error } = manager.listBrands(qb);

	return { result, error };
};

export const getInfluencerOptions = (manager: InfluencerManager, filter: DashboardFilters): { result: InfluencerModel[] | undefined; error?: Error } => {
	let qb = RequestQueryBuilder.create<ListInfluencersQuery>();

	if (filter.clients && filter.clients.length > 0) {
		qb = qb.withFilter('clients', filter.clients.slice().sort().join(','));
	}
	if (filter.brands && filter.brands.length > 0) {
		qb = qb.withFilter('brands', filter.brands.slice().sort().join(','));
	}
	if (filter.campaigns && filter.campaigns.length > 0) {
		qb = qb.withFilter('campaigns', filter.campaigns.slice().sort().join(','));
	}

	const { result, error } = manager.listInfluencers(qb);

	return { result, error };
};

export const getInfluencerCountryOptions = (manager: StatisticsManager, filter: DashboardFilters): { result: CountryModel[] | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ListActiveCountriesQuery>(filter);
	const { result, error } = manager.listActiveCountries(qb);

	return { result, error };
};
