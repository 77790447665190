import classNames from 'classnames';
import React from 'react';

import { DarkButton } from 'components/Button';
import Icon from 'components/Icon';

import Styled from './PromotionalBanner.style';

export type PromotionalBannerProps = {
	image: string;
	altText: string;
	ariaLabel?: string;
	text: React.ReactNode;
	onClick?: () => void;
	onClickClose?: () => void;
	CTAText?: string;
};

/**
 * PromotionalBanner
 * @param props {PromotionalBannerProps}
 * @returns {JSX.Element}
 */
const PromotionalBanner = (props: PromotionalBannerProps): JSX.Element => {
	const onClickBanner = () => {
		props.onClick && props.onClick();
	};

	const onClickCloseButton = (e: React.MouseEvent) => {
		e.stopPropagation(); // prevent event bubbling
		props.onClickClose && props.onClickClose();
	};

	return (
		<Styled.PromotionalBanner onClick={onClickBanner} aria-label={props.ariaLabel} className={classNames({ clickable: props.onClick !== undefined })}>
			<Styled.Inner>
				{props.image !== '' && (
					<Styled.ImageContainer>
						<Styled.Image src={props.image} alt={props.altText} />
					</Styled.ImageContainer>
				)}

				<Styled.TextContainer>{props.text}</Styled.TextContainer>
				{props.CTAText && (
					<Styled.CTAContainer>
						<DarkButton>{props.CTAText}</DarkButton>
					</Styled.CTAContainer>
				)}
			</Styled.Inner>
			{props.onClickClose && (
				<Styled.CloseButton aria-label='Close banner' onClick={onClickCloseButton}>
					<Icon name='cross' size='16' />
				</Styled.CloseButton>
			)}
		</Styled.PromotionalBanner>
	);
};

export default PromotionalBanner;
