import { RouteObject } from 'react-router-dom';

import { PageNotFound } from 'components/ErrorPages';
import VerifyEmailInfo from 'views/influencer/VerifyEmail/VerifyEmailInfo';

/**
 * Verify email Routes
 *
 * @returns {Array<RouteObject>}
 */

const routes: Array<RouteObject> = [
	{
		path: '',
		children: [
			{ index: true, element: <VerifyEmailInfo /> },
			{ path: 'welcome', element: <VerifyEmailInfo /> },
		],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
