import { Model } from 'json-api-models';
import moment from 'moment';

export interface IAMProgramListItem extends Model {
	name: string;
	adtractionId: string;
	storeLink: string;
	storeCommissionPercentage: number;
	influencerCommissionPercentage: number;
	collabsCommissionPercentage: number;
	background: string;
	guidelines: string;
	createdAt: string;
	sort: number;
}

export class AMProgramListItem {
	id: string;
	title: string;
	programId: string;
	storeLink: string;
	storeCommission: number;
	influencerCommission: number;
	collabsCommission: number;
	createdAt: string;
	sort: number;

	constructor(amProgram: IAMProgramListItem) {
		this.id = amProgram.id;
		this.title = amProgram.name;
		this.programId = amProgram.adtractionId;
		this.storeLink = amProgram.storeLink;
		this.storeCommission = amProgram.storeCommissionPercentage;
		this.influencerCommission = amProgram.influencerCommissionPercentage;
		this.collabsCommission = amProgram.collabsCommissionPercentage;
		this.createdAt = moment(amProgram.createdAt).format('YYYY-MM-DD');
		this.sort = amProgram.sort;
	}
}
