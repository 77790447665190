import styled from 'styled-components';

import { InputFieldContainerWithIconV2, InputFieldV2 } from 'styles/formElements/input';
import { InputType } from 'styles/formElements/types';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { px, rem, spacing } from 'styles/variables/spacings';

const AffiliateSidebar = styled.div`
	position: relative;
	height: 100%;
	padding-left: ${spacing[2].rem};
`;

const SearchContainer = styled.div`
	margin-bottom: ${px[2]}px;
	margin-right: ${px[2]}px;

	@media screen and (${devices.lg}) {
		margin-left: 0;
		margin-right: 0;
	}
`;

const SearchBox = styled.div``;

const SearchInputContainer = styled.div<InputType>`
	${InputFieldContainerWithIconV2};

	.icon {
		margin-left: ${spacing[2].px};
		margin-right: ${spacing[2].px};
	}
`;

const SearchInput = styled.input`
	${InputFieldV2};
	border-left: none;
	padding-left: 0;
	font-size: 0.875rem;
`;

const FilterContainer = styled.div`
	background-color: ${colors.AffiliateMarketplaceSidebar.FilterContainerBackground};
	border-radius: 10px;
	padding: ${rem[3]}rem ${rem[2]}rem;
	margin-left: ${px[2]}px;
	margin-right: ${px[2]}px;
	margin-bottom: ${px[3]}px;

	@media screen and (${devices.lg}) {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;
	}
`;

const SortFilter = styled.div``;

const SortCategories = styled.div`
	height: 300px;

	& + div {
		margin-bottom: ${px[3]}px;
	}

	@media screen and (${devices.lg}) {
		height: 748px;
	}

	${scrollbarY};

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: flex;
		padding: ${rem[0]}rem 0;
		width: 100%;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		a {
			padding: ${rem[1]}rem;
			width: 100%;
			border-radius: 4px;
			transition: background-color 0.2s ease-in-out;

			&.active {
				background-color: ${colors.AffiliateMarketplaceSidebar.activeItemBackground};
			}
		}

		.counter {
			color: ${colors.AffiliateMarketplaceSidebar.counterLabelColor};
		}
	}
`;

const Styled = {
	AffiliateSidebar,
	SearchContainer,
	SearchBox,
	SearchInput,
	SearchInputContainer,
	FilterContainer,
	SortFilter,
	SortCategories,
};

export default Styled;
