import styled from 'styled-components';

const Wrapper = styled.div`
	overflow: hidden;
	padding: 1rem;
	height: 80vh;
	overflow-y: scroll;
`;

const RightInner = styled.div`
	height: 100%;
	.toggler .icon {
		position: absolute;
		left: 0px;
		top: 0px;
		line-height: 1;
	}
`;

const Headline = styled.div`
	display: flex;
	align-items: center;
	height: 56px;
	h3 {
		font-size: 1rem;
		margin-bottom: 0;
	}
`;

const Styled = {
	Wrapper,
	RightInner,
	Headline,
};

export default Styled;
