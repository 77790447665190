import { getAssignmentsByCampaign, getAssignmentsByCountry, getAssignmentsByNetwork } from 'components/DataLibrary/AllRequests/AssignmentsData';
import { getAudienceAge, getAudienceCountry, getAudienceGender } from 'components/DataLibrary/AllRequests/AudienceData';
import {
	getBudgetOverTime,
	getBudgetsByCountry,
	getBudgetTotal,
	getConversionTotal,
	getCPC,
	getCPM,
	getInfluencerSalesTotal,
	getSalesOverTime,
	getSalesTotal,
} from 'components/DataLibrary/AllRequests/BudgetData';
import {
	getEngagementByPlatforms,
	getEngagementComments,
	getEngagementLikes,
	getEngagementSaves,
	getEngagementShares,
	getEngagementTotal,
} from 'components/DataLibrary/AllRequests/EngagementData';
import { getImpressionOverTime, getImpressionTotal, getImpressionTotalByPlatforms } from 'components/DataLibrary/AllRequests/ImpressionData';
import { getInfluencerTotal } from 'components/DataLibrary/AllRequests/InfluencerData';
import { getReachAndEngagementPerCampaign, getReachCounts, getReachTotal, getReachTotalByPlatforms } from 'components/DataLibrary/AllRequests/ReachData';
import { getClicksOverTime, getTrafficCTRTotal, getTrafficLinkTotal } from 'components/DataLibrary/AllRequests/TrafficData';
import {
	Total,
	ByAssignmentType,
	OverTime,
	ByInfluencerContainer,
	ByGender,
	ByCountryContainer,
	ByAgeContainer,
} from 'components/DataLibrary/Graphs/GraphComponents';
import ByCampaignContainer from 'components/DataLibrary/Graphs/GraphComponents/ByCampaign/ByCampaignContainer';
import ByInfluencerTable from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/GraphTypes/Table/ByInfluencerTable';
import PaidMediaTable from 'components/DataLibrary/Graphs/GraphComponents/PaidMedia/PaidMediaTable';
import {
	REACH,
	IMPRESSIONS,
	ENGAGEMENT,
	TRAFFIC,
	AUDIENCE,
	BUDGET,
	SALES,
	INFLUENCERS,
	MEDIA_OBJECTS,
	PAID_MEDIA,
	EXPLANATIONS,
	CALCULATION_FORMULA,
} from 'constants/data-library';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import colors from 'styles/variables/colors';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

type FilterSectionProps = {
	type: string;
	chartId: string | number;
};

const useGetChart = () => {
	const getChart = ({ type, chartId }: FilterSectionProps) => {
		// TODO maybe move this method
		const noop = (_manager: StatisticsManager, _filter: DashboardFilters): { result: never | undefined; error?: Error } => {
			return { result: undefined };
		};

		switch (type) {
			case REACH.TOTAL:
				return (
					<Total
						chartId={chartId}
						fetchData={getReachTotal}
						title={REACH.TOTAL_TITLE}
						chartType={REACH.TOTAL}
						chartOnDashboard={true}
						chartExplanation={{ explanation: EXPLANATIONS.REACH.TOTAL }}
					/>
				);
			case REACH.BY_SOCIAL_MEDIA:
				return (
					<ByAssignmentType
						chartId={chartId}
						fetchData={getReachTotalByPlatforms}
						title={REACH.BY_SOCIAL_MEDIA_TITLE}
						chartOnDashboard={true}
						chartType={REACH.BY_SOCIAL_MEDIA}
						displayBar
						chartExplanation={{ explanation: EXPLANATIONS.REACH.BY_FORMAT }}
					/>
				);
			case REACH.OVERTIME:
				return (
					<OverTime
						chartId={chartId}
						fetchData={getReachCounts}
						title={REACH.OVERTIME_TITLE}
						color={colors.chartLineReach}
						isHoverable={true}
						chartType={REACH.OVERTIME}
						chartOnDashboard={true}
						chartExplanation={{ explanation: EXPLANATIONS.REACH.OVERTIME }}
					/>
				);
			case REACH.BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={REACH.BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartOnDashboard={true}
						chartType={REACH.BY_INFLUENCER}
						valueType='grossReach'
						chartExplanation={{ explanation: EXPLANATIONS.REACH.BY_INFLUENCER }}
					/>
				);
			case REACH.REACH_AND_CPR_BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={REACH.REACH_AND_CPR_BY_INFLUENCER_TITLE}
						chartOnDashboard={true}
						chartType={REACH.REACH_AND_CPR_BY_INFLUENCER}
						valueType='reach-cpr'
						displayCombinedBar
						chartExplanation={{ explanation: EXPLANATIONS.REACH.REACH_CPR_BY_INFLUENCER }}
					/>
				);
			case REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN:
				return (
					<ByCampaignContainer
						chartId={chartId}
						fetchData={getReachAndEngagementPerCampaign}
						title={REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN_TITLE}
						chartOnDashboard={true}
						chartType={REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN}
						displayCombinedBarWithLine
						chartExplanation={{ explanation: EXPLANATIONS.REACH.REACH_ENGAGEMENT_BY_CAMPAIGN }}
					/>
				);
			case IMPRESSIONS.TOTAL:
				return (
					<Total
						chartId={chartId}
						fetchData={getImpressionTotal}
						title={IMPRESSIONS.TOTAL_TITLE}
						chartOnDashboard={true}
						chartType={IMPRESSIONS.TOTAL}
						chartExplanation={{ explanation: EXPLANATIONS.IMPRESSIONS.TOTAL }}
					/>
				);
			case IMPRESSIONS.BY_ASSIGNMENT_TYPE:
				return (
					<ByAssignmentType
						chartId={chartId}
						fetchData={getImpressionTotalByPlatforms}
						title={IMPRESSIONS.BY_ASSIGNMENT_TYPE_TITLE}
						chartOnDashboard={true}
						chartType={IMPRESSIONS.BY_ASSIGNMENT_TYPE}
						displayBar
						chartExplanation={{ explanation: EXPLANATIONS.IMPRESSIONS.BY_CHANNEL }}
					/>
				);
			case IMPRESSIONS.OVERTIME:
				return (
					<OverTime
						chartId={chartId}
						fetchData={getImpressionOverTime}
						title={IMPRESSIONS.OVERTIME_TITLE}
						color={colors.chartLineImpressions}
						isHoverable={true}
						chartOnDashboard={true}
						chartType={IMPRESSIONS.OVERTIME}
						chartExplanation={{ explanation: EXPLANATIONS.IMPRESSIONS.OVERTIME }}
					/>
				);
			case IMPRESSIONS.BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={IMPRESSIONS.BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartOnDashboard={true}
						chartType={IMPRESSIONS.BY_INFLUENCER}
						valueType='impressions'
						chartExplanation={{ explanation: EXPLANATIONS.IMPRESSIONS.BY_INFLUENCER }}
					/>
				);

			case ENGAGEMENT.TOTAL:
				return (
					<Total
						chartId={chartId}
						fetchData={getEngagementTotal}
						title={ENGAGEMENT.TOTAL_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.TOTAL}
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.ENGAGEMENT_TOTAL, formula: CALCULATION_FORMULA.ENGAGEMENT_TOTAL }}
					/>
				);
			case ENGAGEMENT.TOTAL_LIKES:
				return (
					<Total
						chartId={chartId}
						fetchData={getEngagementLikes}
						title={ENGAGEMENT.TOTAL_LIKES_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.TOTAL_LIKES}
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.LIKES_TOTAL }}
					/>
				);
			case ENGAGEMENT.TOTAL_COMMENTS:
				return (
					<Total
						chartId={chartId}
						fetchData={getEngagementComments}
						title={ENGAGEMENT.TOTAL_COMMENTS_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.TOTAL_COMMENTS}
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.COMMENTS_TOTAL }}
					/>
				);
			case ENGAGEMENT.TOTAL_SHARES:
				return (
					<Total
						chartId={chartId}
						fetchData={getEngagementShares}
						title={ENGAGEMENT.TOTAL_SHARES_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.TOTAL_SHARES}
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.SHARES_TOTAL }}
					/>
				);
			case ENGAGEMENT.TOTAL_SAVES:
				return (
					<Total
						chartId={chartId}
						fetchData={getEngagementSaves}
						title={ENGAGEMENT.TOTAL_SAVES_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.TOTAL_SAVES}
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.SAVES_TOTAL }}
					/>
				);
			case ENGAGEMENT.BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={ENGAGEMENT.BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.BY_INFLUENCER}
						valueType='engagement'
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.ENGAGEMENT_BY_INFLUENCER }}
					/>
				);
			case ENGAGEMENT.ENGAGEMENT_AND_CPE_BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={ENGAGEMENT.ENGAGEMENT_CPE_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.ENGAGEMENT_AND_CPE_BY_INFLUENCER}
						valueType='engagement-cpe'
						displayCombinedBar
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.ENGAGEMENT_CPE_BY_INFLUENCER, formula: CALCULATION_FORMULA.CPE }}
					/>
				);
			case ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER_TITLE}
						chartOnDashboard={true}
						chartType={ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER}
						valueType='engagement-reach-by-influencer'
						displayCombinedBar
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.ENGAGEMENT_REACH_BY_INFLUENCER }}
					/>
				);
			case ENGAGEMENT.BY_ASSIGNMENT_TYPE:
				return (
					<ByAssignmentType
						chartId={chartId}
						chartOnDashboard={true}
						fetchData={getEngagementByPlatforms}
						title={ENGAGEMENT.BY_ASSIGNMENT_TYPE_TITLE}
						chartType={ENGAGEMENT.BY_ASSIGNMENT_TYPE}
						displayBar
						chartExplanation={{ explanation: EXPLANATIONS.ENGAGEMENT.ENGAGEMENT_BY_CHANNEL }}
					/>
				);

			case TRAFFIC.CLICKS_OVERTIME:
				return (
					<OverTime
						chartId={chartId}
						fetchData={getClicksOverTime}
						title={TRAFFIC.CLICKS_OVERTIME_TITLE}
						color={colors.chartLineTraffic}
						isHoverable={true}
						chartOnDashboard={true}
						chartType={TRAFFIC.CLICKS_OVERTIME}
						chartExplanation={{ explanation: EXPLANATIONS.TRAFFIC.CLICKS_OVERTIME }}
					/>
				);
			case TRAFFIC.TOTAL_CLICKS:
				return (
					<Total
						chartId={chartId}
						fetchData={getTrafficLinkTotal}
						title={TRAFFIC.TOTAL_CLICKS_TITLE}
						chartOnDashboard={true}
						chartType={TRAFFIC.TOTAL_CLICKS}
						chartExplanation={{ explanation: EXPLANATIONS.TRAFFIC.CLICKS_TOTAL }}
					/>
				);
			case TRAFFIC.CTR:
				return (
					<Total
						chartId={chartId}
						fetchData={getTrafficCTRTotal}
						title={TRAFFIC.CTR_TITLE}
						chartOnDashboard={true}
						chartType={TRAFFIC.CTR}
						isPercent
						chartExplanation={{ explanation: EXPLANATIONS.TRAFFIC.CTR, formula: CALCULATION_FORMULA.CTR }}
					/>
				);
			case TRAFFIC.BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={TRAFFIC.BY_INFLUENCER_TITLE}
						chartOnDashboard={true}
						chartType={TRAFFIC.BY_INFLUENCER}
						valueType='clicks'
						isTopThree={true}
						chartExplanation={{ explanation: EXPLANATIONS.TRAFFIC.CLICKS_BY_INFLUENCER }}
					/>
				);
			case TRAFFIC.CTR_BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={TRAFFIC.CTR_BY_INFLUENCER_TITLE}
						chartOnDashboard={true}
						chartType={TRAFFIC.CTR_BY_INFLUENCER}
						valueType='ctr'
						isTopThree={true}
						isPercent
						chartExplanation={{ explanation: EXPLANATIONS.TRAFFIC.CTR_BY_INFLUENCER, formula: CALCULATION_FORMULA.CTR }}
					/>
				);

			case AUDIENCE.AGE:
				return (
					<ByAgeContainer
						displayBar
						chartId={chartId}
						fetchData={getAudienceAge}
						title={AUDIENCE.AGE_TITLE}
						chartType={AUDIENCE.AGE}
						chartOnDashboard={true}
						chartExplanation={{ explanation: EXPLANATIONS.AUDIENCE.BY_AGE }}
					/>
				);
			case AUDIENCE.GENDER:
				return (
					<ByGender
						chartId={chartId}
						fetchData={getAudienceGender}
						title={AUDIENCE.GENDER_TITLE}
						chartType={AUDIENCE.GENDER}
						chartOnDashboard={true}
						chartExplanation={{ explanation: EXPLANATIONS.AUDIENCE.BY_GENDER }}
					/>
				);
			case AUDIENCE.COUNTRY:
				return (
					<ByCountryContainer
						chartId={chartId}
						fetchData={getAudienceCountry}
						title={AUDIENCE.COUNTRY_TITLE}
						subTitle={'Top performing countries'}
						chartType={AUDIENCE.COUNTRY}
						chartOnDashboard={true}
						displayBar
						chartExplanation={{ explanation: EXPLANATIONS.AUDIENCE.BY_COUNTRY }}
					/>
				);

			case BUDGET.TOTAL:
				return (
					<Total
						isAmount
						chartId={chartId}
						fetchData={getBudgetTotal}
						title={BUDGET.TOTAL_TITLE}
						chartOnDashboard={true}
						chartType={BUDGET.TOTAL}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.BUDGET_TOTAL }}
					/>
				);
			case BUDGET.OVERTIME:
				return (
					<OverTime
						isAmount
						chartId={chartId}
						fetchData={getBudgetOverTime}
						title={BUDGET.OVERTIME_TITLE}
						color={colors.chartLineBudget}
						isHoverable={true}
						chartOnDashboard={true}
						chartType={BUDGET.OVERTIME}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.BUDGET_OVERTIME }}
					/>
				);
			case BUDGET.CPC:
				return (
					<Total
						isAmount
						chartId={chartId}
						fetchData={getCPC}
						title={BUDGET.CPC_TITLE}
						chartOnDashboard={true}
						chartType={BUDGET.CPC}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.CPC, formula: CALCULATION_FORMULA.CPC }}
					/>
				);
			case BUDGET.CPM:
				return (
					<Total
						isAmount
						chartId={chartId}
						fetchData={getCPM}
						title={BUDGET.CPM_TITLE}
						chartOnDashboard={true}
						chartType={BUDGET.CPM}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.CPM, formula: CALCULATION_FORMULA.CPM }}
					/>
				);
			case BUDGET.BY_COUNTRY:
				return (
					<ByCountryContainer
						isAmount
						displayBar
						chartId={chartId}
						fetchData={getBudgetsByCountry}
						title={BUDGET.BY_COUNTRY_TITLE}
						chartOnDashboard={true}
						chartType={BUDGET.BY_COUNTRY}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.BUDGET_PER_COUNTRY }}
					/>
				);

			case SALES.TOTAL:
				return (
					<Total
						isAmount
						chartId={chartId}
						fetchData={getSalesTotal}
						title={SALES.TOTAL_TITLE}
						chartOnDashboard={true}
						chartType={SALES.TOTAL}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.SALE_TOTAL }}
					/>
				);
			case SALES.TOTAL_CONVERSIONS:
				return (
					<Total
						chartId={chartId}
						fetchData={getConversionTotal}
						title={SALES.TOTAL_CONVERSIONS_TITLE}
						chartOnDashboard={true}
						chartType={SALES.TOTAL_CONVERSIONS}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.TOTAL_CONVERSIONS }}
					/>
				);
			case SALES.OVERTIME:
				return (
					<OverTime
						chartId={chartId}
						fetchData={getSalesOverTime}
						title={SALES.OVERTIME_TITLE}
						color={colors.chartLineBudget}
						isHoverable={true}
						chartOnDashboard={true}
						chartType={SALES.OVERTIME}
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.SALE_OVERTIME }}
					/>
				);

			case SALES.CONVERSIONS_BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerSalesTotal}
						title={SALES.CONVERSIONS_BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartOnDashboard={true}
						chartType={SALES.CONVERSIONS_BY_INFLUENCER}
						valueType='conversions'
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.CONVERSIONS_BY_INFLUENCER }}
					/>
				);

			case SALES.ORDER_VALUE_BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						isAmount
						chartId={chartId}
						fetchData={getInfluencerSalesTotal}
						title={SALES.ORDER_VALUE_BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartOnDashboard={true}
						chartType={SALES.ORDER_VALUE_BY_INFLUENCER}
						valueType='orderValue'
						chartExplanation={{ explanation: EXPLANATIONS.SALES_AND_BUDGET.ORDER_VALUE_BY_INFLUENCER }}
					/>
				);

			case INFLUENCERS.SUMMARY:
				return (
					<ByInfluencerTable
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={INFLUENCERS.SUMMARY_TITLE}
						chartOnDashboard={true}
						chartType={INFLUENCERS.SUMMARY}
						chartExplanation={{
							explanation: EXPLANATIONS.SUMMARY.INFLUENCER,
							formula: `Reach rate: ${CALCULATION_FORMULA.SUMMARY.REACH_RATE} | Engagement rate: ${CALCULATION_FORMULA.SUMMARY.ACTUAL_ENGAGEMENT_RATE}`,
						}}
					/>
				);

			case PAID_MEDIA.SUMMARY:
				return <PaidMediaTable chartId={chartId} fetchData={noop} title={PAID_MEDIA.SUMMARY_TITLE} chartType={PAID_MEDIA.SUMMARY} chartOnDashboard={true} />;

			case MEDIA_OBJECTS.COUNTRY_BAR:
				return (
					<ByCountryContainer
						chartId={chartId}
						fetchData={getAssignmentsByCountry}
						title={MEDIA_OBJECTS.COUNTRY_TITLE}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.COUNTRY_BAR}
						displayBarTotal
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_COUNTRY }}
					/>
				);
			case MEDIA_OBJECTS.COUNTRY_CIRCLE:
				return (
					<ByCountryContainer
						chartId={chartId}
						fetchData={getAssignmentsByCountry}
						title={MEDIA_OBJECTS.COUNTRY_TITLE}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.COUNTRY_CIRCLE}
						displayCircle
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_COUNTRY }}
					/>
				);
			case MEDIA_OBJECTS.CAMPAIGN_BAR:
				return (
					<ByCampaignContainer
						chartId={chartId}
						fetchData={getAssignmentsByCampaign}
						title={MEDIA_OBJECTS.CAMPAIGN_TITLE}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.CAMPAIGN_BAR}
						displayBar
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_CAMPAIGN }}
					/>
				);
			case MEDIA_OBJECTS.CAMPAIGN_CIRCLE:
				return (
					<ByCampaignContainer
						chartId={chartId}
						fetchData={getAssignmentsByCampaign}
						title={MEDIA_OBJECTS.CAMPAIGN_TITLE}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.CAMPAIGN_CIRCLE}
						displayCircle
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_CAMPAIGN }}
					/>
				);
			case MEDIA_OBJECTS.NETWORK_BAR:
				return (
					<ByAssignmentType
						chartId={chartId}
						fetchData={getAssignmentsByNetwork}
						title={MEDIA_OBJECTS.NETWORK_TITLE}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.NETWORK_BAR}
						displayBar
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_CHANNEL }}
					/>
				);
			case MEDIA_OBJECTS.NETWORK_CIRCLE:
				return (
					<ByAssignmentType
						chartId={chartId}
						fetchData={getAssignmentsByNetwork}
						title={MEDIA_OBJECTS.NETWORK_TITLE}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.NETWORK_CIRCLE}
						displayCircle
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_CHANNEL }}
					/>
				);
			case MEDIA_OBJECTS.BY_INFLUENCER:
				return (
					<ByInfluencerContainer
						chartId={chartId}
						fetchData={getInfluencerTotal}
						title={MEDIA_OBJECTS.BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartOnDashboard={true}
						chartType={MEDIA_OBJECTS.BY_INFLUENCER}
						valueType='assignments'
						chartExplanation={{ explanation: EXPLANATIONS.ACTIVATIONS.BY_INFLUENCER }}
					/>
				);

			default:
				return null;
		}
	};

	return [getChart];
};

export default useGetChart;
