import styled, { css } from 'styled-components';

import { TrackingLinkSectionStyle, ExternalLinkStyle, ButtonsWrapperStyle, GuidelineSectionStyle } from 'components/AffiliateMarketplace/shared.style';
import { InputFieldContainerWithIconV2, InputFieldV2 } from 'styles/formElements/input';
import { InputType } from 'styles/formElements/types';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const linkStyle = css`
	color: ${colors.DetailContent.linkColor};
	cursor: pointer;
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease-in-out 0s;

	&:hover {
		color: ${colors.DetailContent.linkColor};
		border-bottom: 1px solid ${colors.DetailContent.linkColor};
	}
`;

const BrandLinkSection = styled.div`
	border-bottom: 1px solid ${colors.DetailContent.brandLinkBorder};
	margin-bottom: ${spacing[4].px};
`;

const TrackingLinkWrapper = styled.div`
	${TrackingLinkSectionStyle};
	margin-bottom: ${spacing[3].px};
`;

const LinkInfo = styled.div`
	word-wrap: break-word;
	border-bottom: 1px solid ${colors.DetailContent.brandLinkBorder};
	padding-bottom: ${spacing[4].px};

	& > a {
		color: ${colors.DetailContent.infoText} !important;
		border-bottom: none !important;
		font-weight: 500;

		&:hover {
			transition: revert;
			border-bottom: none !important;
		}
	}

	& > span {
		&.scroll-to-generate-link {
			${linkStyle};
		}
	}
`;

const ExternalLink = styled.a`
	${ExternalLinkStyle};
`;

const ExternalLinkText = styled.span`
	word-break: break-all;
`;

const Label = styled.label`
	font-size: 0.875rem;
	font-weight: 400;
	color: ${colors.DetailContent.label};
`;

const GenerateTrackingLinkInputContainer = styled.div<InputType>`
	${InputFieldContainerWithIconV2}
	.icon {
		margin-left: ${spacing[2].px};
	}
`;

const GeneratedTrackingLinkSection = styled.div`
	margin-bottom: ${spacing[4].px};
	${TrackingLinkSectionStyle};
`;

const GenerateTrackingLinkInput = styled.input`
	${InputFieldV2};
	border-left: none;
	font-size: 0.875rem;
	padding: ${spacing[2].rem};
	padding-left: ${spacing[0].rem};

	background-color: ${colors.DetailContent.inputBackground};
`;

const ButtonsWrapper = styled.div`
	${ButtonsWrapperStyle};
	position: relative;

	button:disabled {
		color: ${colors.black1};
	}
`;

const AccordionsWrapper = styled.div`
	${GuidelineSectionStyle};
`;

const AccordionContentWrapper = styled.div`
	line-height: 150%;
	font-size: 0.875rem;
	color: ${colors.gray8};

	& > p {
		font-size: 0.875rem;
	}

	ol,
	ul {
		padding-left: revert;
	}
`;

const ErrorText = styled.div`
	margin-top: ${spacing[0].px};
	p {
		margin-top: 0;
	}
	& > div {
		padding-top: ${spacing[1].rem};

		i {
			display: flex;
		}
	}
`;

const BackgroundSection = styled.div`
	${TrackingLinkSectionStyle};
	& > h6 {
		margin-bottom: ${spacing[3].px};
	}
`;

const BackgroundCard = styled.div`
	padding: ${spacing[2].rem} ${spacing[3].rem};
	background-color: ${colors.AccordionV2.background};
	border-radius: 10px;

	& > div {
		font-size: 0.875rem;
		position: relative;
		&.commission-info {
			& > span {
				font-weight: 600;
			}

			&:after {
				content: ' ';
				height: 1px;
				background-color: ${colors.borderAlmostLightGray};
				position: absolute;
				right: -10px;
				left: -10px;
				bottom: -8px;
			}
		}

		&.campaign-background {
			margin-top: ${spacing[3].px};
		}
	}
`;

const Tooltip = styled.div`
	position: absolute;
	background-color: ${colors.dropdown.tooltip.background};
	box-shadow: 0 3px 6px ${colors.dropdown.tooltip.boxShadowColor};
	border-radius: 4px;
	opacity: 0;
	transition: opacity 0.2s linear;

	white-space: nowrap;

	display: flex;
	justify-content: center;

	top: -50%;
	right: 20%;
	left: 20%;

	&.visible {
		opacity: 1;
	}
`;

const TooltipText = styled.span`
	padding-top: ${spacing[0].rem};
	padding-bottom: ${spacing[0].rem};

	color: ${colors.dropdown.tooltip.color};
	font-size: 0.75rem;
	width: 100%;
	text-align: center;
`;

const ContactWrapper = styled.div`
	text-align: center;

	p {
		font-size: 0.875rem;
	}

	a.contact-link {
		${linkStyle};
	}
`;

const Styled = {
	BrandLinkSection,
	LinkInfo,
	ExternalLink,
	ExternalLinkText,
	TrackingLinkWrapper,
	Label,
	GenerateTrackingLinkInputContainer,
	GenerateTrackingLinkInput,
	ButtonsWrapper,
	GeneratedTrackingLinkSection,
	AccordionsWrapper,
	AccordionContentWrapper,
	ErrorText,
	BackgroundSection,
	BackgroundCard,
	Tooltip,
	TooltipText,
	ContactWrapper,
};

export default Styled;
