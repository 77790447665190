import { JsonApiDocument, Model, Store } from 'json-api-models';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';
import LoadingSpinner from 'components/LoadingSpinner';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import { createClient } from 'shared/ApiClient/ApiClient';
import Styled from 'views/influencer/SignUp/InfluencerSignUpContainer.style';
import AccountCreation from 'views/influencer/SignUp/Steps/AccountCreation';
import LoginStyle from 'views/login/Login.style';

import BillingInformation from './Steps/BillingInformation';
import PersonalInformation from './Steps/PersonalInformation';

type RouteParams = {
	inviteToken: string;
};

/**
 */
const PublisherSignUpContainer = () => {
	const { inviteToken: token } = useParams<RouteParams>();
	const navigate = useNavigate();
	const [step, setStep] = useState(0);
	const [userId, setUserId] = useState<number | string | undefined>();
	const [publisherEdit, setPublisherEdit] = useState<string | undefined>('');
	const [isNewOrganization, setIsNewOrganization] = useState<boolean>(false);
	const incrementStep = () => setStep(step + 1);
	const [isTeamMember, setIsTeamMember] = useState(false);
	const [createAccountText, setCreateAccountText] = useState('Create account');
	const [isIncompletedRegistration, setIsincompletedRegistration] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const user = useAppSelector((state) => state.user);
	const userDispatch = useAppDispatch();
	const logoutService = useInjection<LogoutService>(LogoutService);

	const steps = [
		<AccountCreation
			key={0}
			inviteToken={token}
			onCreate={async (id) => {
				setUserId(id);
				const { data } = await createClient().get<JsonApiDocument>('/me', { params: { includes: 'publisher' } });
				const user = new Store().sync(data) as Model;
				setPublisherEdit(user.publisher.links.edit);
				if (user.publisher.links.edit === undefined) {
					setIsTeamMember(true);
				}
				incrementStep();
			}}
			onCheckToken={(_isNewOrganization: boolean) => setIsNewOrganization(_isNewOrganization)}
		/>,
		<PersonalInformation
			key={1}
			userId={userId!}
			publisherEdit={publisherEdit}
			isNewOrganization={isNewOrganization}
			onSubmit={isTeamMember ? () => navigate('/dashboard') : () => incrementStep()}
			incomplete={isIncompletedRegistration}
		/>,
		<BillingInformation key={2} publisherEdit={publisherEdit} onSubmit={() => navigate('/dashboard')} incomplete={isIncompletedRegistration} />,
	];

	const checkUser = async () => {
		if (user.id) {
			await logoutService.logout(userDispatch);
		}
	};

	useEffect(() => {
		checkUser();

		if (isNil(token)) {
			createClient()
				.get<JsonApiDocument>('/me', { params: { includes: 'publisher' } })
				.then((data) => {
					const user = new Store().sync(data.data) as Model;
					setPublisherEdit(user.publisher.links.edit);
					setIsNewOrganization(user.publisher.links.edit !== undefined);
					if (user.publisher.links.edit === undefined) {
						setIsTeamMember(true);
					}
					setCreateAccountText('Update account');
					setStep(1);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		setIsincompletedRegistration(isNil(token));
	}, [token]);

	return (
		<Styled.Wrapper>
			<Styled.Card>
				<Styled.CardHeader>
					<span className='card-title'>{isIncompletedRegistration ? 'Complete your registration' : 'Sign up'}</span>
				</Styled.CardHeader>
				<Styled.CardBody>
					<Styled.Title>{createAccountText}</Styled.Title>
					{isLoading ? <LoadingSpinner position='center' /> : steps[step]}
				</Styled.CardBody>
			</Styled.Card>
			<LoginStyle.LogotypeContainer>
				<img src={fallbackLogotype} alt='Collabs' />
			</LoginStyle.LogotypeContainer>
		</Styled.Wrapper>
	);
};

export default PublisherSignUpContainer;
