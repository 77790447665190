import Select from 'react-select';
import styled from 'styled-components';

import { Scale } from 'styles/theme/types';
import { scrollbarY } from 'styles/utils/scrollbar';

/**
 * Override react select styles
 */

const SelectMenu = styled(Select)<{ scale: Scale }>`
	font-size: ${({ theme, scale }) => {
		switch (scale) {
			case 'small':
				return theme.fontSizes.smallInput;
			case 'large':
				return theme.fontSizes.largeInput;
			default:
				return theme.fontSizes.input;
		}
	}};
	color: ${({ theme }) => theme.colors.text};

	.select-field {
		&__placeholder {
			color: ${({ theme }) => theme.colors.placeholder};
		}

		&__indicator-separator {
			display: none;
		}

		&__dropdown-indicator {
			padding: 0 !important;

			.icon {
				line-height: 0;
				svg {
					width: 24px;
					height: 24px;
				}
				path {
					fill: #${({ theme }) => theme.colors.text};
				}
			}
		}

		&__menu {
			margin-top: 0;
			border-radius: 0;
			box-shadow: ${({ theme }) => theme.shadows.small};
		}

		&__menu-list {
			${scrollbarY};
		}

		&__control {
			cursor: pointer;
			border-radius: ${({ theme }) => theme.radius.small};
			padding: ${({ scale }) => {
				switch (scale) {
					case 'small':
						return '0.4375rem 0.5rem';
					case 'large':
						return '1rem';
					default:
						return '0.55rem 0.625rem';
				}
			}};
			box-shadow: none;
			transition:
				box-shadow 0.2s ease-in-out,
				border-color 0.2s ease-in-out;
			background-color: ${({ theme }) => theme.colors.inputs};
			border: 1px solid ${({ theme }) => theme.borders.secondary};
			height: ${({ scale }) => {
				switch (scale) {
					case 'small':
						return '2.375rem';
					case 'large':
						return '3.75rem';
					default:
						return '2.75rem';
				}
			}};

			&--menu-is-open,
			&:hover {
				border-color: ${({ theme }) => theme.borders.hover};
			}

			&--is-focused {
				box-shadow: none;
				outline: none;
				border-color: ${({ theme }) => theme.borders.focus};
			}
		}

		&__input-container {
			margin: 0;
			padding-top: 0.375rem;
			padding-bottom: 0.0625rem;
		}

		&__value-container {
			padding-left: 0;
			margin: 0;

			> div {
				overflow: visible;
			}

			input {
				line-height: 1.5;
			}
		}

		&__option {
			cursor: pointer;
			transition: background-color 0.1s ease-in-out;

			&--is-focused,
			&--is-selected,
			&:active {
				background-color: ${({ theme }) => theme.select.optionBackground};
			}

			&--is-selected {
				color: ${({ theme }) => theme.colors.text};
			}
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectField = (props: any) => <SelectMenu classNamePrefix='select-field' {...props} />;
