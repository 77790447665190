import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const cm = colors.contentManagement;

const NameContainer = styled.div`
	display: block;
	flex: 1;
	max-width: 95%;
	align-items: center;
	padding: 0.5313rem 0.25rem;
	font-weight: 600;
	line-height: 1.5;
	transition:
		border-color 200ms ease-in-out,
		border-color 200ms ease-in-out;
`;

const Name = styled.div`
	display: flex;
	flex-direction: column;
	height: 42px;

	> span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.875rem;
		max-width: 90%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.m};
	width: 100%;
	padding: 0 ${guttersWithRem.xl};
	cursor: pointer;
	color: ${colors.black1};
	border-radius: ${borderRadius.s};
	transition:
		background-color 200ms ease-in-out,
		border-color 200ms ease-in-out,
		color 200ms ease-in-out;

	.icon {
		line-height: 0;
		height: 27px;
		width: 24px;

		path {
			transition: fill 200ms ease-in-out;
		}
	}

	&.selected,
	&:hover {
		background-color: ${cm.campaignList.listItem.hover.backgroundColor};
		border-color: ${cm.campaignList.listItem.hover.borderColor};
		color: ${cm.campaignList.listItem.hover.color};

		.icon path {
			fill: ${cm.assignmentTypeList.listItem.hover.color};
		}

		& > div:nth-child(2) {
			border-bottom-color: transparent;
		}
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	& > figure {
		margin: 0;
	}
`;

const ListItem = styled.li`
	display: flex;
	height: 64px;
	border-radius: ${borderRadius.s};

	&.disabled {
		opacity: 0.5;

		${Wrapper} {
			cursor: default;

			&.selected,
			&:hover {
				background-color: transparent;
				color: inherit;

				.icon path {
					fill: ${colors.black1};
				}

				& > div:nth-child(2) {
					border-bottom-color: ${cm.assignmentTypeList.listItem.borderColor};
				}
			}
		}
	}
`;

const ReviewStatusLabel = styled.span`
	font-weight: 400;
`;

const StatusText = styled.span`
	display: flex;
`;

const AssignmentTypeLabel = styled.span`
	font-weight: 400;
	margin-left: auto;
`;

const Styled = {
	ListItem,
	Wrapper,
	ImageWrapper,
	NameContainer,
	Name,
	ReviewStatusLabel,
	AssignmentTypeLabel,
	StatusText,
};

export default Styled;
