import Styled from './Intro.style';

/**
 */
const Intro = (props: { id: string; isAffiliate: boolean }) => {
	return (
		<Styled.Wrapper id={props.id}>
			<Styled.IntroTextWrapper>
				<Styled.WelcomeText>
					<h1>Hi, there! 👋</h1>
					{props.isAffiliate ? (
						<p>
							We&apos;re very excited you came here. You&apos;ve been invited to our affiliate program. This is a campaign where you earn money based on your
							sales. Join now and grab your unique adlinks and track your sales commission
						</p>
					) : (
						<p>
							We&apos;re very excited you came here. You&apos;ve been invited to our campaign as we&apos;d like to collaborate with you. Join now before all
							spots are taken. It&apos;s first come first served.
						</p>
					)}
				</Styled.WelcomeText>
			</Styled.IntroTextWrapper>
		</Styled.Wrapper>
	);
};

export default Intro;
