import { ByAgeContainer, ByCountryContainer, ByGender } from 'components/DataLibrary/Graphs/GraphComponents';
import { AUDIENCE } from 'constants/data-library';

/**
 * @returns object
 */
const AudienceGraphs = (): object => {
	const graphItems = {
		category: 'Audience',
		items: [
			{
				title: AUDIENCE.AGE_TITLE,
				icon: AUDIENCE.AGE_ICON,
				id: AUDIENCE.AGE,
				component: <ByAgeContainer displayBar title={AUDIENCE.AGE_TITLE} chartType={AUDIENCE.AGE} />,
			},
			{
				title: AUDIENCE.COUNTRY_TITLE,
				icon: AUDIENCE.COUNTRY_ICON,
				id: AUDIENCE.COUNTRY,
				component: <ByCountryContainer displayBar title={AUDIENCE.COUNTRY_TITLE} chartType={AUDIENCE.COUNTRY} />,
			},
			{
				title: AUDIENCE.GENDER_TITLE,
				icon: AUDIENCE.GENDER_ICON,
				id: AUDIENCE.GENDER,
				component: <ByGender title={AUDIENCE.GENDER_TITLE} chartType={AUDIENCE.GENDER} />,
			},
		],
	};

	return graphItems;
};
export default AudienceGraphs;
