import { isNil } from 'lodash';
import { Navigate, useParams } from 'react-router-dom';

import { FEATURE_FLAG_AFFILIATE_MARKETPLACE } from 'constants/feature-flag-keys';
import { CREATE_AFFILIATE_PROGRAM } from 'constants/hateoas-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import usePermissions from 'hooks/usePermissions';

import AMCampaignCreateContainer from './AMCampaignCreateContainer';
import AMCampaignEditContainer from './AMCampaignEditContainer';
import Styled from './AffiliateMarketplaceCampaignFormContainer.style';

/**
 * @returns {JSX.Element}
 */
const AffiliateMarketplaceCampaignFormContainer = () => {
	const { userCan } = usePermissions();
	const { programId } = useParams();

	const [isEnabled] = useFeatureToggle();

	if (!isEnabled(FEATURE_FLAG_AFFILIATE_MARKETPLACE)) {
		return <Navigate to='/campaigns/create' />;
	}

	if (!userCan(CREATE_AFFILIATE_PROGRAM)) {
		return <Navigate to='/dashboard' />;
	}

	return <Styled.Wrapper>{isNil(programId) ? <AMCampaignCreateContainer /> : <AMCampaignEditContainer />}</Styled.Wrapper>;
};

export default AffiliateMarketplaceCampaignFormContainer;
