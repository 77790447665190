import styled from 'styled-components';

import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const LeftContainer = styled.div`
	display: none;

	@media screen and (${devices.lg}) {
		position: sticky;
		top: 60px;
		height: 75vh;
	}
`;

const MainContainer = styled.div``;

const MainContainerInner = styled.div``;

const AffiliateMarketplace = styled.div`
	padding-bottom: ${spacing[4].rem};

	@media screen and (${devices.lg}) {
		margin: 0 ${spacing[2].px};
	}

	&.side-open {
		@media screen and (${devices.md}) {
			${MainContainerInner} {
				padding-right: ${spacing[4].rem};
			}
		}

		@media screen and (${devices.lg}) {
			display: grid;
			margin: 0;
			column-gap: 32px;
			grid-template-columns: 316px auto;
		}

		${LeftContainer} {
			display: block;
			grid-column: 1;
		}

		${MainContainer} {
			grid-column: 2;
		}
	}
`;

const Styled = {
	AffiliateMarketplace,
	LeftContainer,
	MainContainer,
	MainContainerInner,
};

export default Styled;
