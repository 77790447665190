import { Store, JsonApiDocument } from 'json-api-models';
import { useState, useEffect } from 'react';

import { convertDateFormat } from 'components/Discovery/Components/InfluencerDetailCard/types';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modals/Modal';
import { ICollabsResponse } from 'services/Response.types';

import Styled from './WhyDoIGetThisResultModal.style';

type Post = {
	date: string;
	id: string;
	score: number;
	url: string;
};

/**
 */
const WhyDoIGetThisResultModal = (props: { isOpen: boolean; onClose: () => void; isLoading: boolean; reasons: ICollabsResponse | null }) => {
	const [posts, setPosts] = useState<Post[]>([]);
	const reasonStore = new Store();

	useEffect(() => {
		if (props.reasons) {
			reasonStore.sync(props.reasons as JsonApiDocument);
			const explainData = reasonStore.findAll('explainResult').length > 0 ? reasonStore.findAll('explainResult')[0] : null;
			if (explainData !== null) {
				setPosts(explainData.posts);
			}
		}
	}, [props.reasons]);

	return (
		<Modal open={props.isOpen} size='xs' handleClose={props.onClose}>
			<Modal.Header>
				<Styled.ModalHeader>
					<h2>Why do I get this result?</h2>
					<button onClick={props.onClose}>
						<Icon name='cross' size='16' />
					</button>
				</Styled.ModalHeader>
			</Modal.Header>
			<Modal.Body>
				{props.isLoading ? (
					<LoadingSpinner position='center' size='lg' />
				) : (
					<Styled.ContentWrapper>
						{posts.length === 0 ? (
							<div>
								Failed to fetch posts.
								<br />
								They might have been a temporary issues or the posts are deleted.
							</div>
						) : (
							<>
								{posts.length > 0 ? (
									<Styled.Reason>
										<span>The following {posts.length > 1 ? 'posts have' : 'post has'} contributed to this result.</span>
										<Styled.PostItemWrapper>
											{posts.map((post) => {
												return (
													<Styled.PostItem key={post.id}>
														<Styled.DateInfo>{`- Posted at: ${convertDateFormat(post.date)}`}</Styled.DateInfo>
														<Styled.LinkToPost href={post.url} target='_blank'>
															View post
														</Styled.LinkToPost>
													</Styled.PostItem>
												);
											})}
										</Styled.PostItemWrapper>
									</Styled.Reason>
								) : null}
							</>
						)}
					</Styled.ContentWrapper>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default WhyDoIGetThisResultModal;
