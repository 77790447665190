import { ClickDailyQuery, ClickTotalQuery, ReachTotalQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { OverTimeOutput } from 'components/DataLibrary/Graphs/GraphComponents/OverTime/types';
import { TotalOutput } from 'components/DataLibrary/Graphs/GraphComponents/Total/types';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getTrafficLinkTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ClickTotalQuery>(filter);
	const { result, error } = manager.showClickTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	return {
		result: {
			value: result.attributes.count,
			currency: undefined,
		},
	};
};

export const getClicksOverTime = (manager: StatisticsManager, filter: DashboardFilters): { result: OverTimeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ClickDailyQuery>(filter);
	const { result, error } = manager.listClickDaily(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = result.map((node) => {
		return {
			date: node.attributes.date,
			count: node.attributes.count,
		};
	});

	return { result: output };
};

export const getTrafficCTRTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qbReach = convertFilterToQueryBuilder<ReachTotalQuery>(filter);
	const { result: resultReach, error: errorReach } = manager.showReachTotal(qbReach);

	const qbClick = convertFilterToQueryBuilder<ClickTotalQuery>(filter);
	const { result: resultClick, error: errorClick } = manager.showClickTotal(qbClick);

	if (!resultClick || !resultReach || errorReach || errorClick) {
		return { result: undefined, error: errorReach ?? errorClick };
	}

	let output;

	if (resultClick.attributes.count > 0 && resultReach.attributes.count > 0) {
		// TODO verify if this is correct, it changed a lot..
		output = { value: (100 * resultClick.attributes.count) / resultReach.attributes.count, currency: undefined };
	} else {
		output = { value: 0, currency: undefined };
	}

	return { result: output, error: undefined };
};
