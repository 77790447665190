import classNames from 'classnames';
import { Store } from 'json-api-models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { Icon, LoadingSpinner, LoginAsButton } from 'components';
import InfluencerAvatar from 'components/InfluencerAvatar';
import Table, { Column } from 'components/Table/V2/Table';
import { Network } from 'constants/socialMedia';
import useInjection from 'hooks/useInjection';
import { UserPaymentType } from 'reducers/UserReducers/types';
import GenericApiClientInterface from 'services/ApiClient/GenericApiClientInterface';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import toast from 'services/Toast';
import UserService from 'services/User/User.service';
import { InfluencerUser, UserInfluencerProfile } from 'shared/Types/User';
import Styled from 'views/admin/Components/Table.style';
import { getPinebucketStatus } from 'views/admin/hooks';
const userIncludes = `publisherPrivileges,publisherPrivileges.publisher,clientPrivileges,clientPrivileges.client,brandPrivileges,brandPrivileges.brand,influencers:profilePictureUrl`;

/**
 * @returns {JSX.Element}
 */
const FailedVatValidationTable = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);

	const [influencers, setInfluencers] = useState<InfluencerUser[]>([]);
	const [hasError, setHasError] = useState<boolean>(false);
	const [editField, setEditField] = useState<InfluencerUser>();
	const [editFieldValue, setEditFieldValue] = useState<string>('');

	const client = useInjection<GenericApiClientInterface>(ApiClientService.GenericApiClientInterface);

	const onClickSave = (item: InfluencerUser) => {
		setIsLoading(true);
		const url = item.links.edit;
		client
			.patch(url, { vatNumber: editFieldValue })
			.then(() => {
				setEditField(undefined);
				getInfluencers();
				toast.success('The value has been successfully updated');
			})
			.finally(() => {
				setIsLoading(false);
			})
			.catch((e: Error) => {
				console.error(e);
				setHasError(true);
			});
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, item: InfluencerUser) => {
		if (e.key === 'Enter' && !isLoading) {
			onClickSave(item);
		}
	};

	const onClickCancel = () => {
		setEditField(undefined);
		setEditFieldValue('');
		setHasError(false);
	};

	const columns: Column<InfluencerUser>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'instagramUsername',
				Cell: ({ row }) =>
					row.original.influencers?.map((influencer: UserInfluencerProfile, index) => (
						<Styled.AvatarWrapper key={index}>
							<InfluencerAvatar
								userName={influencer.username}
								network={influencer.network as Network}
								profileImageUrl={influencer.links.profilePictureUrl ?? ''}
								displayNetwork
							/>
							<Styled.Title key={influencer.id} className={classNames({ disabled: row.original.disabledAt })}>
								{influencer.username}
								{row.original.disabledAt && <div>Disabled at {moment(row.original.disabledAt).format('YYYY-MM-DD')}</div>}
							</Styled.Title>
						</Styled.AvatarWrapper>
					)),
			},
			{
				Header: 'Payment type',
				accessor: 'kind',
				width: 50,
			},
			{
				Header: 'Payout status',
				accessor: 'pinebucketStatus',
				Cell: ({ row }) => getPinebucketStatus(row.original.pinebucketStatus),
			},
			{
				Header: 'VAT number',
				accessor: 'vatNumber',
				width: 350,
				Cell: ({ row }) =>
					editField === row.original ? (
						<Styled.InputContainer>
							<Styled.Input
								disabled={isLoading}
								autoFocus
								className={classNames({ error: hasError })}
								name='vatNumber'
								value={editFieldValue || row.original.vatNumber?.toString() || ''}
								onChange={(e) => setEditFieldValue(e.target.value)}
								onKeyDown={(e) => onKeyDown(e, row.original)}
							/>
							<div role='button' onClick={() => onClickSave(row.original)}>
								{!isLoading ? <Icon name='checkmark' size='12' /> : <LoadingSpinner size='sm' />}
							</div>
							<div role='button' onClick={onClickCancel}>
								<Icon name='cross' size='12' />
							</div>
						</Styled.InputContainer>
					) : (
						<Styled.VatWrapper>
							<div>{row.original.vatNumber}</div>
							<Styled.EditButton title={`Edit`} onClick={() => setEditField(row.original)}>
								Edit
							</Styled.EditButton>
						</Styled.VatWrapper>
					),
			},
			{
				Header: 'Valid',
				accessor: 'vatNumberValid',
				width: 50,
				Cell: () => 'False',
			},
			{
				Header: 'VAT validation failed',
				accessor: 'vatNumberValidatedAt',
				Cell: ({ row }) => (row.original.vatNumberValidatedAt ? moment(row.original.vatNumberValidatedAt).format('YYYY-MM-DD') : 'Not validated'),
			},
			{
				Header: '',
				accessor: 'id',
				width: 50,
				disableSortBy: true,
				Cell: ({ row }) => <LoginAsButton uuid={row.original.uuid} />,
			},
		],
		[editField, editFieldValue, influencers],
	);

	const getInfluencers = () => {
		setIsLoading(true);
		UserService.fetchAllUsers(userIncludes)
			.then((res) => {
				setIsLoading(false);
				const models = new Store();
				models.sync(res.data);
				const users = models.findAll('user');
				const influencerUsers = users.filter(
					(user: InfluencerUser) =>
						user.influencers.length > 0 &&
						(user.kind === UserPaymentType.COMPANY || user.kind === UserPaymentType.AGENT || user.kind === UserPaymentType.LEGACY_AGENT) &&
						!user.vatNumberValid,
				);
				setInfluencers(influencerUsers);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getInfluencers();

		return () => {
			setEditField(undefined);
			setEditFieldValue('');
			setHasError(false);
			setIsLoading(false);
		};
	}, []);

	return (
		<>
			<h4>{isLoading ? <LoadingSpinner size='sm' /> : `${influencers?.length} influencers with invalid or missing VAT number`} </h4>
			<Table columns={columns} data={influencers} enableSorting showSearch />
		</>
	);
};
export default FailedVatValidationTable;
