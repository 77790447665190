import styled from 'styled-components';

import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	width: 100%;
	height: 100px;
`;

const InfluencerWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};

	& > figure {
		margin-left: 0;
	}

	& > div > div {
		&.name {
			font-weight: 700;
		}
	}
`;

const ProfileFollower = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};

	font-size: 0.9375rem;

	cursor: pointer;

	& > i {
		display: flex;
	}
	a {
		text-decoration: none;
	}
`;

const Styled = {
	Wrapper,
	InfluencerWrapper,
	ProfileFollower,
};

export default Styled;
