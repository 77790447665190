import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useState, useEffect, useContext } from 'react';

import Checkbox from 'components/Checkbox';
import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import IconButton from 'components/Discovery/Components/IconButton';
import InfluencerDetailCard from 'components/Discovery/Components/InfluencerDetailCard';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { DELETE_INFLUENCER_FROM_LIST } from 'constants/hateoas-keys';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerExtraData, InfluencerListItemType } from 'contexts/Discovery/types';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { formatNumberWithDecimals, formatNumber } from 'shared/helpers/Chart/chart-util';
import { guttersWithRem } from 'styles/variables/gutter';

import InfluencerListItemComments from './Components/Comments/InfluencerListItemComments';
import InfluencerListItemReactions from './Components/Comments/InfluencerListItemReactions';
import Styled from './InfluencerListItem.style';

const defaultData = {
	id: '',
	age: '',
	country: '',
	followersCount: 0,
	gender: '',
	network: '',
	networkId: '',
	networkLinks: {
		instagram: '',
		tiktok: '',
	},
	profileImageUrl: '',
	username: '',
	audienceBrief: {
		age: { name: null, count: null, ratio: null },
		country: { name: null, count: null, ratio: null },
		gender: { name: null, count: null, ratio: null },
	},
	audienceDetail: {
		ages: {
			'13-17': { count: 0, ratio: 0 },
			'18-24': { count: 0, ratio: 0 },
			'25-34': { count: 0, ratio: 0 },
			'35-44': { count: 0, ratio: 0 },
			'45-54': { count: 0, ratio: 0 },
			'55-64': { count: 0, ratio: 0 },
			'65+': { count: 0, ratio: 0 },
		},
		countries: {},
		genders: {
			female: { count: 0, ratio: 0 },
			male: { count: 0, ratio: 0 },
		},
	},
	brandAffiliations: [],
	categories: [],
	averageViews: 0,
	relevancy: 0,
	engagement: 0,
	engagementOrganic: 0,
	engagementPaid: 0,
	paidPostsRatio: 0,
	estimatedStoryViews: 0,
	related: [],
	canBeContacted: true,
};

interface InfluencerListItemProps {
	onSelect: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	isSelected: boolean;
	isList?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onFetchListInflencerExtra?: (influencerId: string) => Promise<any>;
	onDeleteFromList?: () => void;
	listItem?: Model;
	nextPageRef?: (node: HTMLElement | null) => void;
	onOpenMessageModal?: (influencer: InfluencerListItemType) => void;
	isOdd?: boolean;
	whyDoIGetLink?: string;
}

/**
 */
const InfluencerListItem = ({
	onSelect,
	data,
	isSelected,
	isList = false,
	onFetchListInflencerExtra,
	onDeleteFromList,
	listItem,
	nextPageRef,
	onOpenMessageModal,
	isOdd,
	whyDoIGetLink,
}: InfluencerListItemProps) => {
	const { getOneInfluencerExtraData, openMessageModal } = useContext(DiscoveryContext);
	const [influencerData, setInfluencerData] = useState<InfluencerListItemType>(defaultData);
	const [influencerExtraData, setInfluencerExtraData] = useState<InfluencerExtraData | null>(null);
	const [showDetail, setShowDetail] = useState(false);

	const { userCan } = useFeaturePermissions({ ...(isList ? data.listItem.links : {}), ...data.links });

	const getInfluencerDetail = (id: string) => {
		getOneInfluencerExtraData(id).then((res) => {
			setInfluencerData(res);
		});
	};

	const getNameAndRatio = (param: { name?: string | null; count: number | null; ratio: number | null }) => {
		if (param.name !== null && param.ratio !== null) {
			return (
				<>
					{param.name!.toLocaleUpperCase()}
					<Styled.GrayText>{`(${Math.floor(param.ratio * 100)}%)`}</Styled.GrayText>
				</>
			);
		}
	};

	const fetchInfluencerExtraDataHandler = () => {
		if (!showDetail) {
			if (onFetchListInflencerExtra) {
				onFetchListInflencerExtra(data.id as string).then((res) => {
					if (res) {
						setInfluencerExtraData(res);
					} else {
						setInfluencerExtraData(null);
					}
				});
			}
		}
		setShowDetail((prev) => !prev);
	};

	const renderGoToNetwork = () => {
		const { network, networkLinks } = influencerData;
		const networkUrl = networkLinks[network as keyof typeof networkLinks];
		if (!networkUrl) return null;

		return (
			<Styled.LinkToNetwork href={networkUrl} target='_blank'>
				<Styled.CustomIcon name={network} size='14' className='network-icon' />
				{`Go to ${network}`}
			</Styled.LinkToNetwork>
		);
	};

	useEffect(() => {
		if (influencerExtraData) {
			setInfluencerData((prev) => ({ ...prev, ...influencerExtraData }));
		}
	}, [influencerExtraData]);

	useEffect(() => {
		if (data) {
			setInfluencerData((prev) => ({ ...prev, ...data }));
		}
	}, [data]);

	return (
		<>
			<Styled.Wrapper
				ref={nextPageRef}
				className={classNames({ odd: isOdd && !showDetail, isSelected: isSelected })}
				onClick={
					isList
						? fetchInfluencerExtraDataHandler
						: () => {
								getInfluencerDetail(data.id);
								setShowDetail((prev) => !prev);
							}
				}
			>
				<Styled.Td>
					<Styled.Div display='flex'>
						<Checkbox
							checked={isSelected}
							onChange={(e) => {
								e.stopPropagation();
								onSelect();
							}}
						/>
					</Styled.Div>
				</Styled.Td>
				<Styled.Td maxWidth='250px'>
					<Styled.Div display='flex' alignItems='center' columnGap={guttersWithRem.m} overflowX='auto' overflowY='hidden'>
						<span>
							<InfluencerAvatar
								userName={influencerData.username}
								network={influencerData.network as Network}
								displayNetwork
								profileImageUrl={data.profileImageUrl ?? ''}
							/>
						</span>
						<Styled.InfluencerNameWrapper>
							<Styled.InfluencerName>{influencerData.username}</Styled.InfluencerName>
							<Styled.InfluencerSubTextWrapper>
								<Styled.InfluencerSubText className='hover-link'>{renderGoToNetwork()}</Styled.InfluencerSubText>
								<Styled.InfluencerSubText className='follower'>
									<div>{influencerData.followersCount && formatNumber(influencerData.followersCount)} Followers</div>
								</Styled.InfluencerSubText>
							</Styled.InfluencerSubTextWrapper>
						</Styled.InfluencerNameWrapper>
					</Styled.Div>
				</Styled.Td>
				<Styled.Td>
					<Styled.Data>{influencerData.engagement !== null ? formatNumberWithDecimals(influencerData.engagement, '%') : '-'}</Styled.Data>
				</Styled.Td>
				<Styled.Td>{influencerData.audienceBrief.age.name ? <Styled.Data>{getNameAndRatio(influencerData.audienceBrief.age)}</Styled.Data> : `-`}</Styled.Td>
				<Styled.Td maxWidth='100px'>
					{influencerData.audienceBrief.country.name ? <Styled.Data>{getNameAndRatio(influencerData.audienceBrief.country)}</Styled.Data> : `-`}
				</Styled.Td>
				<Styled.Td>
					{influencerData.audienceBrief.gender.name ? <Styled.Data>{getNameAndRatio(influencerData.audienceBrief.gender)}</Styled.Data> : `-`}
				</Styled.Td>
				<Styled.Td text-align='right'>
					<Styled.Div display='flex' columnGap='1rem' justifyContent='flex-end'>
						{isList && listItem && (
							<>
								<InfluencerListItemComments listItem={listItem} />
								<InfluencerListItemReactions listItem={listItem} />
							</>
						)}
						<IconButton>
							<Styled.IconContainer className='dropdown' data-testid='add-to-icon'>
								<DiscoveryDropdown helpText='Add to list/campaign' selectedItems={[influencerData.id]} />
							</Styled.IconContainer>
						</IconButton>

						<IconButton
							className='influencer-list-item'
							iconName='private-message'
							iconSize='20'
							onClick={
								isList
									? (e) => {
											e.stopPropagation();
											if (onOpenMessageModal) {
												onOpenMessageModal(influencerData);
											}
										}
									: (e) => {
											e.stopPropagation();
											openMessageModal(influencerData);
										}
							}
							helpText='Private message'
						/>

						{isList && userCan(DELETE_INFLUENCER_FROM_LIST) && (
							<IconButton
								className='influencer-list-item'
								iconName='trash-bin'
								iconSize='20'
								onClick={(e) => {
									e.stopPropagation(), onDeleteFromList && onDeleteFromList();
								}}
								testId={`list-influencer-delete-${data.id}`}
								helpText='Remove'
							/>
						)}
					</Styled.Div>
				</Styled.Td>
			</Styled.Wrapper>
			{showDetail && (
				<Styled.ExpendWrapper>
					<Styled.Td colSpan={100}>
						<Styled.ExpendInnrWrapper>
							<InfluencerDetailCard
								whyDoIGetLink={whyDoIGetLink}
								influencer={influencerData}
								onFetchInfluencerData={(id: string) => {
									getInfluencerDetail(id);
								}}
								onSelect={() => onSelect()}
								isSelected={isSelected}
								isList={isList ? isList : false}
							/>
						</Styled.ExpendInnrWrapper>
					</Styled.Td>
				</Styled.ExpendWrapper>
			)}
		</>
	);
};

export default InfluencerListItem;
