import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';

const tagPadding = `0.4rem 0.3rem 0.3rem`;
const Wrapper = styled.div`
	padding: ${tagPadding};
	line-height: 1.1;

	border-radius: 4px;
	font-weight: 600;

	border: 1px solid;

	border-color: ${colors.influencerDashboard.todo.tag.pending.color};
	color: ${colors.influencerDashboard.todo.tag.pending.color};
	background-color: ${colors.influencerDashboard.todo.tag.pending.background};

	&.late {
		border-color: ${colors.influencerDashboard.todo.tag.late.color};
		color: ${colors.influencerDashboard.todo.tag.late.color};
		background-color: ${colors.influencerDashboard.todo.tag.late.background};
	}

	&.upcoming {
		border-color: ${colors.influencerDashboard.todo.tag.upcoming.color};
		color: ${colors.influencerDashboard.todo.tag.upcoming.color};
		background-color: ${colors.influencerDashboard.todo.tag.upcoming.background};
	}

	&.pending {
		border-color: ${colors.influencerDashboard.todo.tag.pending.color};
		color: ${colors.influencerDashboard.todo.tag.pending.color};
		background-color: ${colors.influencerDashboard.todo.tag.pending.background};
	}

	&.publish {
		border-color: ${colors.influencerDashboard.todo.tag.publish.color};
		color: ${colors.influencerDashboard.todo.tag.publish.color};
		background-color: ${colors.influencerDashboard.todo.tag.publish.background};
	}

	&.done {
		border-color: ${colors.influencerDashboard.todo.tag.done.color};
		color: ${colors.influencerDashboard.todo.tag.done.color};
		background-color: ${colors.influencerDashboard.todo.tag.done.background};
	}

	@media (max-width: ${breakpoints.sm}) {
		max-width: 70px;
		padding-right: ${guttersWithRem.xxs};
		padding-left: ${guttersWithRem.xxs};
		font-size: 0.75rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
