import styled from 'styled-components';

import colors from 'styles/theme/colors';

import { IAvatarProps } from './types';

const getSize = (size?: string) => {
	switch (size) {
		case 'sm':
			return '24px';
		case 'md':
			return '40px';
		case 'lg':
			return '64px';
		default:
			return '40px';
	}
};

const getFontSize = (size?: string) => {
	switch (size) {
		case 'sm':
			return '0.75rem';
		case 'md':
			return '1rem';
		case 'lg':
			return '1rem';
		default:
			return '1rem';
	}
};

const getPadding = (size?: string) => {
	switch (size) {
		case 'sm':
			return '0.188rem 0.063rem 0.25rem';
		case 'md':
			return '0.75rem 0.563rem';
		case 'lg':
			return '0.75rem 0.563rem';
		default:
			return '0.75rem 0.563rem';
	}
};

const AvatarFigure = styled.figure<IAvatarProps>`
	display: flex;
	width: ${(props) => getSize(props.size)};
	height: ${(props) => getSize(props.size)};
	min-width: ${(props) => getSize(props.size)};
	border-radius: 100px;
	overflow: hidden;
	margin: 0;
	margin: 0 0 0 11px;
	border: 2px solid ${colors.mist};
	& img {
		object-fit: cover;
	}
`;

const FallbackAvatar = styled.figure<IAvatarProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: ${(props) => getSize(props.size)};
	min-width: ${(props) => getSize(props.size)};
	height: ${(props) => getSize(props.size)};
	margin: 0;
	padding: ${(props) => getPadding(props.size)};
	background-color: ${(props) => props.backgroundColor || colors.mist};
	border-radius: 50px;
	overflow: hidden;

	> figcaption {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		font-size: ${(props) => getFontSize(props.size)};
		font-weight: 700;
		text-align: center;
		margin-top: 0.094rem;
		color: ${colors.black};
		text-transform: uppercase;
	}
`;

const Styled = {
	AvatarFigure,
	FallbackAvatar,
};

export default Styled;
