import classNames from 'classnames';
import React, { forwardRef, memo } from 'react';

import { DarkButton } from 'components/Button';

import Styled from './AffiliateCard.style';

export type AffiliateCardProps = React.AllHTMLAttributes<HTMLDivElement> & {
	id: string;
	heading: string;
	imageUrl?: string;
	compensationValue?: number;
	disabled?: boolean;
	onSelectCampaign: () => void;
};

type Detail = {
	unit: string;
	label: string;
	value: number;
};

/**
 * AffiliateCard
 * Card for Affiliate campaigns and products
 * @param {AffiliateCardProps} props
 * @returns {JSX.Element}
 */
const AffiliateCard = forwardRef<HTMLDivElement, AffiliateCardProps>(
	({ heading, imageUrl = '', compensationValue, disabled, onSelectCampaign, tabIndex, id }, ref): JSX.Element => {
		const getDetails = (): Detail | undefined => {
			return { unit: '%', label: 'Commission', value: compensationValue ?? 0 };
		};

		const percentageText = getDetails();

		const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				event.stopPropagation();
				onSelectCampaign && onSelectCampaign();
			}
		};

		return (
			<Styled.AffiliateCard
				id={id}
				aria-label={heading}
				className={classNames('affiliate-card', { disabled: disabled || onSelectCampaign === undefined })}
				onKeyDown={handleKeyDown}
				onClick={(e) => {
					e.stopPropagation();
					onSelectCampaign && onSelectCampaign();
				}}
				data-testid='affiliate-card'
				tabIndex={tabIndex}
				ref={ref}
			>
				<Styled.Body>
					<Styled.ImageWrapper>
						<picture>
							<img src={imageUrl} alt={heading} />
						</picture>
					</Styled.ImageWrapper>
					<Styled.BodyInner>
						<Styled.NameWrapper>
							<span className='text'>{heading}</span>
						</Styled.NameWrapper>
						<Styled.ButtonWrapper>
							<DarkButton size='sm' tabIndex={-1}>
								Create link
							</DarkButton>
						</Styled.ButtonWrapper>
					</Styled.BodyInner>
				</Styled.Body>
				<Styled.DetailsWrapper>
					{percentageText && (
						<Styled.PercentageText>
							<span className='percentage-text__label'>{percentageText.label}:</span>{' '}
							<span className='percentage-text__value'>
								{percentageText.value}
								{percentageText.unit}
							</span>
						</Styled.PercentageText>
					)}
				</Styled.DetailsWrapper>
			</Styled.AffiliateCard>
		);
	},
);

export default memo(AffiliateCard);
