import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import { CampaignItemType } from 'components/ContentManagement/types';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getErrorMessageOnFetch, getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';
import { Commission } from 'shared/Types/Commission';
import { PALETTE } from 'styles/variables/original-colors';
import { formatAmount } from 'utils/formatters';

import AssignmentList from './Components/AssignmentList';
import ConfirmMessage from './Components/ConfirmMessage';
import Styled from './Summary.style';
import useSummaryData from './hooks';

type SummaryProps = {
	activeCampaign: CampaignItemType | undefined;
	assignments?: Model;
	selectedCIO?: Model;
	CIOAS?: Model[];
	refresh: () => Promise<void>;
};

/**
 * Summary
 * Component to show the summary of the selected campaign
 * Managers are able to approve/decline assignments with this component.
 * @param {SummaryProps} props
 * @returns {JSX.Element}
 */
const Summary = ({ activeCampaign, selectedCIO, CIOAS, refresh }: SummaryProps): JSX.Element => {
	const [commission, setCommission] = useState<Commission | null>(null);
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [approveState, setApproveState] = useState<'approve' | 'disapprove'>('approve');
	const [postUrl, setPostUrl] = useState<string>('');
	const [assignmentList, setAssignmentList] = useState<Array<Model>>(selectedCIO?.campaignInstagramOwnerAssignments);

	const campaignInstagramOwner = activeCampaign && activeCampaign.campaignInstagramOwners.find((x) => x.id === selectedCIO?.id);

	const { approve, disapprove } = useSummaryData();

	const { isBrandManager } = usePermissions();

	const getTotalCommission = () => {
		if (campaignInstagramOwner) {
			return campaignInstagramOwner.campaignInstagramOwnerCommissions.length > 0
				? campaignInstagramOwner.campaignInstagramOwnerCommissions[0].commission
				: null;
		}
		return null;
	};

	const openModal = (type: 'approve' | 'disapprove', url: string) => {
		setModalIsOpen(!modalIsOpen);
		setApproveState(type);
		setPostUrl(url);
	};

	const resetActions = () => {
		setPostUrl('');
		setIsLoading(false);
		setModalIsOpen(false);
		setApproveState('approve');
	};

	const onClickApprove = (url: string) => {
		openModal('approve', url);
	};

	const onClickDisapprove = (url: string) => {
		openModal('disapprove', url);
	};

	const approveAssignment = (url: string) => {
		setIsLoading(true);
		approve(url)
			.then(() => {
				refresh()
					.catch((e) => {
						toast.error(getErrorMessageOnFetch('Summary'));
						console.error(e);
					})
					.finally(() => resetActions());
			})
			.catch((e) => {
				toast.error(getErrorMessageOnPost('approving the assignment'));
				resetActions();
				console.error(e);
			});
	};

	const disApproveAssignment = (url: string) => {
		setIsLoading(true);
		disapprove(url)
			.then(() => {
				refresh()
					.catch((e) => {
						toast.error(getErrorMessageOnFetch('Summary'));
						console.error(e);
					})
					.finally(() => resetActions());
			})
			.catch((e) => {
				toast.error(getErrorMessageOnPost('declining the assignment'));
				resetActions();
				console.error(e);
			});
	};

	const onClickSubmit = () => {
		if (approveState === 'approve') {
			approveAssignment(postUrl);
		} else if (approveState === 'disapprove') {
			disApproveAssignment(postUrl);
		} else {
			return;
		}
	};

	useEffect(() => {
		if (activeCampaign) {
			setCommission(getTotalCommission());
		}
	}, [activeCampaign]);

	useEffect(() => {
		if (selectedCIO) {
			setAssignmentList(CIOAS ? CIOAS : []);
		}
	}, [selectedCIO]);

	useEffect(() => {
		CIOAS && setAssignmentList(CIOAS);
	}, [CIOAS]);

	return (
		<>
			<Styled.Wrapper>
				<Styled.Content>
					<h2>Summary</h2>
					{activeCampaign !== undefined ? (
						<AssignmentList
							activeCampaign={activeCampaign}
							assignments={assignmentList}
							onClickApprove={(url: string) => onClickApprove(url)}
							onClickDisapprove={(url: string) => onClickDisapprove(url)}
						/>
					) : null}
					{!isBrandManager && <h3>Commission: {commission !== null ? formatAmount(commission.fixedAmount, commission.fixedAmountCurrency) : '0'}</h3>}
				</Styled.Content>
			</Styled.Wrapper>

			<ConfirmModal
				IconBackgroundColor={approveState === 'approve' ? PALETTE.aquaHaze : PALETTE.channel}
				icon={approveState === 'approve' ? 'check-circle' : 'circle-cross'}
				buttonText={approveState === 'approve' ? 'Yes, approve' : 'Yes, decline'}
				isModalOpen={modalIsOpen}
				toggleModal={() => setModalIsOpen(!modalIsOpen)}
				title={approveState === 'approve' ? 'Approve assignment' : 'Decline assignment'}
				isFetching={isLoading}
				action={onClickSubmit}
				confirmButtonAsDanger={approveState === 'disapprove'}
			>
				<ConfirmMessage approveState={approveState} />
			</ConfirmModal>
		</>
	);
};

export default Summary;
