import {
	FEATURE_COLLABS_AUTH,
	FEATURE_FLAG_DATA_LIBRARY,
	FEATURE_FLAG_DATA_LIBRARY_AUDIENCE,
	FEATURE_FLAG_DATA_LIBRARY_BUDGET,
	FEATURE_FLAG_DATA_LIBRARY_CARD_CAMPAIGN_BUTTON,
	FEATURE_FLAG_DATA_LIBRARY_CARD_MORE_BUTTON,
	FEATURE_FLAG_DATA_LIBRARY_CARD_PIN_BUTTON,
	FEATURE_FLAG_DATA_LIBRARY_DASHBOARD,
	FEATURE_FLAG_DATA_LIBRARY_ENGAGEMENT,
	FEATURE_FLAG_DATA_LIBRARY_IMPRESSIONS,
	FEATURE_FLAG_DATA_LIBRARY_REACH,
	FEATURE_FLAG_DATA_LIBRARY_TRAFFIC,
	FEATURE_FLAG_FACEBOOK_AUTH,
	FEATURE_FLAG_INTEGRATED_INBOX,
	FEATURE_FLAG_NEW_INTEGRATED_INBOX,
	FEATURE_FLAG_DISCOVERY_LISTS_VIEW,
	FEATURE_FLAG_INFLUENCER_ASSIGNMENTS,
	FEATURE_FLAG_INFLUENCER_CONTENT_MANAGEMENT,
	FEATURE_FLAG_DOWNLOAD_MEDIA,
	FEATURE_FLAG_NOTIFICATIONS,
	FEATURE_FLAG_APP_FAKE_SALES_DATA,
	FEATURE_FLAG_NEW_INFLUENCER_DASHBOARD,
	FEATURE_FLAG_NEW_DATA_LIBRARY,
	FEATURE_FLAG_NEW_INFLUENCER_INBOX,
	FEATURE_FLAG_INBOX_UPLOAD_ATTATCHMENTS,
	FEATURE_FLAG_DASHBOARD_PREVIEW,
	FEATURE_FLAG_DOWNLOAD_DASHBOARD_CSV,
	FEATURE_FLAG_DISCOVERY_MESSAGE,
	FEATURE_FLAG_USER_CONTENT_MANAGEMENT,
	FEATURE_FLAG_MULTIPLE_ACCOUNTS,
	FEATURE_FLAG_AFFILIATE_MARKETPLACE,
	FEATURE_FLAG_NEW_FRAME_SELECTOR,
	FEATURE_FLAG_DISCOVERY_LOVE_ICON,
	FEATURE_FLAG_SHOW_AFFILIATE_MARKETPLACE_LINK,
	FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS,
	FEATURE_FLAG_ONBOARDING_VIEW,
	FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK,
	FEATURE_BRIEF_PAGE_IMAGE_CROP,
	FEATURE_FLAG_FORCE_CONNECT,
	FEATURE_FLAG_CAMPAIGN_SIDEDRAWER,
	FEATURE_FLAG_SEGMENTS,
	FEATURE_FLAG_MEDIA_AGENCY_FEE,
	FEATURE_FLAG_CAMPAIGN_BUDGET,
} from 'constants/feature-flag-keys';

export const enabledFeatures = [
	{ name: FEATURE_FLAG_DASHBOARD_PREVIEW, enabled: process.env.VITE_APP_FEATURE_FLAG_DASHBOARD_PREVIEW },
	{ name: FEATURE_FLAG_NEW_DATA_LIBRARY, enabled: process.env.VITE_APP_FEATURE_FLAG_NEW_DATA_LIBRARY },
	{ name: FEATURE_FLAG_DOWNLOAD_DASHBOARD_CSV, enabled: process.env.VITE_APP_FEATURE_DOWNLOAD_DASHBOARD_CSV },
	{ name: FEATURE_FLAG_DATA_LIBRARY, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY },
	{ name: FEATURE_FLAG_DATA_LIBRARY_DASHBOARD, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_DASHBOARD },
	{ name: FEATURE_FLAG_DATA_LIBRARY_REACH, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_REACH },
	{ name: FEATURE_FLAG_DATA_LIBRARY_IMPRESSIONS, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_IMPRESSIONS },
	{ name: FEATURE_FLAG_DATA_LIBRARY_TRAFFIC, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_TRAFFIC },
	{ name: FEATURE_FLAG_DATA_LIBRARY_AUDIENCE, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_AUDIENCE },
	{ name: FEATURE_FLAG_DATA_LIBRARY_BUDGET, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_BUDGET },
	{ name: FEATURE_FLAG_DATA_LIBRARY_ENGAGEMENT, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_ENGAGEMENT },
	{ name: FEATURE_FLAG_DATA_LIBRARY_CARD_PIN_BUTTON, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_CARD_PIN_BUTTON },
	{ name: FEATURE_FLAG_DATA_LIBRARY_CARD_MORE_BUTTON, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_CARD_MORE_BUTTON },
	{ name: FEATURE_FLAG_DATA_LIBRARY_CARD_CAMPAIGN_BUTTON, enabled: process.env.VITE_APP_FEATURE_DATA_LIBRARY_CARD_CAMPAIGN_BUTTON },
	{ name: FEATURE_FLAG_INTEGRATED_INBOX, enabled: process.env.VITE_APP_FEATURE_FLAG_INTEGRATED_INBOX },
	{ name: FEATURE_FLAG_NEW_INTEGRATED_INBOX, enabled: process.env.VITE_APP_FEATURE_FLAG_NEW_INTEGRATED_INBOX },
	{ name: FEATURE_FLAG_NEW_INFLUENCER_INBOX, enabled: process.env.VITE_APP_FEATURE_FLAG_NEW_INFLUENCER_INBOX },
	{ name: FEATURE_FLAG_INBOX_UPLOAD_ATTATCHMENTS, enabled: process.env.VITE_APP_FEATURE_FLAG_INBOX_UPLOAD_ATTATCHMENTS },
	{ name: FEATURE_FLAG_FACEBOOK_AUTH, enabled: process.env.VITE_APP_FEATURE_FACEBOOK_AUTH },
	{ name: FEATURE_COLLABS_AUTH, enabled: process.env.VITE_APP_COLLABS_AUTH },
	{ name: FEATURE_FLAG_DISCOVERY_LISTS_VIEW, enabled: process.env.VITE_APP_FEATURE_DISCOVERY_LISTS_VIEW },
	{ name: FEATURE_FLAG_DISCOVERY_LOVE_ICON, enabled: process.env.VITE_APP_FEATURE_DISCOVERY_LOVE_ICON },
	{ name: FEATURE_FLAG_INFLUENCER_ASSIGNMENTS, enabled: process.env.VITE_APP_FEATURE_INFLUENCER_ASSIGNMENTS },
	{ name: FEATURE_FLAG_INFLUENCER_CONTENT_MANAGEMENT, enabled: process.env.VITE_APP_FEATURE_INFLUENCER_CONTENT_MANAGEMENT },
	{ name: FEATURE_FLAG_USER_CONTENT_MANAGEMENT, enabled: process.env.VITE_APP_FEATURE_USER_CONTENT_MANAGEMENT },
	{ name: FEATURE_FLAG_DOWNLOAD_MEDIA, enabled: process.env.VITE_APP_FEATURE_DOWNLOAD_MEDIA },
	{ name: FEATURE_FLAG_NOTIFICATIONS, enabled: process.env.VITE_APP_FEATURE_FLAG_NOTIFICATIONS },
	{ name: FEATURE_FLAG_APP_FAKE_SALES_DATA, enabled: process.env.VITE_APP_FEATURE_FAKE_SALES_DATA },
	{ name: FEATURE_FLAG_NEW_INFLUENCER_DASHBOARD, enabled: process.env.VITE_APP_FEATURE_FLAG_NEW_INFLUENCER_DASHBOARD },
	{ name: FEATURE_FLAG_DISCOVERY_MESSAGE, enabled: process.env.VITE_APP_FEATURE_FLAG_DISCOVERY_MESSAGE },
	{ name: FEATURE_FLAG_MULTIPLE_ACCOUNTS, enabled: process.env.VITE_APP_FEATURE_MULTILPE_ACCOUNTS },
	{ name: FEATURE_FLAG_AFFILIATE_MARKETPLACE, enabled: process.env.VITE_APP_FEATURE_FLAG_AFFILIATE_MARKETPLACE },
	{ name: FEATURE_FLAG_NEW_FRAME_SELECTOR, enabled: process.env.VITE_APP_FEATURE_FLAG_NEW_FRAME_SELECTOR },
	{ name: FEATURE_FLAG_SHOW_AFFILIATE_MARKETPLACE_LINK, enabled: process.env.VITE_APP_FEATURE_FLAG_SHOW_AFFILIATE_MARKETPLACE_LINK },
	{ name: FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS, enabled: process.env.VITE_APP_TIKTOK_CONNECTOR_IN_SETTINGS },
	{ name: FEATURE_FLAG_ONBOARDING_VIEW, enabled: process.env.VITE_APP_FEATURE_FLAG_ONBOARDING_VIEW },
	{ name: FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK, enabled: process.env.VITE_APP_FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK },
	{ name: FEATURE_BRIEF_PAGE_IMAGE_CROP, enabled: process.env.VITE_APP_FEATURE_BRIEF_PAGE_IMAGE_CROP },
	{ name: FEATURE_FLAG_FORCE_CONNECT, enabled: process.env.VITE_APP_FEATURE_FLAG_FORCE_CONNECT },
	{ name: FEATURE_FLAG_CAMPAIGN_SIDEDRAWER, enabled: process.env.VITE_APP_FEATURE_FLAG_CAMPAIGN_SIDEDRAWER },
	{ name: FEATURE_FLAG_SEGMENTS, enabled: process.env.VITE_APP_FEATURE_FLAG_SEGMENTS },
	{ name: FEATURE_FLAG_MEDIA_AGENCY_FEE, enabled: process.env.VITE_APP_FEATURE_FLAG_MEDIA_AGENCY_FEE },
	{ name: FEATURE_FLAG_CAMPAIGN_BUDGET, enabled: process.env.VITE_APP_FEATURE_FLAG_CAMPAIGN_BUDGET },
];
