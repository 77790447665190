import styled from 'styled-components';

import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	width: 207px;
`;

const LoadingSpinnerWrapper = styled.div`
	width: 204px;
	height: 190px;
	border-radius: 10px;
	background: ${colors.form.dropzone.background};

	display: flex;
	justify-content: center;
	align-items: center;
`;

export default { Wrapper, LoadingSpinnerWrapper };
