// @ts-nocheck

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { ICollabsResponse, StatusCode } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';

import { ICampaignsService } from './types';

/**
 * @deprecated use CampaignManager instead. The CampaignManager is part of our new ApiManager pattern.
 */
class CampaignsService implements ICampaignsService {
	ENDPOINT_URL: string;
	private client: AxiosInstance;

	constructor() {
		this.ENDPOINT_URL = '/campaigns';
		this.client = createClient();
	}

	/**
	 * @deprecated use CampaignManager instead
	 */
	async getCampaigns(includes?: Array<string>) {
		const join = includes ? includes.join(',') : ':hateoas(false)';

		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', join);

		const { data } = await this.client.get<ICollabsResponse>(`${this.ENDPOINT_URL}?${params}`);

		return data;
	}

	/**
	 * @deprecated use CampaignManager instead
	 */
	async getCampaign(id: string, includes?: string, cancelSignal?: AbortSignal) {
		let result: ICollabsResponse = {};
		const url = `${this.ENDPOINT_URL}/${id}${includes ? `?includes=${includes}` : ''}`;

		await this.client
			.get<ICollabsResponse>(url, { signal: cancelSignal })
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included };
			})
			.catch((err: Error | AxiosError) => {
				if (axios.isAxiosError(err)) {
					result = {
						errors: err.response && err.response.data.errors,
					};
				}
			});

		return result;
	}

	async getCampaignUsers(campaignId: number) {
		let result: ICollabsResponse = {};

		await this.client
			.get(`${this.ENDPOINT_URL}/${campaignId}/users`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data };
			})
			.catch((err: Error | AxiosError) => {
				if (axios.isAxiosError(err)) {
					result = {
						errors: err.response && err.response.data.errors,
					};
				} else {
					console.error(err);
				}
			});

		return result;
	}

	async getCampaignInstagramOwners(campaignId: number) {
		let result: ICollabsResponse = {};

		await this.client
			.get(`${this.ENDPOINT_URL}/${campaignId}/instagram-owners`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included };
			})
			.catch((err: Error | AxiosError) => {
				if (axios.isAxiosError(err)) {
					result = {
						errors: err.response && err.response.data.errors,
					};
				} else {
					console.error(err);
				}
			});

		return result;
	}

	async getCampaignConversations(conversationId: number, username?: string, signal?: AbortSignal) {
		let result: ICollabsResponse = {};

		await this.client
			.get(`${this.ENDPOINT_URL}/${conversationId}/conversations${username ? `?username=${username}` : ''}`, { signal })
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included };
			})
			.catch((err: Error | AxiosError) => {
				if (axios.isAxiosError(err)) {
					result = {
						errors: err.response && err.response.data.errors,
					};
				} else {
					console.error(err);
				}
			});

		return result;
	}

	async deleteCampaign(campaignId: string | number) {
		let result;
		await this.client
			.delete(`${this.ENDPOINT_URL}/${campaignId}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				if (response.status === StatusCode.NO_CONTENT) {
					result = StatusCode.NO_CONTENT;
				}
			})
			.catch((err: Error | AxiosError) => {
				throw new Error('error', err);
			});

		return result;
	}

	async deleteCampaignInfluencer(link: string) {
		let result;
		await this.client
			.delete(`${link}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				if (response.status === StatusCode.NO_CONTENT) {
					result = StatusCode.NO_CONTENT;
				}
			})
			.catch((err: Error | AxiosError) => {
				throw new Error('error', err);
			});

		return result;
	}

	async cancelCampaignInfluencer(link: string) {
		let result;
		await this.client
			.post(`${link}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				if (response.status === StatusCode.NO_CONTENT) {
					result = StatusCode.NO_CONTENT;
				}
			})
			.catch((err: Error | AxiosError) => {
				throw new Error('error', err);
			});

		return result;
	}

	async deleteComission(campaignId: string | number, comissionId: string | number) {
		const response = await this.client.delete(`${this.ENDPOINT_URL}/${campaignId}/commissions/${comissionId}`);
		return response;
	}

	async deleteProduct(campaignId: string | number, productId: string | number) {
		const response = await this.client.delete(`${this.ENDPOINT_URL}/${campaignId}/products/${productId}`);
		return response;
	}

	async deleteAssignment(campaignId: string | number, assignemntId: string | number) {
		const response = await this.client.delete(`${this.ENDPOINT_URL}/${campaignId}/assignments/${assignemntId}`);
		return response;
	}

	async sendInvite(campaignId: string | number, influencers: string[], inviteAt?: string) {
		const response = await this.client.post(`${this.ENDPOINT_URL}/${campaignId}/invites`, { influencers, inviteAt });
		return response;
	}

	async archiveCampaign(campaignId: string | number) {
		const response = await this.client.post(`${this.ENDPOINT_URL}/${campaignId}/archive`);
		return response;
	}

	async addCoupon(url: string, coupon: string | null) {
		const response = await this.client.patch(`${url}`, { campaignCode: coupon });
		return response;
	}

	async getInfluencers(campaignId: string) {
		const response = await this.client.get(`${this.ENDPOINT_URL}/${campaignId}/instagram-owners`, {
			params: { includes: 'influencer,influencer.user,campaignInstagramOwnerCommissions.commission,invite,campaignInstagramOwnerAssignments.assignment' },
		});
		return response;
	}

	async addTermsAndConditionsPdf(campaignId: string, pdfId: string) {
		const response = await this.client.patch(`${this.ENDPOINT_URL}/${campaignId}`, { termsAndConditionsFile: pdfId });
		return response;
	}
}

export default new CampaignsService();
