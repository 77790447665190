import { isNil, isEmpty } from 'lodash';
import { useState, useEffect, ReactNode } from 'react';

import CustomDropZone from 'components/Form/Elements/CustomDropZone/CustomDropZone';
import LoadingSpinner from 'components/LoadingSpinner';
import toast from 'services/Toast';
import previewFile from 'services/previewFile';

import Styled from './UploadImage.style';

type Props = {
	isLoading?: boolean;
	ogMetaImage?: string;
	value?: string;
	setValue: (field: string, value: string | File | null) => void;
	fieldName: string;
	deleteImageHateoas?: string;
	onDelete: () => Promise<void>;
	helpText?: string | ReactNode;
};

const UploadImage = ({ isLoading, ogMetaImage, value, setValue, fieldName, deleteImageHateoas, onDelete, helpText }: Props) => {
	const [imageInState, setImageInState] = useState<string | undefined>(value);

	// 4MB
	const FILE_MAX_SIZE = 4 * 1_000_000;

	const removeImage = () => {
		setValue(fieldName, null);
		setImageInState(undefined);
	};

	const onDrop = async (acceptedFiles: File[]) => {
		if (isNil(acceptedFiles) || 0 === acceptedFiles.length) {
			return;
		}
		const file = acceptedFiles[0];

		if (file.size > FILE_MAX_SIZE) {
			const formatter = new Intl.NumberFormat(undefined, { unit: 'byte', notation: 'compact' });
			toast.error(`The max allowed size is ${formatter.format(FILE_MAX_SIZE)}B, your file size is ${formatter.format(file.size)}B`);
			return;
		}

		setValue('coverImageFile', file);
		const image = await previewFile(file);
		setValue(fieldName, image);
		setImageInState(image);
	};

	const onDeleteHandler = () => {
		onDelete().then(() => {
			setValue(fieldName, null);
			setImageInState(undefined);
			toast.success('Image deleted');
		});
	};

	useEffect(() => {
		if (ogMetaImage && (isNil(value) || isEmpty(value))) {
			setImageInState(ogMetaImage);
		}
	}, [ogMetaImage]);

	useEffect(() => {
		if (!isNil(value) || !isEmpty(value)) {
			setImageInState(value);
		}
	}, [value]);

	return (
		<Styled.Wrapper>
			{isLoading && (
				<Styled.LoadingSpinnerWrapper>
					<LoadingSpinner position='center' />
				</Styled.LoadingSpinnerWrapper>
			)}
			{!isLoading && (
				<CustomDropZone
					canEdit
					remove={removeImage}
					deleteImage={onDeleteHandler}
					deleteImageUrl={deleteImageHateoas && deleteImageHateoas}
					handleOnDrop={onDrop}
					imageUrl={imageInState}
					title='Add cover image'
				/>
			)}
			{helpText && <span data-testid='help-text'>{helpText}</span>}
		</Styled.Wrapper>
	);
};

export default UploadImage;
