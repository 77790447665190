import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';

const CategoryList = styled.ul`
	padding: 0;
	list-style: none;
	height: 100%;
	padding-bottom: 10vh;
`;

const CategoryListContainer = styled.div`
	opacity: 0;
	height: 0;
	visibility: hidden;
	display: none;
	&.visible {
		height: calc(100vh - 270px);
		${scrollbarY};
		opacity: 1;
		visibility: visible;
		display: block;
	}
`;

const CategoryListInnerContainer = styled.div``;

export const Styled = {
	CategoryListContainer,
	CategoryListInnerContainer,
	CategoryList,
};
