/**
 * Function to Initialize the Facebook SDK script
 */

import { loginResponse, permissionResponse } from './types';

export function initFacebookSdk() {
	const facebookAppId = process.env.VITE_APP_FACEBOOK_APP_ID;
	window.fbAsyncInit = function () {
		window.FB.init({
			appId: facebookAppId,
			cookie: true,
			xfbml: true,
			version: 'v15.0',
		});
	};

	// load facebook sdk script
	(function (d, s, id) {
		let js,
			fjs = d.getElementsByTagName(s)[0]; // eslint-disable-line prefer-const
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s) as HTMLScriptElement; // eslint-disable-line prefer-const
		js.id = id;
		js.src = 'https://connect.facebook.net/en_US/all.js';
		if (fjs.parentNode) {
			fjs.parentNode.insertBefore(js, fjs);
		}
	})(document, 'script', 'facebook-jssdk');
}

export const logout = () => {
	return (
		window.FB &&
		window.FB.api('/me/permissions', 'delete', (response: permissionResponse) => {
			if (response.success) {
				window.FB.logout();
			}
		})
	);
};

export const login = async (): Promise<loginResponse> => {
	const SCOPES = process.env.VITE_APP_FACEBOOK_API_SCOPES;
	return await new Promise((resolve) => window.FB.login(resolve, { scope: SCOPES, extras: { setup: { channel: 'IG_API_ONBOARDING' } } }));
};
