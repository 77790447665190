import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { Model, Store } from 'json-api-models';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import InfluencerCampaignInfo from 'components/IntegratedInbox/Components/InfluencerCampaignInfo';
import { CampaignInfluencerDetail } from 'components/IntegratedInbox/Components/InfluencerDetailSection/CampaignInfluencerDetail';
import InfluencerInfo from 'components/IntegratedInbox/Components/InfluencerInfo';
import { IntegratedInboxCampaign } from 'components/IntegratedInbox/types';
import PromoCodes from 'components/PromoCodes/PromoCodes';
import { pathCampaignPreviewBrief } from 'routing/PathLookup';
import { createClient } from 'shared/ApiClient/ApiClient';
import Grid from 'styles/grid/grid';
import colors from 'styles/variables/colors';

import Styled from './InfluencerDetailSection.style';

const InfluencerDetailSection = (props: {
	selectedCampaignInfluencers?: Model[];
	campaignInstagramOwner: Model | null;
	selectedCampaign: IntegratedInboxCampaign | null;
	campaignName?: string;
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [campaignInfluencerDetail, setCampaignInfluencerDetail] = useState<CampaignInfluencerDetail | null>(null);
	const [isRightColVisible, setIsRightColVisible] = useState<boolean>(false);
	const [promoCodes, setPromoCodes] = useState<string[]>([]);

	const Client = createClient();
	const campaignInstagramOwnerStore = new Store();
	const campaignBriefUrl = props.selectedCampaign && pathCampaignPreviewBrief(props.selectedCampaign.shortId);

	const getCampaigns = async (): Promise<AxiosResponse> => {
		setIsLoading(true);

		return await Client.get(
			`/campaigns/${props.selectedCampaign!.id}/influencers/${
				props.campaignInstagramOwner!.influencer.id
			}?includes=campaignInstagramOwnerAssignments,campaignInstagramOwnerAssignments.assignment,campaignInstagramOwnerAssignments.assignment.groups,campaignInstagramOwnerCommissions,campaignInstagramOwnerCommissions.commission,campaignInstagramOwnerProducts,campaignInstagramOwnerProducts.product`,
		).then((res) => {
			if (res.data) {
				return res.data;
			}
		});
	};

	useEffect(() => {
		if (props.campaignInstagramOwner && props.selectedCampaign) {
			getCampaigns()
				.then((res) => {
					campaignInstagramOwnerStore.sync(res);
					const campaignInstagramOwnerModel = campaignInstagramOwnerStore.find('campaignInstagramOwner', props.campaignInstagramOwner!.id);
					if (campaignInstagramOwnerModel) {
						setCampaignInfluencerDetail(new CampaignInfluencerDetail(campaignInstagramOwnerModel));
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [props.campaignInstagramOwner, props.selectedCampaign]);

	useEffect(() => {
		const codes = props.selectedCampaignInfluencers?.map(({ campaignInstagramOwnerAssignments }: Model) =>
			campaignInstagramOwnerAssignments.map((assignment: Model) => {
				if (assignment.campaignCode?.length !== undefined || assignment.campaignCode?.length !== null) {
					return assignment.campaignCode;
				}
			}),
		);
		const allCodes = codes?.flat().filter((code: string) => code?.length > 0);
		setPromoCodes(allCodes && allCodes?.length > 0 ? uniq(allCodes) : []);
	}, [props.selectedCampaignInfluencers]);

	return (
		<Grid.Column md={3} className={classNames('three-col', 'overflow-visible', { 'active-col': isRightColVisible })}>
			{promoCodes.length > 0 && isRightColVisible && (
				<PromoCodes promoCodes={promoCodes} backgroundColor={colors.integratedInbox.campaignSection.header.background} />
			)}{' '}
			{props.campaignInstagramOwner ? (
				<Styled.RightInner>
					<div className='toggler'>
						<button onClick={() => setIsRightColVisible(!isRightColVisible)}>
							<Icon name={`chevron-${isRightColVisible ? 'right' : 'left'}`} />
						</button>
					</div>

					<Styled.Wrapper>
						<Styled.Headline>
							<h3>{props.campaignName}</h3>
						</Styled.Headline>
						<InfluencerInfo influencer={props.campaignInstagramOwner.influencer} />
						<InfluencerCampaignInfo
							CIO={props.campaignInstagramOwner}
							campaignDetail={campaignInfluencerDetail}
							isLoading={isLoading}
							isAffiliate={false}
							campaignBriefUrl={campaignBriefUrl}
						/>
					</Styled.Wrapper>
				</Styled.RightInner>
			) : null}
		</Grid.Column>
	);
};

export default InfluencerDetailSection;
