import { ReactNode } from 'react';

import Icon from 'components/Icon';

import Styled from './InfoBlock.style';

type Props = {
	title: string;
	children: ReactNode;
};

const InfoBlock = ({ title, children }: Props) => {
	return (
		<Styled.Wrapper>
			<div className='title-wrapper'>
				<span className='icon-wrapper'>
					<Icon name='info-circle' size='20' />
				</span>
				<div className='info-title'>{title}</div>
			</div>
			<div className='info-content'>{children}</div>
		</Styled.Wrapper>
	);
};

export default InfoBlock;
