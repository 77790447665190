import { createClient } from 'shared/ApiClient/ApiClient';

export const generateTrackingLink = async (hateoasLink: string, data: { url: string; influencerId: string }) => {
	const client = createClient();
	return await client.post(hateoasLink, data);
};

export const getGeneratedTrackingLink = async (hateoasLink: string, influencerId: string) => {
	const client = createClient();
	const correctUrl = hateoasLink.replace('INFLUENCER_ID', influencerId);
	const url = `${correctUrl}?includes=no_defaults`;
	return await client.get(url);
};

export const allowedCountries = ['SWE'];
