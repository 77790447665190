import styled from 'styled-components';

import colors from 'styles/variables/colors';

const getSize = (size: string) => {
	switch (size) {
		case 'sm':
			return '24px';
		case 'lg':
			return '56px';
		default:
			return '48px';
	}
};

const getPosition = (position: string) => {
	switch (position) {
		case 'center':
			return 'margin: auto';
		default:
			return '';
	}
};

const getBorderWidth = (size: string) => {
	switch (size) {
		case 'sm':
			return '2.5px';
		case 'lg':
			return '5px';
		default:
			return '5px';
	}
};

const Spinner = styled.div<{ size: 'sm' | 'md' | 'lg'; position: 'default' | 'center' }>`
	width: ${(props) => getSize(props.size)};
	height: ${(props) => getSize(props.size)};
	border: ${(props) => getBorderWidth(props.size)} solid ${colors.black1};
	${(props) => getPosition(props.position)};
	border-bottom-color: transparent;
	border-radius: 50%;
	display: block;
	box-sizing: border-box;
	animation: rotation 0.7s linear infinite;
`;

const Title = styled.h2`
	padding-top: 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Styled = {
	Spinner,
	Title,
};

export default Styled;
