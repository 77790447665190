import styled from 'styled-components';

const Wrapper = styled.div`
	max-width: 664px;
	margin: 16px auto 45px auto;
`;

const Content = styled.div`
	margin: 0 auto 32px auto;
	ol {
		list-style-type: unset !important;
	}

	.help-text {
		padding-left: 1rem;
		margin-bottom: 28px;

		li {
			line-height: 1.5;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
`;

const TrackingLinkContainer = styled.div`
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const Styled = {
	Wrapper,
	Content,
	TrackingLinkContainer,
};

export default Styled;
