import styled from 'styled-components';

import { ButtonSecondary } from 'components/Button';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div``;

const OptionsWrapper = styled.div`
	min-height: 260px;
	margin-top: ${spacing[4].px};
	display: flex;
	flex-direction: column;
	row-gap: ${spacing[2].px};

	@media screen and (${devices.sm}) {
		width: 423px;
	}
`;

const Option = styled.div`
	border-radius: 10px;
	border: 1px solid ${colors.onboarding.option.border};
	padding: ${spacing[3].px};
	padding-left: ${spacing[2].px};
	cursor: pointer;

	display: flex;
	align-items: flex-start;
	column-gap: ${spacing[2].px};

	& > div {
		& > div {
			line-height: 150%;
			&.title {
				line-height: 1;
				font-weight: 700;
				margin-bottom: ${spacing[1].px};
			}
		}
	}

	&.selected {
		background-color: ${colors.onboarding.option.selected.background};
		color: ${colors.onboarding.option.selected.color};

		.checkbox {
			background-color: transparent;
			border-color: ${colors.onboarding.option.selected.color};

			overflow: hidden;
		}
	}
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.onboarding.divider};

	margin-top: ${spacing[3].px};
	margin-bottom: ${spacing[3].px};
`;

const Button = styled(ButtonSecondary)`
	display: flex;
	width: 100%;
	height: 44px;

	font-size: 1rem;
	flex: 1;

	&:not(:disabled) {
		border-color: ${colors.onboarding.button.hover.background};
		background-color: ${colors.onboarding.button.hover.background};
		color: ${colors.onboarding.button.hover.color};
	}
`;

const Styled = {
	Wrapper,
	OptionsWrapper,
	Option,
	Divider,
	Button,
};

export default Styled;
