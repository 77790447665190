import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import Checkbox from 'components/Checkbox';
import LoadingSpinner from 'components/LoadingSpinner';
import useOnboardingData from 'components/Onboarding/hooks';
import { CategoryType } from 'components/Onboarding/types';

import Styled from './CategorySelect.style';

type Props = {
	selectedCategoryIds: string[];
	onClickCategory: (categoryId: string) => void;
	onGoBack: () => void;
	onSelect: () => void;
	isInvalid: boolean;
	setIsOnlyOther: (bool: boolean) => void;
};

const CategorySelect = ({ onGoBack, selectedCategoryIds, onClickCategory, onSelect, isInvalid, setIsOnlyOther }: Props) => {
	const [categories, setCategories] = useState<CategoryType[]>([]);
	const { getCategories } = useOnboardingData();
	const { models, isLoading, error } = getCategories();

	useEffect(() => {
		if (!isLoading && !error) {
			setCategories(models.findAll('affiliateCategory'));
		}
	}, [isLoading, error]);

	useEffect(() => {
		if (selectedCategoryIds.length === 1) {
			setIsOnlyOther(selectedCategoryIds.some((categoryId) => categoryId === categories.find((category) => category.name === 'Other')?.id));
		} else {
			setIsOnlyOther(false);
		}
	}, [selectedCategoryIds.length]);

	return (
		<Styled.Wrapper>
			<Styled.OptionsWrapper>
				{isLoading && <LoadingSpinner position='center' />}
				{!isLoading &&
					!isEmpty(categories) &&
					categories.map((category) => {
						return (
							<Styled.Option
								data-testid={`onboarding-category-${category.name}`}
								key={category.id}
								className={classNames({ selected: selectedCategoryIds.includes(category.id), error: isInvalid })}
								onClick={() => {
									onClickCategory(category.id);
								}}
							>
								<Checkbox
									checked={selectedCategoryIds.includes(category.id)}
									onChange={(e) => {
										e.stopPropagation();

										onClickCategory(category.id);
									}}
								/>
								{category.name}
							</Styled.Option>
						);
					})}
			</Styled.OptionsWrapper>
			<Styled.Divider />

			<Styled.Button onClick={onSelect} className='select-button' disabled={isEmpty(selectedCategoryIds)}>
				Select
			</Styled.Button>
			<Styled.Button onClick={onGoBack}>Back</Styled.Button>
		</Styled.Wrapper>
	);
};

export default CategorySelect;
