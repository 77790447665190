import classNames from 'classnames';
import React, { useContext } from 'react';

import noResult from 'assets/icons/empty-state-no-result.svg';
import Checkbox from 'components/Checkbox';
import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import InfluencerListItem from 'components/Discovery/Components/InfluencerListItem';
import EmptyState from 'components/EmptyState/V2';
import LoadingSpinner from 'components/LoadingSpinner';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './ResultTable.style';

type AudienceBrief = {
	age: string;
	gender: string;
	country: string;
};

type Influencer = {
	audienceBrief: AudienceBrief;
	canBeContacted: boolean;
	country: string;
	engagement: number;
	followersCount: number;
	id: string;
	links: { [key: string]: string };
	network: string;
	networkId: string;
	networkLinks: { [key: string]: string };
	profileImageUrl: string;
	username: string;
};

type Props = {
	list: Array<Influencer>;
	selectedItems: Array<string>;
	loadMore: () => void;
	isLoadingNext: boolean;
	onSelectAll: () => void;
	onSelectOne: () => (id: string) => void;
	onReset: () => void;
	setShowSelectedInfluencers: (show: boolean) => void;
};

const TABLE_FIELDS = [
	{ property: 'followersCount', label: 'Followers' },
	{ property: 'engagement', label: 'Engagement' },
	{ property: 'audienceBrief.age.ratio', label: 'Age span' },
	{ property: 'audienceBrief.country.ratio', label: 'Location' },
	{ property: 'audienceBrief.gender.ratio', label: 'Gender' },
];

/**
 */
const ResultTable = ({ list, selectedItems, onSelectAll, onSelectOne, onReset, loadMore, isLoadingNext, setShowSelectedInfluencers }: Props) => {
	const isSelected = selectedItems.length > 0;

	const { loading } = useContext(DiscoveryContext);

	if (list.length === 0) {
		return (
			<Styled.EmptyResultWrapper>
				<EmptyState image={noResult} altText='No result found' testId='no-result-found' text={<p>No results to display.</p>} />
			</Styled.EmptyResultWrapper>
		);
	}

	return (
		<>
			<Styled.Table cellSpacing={0}>
				<Styled.TableHeader>
					<tr>
						<Styled.CheckboxTh>
							<Styled.CheckboxWrapper>
								<Styled.FlexDiv columnGap='1rem' position='absolute' left='-5px' bottom='-7px'>
									<Styled.CheckboxInnerWapper columnGap='0.25rem' alignItems='center'>
										<Checkbox
											onChange={() => {
												onSelectAll(), setShowSelectedInfluencers(false);
											}}
											checked={isSelected}
											isIndeterminate={selectedItems.length < list.length}
										/>
									</Styled.CheckboxInnerWapper>

									{selectedItems.length > 0 && (
										<Styled.FlexDiv>
											<DiscoveryDropdown selectedItems={selectedItems} onResetSelection={onReset} size='16' />
										</Styled.FlexDiv>
									)}
								</Styled.FlexDiv>
							</Styled.CheckboxWrapper>
						</Styled.CheckboxTh>
						{TABLE_FIELDS.map(({ property, label }) => (
							<Styled.Th title={`Sort by ${label.toLowerCase()}`} key={property}>
								<Styled.TableHeaderInnerWrapper data-sort-property={property} className={classNames({ followers: label === 'Followers' })}>
									{label}&nbsp;
								</Styled.TableHeaderInnerWrapper>
							</Styled.Th>
						))}
						<Styled.Th />
					</tr>
				</Styled.TableHeader>
				<Styled.TableBody>
					{loading ? (
						<tr>
							<td colSpan={100} />
						</tr>
					) : (
						list.map((influencer, index) => {
							const isSelected = selectedItems.some((item) => item === influencer.id);
							return (
								<InfluencerListItem
									key={`${influencer.id}-${index}`}
									data={influencer}
									onSelect={() => onSelectOne()(influencer.id)}
									isSelected={isSelected}
									isOdd={(index + 1) % 2 === 1}
									whyDoIGetLink={influencer.links.explain}
								/>
							);
						})
					)}
				</Styled.TableBody>
			</Styled.Table>
			{isLoadingNext && (
				<Styled.LoadingSpinnerWrapper>
					<LoadingSpinner size='sm' position='center' />
				</Styled.LoadingSpinnerWrapper>
			)}
			{/* Only show load more if there could be more items */}
			{!isLoadingNext && list.length && list.length % 100 == 0 && (
				<Styled.LoadMoreWrapper>
					<hr />
					<Styled.LoadMoreButton onClick={loadMore}>Load more</Styled.LoadMoreButton>
				</Styled.LoadMoreWrapper>
			)}
		</>
	);
};

export default ResultTable;
