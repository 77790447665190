import { memo } from 'react';
import { JSX } from 'react/jsx-runtime';
import { components, DropdownIndicatorProps, GroupBase, Props as SelectProps } from 'react-select';

import Icon from 'components/Icon';
import { Scale } from 'styles/theme/types';

import { SelectField } from './SelectMenu.style';

const DEFAULT_SCALE: Scale = 'medium';

interface SelectMenuProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> extends Omit<SelectProps<Option, IsMulti, Group>, 'components'> {
	scale?: Scale;
}

const DropdownIndicator = (props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => {
	return components.DropdownIndicator ? (
		<components.DropdownIndicator {...props} data-testid='dropdown-indicator'>
			<Icon testId={props.selectProps.menuIsOpen ? 'open' : 'closed'} size='16' name={props.selectProps.menuIsOpen ? 'chevron-up' : 'chevron-down'} />
		</components.DropdownIndicator>
	) : null;
};

/**
 * SelectMenu
 * Select component for forms etc.
 * @param props
 * @returns {JSX.Element}
 */
const SelectMenu = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
	props: SelectMenuProps<Option, IsMulti, Group>,
): JSX.Element => {
	const scale = props.scale || DEFAULT_SCALE;

	return <SelectField {...props} components={{ DropdownIndicator }} data-scale={scale} />;
};

export default memo(SelectMenu);
