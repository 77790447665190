import classNames from 'classnames';
import { isEqual } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import Datepicker from 'components/Datepicker';
import Grid from 'styles/grid/grid';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterDateFrom, setDashboardFilterDateTo } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './DateOptions.style';
import { getDateSixMonthsAgo, getToday } from './Dates';

/**
 * The default option for all graphs is the time interval of 6 months. Changes can be made
 * with the 3 different predefined intervals and with selected dates from the datepicker.
 *
 * When the intervals are used, the to and from in the dashboard filter will be updated,
 * but they will change everytime the dashboard is displayed. If no interval is selected
 * the to and from will be used.
 *
 * @returns {JSX.Element}
 */
const DateOptions = (props: FilterOptionProps): JSX.Element => {
	const [from, setFrom] = useState<string | undefined>(getDateSixMonthsAgo());
	const [to, setTo] = useState<string | undefined>(getToday());

	const dispatch = useAppDispatch();
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	useEffect(() => {
		dispatch(setDashboardFilterDateFrom(from));
	}, [from]);

	useEffect(() => {
		dispatch(setDashboardFilterDateTo(to));
	}, [to]);

	const handleChangeFrom = (date: Date | null) => {
		if (date && moment(date).isValid()) {
			const from = moment(date).format('YYYY-MM-DD');
			setFrom(from);
		}
	};

	const handleChangeRawFrom = (e: React.FocusEvent<HTMLInputElement>) => {
		if (moment(e.target.value).isValid()) {
			return;
		}
		const date = moment(e.target.value);
		const from = moment(e.target.value).format('YYYY-MM-DD');
		if (date.isValid()) {
			setFrom(from);
		}
	};

	const handleChangeTo = (date: Date | null) => {
		if (date && moment(date).isValid()) {
			const to = moment(date).format('YYYY-MM-DD');
			setTo(to);
		}
	};

	const handleChangeRawTo = (e: React.FocusEvent<HTMLInputElement>) => {
		if (moment(e.target.value).isValid()) {
			return;
		}
		const date = moment(e.target.value);
		const to = moment(e.target.value).format('YYYY-MM-DD');
		if (date.isValid()) {
			setTo(to);
		}
	};

	// Listening to changes on dashboardFilter saved on the dashboard.
	// 1. If filter has manually selected dates update state and display borders
	// 2. If nothing saved => Auto Select last 6 months
	useEffect(() => {
		const filter = dashboard.metaData.dashboardFilter;
		if (filter) {
			if (!isEqual(filter.to, to)) {
				setTo(filter.to);
			}
			if (!isEqual(filter.from, from)) {
				setFrom(filter.from);
			}
		} else {
			setTo(getToday());
			setFrom(getDateSixMonthsAgo());
		}
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		if (props.clearFilter) {
			setFrom(getDateSixMonthsAgo());
			setTo(getToday());
		}
	}, [props.clearFilter]);

	useEffect(() => {
		return () => {
			setFrom(getDateSixMonthsAgo());
			setTo(getToday());
		};
	}, []);

	return (
		<Styled.CustomAccordion title={<> Date (1)</>} className={classNames({ bold: true })}>
			<Styled.CustomGridContainer>
				<Grid.Column sm={12} md={12} xl={6}>
					<Datepicker
						portalId='from-portal'
						selected={moment(from).toDate()}
						onChange={(date) => handleChangeFrom(date)}
						onChangeRaw={(e) => handleChangeRawFrom(e)}
						startDate={moment(from).toDate()}
						endDate={moment(to).toDate()}
						maxDate={moment(to).toDate()}
						dateFormat='yyyy-MM-dd'
						placeholderText='yyyy-MM-dd'
					/>
				</Grid.Column>
				<Grid.Column sm={12} md={12} xl={6}>
					<Datepicker
						portalId='to-portal'
						selected={moment(to).toDate()}
						onChange={(date) => handleChangeTo(date)}
						onChangeRaw={(e) => handleChangeRawTo(e)}
						endDate={moment(to).toDate()}
						startDate={moment(from).toDate()}
						minDate={moment(from).toDate()}
						maxDate={new Date()}
						placeholderText='yyyy-MM-dd'
						dateFormat='yyyy-MM-dd'
					/>
				</Grid.Column>
			</Styled.CustomGridContainer>
			<Styled.CalendarContainer />
		</Styled.CustomAccordion>
	);
};
export default DateOptions;
