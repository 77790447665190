import { JsonApiDocument, Store } from 'json-api-models';
import { isNil, isEmpty } from 'lodash';
import useSWR from 'swr';

import { createClient } from 'shared/ApiClient/ApiClient';

import { CreationFormData } from './types';

export const AM_BASE_URL = '/affiliate-programs';

export type AMCamapignParam = {
	categoryIds?: string[];
	brandIds?: string[];
	searchText?: string;
};

export default function useAffiliateMarketplaceData() {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const getPrograms = () => {
		const models = new Store();

		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', 'coverImage:hateoas(false)');

		const url = `${AM_BASE_URL}?${params}`;
		const { data, error, mutate, isValidating } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
			refresh: () => mutate(),
			isValidating,
		};
	};

	const getLinkCreatedPrograms = (influencerId?: string) => {
		const models = new Store();

		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', 'coverImage:hateoas(false)');
		params.append('joined', 'true');
		if (!isNil(influencerId) && !isEmpty(influencerId)) {
			params.append('influencers', influencerId);
		}
		const url = `${AM_BASE_URL}?${params}`;

		const { data, error, mutate, isValidating } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
			refresh: () => mutate(),
			isValidating,
		};
	};

	const getProgram = (programId?: string) => {
		if (programId) {
			const models = new Store();

			const params = new URLSearchParams();
			params.append('exclude', 'defaultIncludes');
			params.append('include', 'coverImage:hateoas(false)');

			const url = `${AM_BASE_URL}/${programId}?${params}`;
			const { data, error, mutate, isValidating } = useSWR(url, fetcher);

			if (data && !error) {
				models.sync(data);
			}

			return {
				models,
				isLoading: !data && !error,
				error: error,
				refresh: () => mutate(),
				isValidating,
			};
		}
		return {};
	};

	const isInitialTextarea = (value: string | undefined) => {
		return value === '<p><br></p>' || value === undefined;
	};

	const createProgram = async (data: CreationFormData) => {
		const campaignData = {
			name: data.name,
			adtractionId: data.adtractionId,
			storeLink: data.storeLink,
			storeCommissionPercentage: Number(data.storeCommissionPercentage),
			influencerCommissionPercentage: Number(data.influencerCommissionPercentage),
			background: isInitialTextarea(data.background) ? undefined : data.background,
			guidelines: isInitialTextarea(data.guidelines) ? undefined : data.guidelines,
			sort: data.sort,
		};

		return await client.post(AM_BASE_URL, campaignData);
	};

	const saveCoverImage = async (url: string, file: File) => {
		const formData = new FormData();
		if (file) {
			formData.append('file', file);
		}

		return await client.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	};

	const updateProgram = async (url: string, data: CreationFormData) => {
		const campaignData = {
			name: data.name,
			adtractionId: data.adtractionId,
			storeLink: data.storeLink,
			storeCommissionPercentage: Number(data.storeCommissionPercentage),
			influencerCommissionPercentage: Number(data.influencerCommissionPercentage),
			background: isInitialTextarea(data.background) ? undefined : data.background,
			guidelines: isInitialTextarea(data.guidelines) ? undefined : data.guidelines,
			sort: data.sort,
		};
		return await client.patch(url, campaignData);
	};

	const deleteProgram = async (url: string) => {
		return await client.delete(url);
	};

	return {
		getPrograms,
		getLinkCreatedPrograms,
		getProgram,
		createProgram,
		saveCoverImage,
		updateProgram,
		deleteProgram,
	};
}
