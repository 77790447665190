import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Store } from 'json-api-models';
import { useEffect, useState } from 'react';

import MainNavigation from 'components/MainNavigation/MainNavigation';
import { FEATURE_FLAG_NOTIFICATIONS } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setNotifications } from 'reducers/UserReducers/UserNotificationsSlice';
import { getHomePath } from 'reducers/UserReducers/helpers';
import NotificationsService from 'services/Notifications/Notifications.service';
import { getActivePublisher } from 'shared/User/User.helpers';

import NotificationsMenu from './Components/NotificationsMenu/NotificationsMenu';
import UserMenu from './Components/UserMenu/UserMenu';
import Styled from './Header.style';
import { IHeaderProps } from './types';

/**
 * Header
 * @param {IHeaderProps} props
 * @returns {JSX.Element}
 */
const Header = (props: IHeaderProps): JSX.Element => {
	const [scroll, setScroll] = useState<boolean>(false);
	const [isLoadingNotifications, setIsLoadingNotifications] = useState<boolean>(false);

	const [isEnabled] = useFeatureToggle();
	const dispatch = useAppDispatch();

	const POLL_INTERVAL = 45000;
	let notificationInterval: ReturnType<typeof setTimeout>;
	let messageInterval: ReturnType<typeof setTimeout>;

	const activePublisher = getActivePublisher();

	const handleScroll = () => {
		setScroll(window.pageYOffset > 0);
	};

	useEffect(() => {
		if (isEnabled(FEATURE_FLAG_NOTIFICATIONS)) {
			setIsLoadingNotifications(true);
			getTheNotifications();
			notificationInterval = setInterval(() => {
				getTheNotifications();
			}, POLL_INTERVAL);
		}

		return () => {
			clearInterval(messageInterval);
			clearInterval(notificationInterval);
			setIsLoadingNotifications(false);
		};
	}, []);

	const getTheNotifications = () => {
		NotificationsService.getNotifications()
			.then((res) => {
				if (res?.data) {
					const models = new Store();
					models.sync(res.data);
					const notifications = models.findAll('notification');
					dispatch(setNotifications(notifications));
				}
				setIsLoadingNotifications(false);
			})
			.catch((error: AxiosError) => {
				console.error(`Notification poll: `, error.message);
				clearInterval(notificationInterval);
			});
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true);
		return () => {
			window.removeEventListener('scroll', handleScroll, true);
		};
	}, []);

	const homePath = useAppSelector(getHomePath);

	return (
		<Styled.Wrapper className={classNames('header-bar', { 'fixed-header': scroll })}>
			<Styled.HeaderTop>
				<Styled.Logotype to={homePath}>{activePublisher ? activePublisher.name : <span>Collabs</span>}</Styled.Logotype>
				<Styled.Navigation data-testid='main-nav-icon'>
					<MainNavigation menuItems={props.menuItems!} location={props.location!} />
				</Styled.Navigation>
				<Styled.UserList>
					{isEnabled(FEATURE_FLAG_NOTIFICATIONS) && (
						<Styled.UserListItem data-testid='header-notifications-icon'>
							<NotificationsMenu isLoading={isLoadingNotifications} />
						</Styled.UserListItem>
					)}
					<Styled.UserListItem className='margin-left' data-testid='header-profile-icon'>
						<UserMenu />
					</Styled.UserListItem>
				</Styled.UserList>
			</Styled.HeaderTop>
		</Styled.Wrapper>
	);
};

export default Header;
