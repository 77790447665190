import styled from 'styled-components';

import colors from 'styles/variables/colors';

const dl = colors.dataLibrary;

const SearchInputWrapper = styled.div`
	width: 100%;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5px;

	border-radius: 50%;
	cursor: pointer;

	.icon {
		line-height: 0;
	}

	&:hover {
		background: ${dl.button.cancelBackground};
	}
`;

const CustomIconContainer = styled.div``;

const Styled = {
	SearchInputWrapper,
	IconContainer,
	CustomIconContainer,
};

export default Styled;
