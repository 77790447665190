import { Model } from 'json-api-models';

import { AssignmentType } from 'components/Assigment/types';
import { CampaignItemType } from 'components/ContentManagement/types';
import { FEATURE_FLAG_NEW_FRAME_SELECTOR } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';

import StatisticsForm from './StatisticsForm';

export type StatisticsProps = {
	active: boolean;
	selectedCampaign: CampaignItemType;
	selectedCIO?: Model;
	CIOAssignment: Model;
	assignmentType: AssignmentType;
	review: Model;
	assignment: Model;
	influencer: Model;
	refresh: () => Promise<void>;
	getUnsavedChanges: (unsavedChanges: boolean) => void;
};

const Statistics = (props: StatisticsProps) => {
	const [isEnabled] = useFeatureToggle();

	const renderForm = () => {
		if (isEnabled(FEATURE_FLAG_NEW_FRAME_SELECTOR)) {
			return <StatisticsForm {...props} />;
		} else return <></>;
	};

	return renderForm();
};

export default Statistics;
