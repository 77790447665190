import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { Model } from 'json-api-models';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CampaignItemType } from 'components/ContentManagement/types';
import { DetailItem } from 'components/CreateCampaign/types';
import PromoCodes from 'components/PromoCodes/PromoCodes';
import usePermissions from 'hooks/usePermissions';
import { pathCampaignDashboard, pathInfluencerCampaignView } from 'routing/PathLookup';
import colors from 'styles/theme/colors';

import { Styled } from './DetailSection.style';

type DetailSectionProps = {
	activeCampaign?: CampaignItemType;
	CIOAssignment?: Model;
	selectedCIO?: Model;
};

/**
 * DetailSection
 * @param {DetailSectionProps} props
 * @returns {JSX.Element}
 */
const DetailSection = ({ selectedCIO, activeCampaign, CIOAssignment }: DetailSectionProps): JSX.Element => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [backgroundVisible, setBackgroundVisible] = useState<boolean>(false);
	const [promoCodes, setPromoCodes] = useState<string[]>([]);

	const activeAssignment = CIOAssignment && CIOAssignment.assignment;

	const { isInfluencer } = usePermissions();
	const navigate = useNavigate();

	useEffect(() => {
		setBackgroundVisible(false);
		setIsVisible(activeCampaign !== undefined && activeAssignment !== null);

		return () => {
			setBackgroundVisible(true);
			setIsVisible(false);
			setPromoCodes([]);
		};
	}, [activeCampaign, activeAssignment]);

	useEffect(() => {
		const codes = activeCampaign?.campaignInstagramOwners?.map(({ campaignInstagramOwnerAssignments }) =>
			campaignInstagramOwnerAssignments?.map((assignment) => {
				if (assignment?.campaignCode?.length !== null) {
					return assignment.campaignCode;
				}
			}),
		);

		if (codes && codes.length > 0) {
			const all = codes.flat().filter((code) => code && code.length > 0) as Array<string>;
			setPromoCodes(all && all.length > 0 ? uniq(all) : []);
		} else {
			setPromoCodes([]);
		}
	}, [selectedCIO]);

	return (
		<Styled.Wrapper className={classNames({ visible: isVisible })}>
			{promoCodes?.length > 0 && <PromoCodes promoCodes={promoCodes} backgroundColor={colors.snow} />}
			{activeCampaign && activeAssignment && (
				<>
					<Styled.CTAContainer>
						{!isInfluencer() && <Styled.BriefButton onClick={() => navigate(pathCampaignDashboard(activeCampaign.shortId))}>Go to campaign</Styled.BriefButton>}
						{isInfluencer() && (
							<Styled.BriefButton onClick={() => window.open(pathInfluencerCampaignView(activeCampaign.shortId), '_blank')}>View brief</Styled.BriefButton>
						)}
					</Styled.CTAContainer>
					{CIOAssignment.campaignCode && (
						<PromoCodes promoCodes={CIOAssignment.campaignCode ? [CIOAssignment.campaignCode] : []} backgroundColor={colors.snow} />
					)}
					<Styled.Container data-testid='brief-container'>
						<Styled.CustomAccordion
							data-testid='background-accordion'
							className={classNames({ selected: backgroundVisible })}
							onClick={() => setBackgroundVisible(!backgroundVisible)}
						>
							<Styled.Background>
								<Styled.Heading>Assignment background</Styled.Heading>
								<Styled.AccordionContent>
									{activeAssignment?.details?.length ? (
										<>
											{activeAssignment?.details.map((detail: DetailItem, index: number) => {
												return (
													<Styled.DetailItem key={detail.title}>
														<Styled.DetailTitle>{detail.title}</Styled.DetailTitle>
														<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.content) }} />
														{index < activeAssignment?.details.length - 1 ? <hr /> : null}
													</Styled.DetailItem>
												);
											})}
										</>
									) : (
										<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activeAssignment.description) }} />
									)}
								</Styled.AccordionContent>
							</Styled.Background>
						</Styled.CustomAccordion>
					</Styled.Container>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default DetailSection;
