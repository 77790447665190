export default function previewFile(file: File | Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.addEventListener(
			'load',
			() => {
				resolve(reader.result as string);
			},
			false,
		);
		reader.addEventListener(
			'error',
			() => {
				reject(reader.error);
			},
			false,
		);

		reader.readAsDataURL(file);
	});
}
