import { CSSProperties } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Wrapper = styled.tr`
	width: 100%;
	overflow-x: auto;
	background-color: #fafafa;
	border: 1px solid ${newColors.ash};
	border-left: none;
	border-right: none;
	border-bottom: none;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;
	&:nth-child(even) {
		background-color: #f2f2f2;
	}

	&:hover {
		background-color: #c5c5c5;
	}
	&.isSelected {
		background-color: ${newColors.iceBlue};
	}
`;

const Td = styled.td<CSSProperties>`
	padding: ${guttersWithRem.s};
	text-align: ${(props) => props.textAlign || 'left'};
	max-width: ${(props) => props.maxWidth};
	height: ${(props) => props.maxHeight || '50px'};
	vertical-align: middle;
`;

const ExpendWrapper = styled(Wrapper)`
	background-color: ${colors.transparent};
	border-radius: 4px;

	& > ${Td} {
		padding: 0;
	}
`;

const ExpendInnrWrapper = styled.div`
	padding: ${guttersWithRem.s};
	border-radius: 4px;
	background-color: ${colors.mainWhite};
`;

const Div = styled.div<CSSProperties>`
	display: ${(props) => props.display};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	column-gap: ${(props) => props.columnGap};
	row-gap: ${(props) => props.rowGap};
	font-family: ${typography.list.fontFamily};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
`;

const InfluencerNameWrapper = styled.div`
	width: 200px;

	${({ theme }) => theme.mediaQueries.xl} {
		width: 250px;
	}

	&.slide {
		& > div {
			opacity: 0;
			transform: translateY(200%);
		}
	}

	& > div {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 0.1s ease-in;
		transition: transform 0.25s ease-in;
	}
`;

const InfluencerName = styled.p`
	width: 170px;
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.fontSize};
	line-height: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-bottom: 0;

	${({ theme }) => theme.mediaQueries.xl} {
		width: 250px;
	}
`;

const InfluencerSubText = styled(Div)`
	font-family: ${typography.list.accessory.fontFamilies};
	font-size: ${typography.list.small.fontSize};
	white-space: nowrap;

	margin-top: ${spacing[0].px};
	display: none;
	height: 15px;
	align-items: center;
`;

const InfluencerSubTextWrapper = styled.div`
	${InfluencerSubText} {
		&.follower {
			display: flex;
		}
	}

	&:hover {
		${InfluencerSubText} {
			&.follower {
				display: none;
			}

			&.hover-link {
				display: flex;
			}
		}
	}
`;

const Data = styled(Div)`
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.medium.fontSize};
	white-space: nowrap;
`;

const IconContainerWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	position: relative;
	cursor: pointer;
	width: 32px;
	max-height: 32px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.1s ease-in-out;

	& > i {
		height: 32px;
	}

	&.dropdown {
		& > div > div > button {
			&:hover,
			&.show {
				background-color: transparent !important;
			}
		}
	}

	&.detail-button {
		svg {
			transform: rotate(-45deg);
		}
	}

	&.show-detail {
		svg {
			transform: rotate(0deg);
		}
	}
`;

const CustomAvatar = styled(Avatar)`
	margin: 0;
`;

const CustomIcon = styled(Icon)`
	&.icon {
		line-height: 48px;
	}

	&.detail-button {
		padding: 0.3125rem;
	}

	&.network-icon {
		line-height: 0.87;
		fill: ${colors.button.sencondary.color};
	}

	&.disabled {
		cursor: not-allowed;
		fill: ${colors.button.primary.disabled.color};

		&:hover:after {
			content: 'coming soon';
			white-space: nowrap;
			font-size: 8px;
			padding: 0.25rem 0.5rem;
			position: absolute;
			top: 10px;
			left: -50%;
			z-index: 10;
		}
	}

	svg {
		transition: transform 0.1s ease-in-out;
	}
`;

const LinkToNetwork = styled.a`
	display: flex;
	text-decoration: none;
	align-items: center;
	gap: 5px;
	color: ${colors.button.sencondary.color};
	font-weight: 500;
	width: fit-content;

	border-bottom: none !important;

	&:hover {
		transition: revert;
		border-bottom: none !important;
	}
`;

const GrayText = styled.span`
	margin-left: ${guttersWithRem.xxs};
	font-size: 0.875rem;
	color: ${colors.discovery.grayText};
`;

const Styled = {
	Wrapper,
	ExpendWrapper,
	Td,
	Div,
	InfluencerNameWrapper,
	InfluencerName,
	InfluencerSubText,
	InfluencerSubTextWrapper,
	Data,
	IconContainer,
	IconContainerWrapper,
	CustomAvatar,
	CustomIcon,
	LinkToNetwork,
	ExpendInnrWrapper,
	GrayText,
};

export default Styled;
