import styled from 'styled-components';

import Accordion from 'components/Accordion';
import { Button, GhostButton } from 'components/Button';
import Checkbox from 'components/DataLibrary/Checkbox/Checkbox';
import Icon from 'components/Icon';
import Grid from 'styles/grid/grid';
import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import _colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const dl = _colors.dataLibrary;

const FilterWrapper = styled.div`
	padding: 1rem;
	width: 0%;
	transition: width 200ms ease-in-out;
	visibility: hidden;
	position: relative;
	&.visible {
		height: calc(100vh - 255px);
		${scrollbarY};
		visibility: visible;
		float: right;
		width: 100%;
	}
`;

const FilterSectionContainer = styled.div``;

const ClearThisFilter = styled(Button)`
	border: none;
	padding: 0;
	height: auto;
	background-color: transparent;
	cursor: pointer;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	&:disabled {
		div {
			color: 1px solid ${dl.darkGray};
		}
	}
	&:not(:disabled) div {
		color: ${dl.black};
		border-bottom: 1px solid ${dl.black};
	}
	&:hover {
		background-color: transparent !important;
		&:not(:disabled) {
			div {
				border-bottom: 1px solid ${dl.infoBlue} !important;
				color: ${dl.infoBlue} !important;
			}
		}
	}
`;
interface ICustomAccordionProps {
	bold?: boolean;
}
const CustomAccordion = styled(Accordion)<ICustomAccordionProps>`
	border-bottom: 1px solid ${dl.black};
	.accordion__title {
		border-bottom: transparent !important;
	}
	&.inActive {
		.accordion__title {
			opacity: 0.5;
			border-bottom: transparent !important;
		}
	}
	.accordion__header {
		padding: 1rem;
		padding-top: 1.2rem;
		font-family: ${typography.BaseFontFamiliy} !important;
		margin-bottom: 0;
		font-weight: ${(props) => props.bold && 700};

		&:hover {
			background-color: ${colors.smoke};
			color: ${dl.white};

			.icon path {
				stroke: ${dl.white};
			}
		}
	}
	& > input {
		border: none;
	}
	label {
		font-family: ${typography.BaseFontFamiliy} !important;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: 0px;
		text-align: left;
	}
`;

const OptionsList = styled.ul`
	max-height: 400px;
	height: auto;
	overflow: auto;
`;

const AccordionContent = styled.div`
	& > div {
		padding: 1rem;
	}
`;

const FilterOptionListItem = styled.li`
	font-family: ${typography.BaseFontFamiliy};
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0em;
	margin-bottom: 3px;
	border-radius: 4px;
	padding: 0rem 0.5rem;
	&:hover {
		cursor: pointer;
	}
	&.selected {
		background-color: ${dl.blackHover};
		color: ${dl.white};
		input > div {
			border: 1px solid ${dl.white};
		}
		svg {
			stroke: ${dl.white};
		}
	}
	img {
		height: 30px;
		width: 30px;
		border-radius: 100%;
		margin-left: 5px;
		margin-right: 5px;
	}
`;

interface ICustomCheckboxProps {
	checked?: boolean;
}
const CustomCheckbox = styled(Checkbox)<ICustomCheckboxProps>`
	.custom_checkbox {
		border-radius: 4px;
		border: ${(props) => props.checked && `1px solid ${dl.white}`};
		margin-right: 1rem;
	}
	label {
		font-family: ${typography.BaseFontFamiliy} !important;
		width: 250px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		cursor: pointer;
	}
`;

const BorderBottom = styled.div`
	border-bottom: 1px solid ${dl.black};
	padding-bottom: 1rem;
`;

const CustomIcon = styled(Icon)``;

const CustomButton = styled(GhostButton)`
	font-family: ${typography.BaseFontFamiliy} !important;
	height: 44px;
	width: 100%;
	border-radius: 4px;
	font-size: 1rem;
	margin-top: 16px;
	${CustomIcon} {
		&.icon {
			margin-left: 0;
			line-height: 0;
		}
	}

	&:hover:not([disabled]) {
		background-color: ${dl.blackHover} !important;
		color: ${dl.white} !important;
		svg {
			fill: ${dl.white};
		}
	}
`;

const FilterInputContainer = styled.div`
	margin-top: 1rem;
	margin-bottom: 5px;
`;

const ExtraMarginContainer = styled.ul`
	list-style: none;
	margin-top: 15px;
`;

const SpinnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 2rem;
`;

const CalendarContainer = styled.div`
	display: flex;
	button {
		background: transparent;
		border: none;
		cursor: pointer;
	}
`;

const DateFilterWrapper = styled.div`
	width: 100%;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
`;

const DateFilterLabel = styled.label`
	font-family: ${typography.BaseFontFamiliy} !important;
	font-size: 1rem;
`;

const DateFilters = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	background: transparent;
	border: 1px solid ${dl.darkPurple};
	border-radius: 4px;
	align-items: center;
	padding: 0.4rem;
	margin: 5px;
	&.selected {
		border: 2px solid ${dl.black};
	}
`;

const ClearButton = styled(GhostButton)`
	width: 100%;
	height: 56px;
	font-size: 1rem;
	&:hover {
		background-color: ${dl.blackHover} !important;
		color: ${dl.white} !important;
	}
`;

const FilterDataContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	span {
		font-weight: 700;
	}
`;

const CustomGridContainer = styled(Grid.Container)`
	width: 100%;
	margin-top: 1rem;
`;

const FiltersTitle = styled.div`
	font-size: 1rem;
	font-weight: 700;
	position: sticky;
	top: 0;
	padding-top: 0.063rem;
	z-index: 1;
	height: 56px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
	border-bottom: 1px solid #e3e3e3;
	div {
		&.saving-text {
			font-weight: 500;
		}
		&.link-text {
			font-weight: 500;
			border-bottom: 1px solid ${_colors.black1};
			cursor: pointer;
		}
	}
`;

const Total = styled.div`
	font-weight: 400;
`;

const UnsavedText = styled.div`
	margin-bottom: 16px;
`;

const FilterButtonWrapper = styled.div`
	padding: 1rem 1rem 2rem;
`;

const Styled = {
	FilterButtonWrapper,
	UnsavedText,
	FilterWrapper,
	Total,
	CustomAccordion,
	AccordionContent,
	CustomIcon,
	CustomButton,
	BorderBottom,
	FilterOptionListItem,
	CustomCheckbox,
	OptionsList,
	FilterInputContainer,
	ExtraMarginContainer,
	SpinnerContainer,
	CalendarContainer,
	DateFilterWrapper,
	DateFilterLabel,
	DateFilters,
	Icon,
	ClearButton,
	FilterDataContainer,
	ClearThisFilter,
	CustomGridContainer,
	FilterSectionContainer,
	FiltersTitle,
};

export default Styled;
