import classNames from 'classnames';

import fallbackAvatar from 'assets/icons/profile-picture-placeholder.svg';

import Styled from './Avatar.style';
import { IAvatarProps } from './types';

/**
 * Avatar Component
 * @param {IAvatarProps} props
 * @returns {JSX.Element}
 */
const Avatar = (props: IAvatarProps) => {
	const { imageUrl, name, cssClass, backgroundColor } = props;

	const resolveName = (name: string) => {
		if (!name) {
			return '';
		}

		if (name.length === 2) {
			return name;
		} else if (name.indexOf(' ') >= 0) {
			return name
				.split(' ')
				.map((word: string) => word[0])
				.join('')
				.slice(0, 2);
		} else {
			return name.charAt(0);
		}
	};

	return (
		<>
			{imageUrl && imageUrl !== null ? (
				<Styled.AvatarFigure title={name} className={classNames('avatar', cssClass)} aria-label={`Avatar of: ${name}`} {...props}>
					<img
						src={imageUrl}
						alt={name}
						onError={(e) => {
							(e.target as HTMLImageElement).onerror = null;
							/* Todo: Use a self hosted default image */
							(e.target as HTMLImageElement).src = fallbackAvatar;
						}}
						className='avatar__img'
						aria-label={`Avatar of: ${name}`}
					/>
				</Styled.AvatarFigure>
			) : (
				<Styled.FallbackAvatar
					className={classNames('avatar', cssClass)}
					backgroundColor={backgroundColor}
					aria-label={`Avatar of: ${name}`}
					title={name}
					data-testid='fallback-avatar'
					{...props}
				>
					<figcaption>
						<span>{resolveName(name)}</span>
					</figcaption>
				</Styled.FallbackAvatar>
			)}
		</>
	);
};

export default Avatar;
