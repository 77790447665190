import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const BottomWrapper = styled.div`
	width: 100%;
	line-height: 1;
`;

const SeeAll = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;

	.icon {
		margin-right: 8px;
		margin-top: 3px;
	}
	&:before,
	&:after {
		content: ' ';
		flex-grow: 1;
		height: 1px;
		background-color: ${colors.black};
	}

	&:before {
		margin-right: ${guttersWithRem.m};
	}

	&:after {
		margin-left: ${guttersWithRem.m};
	}
`;

const Styled = {
	BottomWrapper,
	SeeAll,
};

export default Styled;
