import { debounce } from 'lodash';

import Icon from 'components/Icon';

import Styled from './Sidebar.style';

type SidebarProps = {
	onSearch: (queryString: string) => void;
};

/**
 * Sidebar
 * @param {SidebarProps} props
 * @returns {JSX.Element}
 */
const Sidebar = ({ onSearch }: SidebarProps): JSX.Element => {
	const debounceSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
		onSearch(e.target.value);
	}, 400);

	return (
		<Styled.AffiliateSidebar>
			<Styled.SearchContainer>
				<Styled.SearchBox>
					<Styled.SearchInputContainer group={true}>
						<Icon name='search' size='20' />
						<Styled.SearchInput data-testid='am-search' placeholder='Search for products or brands' onChange={debounceSearch} type='search' />
					</Styled.SearchInputContainer>
				</Styled.SearchBox>
			</Styled.SearchContainer>
		</Styled.AffiliateSidebar>
	);
};

export default Sidebar;
