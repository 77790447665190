import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PublisherModel } from 'api-models';
import { UpdatePublisherPayload } from 'api-payloads';
import { DarkButton } from 'components/Button';
import CountrySelector from 'components/Form/Elements/CountrySelector/CountrySelector';
import InputText from 'components/Form/FormikElements/Text';
import LoadingSpinner from 'components/LoadingSpinner';
import Styled from 'components/Settings/Settings.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';

import VatValidation from './Components/VatValidation';

/**
 * BillingForm
 * @returns {JSX.Element}
 */
const Billing = (props: { publisher: PublisherModel }): JSX.Element => {
	const { publisher } = props;
	const [shouldValidate, setShouldValidate] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const manager = useInjection<PublisherManager>(PublisherManager);

	function handleSubmit(item: PublisherModel, payload: UpdatePublisherPayload) {
		const placeholder = structuredClone(item);
		setIsLoading(true);

		return manager
			.update(item.id, payload, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				toast.success(`Information saved`);
			})
			.catch((error) => {
				toast.error(getErrorMessageOnPost('updating the information'));
				console.error('Error saving the publisher', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Styled.Content>
			<h4>Billing</h4>
			<Grid.Container>
				<Grid.Column lg={7} xs={12}>
					<Formik
						initialValues={{
							invoiceEmail: publisher.attributes.invoiceEmail,
							legalName: publisher.attributes.legalName,
							invoiceAddress: publisher.attributes.invoiceAddress,
							invoicePostalCode: publisher.attributes.invoicePostalCode,
							invoiceCity: publisher.attributes.invoiceCity,
							invoiceCountryCode: publisher.attributes.invoiceCountryCode,
							vatNumber: publisher.attributes.vatNumber,
							orgNumber: publisher.attributes.vatNumber?.slice(2),
						}}
						enableReinitialize
						validateOnBlur={shouldValidate}
						validateOnChange={shouldValidate}
						onSubmit={(values) => {
							handleSubmit(publisher, {
								invoiceEmail: values.invoiceEmail,
								legalName: values.legalName,
								invoiceAddress: values.invoiceAddress,
								invoicePostalCode: values.invoicePostalCode,
								invoiceCity: values.invoiceCity,
								invoiceCountryCode: values.invoiceCountryCode,
								vatNumber: values.vatNumber,
							});
						}}
						validationSchema={Yup.object().shape({
							legalName: Yup.string().required('Required'),
						})}
					>
						{({ setFieldValue, values, dirty }) => (
							<Form>
								<InputText label='Billing email' type='email' id='invoiceEmail' name='invoiceEmail' placeholder='you@collabs.app' />
								<InputText label='Company name' name='legalName' id='name' placeholder='Company name' />
								<InputText label='Company address' id='invoiceAddress' name='invoiceAddress' placeholder='Company address' />
								<Styled.ZipAndCity>
									<div className='left'>
										<InputText label='ZIP/postal code' id='invoicePostalCode' name='invoicePostalCode' placeholder='Zip code' />
									</div>
									<div className='right'>
										<InputText label='City' id='invoiceCity' name='invoiceCity' placeholder='City' />
									</div>
								</Styled.ZipAndCity>
								<CountrySelector name='invoiceCountryCode' />
								<VatValidation setFieldValue={setFieldValue} values={values} />
								<Styled.ButtonGroup>
									<DarkButton
										size='sm'
										type='submit'
										disabled={!dirty}
										data-testid='submit-btn'
										onClick={() => {
											if (!shouldValidate) {
												setShouldValidate(true);
											}
										}}
									>
										{isLoading ? <LoadingSpinner size='sm' /> : 'Save'}
									</DarkButton>
								</Styled.ButtonGroup>
							</Form>
						)}
					</Formik>
				</Grid.Column>
			</Grid.Container>
		</Styled.Content>
	);
};

export default Billing;
