import BugsnagPerformance from '@bugsnag/browser-performance';
import { ReactRouterRoutingProvider } from '@bugsnag/react-router-performance';
import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'sonner';
import { ThemeProvider } from 'styled-components';

import FeatureToggle from 'components/FeatureToggle';
import FullStoryInit from 'components/FullStoryInit';
import { KEY_COLLABS_API_USER_OBJECT } from 'constants/localStorage-keys';
import { initFacebookSdk } from 'shared/facebook/facebook-sdk';
import { store } from 'store/User';
import { saveState } from 'store/User/Utils';
import GlobalStyle from 'styles/global';
import lightTheme from 'styles/theme/light';
import { omit } from 'utils/objects';

import VersionWatcher from './components/VersionWatcher/VersionWatcher';
import { enabledFeatures } from './featureFlags';
import ErrorElement from './routing/ErrorElement';
import RouteList from './routing/Routes';
import { sonnerToasterIcons, sonnerToasterOptions } from './services/Toast';

const App = () => {
	const DEBOUNCE_TIME = 800;

	useEffect(() => {
		initFacebookSdk();

		const unsubscribe = store.subscribe(
			debounce(() => {
				const { user } = store.getState();
				const userWithoutLoggingOut = omit(user, ['isLoggingOut']); // remove isLoggingOut from object before we store the user object
				saveState(userWithoutLoggingOut, KEY_COLLABS_API_USER_OBJECT);
			}, DEBOUNCE_TIME),
		);
		return unsubscribe;
	}, []);

	const routes = RouteList();
	BugsnagPerformance.start({
		apiKey: 'c2e978af0b1dc628376202ef81d40e47',
		appVersion: process.env.VITE_APP_VERSION || 'no-version',
		releaseStage: process.env.VITE_APP_RELEASE_STAGE || 'development',
		routingProvider: new ReactRouterRoutingProvider(routes),
	});

	const router = createBrowserRouter([{ errorElement: <ErrorElement />, children: routes }]);

	return (
		<>
			<FullStoryInit />
			<VersionWatcher />
			<ThemeProvider theme={lightTheme}>
				<GlobalStyle />
				<FeatureToggle enabledFeatures={enabledFeatures}>
					<RouterProvider router={router} />
					<Toaster position='bottom-center' toastOptions={sonnerToasterOptions} icons={sonnerToasterIcons} />
				</FeatureToggle>
			</ThemeProvider>
		</>
	);
};

export default App;
