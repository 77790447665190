import colors from 'styles/theme/colors';

export type ToggleTheme = {
	thHeadBackground: string;
	thHeadColor: string;
	backgroundEven: string;
	backgroundOdd: string;
	hoverBackground: string;
};

export const light: ToggleTheme = {
	thHeadBackground: colors.mist,
	thHeadColor: colors.black,
	backgroundEven: colors.mist,
	backgroundOdd: colors.snow,
	hoverBackground: colors.dust,
};

export const dark: ToggleTheme = {
	thHeadBackground: colors.mist,
	thHeadColor: colors.black,
	backgroundEven: colors.mist,
	backgroundOdd: colors.snow,
	hoverBackground: colors.dust,
};
