import styled from 'styled-components';

import colors from 'styles/theme/colors';
import typography from 'styles/variables/typography';

const ArrowRight = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

const ListItemContent = styled.div`
	border-bottom: 1px solid ${colors.smoke};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 64px;
`;

const ListItemTitleContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-top: 5px;
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	padding: 0 1rem 0 2rem;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 600;
	height: 64px;
	transition: all 200ms ease-in-out;

	.icon {
		margin-right: 16px;
		margin-top: 5px;

		path {
			transition: fill 200ms ease-in-out;
		}
	}

	&:hover {
		cursor: pointer;
		background-color: ${colors.smoke};
		color: ${colors.snow};

		svg path {
			fill: ${colors.snow};
		}
	}
`;

const WrapperMarginLeft = styled.div`
	margin-left: 16px;
	height: 100%;
`;

const Styled = {
	ListItem,
	ListItemContent,
	ListItemTitleContainer,
	WrapperMarginLeft,
	ArrowRight,
};

export default Styled;
