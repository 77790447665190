import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import marketplaceBanner from 'assets/img/app/bannerImages/marketplace.png';
import PromotionalBanner, { PromotionalBannerProps } from 'components/PromotionalBanner/PromotionalBanner';
import { AFFILIATE_MARKETPLACE_BANNER } from 'constants/localStorage-keys';
import useModels from 'hooks/useModels';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

import { CampaignSection, InvitationSection } from './Components';
import Styled from './InfluencerDashboardContainer.style';
import { getCampaignsUri } from './utils';

/**
 * Influencer dashboard
 * @returns {JSX.Element}
 */
const InfluencerDashboardContainer = (): JSX.Element => {
	const campaignStore = useModels(getCampaignsUri());
	const storage = new BrowserStorage(StorageType.LOCAL);
	const navigate = useNavigate();
	const [showMarketplaceBanner, setShowMakerplaceBanner] = useState(false);

	const bannerProps: PromotionalBannerProps = {
		image: marketplaceBanner,
		altText: 'Affiliate marketplace',
		text: (
			<>
				<h3>Marketplace is now live</h3>
				<p>Join our affiliate programs and maximize your earnings.</p>
			</>
		),
		onClick: () => {
			navigate('/affiliate/marketplace');
			storage.setItem(AFFILIATE_MARKETPLACE_BANNER, 'hidden');
			setShowMakerplaceBanner(false);
		},
		onClickClose: () => {
			storage.setItem(AFFILIATE_MARKETPLACE_BANNER, 'hidden');
			setShowMakerplaceBanner(false);
		},
		CTAText: 'Visit Marketplace',
	};

	useEffect(() => {
		setShowMakerplaceBanner(!storage.getItem(AFFILIATE_MARKETPLACE_BANNER));
	}, []);

	return (
		<Styled.Wrapper>
			{showMarketplaceBanner && (
				<Styled.BannerContainer>
					<PromotionalBanner {...bannerProps} />
				</Styled.BannerContainer>
			)}
			<InvitationSection />
			<CampaignSection campaignStore={campaignStore.models} />
		</Styled.Wrapper>
	);
};

export default InfluencerDashboardContainer;
