import { CSSProperties } from 'react';
import styled from 'styled-components';

import { GhostButton, DarkButton } from 'components/Button';
import { Table, TableHeader, TableBody, Th, Tr, Td } from 'styles/table';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div``;

const ListTable = styled.table`
	${Table};
`;

const ListTableHead = styled.thead`
	${TableHeader};
`;

const ListTableBody = styled.tbody`
	${TableBody};
`;

const ListTableTh = styled.th`
	${Th};
	& > div {
		font-size: 0.75rem;
	}
`;

const ListTableHeadTr = styled.tr`
	${Tr};
	background-color: ${({ theme }) => theme.table.thHeadBackground};
	border-top: none;
`;

const ListTableTd = styled.td<CSSProperties>`
	${Td};

	padding: 1.25rem 1rem;
	text-align: ${(props) => props.textAlign || 'left'};
	max-width: ${(props) => props.maxWidth};
	min-width: ${(props) => props.minWidth};
	width: ${(props) => props.width};
	height: ${(props) => props.maxHeight || '50px'};
	&.space-left {
		min-width: 120px;
	}

	&.no-min-width {
		min-width: unset;
	}
`;

const ListTableBodyTr = styled.tr`
	cursor: pointer;
	width: 100%;
	overflow-x: auto;
	border-left: none;
	border-right: none;
	border-bottom: none;
	transition: background-color ease-in-out 0.2s;
	background-color: ${({ theme }) => theme.table.backgroundOdd};

	&:nth-child(even) {
		background-color: ${({ theme }) => theme.table.backgroundEven};
	}

	&:hover {
		background-color: ${({ theme }) => theme.table.hoverBackground};
	}
`;

const SearchInputContainer = styled.div`
	max-width: 352px;
`;

const ActionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${spacing[4].rem};
`;

const ActionsBtnsWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	& button {
		margin-right: ${spacing[2].rem};

		&:last-child {
			margin-right: 0;
		}
	}
`;

const Button = styled(GhostButton)``;
const SecondaryButton = styled(DarkButton)``;

const Styled = {
	Wrapper,
	SearchInputContainer,
	ListTable,
	ListTableHead,
	ListTableHeadTr,
	ListTableTh,
	ListTableBody,
	ListTableBodyTr,
	ListTableTd,
	ActionsWrapper,
	ActionsBtnsWrapper,
	Button,
	SecondaryButton,
};

export default Styled;
