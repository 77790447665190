import styled from 'styled-components';

import Icon from 'components/Icon';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	background-color: ${colors.mainWhite};
`;

const IntroTextWrapper = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	padding: ${spacing[3].rem} 0;
`;

const WelcomeText = styled.div`
	margin: ${spacing[3].px} 0;
	font-size: 2.5rem;
	h1 {
		font-size: 2.5rem;
	}
	p {
		font-size: 1.2rem;
		@media screen and (${devices.lg}) {
			font-size: 2.065rem;
		}
	}
`;

const SpotsLeft = styled.div`
	font-size: 1.037rem;
	color: ${colors.button.sencondary.color};
	margin-bottom: ${guttersWithRem.s};
`;

const CustomIcon = styled(Icon)`
	fill: ${colors.button.sencondary.color};
`;

const PubliserLogo = styled.img`
	margin: 0 auto;
	width: 100%;
	max-width: 200px;
`;

const PubliserLogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 24px;
`;

const CoverImage = styled.div<{ backgroundUrl: string }>`
	height: 550px;
	width: 100%;
	margin: 0 auto;
	background: url(${(props) => props.backgroundUrl});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;
const ImageWrapper = styled.div`
	& > img {
		object-fit: cover;
	}
	right: 0;
	left: 0;
`;

const JoinMessage = styled.div`
	font-family: Epilogue;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	margin-bottom: ${spacing[3].px};
	display: none;
	@media screen and (${devices.lg}) {
		display: block;
	}
`;

const Styled = {
	JoinMessage,
	CoverImage,
	Wrapper,
	IntroTextWrapper,
	WelcomeText,
	SpotsLeft,
	CustomIcon,
	PubliserLogo,
	PubliserLogoWrapper,
	ImageWrapper,
};

export default Styled;
