import { NavigateFunction } from 'react-router-dom';

import { DarkButton } from 'components/Button';
import Icon from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';

import Styled from './ActionBar.style';

type Props = {
	navigate: NavigateFunction;
};

/**
 * ActionBar
 * @param props
 * @returns {JSX.Element}
 */
const ActionBar = (props: Props): JSX.Element => {
	const { navigate } = props;
	const { isGranted } = usePermissions();

	return (
		<Styled.Wrapper>
			{isGranted(ROLE_PROJECT_MANAGER) && (
				<>
					<DarkButton
						data-testid='ab-new-campaign'
						onClick={() => {
							navigate('/campaigns/create');
						}}
					>
						New campaign <Icon name='expand' />
					</DarkButton>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default ActionBar;
