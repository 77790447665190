import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';

type T = { theme: DefaultThemeV2 };

const NoClients = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	justify-content: center;
	align-items: center;
`;

const IconContainer = styled.div`
	background-color: ${colors.white};
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
	.icon {
		margin-top: 3px;
	}
`;

export default {
	NoClients,
	IconContainer,
};
