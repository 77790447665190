import axios, { AxiosError, AxiosInstance } from 'axios';
import { isEmpty } from 'lodash';

import { ICollabsResponse, StatusCode } from 'services/Response.types';
import Client from 'shared/ApiClient';
import { createClient } from 'shared/ApiClient/ApiClient';

import { CampaignParam, CampaignResponse, ICampaignService } from './types';

/**
 * @deprecated Use ApiManager instead
 */
class CampaignService implements ICampaignService {
	ENDPOINT_URL: string;
	private client: AxiosInstance;

	constructor() {
		this.ENDPOINT_URL = '/campaigns';
		this.client = createClient();
	}

	/**
	 * @deprecated Use ApiManager instead
	 */
	fetchCampaigns = async (param: CampaignParam) => {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(addInfluencers)');
		params.append('limit', '500');

		if (param.statuses && !isEmpty(param.statuses)) {
			params.append('statuses', param.statuses.join(','));
		}
		const { data } = await this.client.get<CampaignResponse>(`${this.ENDPOINT_URL}?${params}`);
		return data;
	};

	/**
	 * @deprecated Use ApiManager instead
	 */
	fetchOneCampaignProduct = async (param: CampaignParam): Promise<ICollabsResponse> => {
		let url = this.ENDPOINT_URL;
		if (param.id) {
			const campaignId = param.id;
			url = `${this.ENDPOINT_URL}/${campaignId}/products`;
		}
		const { data } = await this.client.get<ICollabsResponse>(url);
		return data;
	};

	/**
	 * @deprecated Use ApiManager instead
	 */
	fetchExtraCampaignData = async (url: string): Promise<ICollabsResponse> => {
		const { data } = await this.client.get<ICollabsResponse>(url);
		return data;
	};

	/**
	 * @deprecated Use ApiManager instead
	 */
	setInfluencersToCampaign = async (url: string, influencers: string[]): Promise<number> => {
		let result = 0;

		await Client('post', url, { collabsIds: influencers })
			.then(() => {
				result = StatusCode.CREATED;
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<{ source: { message: number } }> };
					result = errorData.errors?.[0]?.source?.message ?? 0;
				}
			});

		return result;
	};

	/**
	 * fetchInfluencerPreviewData
	 * @deprecated Use fetchInfluencerPreviewDataByUrl and pass hateoas link as url
	 * @param campaignId
	 * @param influencerId
	 * @returns {any}
	 */
	fetchInfluencerPreviewData = async (campaignId: string, influencerId: string) => {
		const { data } = await this.client.get(`${this.ENDPOINT_URL}/${campaignId}/invite/${influencerId}/preview?includes=brand.client.publisher.images`);

		return data;
	};

	/**
	 * @deprecated Use ApiManager instead
	 */
	fetchInfluencerPreviewDataByUrl = async (url: string) => {
		const { data } = await this.client.get(url);
		return data;
	};
}

export default new CampaignService();
