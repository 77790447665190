import { inject, injectable } from 'inversify';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Dispatch } from 'redux';

import { KEY_MERCURE_TOKEN, KEY_TOKENS } from 'constants/localStorage-keys';
import AuthenticationManager from 'services/ApiManager/Authentication.manager';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';

import { MercureMessage, ServiceIdentifier } from './types';

import type MessageHandlerInterface from './MessageHandlerInterface';

/**
 * This service sets up a connection to the Mercure hub and listens for new messages.
 * It also handles errors, re-connects etc.
 *
 * This service is stateful.
 */
@injectable()
class MercureService {
	private eventSource: EventSource | null = null;
	private tokenExpireTimout: number | null = null;
	private reconnectTimeout: number | null = null;
	private manager: AuthenticationManager;
	private storage: BrowserStorage;
	private dispatchFn: Dispatch | null = null;
	private handler: MessageHandlerInterface;

	constructor(
		@inject(AuthenticationManager) authenticationManager: AuthenticationManager,
		@inject(ServiceIdentifier.MercureMessageHandler) handler: MessageHandlerInterface,
		private hubUrl: string = 'https://mercure.collabs.app/.well-known/mercure',
	) {
		this.manager = authenticationManager;
		this.storage = new BrowserStorage();
		this.handler = handler;
	}

	/**
	 * Start the subscription to the Mercure hub.
	 */
	public async startSubscription(dispatch: Dispatch): Promise<void> {
		this.dispatchFn = dispatch;

		const token = await this.verifyToken();
		this.subscribe(token);
	}

	/**
	 * Stop the subscription to the Mercure hub.
	 */
	public stopSubscription = () => {
		this.dispatchFn = null;
		this.clearToken();
		this.cancelTimeouts();

		if (this.eventSource !== null) {
			this.eventSource.close();
			this.eventSource = null;
		}
	};

	private subscribe = (token?: string | null | undefined) => {
		const storedToken = token !== undefined ? this.getToken() : token;
		if (!storedToken) {
			console.error('No Mercure token available. Cannot subscribe.');
			return;
		}

		const url = new URL(this.hubUrl);
		url.searchParams.append('authorization', storedToken);
		const decodedToken: JwtPayload = jwtDecode(storedToken);

		// @ts-ignore
		for (const topic of decodedToken.mercure?.subscribe ?? []) {
			url.searchParams.append('topic', topic);
		}

		this.eventSource = new EventSource(url);

		this.eventSource.onmessage = (event) => {
			const eventData = JSON.parse(event.data);
			if (eventData.type !== undefined && typeof eventData.attributes === 'object') {
				this.handleNewMercureMessage(eventData);
			}
		};

		this.eventSource.onerror = (error) => {
			console.error('Error with Mercure event source:', error);

			if (this.eventSource?.readyState === EventSource.CLOSED) {
				//connection was closed, try reconnect
				this.tryReconnect();
			} else {
				// Handle other errors, e.g., network issues
				console.error('Other error:', error);
				this.tryReconnect();
			}
		};
	};

	private handleNewMercureMessage = (event: MercureMessage) => {
		if (this.dispatchFn === null) {
			return;
		}
		this.handler.handle(event, this.dispatchFn);
	};

	/**
	 * Get token from Collabs API
	 */
	public requestNewMercureToken = async (): Promise<string | null> => {
		try {
			const response = await this.manager.createMercureToken();
			const token = response.attributes.token;
			this.storeToken(token);
			this.expirationTimer(token);

			return token;
		} catch (error) {
			console.error('Error getting mercure token: ', error);
			return null;
		}
	};

	private tryReconnect = () => {
		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
		}

		this.reconnectTimeout = window.setTimeout(() => {
			// eslint-disable-next-line no-console
			console.log('[Mercure] Attempting to reconnect...');
			this.subscribe();
		}, 15_000);
	};

	private cancelTimeouts = () => {
		if (this.tokenExpireTimout !== null) {
			clearTimeout(this.tokenExpireTimout);
			this.tokenExpireTimout = null;
		}

		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = null;
		}
	};

	private expirationTimer = (token: string | null) => {
		if (!token) {
			return;
		}

		const decodedToken: JwtPayload = jwtDecode(token);
		if (decodedToken.exp === undefined) {
			console.error('No expiration date found in token');
			return;
		}

		const timeoutAfterMs = decodedToken.exp * 1000 - (Date.now() - 60_000);

		this.tokenExpireTimout = window.setTimeout(async () => {
			const token = await this.requestNewMercureToken();
			this.subscribe(token);
		}, timeoutAfterMs);
	};

	/**
	 * Make sure token is valid. If not, try to get a new one.
	 */
	private verifyToken = async (): Promise<string | null> => {
		const storedToken = this.getToken();
		if (!storedToken) {
			return await this.requestNewMercureToken();
		} else {
			const decodedToken = jwtDecode(storedToken);

			// If the token is expired, request a new one
			if (this.isTokenExpired(decodedToken)) {
				return await this.requestNewMercureToken();
			}
		}

		return storedToken;
	};

	private storeToken = (token: string) => {
		const tokens = JSON.parse(this.storage.getItem(KEY_TOKENS) || '{}');
		tokens[KEY_MERCURE_TOKEN] = token;
		this.storage.setItem(KEY_TOKENS, JSON.stringify(tokens));
	};

	private getToken = (): string | null => {
		const tokens = this.storage.getItem(KEY_TOKENS);

		if (!tokens) {
			return null;
		}

		try {
			const parsedTokens = JSON.parse(tokens) as unknown;
			if (typeof parsedTokens === 'object' && parsedTokens !== null && !Array.isArray(parsedTokens)) {
				const tokenObj = parsedTokens as { [key: string]: string };
				return tokenObj[KEY_MERCURE_TOKEN] ?? null;
			} else {
				console.warn('Parsed tokens is not an object:', parsedTokens);
				return null;
			}
		} catch (error) {
			console.error('Failed to parse tokens:', error);
			return null;
		}
	};

	private clearToken = (): void => {
		const tokens = JSON.parse(this.storage.getItem(KEY_TOKENS) || '{}');
		tokens[KEY_MERCURE_TOKEN] = null;
		this.storage.setItem(KEY_TOKENS, JSON.stringify(tokens));
	};

	private isTokenExpired = (token: JwtPayload) => {
		const currentTimeStamp = Math.floor(Date.now() / 1000);

		if (token.exp === undefined) {
			return true;
		}

		// If it is soon to expire
		return token.exp < currentTimeStamp + 200;
	};
}

export default MercureService;
