import { Doughnut } from 'components/Chart';

import Styled from './GenderData.style';

const GenderData = (props: { data: { total: number; data: number[]; labels: string[] }; colors: string[] }) => {
	return (
		<Styled.Wrapper>
			<div className='doughnut'>
				<Doughnut
					labels={props.data.labels}
					outsideLabel={false}
					legendDisplay={false}
					backgroundColors={props.colors}
					data={props.data.data}
					cutout='75%'
					spacing={0}
					borderWidth={0}
					innerFontSize='1em'
				/>
			</div>

			<div className='labels'>
				<table>
					<tbody>
						{props.data.labels.map((item, index) => {
							const percent = props.data.total > 0 ? `${Math.round(props.data.data[index] * 100)}%` : `0%`;
							return (
								<Styled.TableRow key={item}>
									<td>
										<Styled.ColorDot color={props.colors[index]} />
									</td>
									<td>{item}</td>
									<td>{percent}</td>
								</Styled.TableRow>
							);
						})}
					</tbody>
				</table>
			</div>
		</Styled.Wrapper>
	);
};

export default GenderData;
