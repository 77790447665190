import { isAxiosError } from 'axios';
import { Formik, Form } from 'formik';
import { isNil } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import Styled from 'components/AffiliateMarketplaceCampaignForm/AffiliateMarketplaceCampaignFormContainer.style';
import CreationForm from 'components/AffiliateMarketplaceCampaignForm/CreationForm';
import useAffiliateMarketplaceData, { AM_BASE_URL } from 'components/AffiliateMarketplaceCampaignForm/hooks';
import { CampaignFormItem, CreationFormData, ICampaignFormItem } from 'components/AffiliateMarketplaceCampaignForm/types';
import { CREATION_FORM_VALIDATION } from 'components/AffiliateMarketplaceCampaignForm/utils';
import { Button } from 'components/Button';
import ConfirmModalV2 from 'components/Modals/ConfirmModalV2';
import { EDIT_AM_CAMPAIGN, UPLOAD_COVER_IMAGE, DELETE_AM_CAMPAIGN } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { LinksType } from 'reducers/UserReducers/types';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';

/**
 * @returns {JSX.Element}
 */
const AMCampaignEditContainer = () => {
	const { mutate } = useSWRConfig();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [shouldValidate, setShouldValidate] = useState<boolean>(false);
	const [initialValues, setInitialValues] = useState<CreationFormData>({
		name: '',
		adtractionId: '',
		storeLink: '',
		coverImage: undefined,
		ogMetaImage: undefined,
		coverImageFile: undefined,
		storeCommissionPercentage: 0,
		influencerCommissionPercentage: 0,
		background: '',
		guidelines: '',
		sort: 0,
	});

	const [amCampaign, setAmCampaign] = useState<ICampaignFormItem | undefined>();
	const { userCan } = useFeaturePermissions((amCampaign?.links as LinksType) || undefined);

	const { programId } = useParams();
	const navigate = useNavigate();

	const { getProgram, updateProgram, saveCoverImage, deleteProgram } = useAffiliateMarketplaceData();
	const { models, isLoading, refresh } = getProgram(programId);

	useEffect(() => {
		if (!isNil(programId) && !isLoading) {
			const newAmCampaign = models?.find({ type: 'affiliateProgram', id: programId });

			setAmCampaign(newAmCampaign);
		}
	}, [programId, isLoading]);

	useEffect(() => {
		if (!isNil(amCampaign)) {
			setInitialValues(new CampaignFormItem(amCampaign));
		}
	}, [amCampaign]);

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={CREATION_FORM_VALIDATION}
			validateOnBlur={shouldValidate}
			validateOnChange={shouldValidate}
			onSubmit={async (values, { setSubmitting }) => {
				if (userCan(EDIT_AM_CAMPAIGN)) {
					setSubmitting(true);
					try {
						if (values.coverImageFile && userCan(UPLOAD_COVER_IMAGE)) {
							await saveCoverImage(amCampaign?.links[UPLOAD_COVER_IMAGE], values.coverImageFile);
						}

						await updateProgram(amCampaign?.links[EDIT_AM_CAMPAIGN], values).then(async () => {
							!isNil(refresh) && (await refresh());
							toast.success('Affiliate program is updated');
							await mutate(
								(key: string) => {
									return key.includes(AM_BASE_URL);
								},
								undefined,
								{
									populateCache: true,
								},
							).then(() => {
								navigate('/affiliate/programs');
							});
						});
					} catch (err) {
						if (isAxiosError(err) && err.message) {
							toast.error(err.message);
						}
						console.error(err);
					} finally {
						setSubmitting(false);
					}
				}
			}}
		>
			{({ values, isValid, isSubmitting }) => {
				return (
					<Form>
						<div>
							<Styled.TopBar>
								<Styled.ButtonsWrapper>
									<Button
										size='sm'
										type='button'
										onClick={() => {
											setIsDeleteModalOpen(true);
										}}
										disabled={!userCan(DELETE_AM_CAMPAIGN)}
									>
										Delete
									</Button>
									<Button data-testid='save-button' disabled={!isEdited || !isValid || !userCan(EDIT_AM_CAMPAIGN) || isSubmitting} size='sm' type='submit'>
										Save changes
									</Button>
								</Styled.ButtonsWrapper>
							</Styled.TopBar>
							<Grid.Container>
								<Grid.Column sm={12} lg={2} />
								<Grid.Column sm={12} lg={8}>
									<CreationForm
										isLoading={isLoading || isSubmitting}
										setIsEdited={(value: boolean) => {
											if (!shouldValidate && value) {
												setShouldValidate(true);
											}
											setIsEdited(value);
										}}
									/>
								</Grid.Column>
							</Grid.Container>
						</div>
						<ConfirmModalV2
							isModalOpen={isDeleteModalOpen}
							toggleModal={() => setIsDeleteModalOpen(false)}
							action={() => {
								if (userCan(DELETE_AM_CAMPAIGN)) {
									deleteProgram(amCampaign?.links[DELETE_AM_CAMPAIGN]).then(async () => {
										await mutate(
											(key: string) => {
												return key.includes(AM_BASE_URL);
											},
											undefined,
											{
												revalidate: true,
											},
										).then(() => {
											navigate('/affiliate/programs');
										});
									});
								}
							}}
							buttonText='Yes, delete campaign'
							isFetching={isSubmitting}
						>
							<Styled.ComfirmModalInner>
								<div className='title'>Delete campaign</div>
								<p>You are about to delete </p>
								<p>{values.name}</p>
							</Styled.ComfirmModalInner>
						</ConfirmModalV2>
					</Form>
				);
			}}
		</Formik>
	);
};

export default AMCampaignEditContainer;
