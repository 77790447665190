import classnames from 'classnames';
import { uniq } from 'lodash';
import React, { useContext, useState } from 'react';

import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './BrandAffiliationsFilter.style';

const BrandAffiliationsFilter = (props: {
	brandAffiliations: { [key: string]: string[] };
	onSetFilter: (value: string, option: string) => void;
	onRemoveBrand: (value: string, option: string) => void;
}) => {
	const { filter } = useContext(DiscoveryContext);
	const [enteredText, setEnteredText] = useState('');
	const [option, setOption] = useState<string>('include');

	const setBrandName = (e: React.KeyboardEvent) => {
		if (e.code === 'Enter' && enteredText.length > 0) {
			// Check for multiple mentions or words
			const hasMultipleMentions = enteredText.split(/@/).length > 2;
			const hasMultipleWords = enteredText.indexOf(' ') >= 0;

			const valuesInFilter = filter?.brandAffiliations;

			if (hasMultipleMentions) {
				const arrayOfMentions = enteredText
					.split(/@/)
					.filter((mention) => mention.length)
					.map((m) => m.trim());

				uniq(arrayOfMentions).forEach((mention) => {
					const atRemoved = mention.replace('@', '').replace('#', '').trim();

					if (mention.length > 0 && !valuesInFilter?.includes(atRemoved)) {
						props.onSetFilter(atRemoved, option);
					}
				});
			} else if (hasMultipleWords) {
				// Remove and @ and make sure the same word is not repeated
				uniq(enteredText.split(' ')).forEach((mention) => {
					const mentionToSave = mention.replace('#', '').replace('@', '').trim();
					if (mentionToSave.length > 0 && !valuesInFilter?.includes(mentionToSave)) {
						props.onSetFilter(mentionToSave, option);
					}
				});
			} else {
				const valueWithoutAt = enteredText.replace('@', '').replace('#', '').trim();
				if (!valuesInFilter?.includes(valueWithoutAt)) {
					props.onSetFilter(valueWithoutAt, option);
				}
			}
			setEnteredText('');
		}
	};

	const changeOptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOption(e.target.value);
	};

	const removeBrandName = (value: string, option: string) => {
		props.onRemoveBrand(value, option);
	};

	return (
		<Styled.Wrapper>
			<Input
				onReset={() => {
					setEnteredText('');
				}}
				value={enteredText}
				onChange={(e) => {
					setEnteredText(e.target.value);
				}}
				onKeyDown={setBrandName}
				contentAfter={
					enteredText ? (
						<Styled.ContentAfterContainter>
							<Styled.EnterLabel title='Press "enter" to submit'>[enter]</Styled.EnterLabel>
							<Styled.IconContainer onClick={() => setEnteredText('')}>
								<Icon name='cancel-circle' size='16' />
							</Styled.IconContainer>
						</Styled.ContentAfterContainter>
					) : undefined
				}
			/>
			<Styled.OptionWrapper>
				<Styled.RadioButtonWrapper className={classnames({ selected: option === 'include' })}>
					<input type='radio' id='include' name='barnd-mentions' value='include' checked={option === 'include'} onChange={changeOptionHandler} />
					<label htmlFor='include'>Include</label>
				</Styled.RadioButtonWrapper>
				<Styled.RadioButtonWrapper className={classnames({ selected: option === 'exclude' })}>
					<input type='radio' id='exclude' name='barnd-mentions' value='exclude' checked={option === 'exclude'} onChange={changeOptionHandler} />
					<label htmlFor='exclude'>Exclude</label>
				</Styled.RadioButtonWrapper>
			</Styled.OptionWrapper>

			<Styled.BrandsWrapper>
				{props.brandAffiliations[option].map((brand, index) => {
					return (
						<Styled.BrandItem
							key={index}
							onClick={() => {
								removeBrandName(brand, option);
							}}
						>
							{brand}
							<Icon name='cross' size='8' />
						</Styled.BrandItem>
					);
				})}
			</Styled.BrandsWrapper>
		</Styled.Wrapper>
	);
};

export default BrandAffiliationsFilter;
