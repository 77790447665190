import classNames from 'classnames';
import { isEmpty, isNil, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import errorImage from 'assets/img/app/empty-state-error.svg';
import location from 'assets/img/app/empty-state-location.svg';
import AffiliateCard from 'components/AffiliateCard';
import { AMCampaignCard } from 'components/AffiliateMarketplace/types';
import { allowedCountries } from 'components/AffiliateMarketplace/utils';
import useAffiliateMarketplaceData from 'components/AffiliateMarketplaceCampaignForm/hooks';
import EmptyState from 'components/EmptyState/V2';
import LoadingSpinner from 'components/LoadingSpinner';
import { LIST_AFFILIATE_PROGRAM } from 'constants/hateoas-keys';
import usePermissions from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setIsShowDetailSidebar, setSelectedAMCampaign } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';

import Styled from './ActiveCampaigns.style';
import useCardFocus from './hooks/useCardFocus';

export type ActiveCampaignsProps = {
	isLoading: boolean;
	isError: boolean;
	isSidebarOpen: boolean;
	isNotInfluencer?: boolean;
};

/**
 * ActiveCampaigns
 * @param {ActiveCampaignsProps} props
 * @returns {JSX.Element}
 */
const ActiveCampaigns = ({ isLoading, isError, isSidebarOpen, isNotInfluencer }: ActiveCampaignsProps): JSX.Element => {
	const { dashboardCampaignCards, searchText, isShowDetailSidebar } = useAppSelector((state) => state.amInfluencer);
	const user = useAppSelector((state) => state.user);
	const { userCan } = usePermissions();
	const [targetCampaignCards, setTargetCampaignCards] = useState<AMCampaignCard[]>([]);
	const [searchResult, setSearchResult] = useState<AMCampaignCard[]>(targetCampaignCards);
	const [isAllTabSelected, setIsAllSelected] = useState<boolean>(true);
	const [linkCreatedCampaignCards, setLinkCreatedCampaignCards] = useState<AMCampaignCard[]>([]);

	const { getLinkCreatedPrograms } = useAffiliateMarketplaceData();
	const { models: linkCreatedModels, isLoading: isLinkCreatedModelsLoading, error } = getLinkCreatedPrograms(user.influencer?.id);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { cardsRef, focusedCardIdRef } = useCardFocus(isShowDetailSidebar, !!searchResult.length);

	const params = useParams();

	const selectedUserCountry = user.countryCode || '';
	const canUseAMByCountry = allowedCountries.includes(selectedUserCountry);

	const { isInfluencer } = usePermissions();

	const programListInfo = () => {
		if (selectedUserCountry === '') {
			// No selected country
			return (
				<EmptyState
					image={location}
					altText='No selected country'
					testId='no-country-selected'
					text={<p>You need to select your country. Please note, our service isn’t available in all countries yet.</p>}
					url='/influencer/settings/delivery-info'
					buttonText='Go to settings'
				/>
			);
		} else if (!canUseAMByCountry) {
			// Not allowed country
			return (
				<EmptyState
					image={location}
					altText='Not available in your country'
					testId='not-availabe-in-country'
					text={<p>We&apos;re are not available in your country yet. We&apos;re working on it, so stay tuned.</p>}
					url='/influencer/dashboard'
					buttonText='Return home'
				/>
			);
		} else if (!userCan(LIST_AFFILIATE_PROGRAM)) {
			return (
				<EmptyState
					image={errorImage}
					altText='No access'
					testId='not-access'
					text={
						<p>
							You don&apos;t have access to view programs.
							<br /> Please contact the support.
						</p>
					}
					url='/influencer/dashboard'
					buttonText='Return home'
				/>
			);
		}

		if (isEmpty(targetCampaignCards)) {
			return (
				<p className='text-center'>
					You don&apos;t have any affiliate programs or products yet. <br /> Please come back later!
				</p>
			);
		}
	};

	useEffect(() => {
		if (!isNil(params.programId)) {
			if (!isEmpty(dashboardCampaignCards)) {
				const targetProgram = dashboardCampaignCards.find((campaignCard) => campaignCard.id === params.programId);
				if (!isNil(targetProgram)) {
					focusedCardIdRef.current = params.programId;
					dispatch(setSelectedAMCampaign(targetProgram));
					dispatch(setIsShowDetailSidebar(true));
				} else {
					navigate('/affiliate/marketplace', { replace: true });
					dispatch(setIsShowDetailSidebar(false));
				}
			}
		} else {
			navigate('/affiliate/marketplace', { replace: true });
			dispatch(setIsShowDetailSidebar(false));
		}
	}, [params.programId, dashboardCampaignCards]);

	useEffect(() => {
		if (!isLinkCreatedModelsLoading && !error) {
			let activeCampaignCards = [];
			if (!isNil(linkCreatedModels)) {
				activeCampaignCards = linkCreatedModels.findAll('affiliateProgram');
			}
			const newActiveCampaignCards = orderBy(activeCampaignCards, 'sort', 'asc');
			setLinkCreatedCampaignCards(newActiveCampaignCards);
		}
	}, [isLinkCreatedModelsLoading]);

	useEffect(() => {
		if (!isEmpty(searchText) && searchText.length >= 3) {
			setSearchResult(targetCampaignCards.filter((amCampaign) => amCampaign.name.toLowerCase().includes(searchText.toLowerCase())));
		} else {
			setSearchResult(targetCampaignCards);
		}
	}, [searchText, targetCampaignCards]);

	useEffect(() => {
		if (isAllTabSelected) {
			setTargetCampaignCards(dashboardCampaignCards);
		} else {
			setTargetCampaignCards(linkCreatedCampaignCards);
		}
	}, [isAllTabSelected, dashboardCampaignCards, linkCreatedCampaignCards]);

	return (
		<Styled.ActiveCampaigns data-testid='am-active-campaigns'>
			<Styled.HeadlineSection>
				<div>
					Stores <span className='counter'>({(canUseAMByCountry && searchResult.length) || 0})</span>
				</div>
				{isNotInfluencer && (
					<Styled.Button
						size='sm'
						onClick={() => {
							navigate('/affiliate/programs');
						}}
					>
						Go back to list
					</Styled.Button>
				)}
			</Styled.HeadlineSection>
			<Styled.TabButtonsWrapper>
				<Styled.Tab
					disabled={!canUseAMByCountry}
					className={classNames({ selected: isAllTabSelected })}
					onClick={() => {
						setIsAllSelected(true);
					}}
				>
					All
				</Styled.Tab>
				<Styled.Tab
					disabled={!canUseAMByCountry}
					className={classNames({ selected: !isAllTabSelected })}
					onClick={() => {
						setIsAllSelected(false);
					}}
				>
					My stores
				</Styled.Tab>
			</Styled.TabButtonsWrapper>
			{isLoading && <LoadingSpinner position='center' />}

			<Styled.TextContainer>
				{!isLoading && isError && (
					<p className='text-center'>
						There was an error when we tried to get your programs or products. <br /> Please try again later.
					</p>
				)}

				{!isLoading && !isError && isInfluencer() && <Styled.EmptyMessageContainer>{programListInfo()}</Styled.EmptyMessageContainer>}
			</Styled.TextContainer>
			{!isLoading && !isError && isInfluencer() && userCan(LIST_AFFILIATE_PROGRAM) && !isEmpty(searchResult) && (canUseAMByCountry || !isInfluencer()) && (
				<Styled.Items isSidebarOpen={isSidebarOpen}>
					{searchResult.map((item, index) => {
						return (
							<Styled.Item key={index}>
								<AffiliateCard
									key={index}
									id={item.id}
									heading={item.name}
									compensationValue={item.influencerCommissionPercentage}
									imageUrl={item.coverImage?.links?.url}
									onSelectCampaign={() => {
										dispatch(setSelectedAMCampaign(item));
										navigate(`/affiliate/marketplace/${item.id}`);
										dispatch(setIsShowDetailSidebar(true));
									}}
									tabIndex={0}
									ref={(el) => {
										if (el) {
											cardsRef.current[index] = el;
										}
									}}
								/>
							</Styled.Item>
						);
					})}
				</Styled.Items>
			)}
		</Styled.ActiveCampaigns>
	);
};
export default ActiveCampaigns;
