import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Button } from 'components/Button';
import Icon from 'components/Icon';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const wrapperPadding = `${guttersWithRem.xs} ${guttersWithRem.s}`;

const Table = styled.table`
	width: 100%;
`;

const TableRow = styled.tr``;

const Th = styled.th<CSSProperties>`
	font-family: ${typography.list.accessory.fontFamilies};
	font-size: ${typography.list.medium.fontSize};
	font-weight: ${typography.list.accessory.fontWeight};
	text-align: ${(props) => props.textAlign || 'left'};
	padding: ${wrapperPadding};
	white-space: nowrap;
`;

const CheckboxTh = styled(Th)`
	padding-top: 20px;
`;

const TableHeader = styled.thead`
	width: 100%;
`;

const TableBody = styled.tbody``;

const IconWrapper = styled.span`
	cursor: pointer;
	&:hover {
		border-radius: 2px;
		background-color: #e2e2e2;
	}
`;

const CustomIcon = styled(Icon)`
	line-height: 0;
`;

const CheckboxWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;

	.dropdown-button {
		padding: 0.125rem;
	}
`;

const FlexDiv = styled.div<CSSProperties>`
	display: flex;
	height: 24px;
	position: ${(props) => props.position};
	column-gap: ${(props) => props.columnGap};
	align-items: ${(props) => props.alignItems};
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
`;

const CheckboxInnerWapper = styled(FlexDiv)`
	padding: 0 5px;
	&:hover {
		border-radius: 2px;
		background-color: transparent;
	}
`;

const TableHeaderInnerWrapper = styled.div`
	display: inline-flex;
	align-items: center;

	&[data-sort-property] {
		cursor: pointer;
	}

	&.followers {
		padding-left: 56px;
	}
`;

const SortIconWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	row-gap: 0.1rem;

	& > div {
		&.arrow-up {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
			border-bottom: 5px solid ${colors.discovery.gray};
		}

		&.arrow-down {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
			border-top: 5px solid ${colors.discovery.gray};
		}

		&.isActive {
			border-color: ${colors.discovery.black};
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
		}
	}
`;

const LoadMoreWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	margin-top: 32px;
	hr {
		width: 100%;
		height: 1px;
		position: absolute;
		top: 50%;
		z-index: 0;
		margin: 0;
		border: none;
		border-bottom: ${(props) => `1px solid ${props.theme.colors.inputsDarker}`};
		color: ${(props) => props.theme.colors.inputsDarker};
	}
`;

const LoadMoreButton = styled(Button)`
	z-index: 1;
	border: none;
	background-color: ${(props) => props.theme.colors.inputs};
	padding: 1rem 2rem;

	&:hover:not(:disabled, .disabled) {
		border: none;
		background-color: ${(props) => props.theme.colors.inputsDarker};
		color: ${(props) => props.theme.colors.text};
	}
`;

const LoadingSpinnerWrapper = styled.div`
	margin-top: 40px;
`;

const EmptyResultWrapper = styled.div`
	max-width: 500px;
	margin: 0 auto;

	p strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

const Styled = {
	TableRow,
	TableHeader,
	TableBody,
	Table,
	Th,
	IconWrapper,
	CustomIcon,
	CheckboxWrapper,
	FlexDiv,
	CheckboxInnerWapper,
	TableHeaderInnerWrapper,
	SortIconWrapper,
	LoadMoreWrapper,
	LoadMoreButton,
	CheckboxTh,
	LoadingSpinnerWrapper,
	EmptyResultWrapper,
};

export default Styled;
