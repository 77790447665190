import { useEffect, useRef } from 'react';

import ContentBody from 'components/DataLibrary/ContentBody/ContentBody';
import ContentHeader from 'components/DataLibrary/ContentHeader/ContentHeader';
import FilterSection from 'components/DataLibrary/FilterSection/FilterSection';
import { UserType } from 'reducers/UserReducers/types';
import Grid from 'styles/grid/grid';
import Styled from 'views/DataLibrary/DataLibrary.style';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { clearDashboardFilter } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { clearDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setShowFilter } from 'views/DataLibrary/reducers/FilterSlice';

/**
 * Main component with 3 column design
 * @returns {JSX.Element}
 */
const DataLibrary = (props: { user: UserType }): JSX.Element => {
	const rightColRef = useRef<HTMLDivElement>(null);
	const filter = useAppSelector((state) => state.filter);
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearDashboard());
			dispatch(clearDashboardFilter());
			dispatch(setShowFilter(false));
		};
	}, []);

	return (
		<Grid.Container gap={'0'}>
			{/*

			Try to remove this part until we know what to do with this page. Add graph button in header instead
			Will remove this code if the users dont complain that its missing

			<Grid.Column sm={3} xl={3} xxl={3}>
				<Styled.SideNavContainer>
					<SideNavCharts />
				</Styled.SideNavContainer>
			</Grid.Column>

			*/}
			<Styled.CustomColumn md={filter.isOpen ? 9 : 12} xl={filter.isOpen ? 9 : 12}>
				<Styled.ContentContainer>
					<ContentHeader user={props.user} />
					<ContentBody />
				</Styled.ContentContainer>
			</Styled.CustomColumn>
			<Styled.CustomColumn ref={rightColRef} md={filter.isOpen ? 3 : 0} xl={filter.isOpen ? 3 : 0}>
				<FilterSection />
			</Styled.CustomColumn>
		</Grid.Container>
	);
};
export default DataLibrary;
