import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';

interface StatusTypeProps {
	status: string;
}

const handleColor = (status: string) => {
	switch (status) {
		case 'archived':
			return colors.statusTag.archived.color;
		case 'active':
			return colors.statusTag.active.color;
		case 'done':
			return colors.statusTag.done.color;
		case 'draft':
			return colors.statusTag.draft.color;
		case 'paid':
			return colors.statusTag.active.color;
		case 'unpaid':
			return colors.statusTag.danger.color;
		case 'unpaidInvoice':
			return newColors.spice;
		default:
			return colors.black;
	}
};

const handleBackgroundColor = (status: string) => {
	switch (status) {
		case 'archived':
			return colors.statusTag.archived.backgroundColor;
		case 'active':
			return colors.statusTag.active.backgroundColor;
		case 'done':
			return colors.statusTag.done.backgroundColor;
		case 'draft':
			return colors.statusTag.draft.backgroundColor;
		case 'paid':
			return colors.statusTag.active.backgroundColor;
		case 'unpaid':
			return colors.statusTag.danger.backgroundColor;
		case 'unpaidInvoice':
			return newColors.butter;
		default:
			return colors.white;
	}
};

const CustomStatusTag = styled.div<StatusTypeProps>`
	color: ${({ status }) => handleColor(status)};
	background-color: ${({ status }) => handleBackgroundColor(status)};
	font-size: 0.875rem;
	font-weight: 500;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	line-height: 1.85;
	border-radius: 10px;
	height: 23px;
	overflow: hidden;
	white-space: nowrap;
`;

export default {
	CustomStatusTag,
};
