import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import InvitationItem from 'components/InfluencerDashboard/Components/InvitationItem/InvitationItem';
import SeeAllToggle from 'components/InfluencerDashboard/Components/SeeAllToggle/SeeAllToggle';
import { getCampaignInvitesUri } from 'components/InfluencerDashboard/utils';
import LoadingSpinner from 'components/LoadingSpinner';
import useModels from 'hooks/useModels';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './InvitationSection.style';

/**
 * @returns {JSX.Element}
 */
const InvitationSection = (): JSX.Element => {
	const { models, loading } = useModels(getCampaignInvitesUri());
	const invites = models.findAll('campaignInvite');
	const userHasInvites = invites.length > 0;
	const [isShowAll, setIsShowAll] = useState(false);
	const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState<boolean>(false);
	const user = useAppSelector((state) => state.user);
	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>(user.influencer.id);

	useEffect(() => {
		if (user.influencer.id !== selectedInfluencerId) {
			setDisplaySkeletonLoader(true);
			setTimeout(() => {
				setDisplaySkeletonLoader(false);
			}, 2000);
			setSelectedInfluencerId(user.influencer.id);
		}
	}, [user.influencer.id]);

	return loading ? (
		<Styled.SpinnerWrapper>
			<LoadingSpinner />
		</Styled.SpinnerWrapper>
	) : userHasInvites ? (
		<Styled.Wrapper data-testid='invitation-section'>
			<Styled.HeadingWrapper>
				<span>{displaySkeletonLoader ? <Skeleton width='180px' height='30px' borderRadius='4px' /> : 'Your invitations'}</span>
			</Styled.HeadingWrapper>
			<Styled.InvitationsWrapper>
				{invites
					.filter((_, index) => {
						if (isShowAll) {
							return true;
						} else {
							return index < 3;
						}
					})
					.map((invitation) => (
						<InvitationItem key={invitation.id} invitation={invitation} displaySkeletonLoader={displaySkeletonLoader} />
					))}
			</Styled.InvitationsWrapper>
			{invites.length > 3 ? <SeeAllToggle setIsShowAll={setIsShowAll} isShowAll={isShowAll} /> : null}
		</Styled.Wrapper>
	) : (
		<></>
	);
};

export default InvitationSection;
