import { Model } from 'json-api-models';

import { ItemAttributes, ItemResponse } from 'types/http';

export type OgMeta = {
	title: string;
	image: string;
	description: string | null;
};

export type OgMetaResponse = ItemResponse<ItemAttributes<OgMeta>>;

export type CreationFormData = {
	name: string;
	adtractionId: string;
	storeLink: string;
	coverImage?: string;
	ogMetaImage?: string;
	coverImageFile?: File;
	storeCommissionPercentage?: number;
	influencerCommissionPercentage?: number;
	background: string;
	guidelines: string;
	sort?: number;
};

export interface ICoverImage extends Model {
	createdAt: string;
	extension: string;
	originalName: string;
	uuid: string;
	links: {
		url: string;
	};
}

export interface ICampaignFormItem extends Model {
	name: string;
	adtractionId?: string;
	storeLink?: string;
	coverImage?: ICoverImage;
	storeCommissionPercentage?: number;
	influencerCommissionPercentage?: number;
	background?: string;
	guidelines?: string;
	sort?: number;
}

export class CampaignFormItem {
	id: string;
	name: string;
	adtractionId: string;
	storeLink: string;
	coverImage: string;
	storeCommissionPercentage: number;
	influencerCommissionPercentage: number;
	background: string;
	guidelines: string;
	sort: number;

	constructor(amProgram: ICampaignFormItem) {
		this.id = amProgram.id;
		this.name = amProgram.name;
		this.adtractionId = amProgram.adtractionId ?? '';
		this.storeLink = amProgram.storeLink ?? '';
		this.coverImage = amProgram.coverImage?.links?.url ?? '';
		this.storeCommissionPercentage = amProgram.storeCommissionPercentage ?? 0;
		this.influencerCommissionPercentage = amProgram.influencerCommissionPercentage ?? 0;
		this.background = amProgram.background ?? '';
		this.guidelines = amProgram.guidelines ?? '';
		this.sort = amProgram.sort ?? 0;
	}
}
