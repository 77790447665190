/* eslint-disable prettier/prettier */
import classNames from 'classnames';
import { useState, useEffect } from 'react';

import { LeftColumn, RightColumn } from 'components/Modals/TwoColumnModal';

import Styled from './GraphsPreview.style';

type GraphsPreviewProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	graphs: Array<any>;
	selectedPreview?: string;
};

/**
 * Preview of selected graphs.
 * @todo Add search function
 * @returns {JSX.Element}
 */
const GraphsPreview = ({ graphs, selectedPreview }: GraphsPreviewProps): JSX.Element => {
	const [graphIndex, setGraphIndex] = useState<number>(0);
	const [elementId, setElementId] = useState<string>('');

	const scrollSmoothTo = (elementId: string) => {
		setElementId(elementId);
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		graphs.map((graph: any, index: number) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const selectCategory = graph.items.filter((item: any) => item.id === selectedPreview);
			if (selectCategory?.length > 0) {
				setGraphIndex(index);
			}
		});
		if (selectedPreview) {
			setElementId(selectedPreview);
			scrollSmoothTo(selectedPreview);
		}
	}, [selectedPreview]);

	useEffect(() => {
		scrollSmoothTo(graphs[graphIndex]?.items[0].id);
		if (selectedPreview) {
			scrollSmoothTo(selectedPreview);
		}
	}, [graphIndex]);

	useEffect(() => {
		return () => {
			setGraphIndex(0);
			setElementId('');
		};
	}, []);

	return (
		<>
			<LeftColumn>
				{// eslint-disable-next-line @typescript-eslint/no-explicit-any
				graphs?.map((graph: { category: string; items: Array<any> }, i: number) => {
					return (
						<Styled.CustomAccordion key={i} title={graph?.category} open={graphIndex === i} onClick={() => setGraphIndex(i)}>
							<Styled.AccordionList>
								{graph?.items?.map((item: { id: string; title: string }, i: number) => {
									return (
										<Styled.AccordionListItem className={classNames({ selected: elementId === item.id })} key={i} onClick={() => scrollSmoothTo(`${item.id}`)}>
											{item.title}
										</Styled.AccordionListItem>
									);
								})}
							</Styled.AccordionList>
						</Styled.CustomAccordion>
					);
				})}
			</LeftColumn>
			<RightColumn overFlowAuto={true} title={<Styled.GraphTitle>{graphs[graphIndex]?.category}</Styled.GraphTitle>}>
				<Styled.GraphList>
					{graphs[graphIndex]?.items?.map((item: { id: string; component: JSX.Element }, i: number) => {
						return (
							<li id={`${item.id}`} key={i}>
								{item.component}
							</li>
						);
					})}
				</Styled.GraphList>
			</RightColumn>
		</>
	);
};
export default GraphsPreview;
