import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';

const GraphListContainer = styled.div`
	height: 0;
	position: absolute;
	right: -450px;
	top: 0;
	width: 100%;
	background-color: ${colors.mist};
	transition:
		right 200ms ease-in-out,
		visibility 400ms ease-in-out,
		opacity 300ms ease-in-out;
	opacity: 0;
	visibility: hidden;

	&.visible {
		height: calc(100vh - 300px);
		overflow: auto;
		${scrollbarY};
		opacity: 1;
		visibility: visible;
		right: 0;
	}
`;

const GraphListInnerContainer = styled.div``;

const GraphList = styled.ul`
	list-style: none;
	height: 50%;
`;

const Added = styled.div`
	font-weight: 400;
`;

export const Styled = {
	GraphListContainer,
	GraphListInnerContainer,
	GraphList,
	Added,
};
