import React, { useState, forwardRef } from 'react';

import Styled from './Checkbox.style';

export type CheckboxProps = {
	className?: string;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	indeterminate?: boolean;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ className, checked: checkedProp, onChange, disabled, ...props }, ref) => {
	const [checkedState, setCheckedState] = useState(checkedProp ?? false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!onChange) {
			setCheckedState(event.target.checked);
		}
		onChange?.(event);
	};

	return (
		<Styled.CheckboxContainer className={className}>
			<Styled.Label>
				<Styled.Input ref={ref} type='checkbox' checked={onChange ? checkedProp : checkedState} onChange={handleChange} disabled={disabled} {...props} />
				<Styled.Checkmark data-testid='checkbox' />
			</Styled.Label>
		</Styled.CheckboxContainer>
	);
});

export default Checkbox;
