import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DarkButton, GhostButton } from 'components/Button';
import { SvgInfoCircle } from 'components/Icon/SvgComponents/svg-info-circle';
import DeclineButton from 'components/NewBriefPage/Components/DeclineButton';
import JoinButton from 'components/NewBriefPage/Components/JoinButton';
import JoinModal from 'components/NewBriefPage/Components/JoinModal/JoinModal';
import LoginModal from 'components/NewBriefPage/Components/LoginModal';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { pathCampaignDashboard, pathCampaignEdit } from 'routing/PathLookup';
import toast from 'services/Toast';
import { formatAmount } from 'utils/formatters';

import Styled from './NavBar.style';

type NavBarProps = {
	onClick: (value: string) => void;
	campaignInstagramOwner: CampaignInstagramOwner;
	campaign: Campaign;
	onPreview?: boolean;
	hasJoined: boolean;
	spotsLeft: number;
};
/**
 * @returns JSX.Element
 */
const NavBar = ({ campaignInstagramOwner, campaign, onPreview, hasJoined, spotsLeft }: NavBarProps): JSX.Element => {
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
	const [previewIsOpen, setPreviewIsOpen] = useState(false);

	const navigate = useNavigate();

	const user = useAppSelector((state) => state.user);

	const totalInvoicedValue = campaignInstagramOwner.campaign.invoices.reduce((prev, current) => prev + current.invoice.value, 0);
	const totalInvoicesAmount = formatAmount(totalInvoicedValue, campaignInstagramOwner.campaign.currency ? campaignInstagramOwner.campaign.currency : undefined)
		.replace(',', '\xa0')
		.replace('.00', '');

	const products = campaignInstagramOwner.campaign.products.reduce((prev, current) => prev + current.quantity, 0);
	const isInfluencerSignedIn = user.id && user.permissions.isInfluencer;

	const onContact = () => {
		onPreview ? toast.error('Only preview') : isInfluencerSignedIn ? navigate(`/influencer/inbox/${campaign.shortId}`) : setIsLoginModalOpen(true);
	};

	const scrollSmoothTo = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	};

	return (
		<>
			<Styled.Wrapper>
				{onPreview && (
					<Styled.TopBanner>
						<Styled.TopBannerContainer>
							<SvgInfoCircle height={20} />
							<Styled.TopBannerText>
								You are in preview mode
								{campaignInstagramOwner.influencer?.username && (
									<>
										&nbsp;for <strong>{campaignInstagramOwner.influencer?.username}</strong>
									</>
								)}
							</Styled.TopBannerText>
						</Styled.TopBannerContainer>
						<Styled.TopBannerButtonWrapper>
							{user.links?.createCampaign && (
								<GhostButton tabIndex={0} className='button-size-small' onClick={() => navigate(pathCampaignEdit(campaign.shortId))}>
									Edit
								</GhostButton>
							)}
							<GhostButton className='button-size-small' onClick={() => setPreviewIsOpen(true)}>
								<span>Preview join popup</span>
							</GhostButton>
							<DarkButton className='button-size-small' onClick={() => navigate(pathCampaignDashboard(campaign.shortId))} data-testid='brief-to-campaign'>
								Go to campaign
							</DarkButton>
							<JoinModal
								onPreview
								isModalOpen={previewIsOpen}
								toggleModal={() => setPreviewIsOpen(!previewIsOpen)}
								campaign={campaign}
								campaignInstagramOwner={campaignInstagramOwner}
								join={() => {}}
							/>
						</Styled.TopBannerButtonWrapper>
					</Styled.TopBanner>
				)}
				<Styled.JoinMessage>Join our campaign</Styled.JoinMessage>
				<Styled.Menu>
					<Styled.InfoBoxWrapper>
						<Styled.CampaignName data-testid='brief-campaign-name'>{campaign.name}</Styled.CampaignName>
						{totalInvoicedValue > 0 ? (
							<Styled.InfoBox onClick={() => scrollSmoothTo('compensation')}>
								Commission: <span>{totalInvoicesAmount}</span>
							</Styled.InfoBox>
						) : null}
						<Styled.InfoBox onClick={() => scrollSmoothTo('assignments')}>
							Assignments:<span>{campaignInstagramOwner?.campaign?.assignments?.length} </span>
						</Styled.InfoBox>
						{products ? (
							<Styled.InfoBox onClick={() => scrollSmoothTo('products')}>
								Products:<span>{products} </span>
							</Styled.InfoBox>
						) : null}
						{!hasJoined && (
							<Styled.InfoBox className={classNames({ oneLeft: spotsLeft < 2 }, 'noClick')}>
								{spotsLeft > 0 ? (
									<>
										<strong>Spot{spotsLeft > 1 && 's'} left:</strong>
										<span>{spotsLeft}</span>
									</>
								) : (
									<strong>Campaign is full</strong>
								)}
							</Styled.InfoBox>
						)}
					</Styled.InfoBoxWrapper>
					<Styled.Buttons>
						<GhostButton onClick={() => onContact()}>Contact us</GhostButton>
						{campaignInstagramOwner?.links?.decline && <DeclineButton link={campaignInstagramOwner?.links?.decline} />}
						{campaignInstagramOwner && <JoinButton campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />}
					</Styled.Buttons>
				</Styled.Menu>
				<LoginModal
					isOpen={isLoginModalOpen}
					onClose={() => {
						setIsLoginModalOpen(false);
					}}
				/>
			</Styled.Wrapper>
		</>
	);
};

export default NavBar;
