/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	UpdateClientPrivilegePayload,
	UpdateClientPayload,
	CreateClientInvitePayload,
	UpdateClientLogoPayload,
	CreateClientPayload,
	LegacyUpdateClientPayload,
} from 'api-payloads';
import {
	ListClientsQuery,
	UpdateClientPrivilegeQuery,
	UpdateClientQuery,
	UpdateClientLogoQuery,
	ListClientPrivilegesQuery,
	CreateClientQuery,
	LegacyUpdateClientQuery,
} from 'api-queries';
import {
	ClientCollectionResponse,
	ClientPrivilegeResponse,
	ClientResponse,
	ClientInviteCollectionResponse,
	ClientPrivilegeCollectionResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import ClientApiClientInterface from './ClientApiClientInterface';

@injectable()
export default class ClientApiClient implements ClientApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Delete client invite
	 */
	async deleteInvite(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/client-invites/${id}`);
		return response.data;
	}

	/**
	 * List clients
	 */
	async listClients(params: ListClientsQuery): Promise<ClientCollectionResponse> {
		const response = await this.client.get<ClientCollectionResponse>(`/clients`, { params });
		return response.data;
	}

	/**
	 * Delete client privilege
	 */
	async deletePrivilege(clientId: string, userId: string): Promise<void> {
		const response = await this.client.delete<void>(`/clients/${clientId}/users/${userId}`);
		return response.data;
	}

	/**
	 * Update client privilege
	 */
	async updatePrivilege(
		clientId: string,
		userId: string,
		payload: UpdateClientPrivilegePayload,
		params: UpdateClientPrivilegeQuery,
	): Promise<ClientPrivilegeResponse> {
		const response = await this.client.patch<ClientPrivilegeResponse>(`/clients/${clientId}/users/${userId}`, payload, { params });
		return response.data;
	}

	/**
	 * Delete client
	 */
	async delete(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/clients/${id}`);
		return response.data;
	}

	/**
	 * Update client
	 */
	async update(id: string, payload: UpdateClientPayload, params: UpdateClientQuery): Promise<ClientResponse> {
		const response = await this.client.patch<ClientResponse>(`/clients/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * List client invites
	 */
	async listInvites(id: string, params: { include?: string }): Promise<ClientInviteCollectionResponse> {
		const response = await this.client.get<ClientInviteCollectionResponse>(`/clients/${id}/invites`, { params });
		return response.data;
	}

	/**
	 * Create client invite
	 */
	async createInvite(id: string, payload: CreateClientInvitePayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/clients/${id}/invites`, payload, { params });
		return response.data;
	}

	/**
	 * Update client logo
	 */
	async updateLogo(id: string, payload: UpdateClientLogoPayload, params: UpdateClientLogoQuery): Promise<ClientResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<ClientResponse>(`/clients/${id}/logo`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Delete client logo
	 */
	async deleteLogo(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/clients/${id}/logo`);
		return response.data;
	}

	/**
	 * List privileges
	 */
	async listPrivileges(id: string, params: ListClientPrivilegesQuery): Promise<ClientPrivilegeCollectionResponse> {
		const response = await this.client.get<ClientPrivilegeCollectionResponse>(`/clients/${id}/privileges`, { params });
		return response.data;
	}

	/**
	 * Create client
	 */
	async create(id: string, payload: CreateClientPayload, params: CreateClientQuery): Promise<ClientResponse> {
		const response = await this.client.post<ClientResponse>(`/publishers/${id}/clients`, payload, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async legacyDelete(publisherId: string, clientId: string): Promise<void> {
		const response = await this.client.delete<void>(`/publishers/${publisherId}/clients/${clientId}`);
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async legacyUpdate(publisherId: string, clientId: string, payload: LegacyUpdateClientPayload, params: LegacyUpdateClientQuery): Promise<ClientResponse> {
		const response = await this.client.patch<ClientResponse>(`/publishers/${publisherId}/clients/${clientId}`, payload, { params });
		return response.data;
	}
}
