import styled from 'styled-components';

import Icon from 'components/Icon';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Input = styled.input`
	width: 100%;
	min-height: 44px;
	border: none;
	background-color: ${({ theme }) => theme.colors.inputs};

	&:focus {
		outline: none;
	}
	padding-top: 0.25rem;
`;

const SearchInputWrapper = styled.div`
	width: 100%;
	max-height: 44px;
	font-size: ${typography.input.fontSize};
	line-height: ${typography.input.lineHeight};
	overflow: hidden;
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	padding: 1rem;

	background-color: ${({ theme }) => theme.colors.inputs};
	border-radius: ${({ theme }) => theme.radius.small};
	border: 1px solid ${({ theme }) => theme.borders.secondary};
	transition: border-color 0.2s ease-in-out;

	&:hover {
		border-color: ${({ theme }) => theme.borders.hover};
	}

	&:focus-within {
		border-color: ${({ theme }) => theme.borders.focus} !important;
		outline: none;
	}
	&.small {
		max-height: 44px;
	}
`;

const IconContainer = styled.div`
	display: flex;
	cursor: pointer;
	padding: 0.3rem;
	border-radius: 50%;
	&:hover {
		background-color: ${colors.gray9};
	}
`;

const CustomIcon = styled(Icon)`
	line-height: 0;
`;

const LookALikePillWrapper = styled.div`
	height: 48px;
	border-radius: ${borderRadius.m};
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	background-color: ${colors.discovery.lookalikePillBackground};
	margin-right: 20px;
`;

const LookALikeText = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const PlatformIconWrapper = styled.div`
	flex: 1;
	display: flex;
	transition: transform 0.1s ease-in;
	transform: translateY(100%);
	cursor: pointer;
	margin-left: 5px;

	& > div {
		display: flex;
		margin-top: 10px;

		&.icon-wrapper {
			display: flex;
		}

		&.toggle-wrapper {
			position: relative;
			& > div {
				&.toggle-inner-wrapper {
					position: absolute;
					bottom: -8px;
					right: -8px;
				}
			}
		}

		& > i {
			line-height: 0;
		}
	}

	&.set {
		transform: translateY(0);
	}

	&.unset {
		opacity: 0;
	}
`;

const EnterLabel = styled.div`
	color: ${colors.gray7};
`;

const AiIconContainer = styled.div`
	padding-top: 0.625rem;
	margin-right: 4px;
`;

const Styled = {
	AiIconContainer,
	SearchInputWrapper,
	IconContainer,
	CustomIcon,
	Input,
	LookALikePillWrapper,
	LookALikeText,
	PlatformIconWrapper,
	EnterLabel,
};

export default Styled;
