import axios, { AxiosResponse } from 'axios';
import { FieldArray, Formik } from 'formik';
import { Model, Store } from 'json-api-models';
import { uniqueId, isEmpty, isNil, orderBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import ApproveMessage from 'components/ContentManagement/Components/ApprovedMessage';
import Comments from 'components/ContentManagement/Components/Comments';
import { selectedTabType } from 'components/ContentManagement/Components/Comments/types';
import FileUploadProgress from 'components/ContentManagement/Components/FileUploadProgress';
import ManagerTopbar from 'components/ContentManagement/Components/Topbar/ManagerHeader';
import UploadMedia from 'components/ContentManagement/Components/Views/UploadMedia';
import { customValidator, reorderArray } from 'components/ContentManagement/Utils';
import { useCampaignInstagramOwnerAssignments } from 'components/ContentManagement/hooks';
import {
	PostMedia,
	AssignmentReviewCommentType,
	AssignmentReviewInternalCommentType,
	CIOAType,
	MediaStatus,
	ReviewType,
} from 'components/ContentManagement/types';
import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';
import DeleteModal from 'components/Modals/DeleteModal';
import {
	EDIT,
	CREATE_ASSIGNMENT_REVIEW,
	DELETE,
	CLIENT_APPROVE,
	CREATE_COMMENT,
	APPROVE_REVIEW,
	REQUEST_CHANGE,
	CREATE_INTERNAL_COMMENT,
	CREATE_INSTAGRAM_STORY,
	REQUEST_REVIEW,
} from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnFetch, getErrorMessageOnPost, getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useUnsavedChangesWarning from 'hooks/useUnsavedChangesWarning';
import { useAppSelector, useAppDispatch } from 'hooks/useUserAppSelector';
import { setIsProgressVisible } from 'reducers/ContentManagementReducers/ContentManagementSlice';
import { ErrorResponse, ICollabsResponse, StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import {
	AssignmentReviewMediaPresignUrlType,
	AssignmentReviewMediaType,
	AssignmentStatus,
	isAssignmentApproved,
	isAssignmentDisapproved,
	isContentApproved,
	isStatsUploaded,
	UserUploadStatus,
} from 'shared/Types/Assignment';
import { CampaignType } from 'shared/Types/Campaign/Campaign.type';
import colors from 'styles/variables/colors';
import { PALETTE } from 'styles/variables/original-colors';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

import Styled from './Story.style';
import StoryItem from './StoryItem';

export type StoryProps = {
	id: string;
	selectedCIOA: CIOAType;
	selectedCampaign: Pick<CampaignType, 'hashtags' | 'mentions'>;
	review: ReviewType;
	CIOArefresh: () => Promise<void>;
	campaignRefresh: () => void;

	goToStats: () => void;
};

/**
 * Story view
 * Post view to upload, and manage posts and reels.
 * Note: useSWR do the polling.
 * @param {StoryProps}
 * @returns {JSX.Element}
 */
const Story = ({ id, selectedCIOA, selectedCampaign, review, CIOArefresh, goToStats, campaignRefresh }: StoryProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const ap = useFeaturePermissions({ ...selectedCIOA.links });
	const { userCan } = useFeaturePermissions({ ...review?.links });

	const {
		postReview,
		postStatus,
		postPresignUrl,
		putMediaInBucket,
		patchMedia,
		setAsUploadedMediaItem,
		deleteMedia,
		postApproveAsClient,
		postComment,
		getComments,
		progress,
		postReviewRequest,
	} = useCampaignInstagramOwnerAssignments();

	const CAN_CREATE_STORY = ap.userCan(CREATE_INSTAGRAM_STORY);
	const CAN_APPROVE = userCan(APPROVE_REVIEW);
	const CAN_REQUEST_CHANGES = userCan(REQUEST_CHANGE);
	const CAN_REQUEST_REVIEW = userCan(REQUEST_REVIEW);

	const CAN_EDIT_POST = userCan(EDIT);
	const CAN_COMMENT = userCan(CREATE_COMMENT);
	const CAN_COMMENT_INTERNAL = userCan(CREATE_INTERNAL_COMMENT);
	const CAN_CREATE_ASSIGNMENT_REVIEW = ap.userCan(CREATE_ASSIGNMENT_REVIEW);
	const CAN_CLIENT_APPROVE = userCan(CLIENT_APPROVE);

	const [medias, setMedias] = useState<PostMedia[]>([]);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isUploadingContent, setIsUploadingContent] = useState<boolean>(false);

	const [isSendForReviewModalOpen, setIsSendForReviewModalOpen] = useState<boolean>(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
	const [isRequestChangeModalOpen, setIsRequestChangeModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [selectedMediaId, setSelectedMediaId] = useState<string>('');

	const [activeCommentTab, setActiveCommentTab] = useState<selectedTabType>(selectedTabType.Influencer);
	const [comments, setComments] = useState<Array<AssignmentReviewInternalCommentType | AssignmentReviewCommentType> | Array<unknown>>([]);
	const [internalComments, setInternalComments] = useState<Array<AssignmentReviewInternalCommentType | AssignmentReviewCommentType> | Array<unknown>>([]);
	const [enableReinitialize, setEnableReinitialize] = useState<boolean>(true);

	const [isPosting, setIsPosting] = useState<boolean>(false);
	const [isClientApproveLoading, setIsClientApproveLoading] = useState<boolean>(false);
	const [isUploadingFiles, setIsUploadingFiles] = useState(false);

	const user = useAppSelector((state) => state.user);
	const contentManagement = useAppSelector((state) => state.contentManagement);

	useUnsavedChangesWarning(isUploadingFiles);

	const IN_REVIEW = review !== null;
	const hasBeenAssignmentReviewCreated = isNil(review);

	const initialValues = () => {
		return medias.map((media: PostMedia) => {
			return {
				id: media.id,
				file: media.file ?? undefined,
				sort: media.sort,
				fileUrl: media.links.screenshot,
				originalFilename: media.originalFilename,
				links: media.links,
				type: media.vimeoId ? 'video' : 'image',
				vimeoId: media.vimeoId,
				url: media.url || '',
				text: media.text || '',
				trackingUrl: media.trackingUrl || '',
				status: media.links[DELETE] || MediaStatus.PENDING || MediaStatus.UPLOADED ? MediaStatus.UPLOADED : MediaStatus.DRAFT,
				uploadStatus: media.uploadStatus,
			};
		});
	};

	const inputRef = useRef<HTMLInputElement | null>(null);
	const commentsRef = useRef<HTMLDivElement | null>(null);

	let commentsInterval: ReturnType<typeof setTimeout>;
	let internalCommentsInterval: ReturnType<typeof setTimeout>;

	const COMMENTS_INTERVAL_COUNT = 10_000;

	// Upload media post to presign, and put a file
	const uploadMediaToAssignment = async (assignmentReview: ReviewType, mediaItems: PostMedia[]) => {
		let result = false;
		setIsEditing(false);

		try {
			const promises = mediaItems.map((media) =>
				postPresignUrl(assignmentReview.links.createPresignUrl, {
					filename: media.file!.name,
					sort: medias.findIndex((x) => x.id === media.id),
					text: media.text,
					productUrl: media.url || null,
				}).then(async (res: ICollabsResponse | undefined) => {
					if (!res) {
						return;
					}

					const { setAsUploaded } = res.data.links;
					const response = await putMediaInBucket(res.data.attributes.url, media.file!);

					if (response && response.status === StatusCode.OK && setAsUploaded) {
						try {
							await setAsUploadedMediaItem(setAsUploaded);
						} catch (e) {
							console.error(e);
							toast.error(getErrorMessageOnPost('uploading the images'));
						}
					}
				}),
			);
			await Promise.all(promises);
			result = true;
			setIsUploadingContent(false);
		} catch (err) {
			console.error(err);
			result = false;
		}
		return result;
	};

	// Post Status
	const postStatusHandler = (type: 'approve' | 'requestChange' | 'reject') => {
		if (!CAN_APPROVE && !review) {
			return;
		}

		setIsPosting(true);

		postStatus(review.links[type])
			.then(() => {
				// Wait for both refreshes to complete
				return Promise.all([campaignRefresh(), CIOArefresh()]);
			})
			.then(() => {
				if (type === 'approve') {
					toast.success('Approved for posting');
				} else if (type === 'requestChange') {
					toast.success('Request sent');
				} else {
					toast.success('Content declined');
				}

				setIsApproveModalOpen(false);
				setIsRequestChangeModalOpen(false);
			})
			.catch((e) => {
				toast.error('There was an error when updating the content. Please try again later.');
				console.error(e);
			})
			.finally(() => {
				setIsPosting(false);
			});
	};

	// Post review
	const sendForReviewHandler = (mediaItems: PostMedia[]) => {
		if (mediaItems?.length === 0) {
			toast.error('You need to upload at least one image');
			return false;
		}
		setIsUploadingContent(true);
		setIsPosting(true);

		postReview(selectedCIOA.links[CREATE_ASSIGNMENT_REVIEW], { text: '', url: '' })
			.then((response) => {
				const reviewModel = new Store();
				reviewModel.sync(response.data);
				const assignmentReview = reviewModel.findAll('assignmentReview')[0];
				return { assignmentReview: assignmentReview as unknown as ReviewType };
			})
			.then(async ({ assignmentReview }) => {
				await uploadMediaToAssignment(assignmentReview, mediaItems?.length > 0 ? mediaItems : []);
			})
			.then(() => {
				return Promise.all([campaignRefresh(), CIOArefresh()]);
			})
			.then(() => {
				setIsUploadingContent(false);
				setIsSendForReviewModalOpen(false);
				setIsPosting(false);
				return true;
			})
			.catch((error) => {
				setIsUploadingContent(false);
				toast.error(getSomethingWentWrongMessage());
				console.error(error);
			});
	};

	// Patch review
	const patchReviewHandler = async (mediaItems: PostMedia[]): Promise<boolean> => {
		setIsPosting(true);

		try {
			const orderChangeImages = mediaItems.filter((image) => image.id !== undefined);
			const orderChangePresignImages = mediaItems.filter((image) => image.status === MediaStatus.PENDING);
			const newImages = mediaItems.filter((image) => image.status === MediaStatus.DRAFT);

			// Update new images if there are any
			if (newImages?.length > 0) {
				await uploadMediaToAssignment(review, newImages);
			}

			// Update existing images using patchMedia in parallel
			await Promise.all(
				orderChangeImages.map((media) =>
					patchMedia(
						{
							sort: mediaItems.findIndex((x) => x.id === media.id),
							text: media.text,
							url: media.url || null,
						},
						media.links?.edit || '',
					),
				),
			);

			// If possible, send a review request
			if (CAN_REQUEST_REVIEW) {
				await postReviewRequest(review.links[REQUEST_REVIEW]);
			}

			// Update presign medias if there are any
			if (orderChangePresignImages?.length > 0) {
				await Promise.all(
					orderChangePresignImages.map((media) =>
						patchMedia(
							{
								sort: mediaItems.findIndex((x) => x.id === media.id),
								text: media.text,
								url: media.url,
							},
							media.links.edit,
						),
					),
				);
			}
			setIsPosting(false);
			await Promise.all([campaignRefresh(), CIOArefresh()]);

			return true; // Everything went well
		} catch (error) {
			console.error(error);
			setIsPosting(false);

			if (!axios.isAxiosError(error)) {
				console.error('Unexpected error %O', error);
				return false;
			}

			const { data } = error.response! as AxiosResponse<ErrorResponse>;
			if (data.errors) {
				const errors = data.errors;

				errors.forEach((item) => {
					const { status, source } = item;
					if (+status === StatusCode.BAD_REQUEST) {
						toast.error(source.message);
					}
				});

				if (errors.some((item) => +item.status >= StatusCode.INTERNAL_SERVER_ERROR)) {
					toast.error(getSomethingWentWrongMessage());
				}
			}

			return false;
		}
	};

	// Post comment
	const postCommentHandler = async (text: string) => {
		const tempComment = {
			id: uniqueId(),
			attributes: {
				createdAt: new Date().toString(),
				text: text,
				readAt: '',
				links: {},
			},
			user: {
				id: user.id ?? '',
				name: user.name ?? '',
				initials: user.initials ?? '',
			},
		};

		if (activeCommentTab === selectedTabType.BrandManager) {
			setInternalComments((prev) => [...prev, tempComment]);
		} else {
			setComments((prev) => [...prev, tempComment]);
		}

		// Errors are handled in the comments component already
		const postUrl = activeCommentTab === selectedTabType.BrandManager ? CREATE_INTERNAL_COMMENT : CREATE_COMMENT;
		return await postComment(review?.links[postUrl], text).then(() => CIOArefresh());
	};

	// Click event to approve content as a client
	const onClickClientApprove = () => {
		setIsClientApproveLoading(true);
		postApproveAsClient(review.links[CLIENT_APPROVE])
			.then(() => {
				return Promise.all([campaignRefresh(), CIOArefresh()]);
			})
			.then(() => {
				toast.success('Approved for posting');
			})
			.finally(() => setIsClientApproveLoading(false))
			.catch((e) => {
				toast.error('Something went wrong when updating the content. Please try again later.');
				console.error(e);
				setIsClientApproveLoading(false);
			});
	};

	// Get medias and store them in the local state
	const getMedia = () => {
		const uploadedMedias: Array<PostMedia> = review?.medias.map((media: AssignmentReviewMediaType) => {
			return {
				id: media.id,
				sort: media.sort,
				fileUrl: media.links.screenshot,
				originalFilename: media.originalFilename,
				links: media.links,
				type: media.vimeoPlaybackUrl || media.vimeoId ? 'video' : 'image',
				vimeoId: media.vimeoId,
				vimeoPlaybackUrl: media.vimeoPlaybackUrl,
				url: media.url || '',
				text: media.text || '',
				trackingUrl: media.trackingUrl || '',
				status: MediaStatus.UPLOADED,
				uploadStatus: media.uploadStatus,
			} as PostMedia;
		});

		const pendingMedias: Array<PostMedia> = review?.pendingUploads.map((media: AssignmentReviewMediaPresignUrlType) => {
			return {
				id: media.id,
				sort: media.sort !== null ? media.sort : 0,
				fileUrl: media.links.screenshot,
				originalFilename: media.filename,
				links: media.links,
				type: media.type?.includes('image') ? 'image' : media.type?.includes('video') ? 'video' : 'unknown',
				vimeoId: '',
				vimeoPlaybackUrl: '',
				url: media.productUrl || '',
				text: media.text || '',
				trackingUrl: media.trackingUrl || '',
				status: MediaStatus.PENDING,
				uploadStatus: UserUploadStatus.INIT,
			} as PostMedia;
		});

		if (uploadedMedias) {
			const mediaArr = uploadedMedias.concat(pendingMedias);
			setMedias(mediaArr.sort((a: { sort: number }, b: { sort: number }) => a.sort - b.sort));
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const AddMedias = (files: Array<File>, setFieldValue: any) => {
		if (files?.length > 0) {
			setEnableReinitialize(true);
			const availableFiles = files.filter((file) => {
				const isAvailable = customValidator(file);
				if (isAvailable === null) {
					return file;
				} else {
					toast.error(isAvailable.message);
				}
			});

			const newMedias = availableFiles.map(
				(file: File, index: number) =>
					({
						id: uniqueId(),
						sort: medias.length + index,
						file: file,
						text: '',
						originalFilename: file.name,
						status: MediaStatus.DRAFT,
						uploadStatus: UserUploadStatus.SUCCESS,
						links: {},
					}) as PostMedia,
			);
			setMedias([...medias, ...newMedias]);
			setFieldValue('medias', [...medias, ...newMedias]);
			setEnableReinitialize(false);

			if (inputRef.current) {
				inputRef.current.value = '';
			}
		}
	};

	const getInfluencerComments = async () => {
		try {
			const response = getComments(review.links?.comments);
			const data = (await response).data;
			const model = new Store();
			model.sync(data);
			const comments = model.findAll('assignmentReviewComment') as unknown as AssignmentReviewCommentType[];

			const sortedComments = orderBy(comments, 'createdAt');
			setComments(sortedComments);
		} catch (e) {
			toast.error(getErrorMessageOnFetch('The comments'));
		}
	};

	const getInternalComments = async () => {
		try {
			const response = getComments(review.links?.internalComments);
			const data = (await response).data;
			const model = new Store();
			model.sync(data);
			const comments = model.findAll('assignmentReviewInternalComment') as unknown as AssignmentReviewCommentType[];
			const sortedComments = orderBy(comments, 'createdAt');

			setInternalComments(sortedComments);
		} catch (e) {
			toast.error(getErrorMessageOnFetch('The brand manager comments'));
		}
	};

	const pollInfluencerComments = () => {
		if (CAN_COMMENT) {
			getInfluencerComments();
			commentsInterval = setInterval(getInfluencerComments, COMMENTS_INTERVAL_COUNT);
		}
	};

	const pollInternalComments = () => {
		if (CAN_COMMENT_INTERNAL) {
			getInternalComments();
			internalCommentsInterval = setInterval(getInternalComments, COMMENTS_INTERVAL_COUNT);
		}
	};

	// OnChange event for the files
	const onChangeFiles = (e: React.FormEvent<HTMLInputElement>, push: (obj: unknown) => void) => {
		const fileList: FileList | null = (e.target as HTMLInputElement).files;
		const filesArray = fileList ? Array.from(fileList) : [];

		if (filesArray.length > 0) {
			setEnableReinitialize(true);
			const availableFiles = filesArray.filter((file) => {
				const isAvailable = customValidator(file);
				if (isAvailable === null) {
					return file;
				} else {
					toast.error(isAvailable.message);
				}
			});

			const newMedias = availableFiles.map(
				(file: File, index: number) =>
					({
						id: uniqueId(),
						sort: medias.length + index,
						file: file,
						originalFilename: file.name,
						status: MediaStatus.DRAFT,
						uploadStatus: UserUploadStatus.SUCCESS,
						links: {},
					}) as PostMedia,
			);

			setMedias([...medias, ...newMedias]);

			newMedias.forEach((media) => {
				push(media);
				setEnableReinitialize(false);
			});

			if (inputRef.current) {
				inputRef.current.value = '';
			}
		}
	};

	// Click event to cancel edit mode
	const cancelEditHandler = () => {
		setMedias((prev) => {
			if (prev !== null) {
				return prev.filter((media) => !media.id.includes('media'));
			} else {
				return prev;
			}
		});
		setIsEditing(false);
	};

	// Click event to delete medias
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onClickDelete = (mediaId: string, setFieldValue: any) => {
		const targetMedia = medias.find((m) => m.id === mediaId);
		setEnableReinitialize(true);

		if (targetMedia && targetMedia.links[DELETE] && targetMedia.status !== MediaStatus.DRAFT) {
			setIsPosting(true);

			deleteMedia(targetMedia.links[DELETE])
				.then(() => {
					setMedias(medias.filter((m) => m.id !== mediaId));
					setFieldValue(
						'medias',
						medias.filter((m) => m.id !== mediaId),
					);
				})
				.then(() => {
					CIOArefresh().finally(() => {
						toast.success('Item deleted');
					});
				})
				.finally(() => {
					setEnableReinitialize(false);
					setIsPosting(false);
					setIsDeleteModalOpen(false);
					dispatch(setIsProgressVisible(false));
					setSelectedMediaId('');
				})
				.catch(() => {
					toast.error(getErrorMessageOnPost('deleting your item'));
				});
		}
		setIsEditing(false);
	};

	// Click event to change order on medias
	const onClickMove = (direction: 'up' | 'down', index: number) => {
		const reorderedArray = reorderArray({ oldIndex: index, newIndex: index + (direction === 'up' ? -1 : 1) }, medias);

		setMedias(reorderedArray);
	};

	// Click on comment tabs
	const onClickCommentTab = (selectedTab: selectedTabType) => {
		setActiveCommentTab(selectedTab);
	};

	const onClickComments = () => {
		if (commentsRef.current) {
			commentsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	useEffect(() => {
		const values = Object.values(progress);
		const hasValueGreaterThanZero = values.some((value) => value > 0);
		dispatch(setIsProgressVisible(hasValueGreaterThanZero));
	}, [progress]);

	useEffect(() => {
		getMedia();

		if (userCan(CREATE_INTERNAL_COMMENT) && !userCan(CREATE_COMMENT)) {
			setActiveCommentTab(selectedTabType.BrandManager);
		} else {
			setActiveCommentTab(selectedTabType.Influencer);
		}

		dispatch(setIsProgressVisible(false));

		return () => {
			setMedias([]);
			setComments([]);
			setInternalComments([]);
			clearInterval(commentsInterval);
			clearInterval(internalCommentsInterval);
		};
	}, [id]);

	useEffect(() => {
		pollInfluencerComments();
		pollInternalComments();
	}, [activeCommentTab, id]);

	useEffect(() => {
		if (!isEditing) {
			getMedia();
		}
	}, [review]);

	useEffect(() => {
		if (isEditing) {
			setEnableReinitialize(false);
		} else {
			setEnableReinitialize(true);
		}
	}, [isEditing]);

	useEffect(() => {
		return () => {
			clearInterval(commentsInterval);
			clearInterval(internalCommentsInterval);
		};
	}, []);

	return (
		<Styled.Wrapper>
			<Formik
				enableReinitialize={enableReinitialize}
				validateOnChange={false}
				initialValues={{
					medias: initialValues(),
				}}
				validationSchema={Yup.object().shape({
					medias: Yup.array()
						.of(
							Yup.object().shape({
								text: Yup.string().required('Please add a caption'),
								url: Yup.string().trim().url('The sticky link is not valid'),
							}),
						)
						.required('You need to upload at least 1 image!') // these constraints are shown if and only if inner constraints are satisfied
						.min(1, 'You need to upload at least 1 image!'),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						const mediaItems: Array<PostMedia> = medias.map((media, index) => {
							return { ...media, text: values.medias && values.medias[index].text, url: values.medias && values.medias[index].url?.trim() };
						});

						if (!isEditing) {
							try {
								sendForReviewHandler(mediaItems);
							} catch (e) {
								toast.error(getSomethingWentWrongMessage());
								console.error(e);
							} finally {
								CIOArefresh();
								setSubmitting(false);
							}
						} else {
							await patchReviewHandler(mediaItems)
								.then((result) => {
									if (result) {
										setSubmitting(false);
										setIsEditing(false);
										setIsSendForReviewModalOpen(false);
										CIOArefresh();
										toast.success('Your assignment is updated');
									}
								})
								.catch((e) => {
									if (e.response.data && e.response.data.errors) {
										const errors: Array<{ status: number; source: { message: string } }> = e.response.data.errors;
										if (errors?.length > 0) {
											const validationErrors = errors.filter((x) => +x.status === 400);
											const serverErrors = errors.filter((x) => +x.status >= 500);

											validationErrors.forEach((item) => {
												const { status, source } = item;
												if (+status === StatusCode.BAD_REQUEST) {
													toast.error(source.message);
												}
											});

											if (serverErrors?.length > 0) {
												toast.error(getSomethingWentWrongMessage());
											}
										}
									}
								});
						}
					} catch (error) {
						console.error(error);
						setSubmitting(false);
					}
				}}
			>
				{({ handleSubmit, isSubmitting, resetForm, errors, validateForm, values, setFieldValue, isValid, dirty }) => {
					return (
						<>
							<ManagerTopbar
								medias={medias}
								heading={selectedCIOA.assignment.name}
								status={{
									reviewStatus: review?.newStatus,
									newFancyStatus: selectedCIOA.newFancyStatus,
								}}
								permissions={{
									canApprove: CAN_APPROVE,
									canRequestChanges: CAN_REQUEST_CHANGES,
									canEdit:
										(!isAssignmentApproved(selectedCIOA.newFancyStatus) && CAN_EDIT_POST) ||
										(isContentApproved(selectedCIOA.newFancyStatus) && medias.filter((x) => x.links[EDIT] !== undefined)?.length > 0),
									canComment: CAN_COMMENT,
									canCreateReview: CAN_CREATE_ASSIGNMENT_REVIEW,
									canClientApprove: CAN_CLIENT_APPROVE,
								}}
								isEditing={isEditing}
								loading={{
									isClientApproveLoading: isClientApproveLoading,
									isSaving: isSubmitting,
									isUploadingFiles: isUploadingFiles,
									isPosting: isPosting,
								}}
								handlers={{
									onClickOpenSendForReviewModal: () => {
										validateForm().then((res) => {
											if (isEmpty(res)) {
												setIsSendForReviewModalOpen(true);
											}
										});
									},
									onClickComments: onClickComments,
									onClickApprove: () => setIsApproveModalOpen(true),
									onClickClientApprove: onClickClientApprove,
									onClickEdit: () => setIsEditing(!isEditing),
									onClickCancel: () => {
										cancelEditHandler();
										resetForm();
									},
									onClickUploadStats: goToStats,
									onClickSaveChanges: handleSubmit,
									onClickRequestChanges: () => setIsRequestChangeModalOpen(true),
								}}
								errors={errors}
								files={medias.length}
								CIOAssignment={selectedCIOA}
								latestReview={review}
								isValid={isValid && dirty}
							/>

							{(isContentApproved(selectedCIOA.newFancyStatus) || isStatsUploaded(selectedCIOA.newFancyStatus)) && (
								<ApproveMessage contentType={ASSIGNMENT_TYPE.INSTAGRAM_POST} trackingLink={review?.trackingUrl} campaignCode={selectedCIOA.campaignCode} />
							)}

							{!isEditing && contentManagement.isProgressVisible && (
								<FileUploadProgress
									onClickClose={() => dispatch(setIsProgressVisible(false))}
									getIsUploadingFiles={(isLoading) => {
										setIsUploadingFiles(isLoading);
										if (!isLoading) {
											CIOArefresh();
										}
									}}
									files={progress}
								/>
							)}
							{selectedCIOA.newFancyStatus !== AssignmentStatus.DECLINED && (
								<>
									{isEmpty(medias) && hasBeenAssignmentReviewCreated && (
										<UploadMedia
											onChange={(files) => {
												AddMedias(files, setFieldValue), setEnableReinitialize(true);
											}}
										/>
									)}

									<FieldArray name='medias'>
										{({ swap, push }) => {
											return (
												<>
													{values.medias
														.filter((media) => media.id)
														.map((media, index: number) => {
															return (
																<StoryItem
																	setFieldValue={setFieldValue}
																	selectedCIOA={selectedCIOA}
																	selectedCampaign={selectedCampaign}
																	isInfluencer={user.permissions.isInfluencer}
																	key={media.id}
																	index={index}
																	media={media}
																	errors={errors}
																	values={values}
																	canDelete={Boolean(media.links[DELETE]) || media.status === MediaStatus.DRAFT}
																	canEdit={CAN_CREATE_STORY || CAN_EDIT_POST}
																	isUploading={isUploadingContent}
																	isEditing={isEditing || !IN_REVIEW}
																	isMoveEnabled={medias.length > 1}
																	onClickDelete={(item: Pick<PostMedia, 'id' | 'status'>) => {
																		setSelectedMediaId(item.id);
																		if (item.status !== MediaStatus.DRAFT) {
																			setIsDeleteModalOpen(!isDeleteModalOpen);
																		} else {
																			onClickDelete(item.id, setFieldValue);
																			const update = medias.filter((m) => m.id !== item.id);
																			setMedias(update);
																			setFieldValue('medias', update);
																		}
																	}}
																	onClickMove={(direction: 'up' | 'down', idx: number) => {
																		if (direction === 'up' && index !== 0) {
																			IN_REVIEW && onClickMove(direction, index);
																			swap(idx, idx - 1);
																		}
																		if (direction === 'down' && index !== medias.length - 1) {
																			IN_REVIEW && onClickMove(direction, index);
																			swap(idx, idx + 1);
																		}
																		validateForm();
																	}}
																/>
															);
														})}

													{(((CAN_CREATE_ASSIGNMENT_REVIEW || isEditing) && !isEmpty(medias)) ||
														(isEmpty(medias) && !hasBeenAssignmentReviewCreated && isEditing)) &&
														!isContentApproved(selectedCIOA.newFancyStatus) && (
															<Styled.AddFrameContainer data-testid='add-item'>
																<Styled.AddFrameContainerInner>
																	<Styled.ClickArea
																		onClick={() => {
																			if (inputRef.current) {
																				inputRef.current.click();
																			}
																		}}
																	>
																		<Styled.IconContainer>
																			<Icon name='plus' size='18' />
																		</Styled.IconContainer>
																		<span>Add frame</span>
																	</Styled.ClickArea>
																	<Styled.InvisibleInput
																		ref={inputRef}
																		type='file'
																		accept='image/*, video/*'
																		onChange={(e) => {
																			onChangeFiles(e, push);
																		}}
																		multiple
																	/>
																</Styled.AddFrameContainerInner>
															</Styled.AddFrameContainer>
														)}
												</>
											);
										}}
									</FieldArray>
								</>
							)}
							<ConfirmModal
								IconBackgroundColor={PALETTE.aquaHaze}
								icon='upload'
								isModalOpen={isSendForReviewModalOpen}
								toggleModal={() => setIsSendForReviewModalOpen((prev) => !prev)}
								title='Submit for review'
								action={() => {
									handleSubmit();
								}}
								buttonText='Send for review'
								isFetching={isPosting}
							>
								<p>Once submitted, your content will be reviewed as soon as possible and you’ll be notified.</p>
							</ConfirmModal>

							<DeleteModal
								isModalOpen={isDeleteModalOpen}
								toggleModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
								action={() => onClickDelete(selectedMediaId, setFieldValue)}
								title='Are you sure?'
								isFetching={isPosting}
							>
								<p>Are you sure you want to delete this media?</p>
							</DeleteModal>
						</>
					);
				}}
			</Formik>

			{(CAN_COMMENT || CAN_COMMENT_INTERNAL) && (
				<Styled.CommentsContainer ref={commentsRef}>
					<Styled.TitleWrapper>
						<div>{user?.permissions.isInfluencer ? 'Comments' : 'Add a comment'}</div>
						{user?.permissions.isInfluencer ? <p>Requested changes or updates for this assignment</p> : <p>Request changes or updates for this assignment.</p>}
					</Styled.TitleWrapper>
					<Comments
						user={user}
						canUseTabs={CAN_COMMENT && CAN_COMMENT_INTERNAL}
						comments={
							(activeCommentTab === selectedTabType.Influencer
								? comments
								: internalComments) as unknown as Array<Model> /* Until we refacor the comment component */
						}
						disabled={isAssignmentDisapproved(selectedCIOA.newFancyStatus) || !IN_REVIEW}
						activeTab={activeCommentTab}
						onClickTab={(selectedTab: string) => onClickCommentTab(selectedTab as selectedTabType)}
						onSend={(text: string) => postCommentHandler(text)}
					/>
				</Styled.CommentsContainer>
			)}

			<ConfirmModal
				IconBackgroundColor={colors.contentManagement.iconBackground}
				isModalOpen={isApproveModalOpen}
				toggleModal={() => setIsApproveModalOpen(!isApproveModalOpen)}
				action={() => postStatusHandler(APPROVE_REVIEW)}
				title='Approve content for posting'
				isFetching={isPosting}
				buttonText='Approve'
				icon='check-circle'
			>
				<p>Please make sure you have checked all required information is added.</p>
			</ConfirmModal>

			<ConfirmModal
				isModalOpen={isRequestChangeModalOpen}
				toggleModal={() => setIsRequestChangeModalOpen(!isRequestChangeModalOpen)}
				action={() => postStatusHandler(REQUEST_CHANGE)}
				title='Request changes'
				isFetching={isPosting}
				buttonText='Request changes'
				IconBackgroundColor={colors.contentManagement.iconBackground}
				icon='changes'
			>
				<p>Add your requested changes in the comments before notifying the influencer.</p>
			</ConfirmModal>
		</Styled.Wrapper>
	);
};

export default Story;
