import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { useFormik } from 'formik';
import { Store } from 'json-api-models';
import { isNil, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import * as Yup from 'yup';

import AccordionV2 from 'components/AffiliateMarketplace/Components/AccordionV2';
import GeneratedTrackingLinks from 'components/AffiliateMarketplace/Components/GeneratedTrackingLinks';
import InfoBlock from 'components/AffiliateMarketplace/Components/InfoBlock';
import { generateTrackingLink, getGeneratedTrackingLink } from 'components/AffiliateMarketplace/utils';
import { AM_BASE_URL } from 'components/AffiliateMarketplaceCampaignForm/hooks';
import { DarkButton } from 'components/Button';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import { ADD_TRACKING_LINK, LIST_TRACKING_LINK } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { useAppSelector, useAppDispatch } from 'hooks/useUserAppSelector';
import { setSelectedAMCampaignGeneratedTrackingLinks } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';
import { StatusCode } from 'services/Response.types';
import errorHandler from 'utils/formik_error_handler';
import { gtagEvent } from 'utils/ga';

import Styled from './ProgramCampaignDetail.style';

type Props = {
	URL: string;
	influencerCommission: number;
	links: { [key: string]: string };
	background: string;
	guidelines: string;
	isNotInfluencer?: boolean;
};

const ProgramCampaignDetail = ({ URL, links, influencerCommission, background, guidelines, isNotInfluencer }: Props) => {
	const [isDisplayTooltip, setIsDisplayTooltip] = useState<boolean>(false);
	const isShowDetailSidebar = useAppSelector((state) => state.amInfluencer.isShowDetailSidebar);
	const generatedTrackingLinks = useAppSelector((state) => state.amInfluencer.selectedAMCampaignGeneratedLinks);
	const influencerId = useAppSelector((state) => state.user.influencer?.id);
	const influencerName = useAppSelector((state) => state.user.influencer?.username);
	const { userCan } = useFeaturePermissions(links);
	const { mutate } = useSWRConfig();
	const { programId } = useParams();

	const dispatch = useAppDispatch();

	const setClipboard = async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			setIsDisplayTooltip(true);
		} catch (err) {
			console.error('Unable to copy text to clipboard', err);
		}
	};

	const formik = useFormik({
		initialValues: { originalUrl: '' },
		validationSchema: Yup.object().shape({
			originalUrl: Yup.string().url('Please enter a valid URL.').required('Please enter URL.'),
		}),
		onSubmit: async ({ originalUrl }, { setErrors, setSubmitting, resetForm }) => {
			if (userCan(ADD_TRACKING_LINK)) {
				setSubmitting(true);
				gtagEvent('am_tracking_link_generated', { originalUrl, influencerName, programId });

				await generateTrackingLink(links[ADD_TRACKING_LINK], {
					url: originalUrl,
					influencerId: influencerId,
				})
					.then((res) => {
						if (res.status === StatusCode.OK) {
							setClipboard(res.data.data.attributes.trackingUrl);
							const models = new Store();
							getGeneratedTrackingLink(links[LIST_TRACKING_LINK], influencerId).then((res) => {
								models.sync(res.data);
								const sortedTrackingLinks = models
									.findAll('affiliateTrackingLink')
									.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
								dispatch(setSelectedAMCampaignGeneratedTrackingLinks(sortedTrackingLinks));
							});
							mutate(
								(key: string) => {
									return key.includes(AM_BASE_URL) && key.includes('joined');
								},
								undefined,
								{
									revalidate: true,
								},
							);
							resetForm();
						}
					})
					.catch((err) => {
						errorHandler(err, setErrors);
						console.error(err);
					})
					.finally(() => {
						setSubmitting(false);
					});
			}
		},
	});

	const renderTooltip = () => {
		return (
			<Styled.Tooltip className={classNames({ visible: isDisplayTooltip })}>
				<Styled.TooltipText>Link copied to clipboard</Styled.TooltipText>
			</Styled.Tooltip>
		);
	};

	useEffect(() => {
		if (!isShowDetailSidebar) {
			formik.resetForm();
		}
	}, [isShowDetailSidebar]);

	useEffect(() => {
		if (isDisplayTooltip) {
			setTimeout(() => {
				setIsDisplayTooltip(false);
			}, 2000);
		}
	}, [isDisplayTooltip]);

	return (
		<>
			<Styled.BrandLinkSection>
				<InfoBlock title='Select a product to promote'>
					<Styled.LinkInfo>
						Visit{' '}
						<a href={URL} target='_blank' rel='noreferrer'>
							{URL.replace('https://', '').split('/')[0].replace('www.', '')}
						</a>{' '}
						and select the product you&apos;d like to promote. Copy the URL, and create a unique{' '}
						<span
							className='scroll-to-generate-link'
							onClick={() => {
								document.getElementById('generate-tracking-link-input')?.scrollIntoView({ block: 'start', behavior: 'smooth' });
							}}
						>
							trackable link here
						</span>
						. You can create as many links as you like.
					</Styled.LinkInfo>
				</InfoBlock>

				<Styled.ExternalLink href={`${URL}`} target='_blank'>
					<Icon name='external-link' size='20' />
					<Styled.ExternalLinkText data-testid='am-program-detail-store-link'>Go to {URL}</Styled.ExternalLinkText>
				</Styled.ExternalLink>
			</Styled.BrandLinkSection>

			<Styled.TrackingLinkWrapper>
				<form onSubmit={formik.handleSubmit}>
					<h6>Create your link</h6>
					<div className='help-text'>Generate a link from your favourite store.</div>
					<div className='input-wrapper' id='generate-tracking-link-input'>
						<Field
							label='Add link'
							validationState={formik.touched.originalUrl && formik.errors.originalUrl ? 'error' : undefined}
							validationMessage={formik.errors.originalUrl}
						>
							<Input
								data-testid='originalUrl'
								name='originalUrl'
								placeholder='Add product link'
								onChange={formik.handleChange}
								value={formik.values.originalUrl}
								onBlur={() => {
									formik.setFieldTouched('originalUrl');
								}}
								contentBefore={<Icon name='url-link' size='20' />}
							/>
						</Field>
					</div>
					<Styled.ButtonsWrapper>
						<DarkButton
							size='sm'
							disabled={isEmpty(formik.values.originalUrl) || formik.isSubmitting || !formik.isValid || isNotInfluencer}
							type='submit'
							data-testid='generate-traking-link-button'
						>
							Get link
						</DarkButton>
						{isDisplayTooltip && renderTooltip()}
					</Styled.ButtonsWrapper>
				</form>

				{!isNil(generatedTrackingLinks) && !isEmpty(generatedTrackingLinks) && (
					<Styled.GeneratedTrackingLinkSection>
						<h6>Generated links</h6>
						<div className='help-text'>Your generated links for this program.</div>
						<GeneratedTrackingLinks generatedTrackingLinks={generatedTrackingLinks} />
					</Styled.GeneratedTrackingLinkSection>
				)}

				<Styled.AccordionsWrapper>
					<AccordionV2 title='Posting guidelines' open={false}>
						<Styled.AccordionContentWrapper dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(guidelines) }} />
					</AccordionV2>
				</Styled.AccordionsWrapper>

				<Styled.BackgroundSection>
					<h6>Details</h6>
					<Styled.BackgroundCard>
						<div className='commission-info'>
							You get <span>{influencerCommission}</span> % per sale
						</div>
						<div className='campaign-background'>
							<div>Campaign background</div>
							<Styled.AccordionContentWrapper dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(background) }} />
						</div>
					</Styled.BackgroundCard>
				</Styled.BackgroundSection>
			</Styled.TrackingLinkWrapper>
			<Styled.ContactWrapper>
				<p>
					Got questions? Reach out to <br />
					<a className='contact-link' href='mailto:marketplace@collabs.se'>
						marketplace@collabs.se
					</a>
				</p>
			</Styled.ContactWrapper>
		</>
	);
};

export default ProgramCampaignDetail;
