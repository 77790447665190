import classNames from 'classnames';
import { Model, Store } from 'json-api-models';
import { isNil } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import AssignmentList from 'components/ContentManagement/Components/AssignmentList/AssignmentList';
import InfluencerList from 'components/ContentManagement/Components/InfluencerList/InfluencerList';
import { CampaignItemType } from 'components/ContentManagement/types';
import Icon from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { UserType } from 'reducers/UserReducers/types';
import { isMobile } from 'shared/utils/navigator';
import { LegacyModelCampaignFinder } from 'utils/LegacyModelCampaignFinder';

import CampaignList from './CampaignList';
import Styled from './NavigationSection.style';

type NavigationSectionProps = {
	campaignModels: Store;
	campaignLoading: boolean;
	selectedCampaign: CampaignItemType | undefined;
	selectedCIO: Model | undefined;
	CIOAS: Model[];
	selectedAssignment: Model | undefined;
	onReset: () => void;
	user: UserType;
};

/**
 * NavigationSection
 * CIO: CampaignInstagramOwner
 * @todo test
 * @param {NavigationSectionProps} props
 * @returns {JSX.Element}
 */
const NavigationSection = forwardRef((props: NavigationSectionProps, ref): JSX.Element => {
	const isReviewListVisisble = !isNil(props.selectedCampaign);
	const assignmentsUrl = props.selectedCIO?.links.campaignInstagramOwnerAssignments;
	const isAssignmentListVisisble = !isNil(assignmentsUrl);

	const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState<boolean>(false);
	const user = useAppSelector((state) => state.user);
	const selectedCampaign: Model | undefined = props.selectedCampaign && LegacyModelCampaignFinder(props.campaignModels, props.selectedCampaign.shortId);
	const { isInfluencer } = usePermissions();

	const SideNavContainerHeading = () => {
		if (isInfluencer()) {
			if (props.selectedCampaign) {
				return props.selectedCampaign.name;
			} else {
				return 'Campaigns';
			}
		}

		if (props.selectedCampaign && !props.selectedCIO) {
			// if the user has clicked on one campaign and not an influencer.
			return props.selectedCampaign.name;
		} else if (props.selectedCIO) {
			// If user has selected an influencer
			return props.selectedCIO.influencer.username;
		} else {
			// Start
			return 'Campaigns';
		}
	};

	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>(user.influencer.id);

	useEffect(() => {
		if (user.influencer.id !== selectedInfluencerId) {
			setDisplaySkeletonLoader(true);
			setTimeout(() => {
				setDisplaySkeletonLoader(false);
			}, 2000);
			setSelectedInfluencerId(user.influencer.id);
		}
	}, [user.influencer.id]);

	return (
		<Styled.SideNavContainer>
			{isMobile() && (
				<Styled.SideNavHeading onClick={isReviewListVisisble ? props.onReset : () => {}} className={classNames({ back: isReviewListVisisble })}>
					<Styled.BackButton className={classNames({ visible: isReviewListVisisble })} data-testid='cm-go-back-btn'>
						<Icon name='arrow-left' size='20' />
					</Styled.BackButton>
					<h1>{displaySkeletonLoader ? <Skeleton width={'120px'} borderRadius='4px' /> : SideNavContainerHeading()}</h1>
				</Styled.SideNavHeading>
			)}
			{!props.campaignLoading && (
				<Styled.ListContainer data-testid='campaign-list'>
					{!selectedCampaign && <CampaignList />}
					{!isInfluencer() && (
						<InfluencerList
							isVisible={isReviewListVisisble && !isAssignmentListVisisble}
							owners={selectedCampaign?.campaignInstagramOwners.filter((owner: Model) => !owner.cancelledAt && owner.attributes.joined) ?? []}
							selectedCampaign={selectedCampaign}
						/>
					)}
					{isInfluencer() && !assignmentsUrl && <Styled.EmptyText>You dont have any assignments for this campaign</Styled.EmptyText>}
					{assignmentsUrl && !isNil(props.selectedCIO) && (
						<AssignmentList ref={ref} assignmentsUrl={assignmentsUrl} user={props.user} ownerId={props.selectedCIO.id} />
					)}
				</Styled.ListContainer>
			)}
		</Styled.SideNavContainer>
	);
});
export default NavigationSection;
