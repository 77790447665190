import classNames from 'classnames';
import { isNil, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

import useAffiliateMarketplaceData from 'components/AffiliateMarketplaceCampaignForm/hooks';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';
import { CREATE_AFFILIATE_PROGRAM } from 'constants/hateoas-keys';
import usePermissions from 'hooks/usePermissions';

import Styled from './AMCampaignListContainer.style';
import { AMProgramListItem } from './untils';

const AMCampaignListContainer = () => {
	const { userCan } = usePermissions();
	const [affiliateCampaigns, setAffiliateCampaigns] = useState<AMProgramListItem[]>([]);
	const [searchValue, setSearchValue] = useState<string>('');
	const navigate = useNavigate();

	const { getPrograms } = useAffiliateMarketplaceData();
	const { models, isLoading } = getPrograms();

	useEffect(() => {
		if (!isLoading) {
			const activeCampaignCards = models.findAll('affiliateProgram');
			const newActiveCampaignCards = activeCampaignCards
				? orderBy(activeCampaignCards, 'createdAt', 'desc').map((amCampaign) => new AMProgramListItem(amCampaign))
				: [];
			setAffiliateCampaigns(newActiveCampaignCards);
		}
	}, [isLoading]);

	if (!userCan(CREATE_AFFILIATE_PROGRAM)) {
		return <Navigate to='/dashboard' />;
	}

	return (
		<Styled.Wrapper>
			<Styled.ActionsWrapper>
				<Styled.SearchInputContainer>
					<SearchInput
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
						}}
						onReset={() => {
							setSearchValue('');
						}}
					/>
				</Styled.SearchInputContainer>
				<Styled.ActionsBtnsWrapper>
					<Styled.Button size='sm' onClick={() => navigate('/affiliate/marketplace')}>
						Go to influencer&apos;s view
					</Styled.Button>
					<Styled.SecondaryButton size='sm' onClick={() => navigate('/affiliate/create')}>
						Create new
					</Styled.SecondaryButton>
				</Styled.ActionsBtnsWrapper>
			</Styled.ActionsWrapper>
			{isLoading && <LoadingSpinner position='center' />}
			{!isNil(affiliateCampaigns) && (
				<Styled.ListTable>
					<Styled.ListTableHead>
						<Styled.ListTableHeadTr>
							<Styled.ListTableTh>Title</Styled.ListTableTh>
							<Styled.ListTableTh>Store link</Styled.ListTableTh>
							<Styled.ListTableTh>Program ID</Styled.ListTableTh>
							<Styled.ListTableTh>
								Commission
								<div>(Total/Influencer/Collabs)</div>
							</Styled.ListTableTh>
							<Styled.ListTableTh>Start Date</Styled.ListTableTh>
							<Styled.ListTableTh>Sort</Styled.ListTableTh>
						</Styled.ListTableHeadTr>
					</Styled.ListTableHead>
					<Styled.ListTableBody>
						{affiliateCampaigns
							.filter((campaign) => {
								if (searchValue.length > 1) {
									return campaign.title.toLowerCase().includes(searchValue.toLowerCase());
								} else {
									return true;
								}
							})
							.map((amCampaign: AMProgramListItem, i: number) => {
								return (
									<Styled.ListTableBodyTr
										key={amCampaign.id}
										className={classNames(i % 2 ? 'dark-blue' : 'light-blue')}
										onClick={() => {
											navigate(`/affiliate/create/${amCampaign.id}`);
										}}
										data-testid='am-list-item'
									>
										<Styled.ListTableTd minWidth='350px'>{amCampaign.title}</Styled.ListTableTd>
										<Styled.ListTableTd>
											<div
												onClick={(e) => {
													e.stopPropagation();
													window.open(amCampaign.storeLink, '_blank');
												}}
											>
												<Icon name='external-link' size='24' />
											</div>
										</Styled.ListTableTd>
										<Styled.ListTableTd>{amCampaign.programId}</Styled.ListTableTd>
										<Styled.ListTableTd>{`${amCampaign.storeCommission.toFixed(2)}% / ${amCampaign.influencerCommission.toFixed(
											2,
										)}% / ${amCampaign.collabsCommission.toFixed(2)}%`}</Styled.ListTableTd>
										<Styled.ListTableTd>{amCampaign.createdAt}</Styled.ListTableTd>
										<Styled.ListTableTd>{amCampaign.sort}</Styled.ListTableTd>
									</Styled.ListTableBodyTr>
								);
							})}
					</Styled.ListTableBody>
				</Styled.ListTable>
			)}
		</Styled.Wrapper>
	);
};

export default AMCampaignListContainer;
