import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import FullscreenModal from 'components/ContentManagement/Components/FullscreenModal';
import MediaStatusMessage from 'components/ContentManagement/Components/MediaStatusMessage';
import { MediaStatus, PostMedia } from 'components/ContentManagement/types';
import DownloadableUrl from 'components/DownloadableUrl';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import { VIMEO_PLAYER_URL } from 'constants/enviroments';
import { CREATE_DOWNLOAD_URL } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { UserUploadStatus } from 'shared/Types/Assignment';

import Styled from './PostItem.style';

type ContentItemProps = {
	index: number;
	media: PostMedia;
	canDelete: boolean;
	canEdit: boolean;
	isEditing: boolean;
	isUploading: boolean;
	isMoveEnabled: boolean;
	onClickDelete: (item: Pick<PostMedia, 'id' | 'status'>) => void;
	onClickMove: (direction: string) => void;
};

/**
 * PostItem
 * @todo test
 * @param {ContentItemProps} props;
 * @returns {JSX.Element}
 */
const PostItem = (props: ContentItemProps): JSX.Element => {
	const [_file, setFile] = useState(props.media && props.media);
	const [isFullscreenOpen, setIsFullscreenOpen] = useState<boolean>(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const { userCan } = useFeaturePermissions(props.media.links || {});

	const CAN_DOWNLOAD_MEDIA = userCan(CREATE_DOWNLOAD_URL);

	const onClickFullScreen = () => {
		setIsFullscreenOpen(true);
	};

	const oldVimeoLink = (url?: string) => {
		if (_.isNil(url)) {
			return '';
		}

		return `${VIMEO_PLAYER_URL}/${url.split('/')[2]}`;
	};

	const renderMediaItem = (media: PostMedia) => {
		if (media.fileUrl) {
			if (media.type === 'video' && (media.vimeoPlaybackUrl || media.vimeoId)) {
				return (
					<Styled.Iframe
						src={media.vimeoPlaybackUrl || oldVimeoLink(media.vimeoId)}
						frameBorder='0'
						allow='autoplay; fullscreen; picture-in-picture'
						allowFullScreen
					/>
				);
			} else {
				return (
					<>
						<Styled.Image imageUrl={media.fileUrl} />
						<Styled.ShowFullSizeButton onClick={onClickFullScreen}>
							<Icon name='zoom' size='18' />
						</Styled.ShowFullSizeButton>
					</>
				);
			}
		}

		//@TEMP_FIX: When video was uploaded to Vimeo successfully but didn't get vimeoPlaybackUrl
		if (media.type === 'video' && !_.isNil(media.vimeoId) && _.isNil(media.vimeoPlaybackUrl)) {
			return <Styled.Iframe src={oldVimeoLink(media.vimeoId)} frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen />;
		}

		if (media.file && media.file.type.includes('video')) {
			return (
				<Styled.Video controls>
					<source src={URL.createObjectURL(media.file)} />
				</Styled.Video>
			);
		}

		if (media.file && media.file.type.includes('image')) {
			return (
				<>
					<Styled.Image imageUrl={URL.createObjectURL(media.file)} />
					<Styled.ShowFullSizeButton onClick={onClickFullScreen}>
						<Icon name='zoom' size='18' />
					</Styled.ShowFullSizeButton>
				</>
			);
		}
	};

	// memo to prevent flashing images with base64 onchange / blur
	const _renderMediaFile = useMemo(() => {
		return renderMediaItem(_file);
	}, [_file]);

	useEffect(() => {
		setFile(props.media);
	}, [props.media]);

	return (
		<>
			<Styled.Wrapper data-testid={`post-media-${props.index}`}>
				<Styled.Header>
					<Styled.ItemIndex>{(props.index + 1).toString().padStart(2, '0')}</Styled.ItemIndex>
					{props.isMoveEnabled && (
						<>
							<Styled.ArrowButtons>
								<Styled.ArrowButton onClick={() => props.onClickMove('up')}>
									<Icon name='chevron-up' size='24' />
								</Styled.ArrowButton>
								<Styled.ArrowButton onClick={() => props.onClickMove('down')}>
									<Icon name='chevron-down' size='24' />
								</Styled.ArrowButton>
							</Styled.ArrowButtons>
						</>
					)}
					{(CAN_DOWNLOAD_MEDIA || props.canDelete) && (
						<Styled.CustomDropdown icon='options' size='18' open={isDropdownOpen}>
							<DropdownMenu minWidth='220px'>
								{CAN_DOWNLOAD_MEDIA && (
									<DropdownItem>
										<DownloadableUrl url={props.media?.links?.createDownloadUrl ?? ''} originalFilename={props.media.originalFilename}>
											<Styled.DropdownItemIcon name='download' size='18' />
											Download content
										</DownloadableUrl>
									</DropdownItem>
								)}
								{props.canDelete && props.isEditing && (
									<DropdownItem
										onClick={() => {
											props.onClickDelete(props.media);
											setIsDropdownOpen(false);
										}}
									>
										<Styled.DropdownItemIcon name='trash-bin' size='18' />
										Delete
									</DropdownItem>
								)}
							</DropdownMenu>
						</Styled.CustomDropdown>
					)}
				</Styled.Header>
				<Styled.Media data-status={props.media.uploadStatus.toString()}>
					{props.media.uploadStatus === UserUploadStatus.SUCCESS ? <>{_renderMediaFile}</> : <MediaStatusMessage uploadStatus={props.media.uploadStatus} />}
				</Styled.Media>
			</Styled.Wrapper>
			{props.media.status !== MediaStatus.PENDING && (
				<FullscreenModal file={props.media.file || props.media.fileUrl || ''} isOpen={isFullscreenOpen} handleClose={() => setIsFullscreenOpen(false)} />
			)}
		</>
	);
};

export default PostItem;
