import { isNil } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LinkButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { getErrorMessageOnFetch } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import toast from 'services/Toast';
import { LegacyModelCampaignFinder } from 'utils/LegacyModelCampaignFinder';

import Styled from './CMContainer.style';
import { NavigationSection, ContentSection, DetailSection } from './Components';
import useContentManagementData, { useCampaignInstagramOwnerAssignments } from './hooks';
import { CampaignItemType } from './types';

/**
 * CMContainer
 * @returns {JSX.Element}
 */
const CMContainer = (): JSX.Element => {
	const [isleftColVisisble, setIsLeftColVisible] = useState<boolean>(true);
	const [isRightColVisible, setIsRightColVisible] = useState<boolean>(false);

	const publisherRoute = '/content-management';
	const influencerRoute = `/influencer/content-management`;

	const navigate = useNavigate();
	const { campaignId, ownerId, assignmentId } = useParams();

	const user = useAppSelector((state) => state.user);
	const selectedProfileId = user.influencer.id;
	const userIsInfluencer = user.permissions.isInfluencer;
	const route = userIsInfluencer ? influencerRoute : publisherRoute;

	const { getCampaign } = useContentManagementData(userIsInfluencer, selectedProfileId);
	const { useGetIOAS } = useCampaignInstagramOwnerAssignments();

	const { models: modelsCampaign, error: campaignError, loading: campaignLoading, mutation: campaignMutation } = getCampaign(campaignId);
	const campaignInstaOwners = modelsCampaign.findAll('campaignInstagramOwner');

	let selectedCIO = ownerId ? modelsCampaign.find('campaignInstagramOwner', ownerId) : null;

	const [initialProfileId, setInitialProfileId] = useState(selectedProfileId);

	if (null === selectedCIO && userIsInfluencer) {
		selectedCIO = campaignInstaOwners.find((cio) => cio.influencer?.id === selectedProfileId);
	}

	const { models: modelsIOA, error: IOAError, loading: IOALoading, mutation: IOAMutation } = useGetIOAS(selectedCIO?.links.campaignInstagramOwnerAssignments);

	let campaign;
	if (campaignId) {
		campaign = LegacyModelCampaignFinder(modelsCampaign, campaignId) as CampaignItemType;
	}

	// CampaignInstagramOwnerAssignments
	const CIOAS = modelsIOA.findAll('campaignInstagramOwnerAssignment');
	const selectedAssignment = CIOAS.find((item) => item.id === assignmentId || item.assignment.id === assignmentId);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const assignmentListRef = useRef<any>(null);

	const handleRefreshAssignments = () => {
		if (assignmentListRef.current) {
			assignmentListRef.current.refreshAssignments();
		}
	};

	const refreshCampaign = () => {
		handleRefreshAssignments();
		campaignMutation.refresh();
	};

	const onReset = () => {
		if (userIsInfluencer) {
			navigate(route);
		} else {
			if (assignmentId) {
				navigate(`${route}/${campaignId}/${selectedCIO.id}`);
				return false;
			} else if (!assignmentId && ownerId) {
				navigate(`${route}/${campaignId}`);
				return false;
			} else if (!ownerId && !assignmentId && campaignId) {
				navigate(route);
			} else {
				navigate(-1);
			}
		}
	};

	const getCampaignInstagramOwnerAssignmentById = (id: string) => {
		return modelsIOA.find('campaignInstagramOwnerAssignment', id);
	};

	const getCampaignInstagramOwnerById = (id: string) => {
		return modelsCampaign.find('campaignInstagramOwner', id);
	};

	useEffect(() => {
		// This useEffect will run only once on component mount and store the initial selectedProfileId
		setInitialProfileId(selectedProfileId);
	}, []);

	useEffect(() => {
		if (assignmentId) {
			setIsLeftColVisible(false);
		}

		if (!userIsInfluencer && !assignmentId) {
			setIsRightColVisible(false);
		}
	}, [campaignId, ownerId, assignmentId]);

	useEffect(() => {
		if (campaignError) {
			toast.error(getErrorMessageOnFetch('Campaign'));
			console.error(campaignError);
		}
	}, [campaignError]);

	useEffect(() => {
		if (IOAError) {
			toast.error(getErrorMessageOnFetch('Assignments'));
			console.error(IOAError);
		}
	}, [IOAError]);

	useEffect(() => {
		if (userIsInfluencer && selectedProfileId !== initialProfileId) {
			navigate(`${route}`);
		}
	}, [selectedProfileId, userIsInfluencer, initialProfileId]);

	useEffect(() => {
		if (userIsInfluencer && isNil(ownerId) && selectedCIO) {
			navigate(`${route}/${campaignId}/${selectedCIO.id}`);
		}
	}, [userIsInfluencer, ownerId, selectedCIO]);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	return (
		<Styled.Wrapper>
			<Styled.Grid className='three-col-layout cm--user'>
				<Styled.LeftColumn visible={isleftColVisisble}>
					<NavigationSection
						ref={assignmentListRef}
						campaignModels={modelsCampaign}
						campaignLoading={IOALoading}
						selectedCampaign={campaign}
						selectedCIO={selectedCIO && getCampaignInstagramOwnerById(selectedCIO.id)}
						CIOAS={CIOAS}
						selectedAssignment={selectedAssignment}
						onReset={onReset}
						user={user}
					/>
				</Styled.LeftColumn>
				<Styled.MiddleColumn visible={!isleftColVisisble} className='mid-column'>
					{!isleftColVisisble && (
						<Styled.GoBackContainer className='toggler'>
							<LinkButton
								onClick={() => {
									setIsLeftColVisible(!isleftColVisisble);
									setIsRightColVisible(false);
								}}
								size='sm'
							>
								<Icon name='arrow-left' size='16' /> Go back
							</LinkButton>
						</Styled.GoBackContainer>
					)}

					{campaignLoading || IOALoading ? (
						<LoadingSpinner position='center' />
					) : (
						<ContentSection
							selectedCampaign={campaign}
							CIOAssignment={selectedAssignment && getCampaignInstagramOwnerAssignmentById(selectedAssignment.id)}
							selectedCIO={selectedCIO}
							CIOAS={CIOAS}
							CIOArefresh={IOAMutation.refresh}
							refresh={refreshCampaign}
							add={campaignMutation.addIncluded}
						/>
					)}
				</Styled.MiddleColumn>
				<Styled.RightColumn visible={isRightColVisible}>
					{!isleftColVisisble && (
						<div className='toggler'>
							<button onClick={() => setIsRightColVisible(!isRightColVisible)}>
								<Icon name={`chevron-${isRightColVisible ? 'right' : 'left'}`} />
							</button>
						</div>
					)}
					<DetailSection
						selectedCIO={selectedCIO}
						activeCampaign={campaign}
						CIOAssignment={selectedAssignment && getCampaignInstagramOwnerAssignmentById(selectedAssignment.id)}
					/>
				</Styled.RightColumn>
			</Styled.Grid>
		</Styled.Wrapper>
	);
};

export default CMContainer;
