import { isNil, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Message from 'components/Form/Elements/Message/Message';
import { GET_INFLUENCER_ONBOARDING_DATA } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { InfluencerType } from 'reducers/UserReducers/types';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import toast from 'services/Toast';
import { silentRefreshUser } from 'shared/User/User.helpers';
import getModel from 'shared/utils/getModel';

import BrandSelect from './BrandSelect';
import CategorySelect from './CategorySelect';
import CollaborationType from './CollaborationType';
import Styled from './OnboardingContainer.style';
import ProfileSelector from './ProfileSelector';
import useOnboardingData from './hooks';
import { ONBOARDING_HEADER_CONTENTS, ONBOARDING_ERROR_TYPES } from './types';

type ProfileOnboardingDataType = {
	collaborationTypes?: string[];
	categoryIds?: string[];
	brandIds?: string[];
};

const OnboardingContainer = () => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const user = useAppSelector((state) => state.user);
	const [profiles, setProfiles] = useState<InfluencerType[]>([]);

	const [defaultOnboardingData, setDefaultOnboardingData] = useState<(ProfileOnboardingDataType & { username: string }) | undefined>();

	const [selectedProfile, setSelectedProfile] = useState<InfluencerType | undefined>(undefined);
	const [selectedCollaborationTypes, setSelectedCollaborationTypes] = useState<string[]>([]);
	const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
	const [selectedBrandIds, setSelectedBrandIds] = useState<string[]>([]);
	const [isOnlyOther, setIsOnlyOther] = useState<boolean>(false);

	const [step, setStep] = useState<number>(0);
	const [isInvalid, setIsInvalid] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [missingPreferenceProfileIds, setMissingPreferenceProfileIds] = useState<string[] | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const { getInfluencerOnboardingData, saveInfluencerOnboardingData, getProfilesData } = useOnboardingData();
	const { userCan } = useFeaturePermissions(selectedProfile?.links);

	const navigate = useNavigate();

	const selectProfileHandler = (profile: InfluencerType) => {
		if (step === 1 && isEmpty(selectedCategoryIds)) {
			setErrorMessage(ONBOARDING_ERROR_TYPES.noCategorySelected);
			setIsInvalid(true);
		} else if (step === 2 && isEmpty(selectedBrandIds)) {
			setErrorMessage(ONBOARDING_ERROR_TYPES.noBrandSelected);
			setIsInvalid(true);
		} else {
			setSelectedProfile(profile);
			if (!isNil(missingPreferenceProfileIds)) {
				setMissingPreferenceProfileIds((prev) => prev!.filter((profileId) => profileId !== profile.id));
				setIsInvalid(false);
			}
		}
	};

	const goBackHandler = () => {
		setStep((prev) => prev - 1);
	};

	const onSelect = () => {
		setStep((prev) => prev + 1);
	};

	const checkAllProfilePreferenceAdded = async (authService: CollabsAuthService) => {
		const profiles = await getProfilesData(authService);
		return profiles.filter((profile) => {
			if (profile.links) {
				return isNil(profile.links[GET_INFLUENCER_ONBOARDING_DATA]);
			} else {
				return false;
			}
		});
	};

	const onFinishBrands = () => {
		if (selectedProfile) {
			saveInfluencerOnboardingData({
				influencerIds: [selectedProfile?.id],
				collaborationTypes: selectedCollaborationTypes,
				categoryIds: selectedCategoryIds,
				brandIds: selectedBrandIds,
			})
				.then(() => {
					toast.success(`${selectedProfile.username} preference data has been saved`);
					checkAllProfilePreferenceAdded(authService).then((noPreferenceAddedProfiles) => {
						if (isEmpty(noPreferenceAddedProfiles)) {
							navigate('/influencer/dashboard');
						} else {
							setMissingPreferenceProfileIds(noPreferenceAddedProfiles.map((profile) => profile.id));
							setErrorMessage(ONBOARDING_ERROR_TYPES.missingPreferenceProfiles);
							setIsInvalid(true);
						}
					});
				})
				.catch(() => {
					toast.error(getSomethingWentWrongMessage());
				});
		}
	};

	const selectCollaborationOption = (type: 'paid' | 'affiliate') => {
		if (!isNil(selectedCollaborationTypes)) {
			setSelectedCollaborationTypes((prev) => {
				if (prev?.includes(type)) {
					return prev.filter((collaborationType) => collaborationType !== type);
				} else {
					return prev?.concat(type);
				}
			});
		} else {
			setSelectedCollaborationTypes([type]);
		}
	};

	const selectOneCategoryOption = (categoryId: string) => {
		setIsInvalid(false);
		if (selectedCategoryIds.includes(categoryId)) {
			setSelectedCategoryIds(selectedCategoryIds.filter((id) => id !== categoryId));
		} else {
			setSelectedCategoryIds(selectedCategoryIds.concat(categoryId));
		}
	};

	const selectOneBrandOption = (brandId: string) => {
		if (selectedBrandIds.includes(brandId)) {
			setSelectedBrandIds(selectedBrandIds.filter((id) => id !== brandId));
		} else {
			setSelectedBrandIds(selectedBrandIds.concat(brandId));
		}
	};

	const copyProfileHandler = async () => {
		if (defaultOnboardingData && selectedProfile) {
			const { collaborationTypes, categoryIds, brandIds } = defaultOnboardingData;

			setSelectedCollaborationTypes(collaborationTypes ?? []);
			setSelectedCategoryIds(categoryIds ?? []);
			setSelectedBrandIds(brandIds ?? []);
			await saveInfluencerOnboardingData({
				influencerIds: [selectedProfile?.id],
				collaborationTypes: collaborationTypes,
				categoryIds: categoryIds,
				brandIds: brandIds,
			})
				.then(async () => {
					await silentRefreshUser();
					toast.success(`${selectedProfile.username} preference data has been saved`);
					setStep(2);
				})
				.catch(() => {
					toast.error(getSomethingWentWrongMessage());
				});
		}
	};

	const steps = [
		<CollaborationType
			key={0}
			collaborationTypes={selectedCollaborationTypes}
			onClickCollaboration={selectCollaborationOption}
			onSelect={onSelect}
			isLoading={isLoading}
		/>,
		<CategorySelect
			key={1}
			onGoBack={goBackHandler}
			selectedCategoryIds={selectedCategoryIds}
			onClickCategory={selectOneCategoryOption}
			onSelect={onSelect}
			isInvalid={isInvalid}
			setIsOnlyOther={(bool: boolean) => setIsOnlyOther(bool)}
		/>,
		<BrandSelect
			key={2}
			onGoBack={goBackHandler}
			selectedCategoryIds={selectedCategoryIds}
			selectedBrandIds={selectedBrandIds}
			onClickBrand={selectOneBrandOption}
			onFinish={onFinishBrands}
			isNeedRandomBrands={isOnlyOther}
		/>,
	];

	useEffect(() => {
		const firstProfileThatHasOnboardingData = profiles.filter((profile) => !isNil(profile.links![GET_INFLUENCER_ONBOARDING_DATA]))[0];
		if (!isNil(firstProfileThatHasOnboardingData)) {
			getInfluencerOnboardingData(firstProfileThatHasOnboardingData.links![GET_INFLUENCER_ONBOARDING_DATA]).then((res) => {
				const influencerPreferenceModel = getModel(res, 'affiliateInfluencerPreferences', res.data.data.id);
				const influencerPreferenceCategories = getModel(res, 'affiliateCategory');
				const influencerPreferenceBrands = getModel(res, 'affiliateBrand');
				setDefaultOnboardingData({
					username: firstProfileThatHasOnboardingData.username,
					collaborationTypes: influencerPreferenceModel.collaborationTypes ?? [],
					categoryIds: influencerPreferenceCategories.map((category: { id: string }) => category.id),
					brandIds: influencerPreferenceBrands.map((brand: { id: string }) => brand.id),
				});
			});
		} else {
			setDefaultOnboardingData(undefined);
		}
	}, [profiles]);

	useEffect(() => {
		if (!isNil(user.influencers) && !isEmpty(user.influencers)) {
			setProfiles(user.influencers);
			if (isNil(selectedProfile)) {
				setSelectedProfile(user.influencers[0]);
			}
		}
	}, [user]);

	useEffect(() => {
		const setOnboardingData = async (selectedProfile: InfluencerType) => {
			setIsLoading(true);
			await getInfluencerOnboardingData(selectedProfile.links![GET_INFLUENCER_ONBOARDING_DATA])
				.then((res) => {
					const influencerPreferenceModel = getModel(res, 'affiliateInfluencerPreferences', res.data.data.id);
					const influencerPreferenceCategories = getModel(res, 'affiliateCategory');
					const influencerPreferenceBrands = getModel(res, 'affiliateBrand');

					setSelectedCollaborationTypes(influencerPreferenceModel.collaborationTypes ?? []);
					setSelectedCategoryIds(influencerPreferenceCategories.map((category: { id: string }) => category.id));
					setSelectedBrandIds(influencerPreferenceBrands.map((brand: { id: string }) => brand.id));
				})
				.finally(() => {
					setIsLoading(false);
				});
		};

		if (!isNil(selectedProfile)) {
			if (userCan(GET_INFLUENCER_ONBOARDING_DATA)) {
				setOnboardingData(selectedProfile);
			} else {
				setSelectedCollaborationTypes([]);
				setSelectedCategoryIds([]);
				setSelectedBrandIds([]);
				setIsLoading(false);
			}
			setStep(0);
		}
	}, [selectedProfile]);

	return (
		<Styled.Wrapper>
			<Styled.Header>
				<h3>{ONBOARDING_HEADER_CONTENTS[step].title}</h3>
				<p>{ONBOARDING_HEADER_CONTENTS[step].description}</p>
			</Styled.Header>
			<Styled.ProfileSelectorWrapper>
				{profiles.length > 1 && (
					<>
						<h6 className='title'>Select social profile</h6>
						<ProfileSelector
							profiles={profiles}
							selectedProfile={selectedProfile}
							onSelect={selectProfileHandler}
							missingPreferenceProfileIds={missingPreferenceProfileIds}
						/>
						{isEmpty(selectedCollaborationTypes) && isEmpty(selectedCategoryIds) && isEmpty(selectedBrandIds) && !isNil(defaultOnboardingData) && (
							<div className='copy-button-wrapper'>
								<Styled.CopyProfileButton onClick={copyProfileHandler}>Copy from {defaultOnboardingData.username}</Styled.CopyProfileButton>
							</div>
						)}
					</>
				)}
				{isInvalid && (
					<div className='error-wrapper'>
						<Message isError={true} message={errorMessage || ''} />
					</div>
				)}
			</Styled.ProfileSelectorWrapper>
			{steps[step]}
		</Styled.Wrapper>
	);
};

export default OnboardingContainer;
