import styled from 'styled-components';

import colors from 'styles/theme/colors';
import typography from 'styles/variables/typography';

const ListItemContent = styled.div`
	border-bottom: 1px solid ${colors.smoke};
	width: calc(100% - 16px);
	justify-content: space-between;
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 1rem;
	position: relative;
`;

const ListItemTitleContainer = styled.div`
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 200px;
`;

const AddToDashboardDiv = styled.div`
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	padding: 0 1rem 0 2rem;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 600;
	height: 64px;
	transition: all 200ms ease-in-out;

	svg path {
		transition: fill 0.2s ease-in-out;
	}

	&:hover {
		background-color: ${colors.smoke};
		color: #fafafa;

		${ListItemContent} {
			border-bottom: 1px solid ${colors.smoke};
		}

		svg path {
			fill: #fafafa;
		}
	}
	&.removeHover {
		background-color: #f2f2f2;
		color: ${colors.smoke};

		svg path {
			fill: ${colors.smoke};
		}

		${ListItemContent} {
			border-bottom: 1px solid ${colors.smoke} !important;
		}
	}
`;

const ShowPreview = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 41px;
	max-width: 41px;
	border-radius: 4px;
	width: 30%;
	.icon {
		margin-top: 3px;
	}
	rect {
		stroke: ${colors.smoke};
		stroke-width: 1px;
	}
	mask {
		display: none;
	}

	svg path {
		transition: fill 0.2s ease-in-out;
	}

	&:hover {
		background-color: ${colors.smoke};

		svg path {
			fill: #fafafa;
		}
	}
`;

const Styled = {
	ListItem,
	ListItemTitleContainer,
	ShowPreview,
	ListItemContent,
	AddToDashboardDiv,
};

export default Styled;
