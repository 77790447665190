import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // eslint-disable-line import/no-unassigned-import

import Styled from './TextareaV2.style';

type Props = {
	value: string;
	setValue: (enteredValue: string) => void;
	placeholder?: string;
	onBlur?: () => void;
	className?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customOptions?: any;
	canEdit?: boolean;
};

const TextareaV2 = ({ value, setValue, placeholder, onBlur, className, customOptions, canEdit = true }: Props) => {
	const toolbarOptions = useMemo(() => [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ header: [1, 2, false] }]], []);

	const isInitialValue = useMemo(() => {
		return value === '<p><br></p>' || value === '';
	}, [value]);

	const modules = useMemo(
		() => ({
			toolbar: customOptions ?? toolbarOptions,
			history: {
				delay: 1000,
				maxStack: 100,
				userOnly: false,
			},
		}),
		[customOptions, toolbarOptions],
	);

	const sanitizedValue = useMemo(() => DOMPurify.sanitize(value), [value]);

	return (
		<Styled.Wrapper className={classNames({ 'white-background': !isInitialValue && !isNil(value) }, className)}>
			{canEdit ? (
				<ReactQuill value={value} onChange={setValue} modules={modules} placeholder={placeholder} onBlur={onBlur} />
			) : (
				<Styled.ReadOnly dangerouslySetInnerHTML={{ __html: sanitizedValue }} />
			)}
		</Styled.Wrapper>
	);
};

export default TextareaV2;
