import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonSecondary, DarkButton, GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';

import BackButton from './Components/BackButton';
import CloseButton from './Components/CloseButton';
import Styled from './DiscoveryDropdown.style';

type Item = { addInfluencersUrl: string; id: string; itemName: string };

/**
 */
const CampaignListMenuItems = (props: {
	items: Array<Item>;
	onCancel?: () => void;
	onClick: (url: string, campaignId: string, campaignName: string) => void;
	onClose: () => void;
	isCancellable?: boolean;
	isSaving: boolean;
}) => {
	const [selectedCampaing, setSelectedCampaign] = useState<Item | null>(null);
	const [isSelectionEnabled, setIsSelectionEnabled] = useState(true);
	const [searchValue, setSearchValue] = useState<string>('');
	const [campaignsToList, setCampaignsToList] = useState<Item[]>(props.items);

	const [cursor, setCursor] = useState(-1);

	const addSelectionToCampaignhandler = (addInfluencersUrl: string, id: string, campaignName: string) => {
		props.onClick(addInfluencersUrl, id, campaignName);
	};

	const navigate = useNavigate();

	const scrollToItem = (id: string) => {
		id &&
			document.getElementById(id)?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
	};

	const onSearchCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);
		if (value?.length === 0) {
			setCampaignsToList(props.items);
		} else {
			const filteredData = props.items.filter((item: Item) => {
				return item.itemName.toLowerCase().indexOf(value.toLowerCase()) > -1;
			});
			setCampaignsToList(filteredData);
		}
	};

	const onResetSearch = () => {
		setSearchValue('');
		setCampaignsToList(props.items);
		setCursor(-1);
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (campaignsToList.length > 0) {
				setCursor((prev) => {
					return prev < campaignsToList.length - 1 ? prev + 1 : prev;
				});
			}
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (campaignsToList.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}
		if (e.key === 'Escape') {
			setCursor(-1);
			setCampaignsToList(props.items);
			setSearchValue('');
		}

		if (e.key === 'Enter') {
			const selectedCampaign = campaignsToList[cursor];
			if (selectedCampaign && selectedCampaign.addInfluencersUrl) {
				addSelectionToCampaignhandler(selectedCampaign.addInfluencersUrl, selectedCampaign.id, selectedCampaign.itemName);
			} else {
				console.warn('Campaign data is missing or incomplete for the selected item.');
			}
		}
	};

	useEffect(() => {
		scrollToItem(`item-${cursor}`);
	}, [cursor]);

	const renderItems = () => {
		if (props.items.length === 0) {
			return (
				<Styled.EmptyState>
					<p>No campaigns created yet.</p>
					<GhostButton onClick={() => navigate('/campaigns/create')}>Create new campaign</GhostButton>
				</Styled.EmptyState>
			);
		}
		return (
			<Styled.MenuItemsWrapper className='campaign-list grow-1'>
				<SearchInput
					onKeyDown={(e) => {
						keyboardNavigation(e);
					}}
					value={searchValue}
					onChange={(e) => onSearchCampaignName(e)}
					onReset={() => onResetSearch()}
					className='small'
					placeholder='Search for campaigns'
				/>
				{campaignsToList.map((item, key) => {
					return (
						<Styled.DropdownItemInnerWrapper
							id={`item-${key}`}
							key={key}
							onClick={() => {
								if (isSelectionEnabled) {
									setSelectedCampaign({ addInfluencersUrl: item.addInfluencersUrl, id: item.id, itemName: item.itemName });
								}
							}}
							onDoubleClick={() => {
								setIsSelectionEnabled(false);
								addSelectionToCampaignhandler(item.addInfluencersUrl, item.id, item.itemName);
								setIsSelectionEnabled(true);
								setSelectedCampaign(null);
							}}
							className={classNames({ 'selected-item': selectedCampaing?.id === item.id, cursor: cursor === key })}
						>
							<Styled.NameWrapper>
								<Icon name='campaign' size='16' />
								<Styled.Name>{item.itemName}</Styled.Name>
							</Styled.NameWrapper>
						</Styled.DropdownItemInnerWrapper>
					);
				})}
			</Styled.MenuItemsWrapper>
		);
	};

	return (
		<Styled.CustomMenu>
			<Styled.MenuHeader>
				{props.isCancellable && props.onCancel && <BackButton onCancel={props.onCancel!} />}
				<CloseButton onClose={props.onClose} />
			</Styled.MenuHeader>

			{renderItems()}

			<Styled.MenuItemsWrapper>
				<Styled.MenuFooter>
					<ButtonSecondary type='button' size='sm' disabled={selectedCampaing === null} onClick={() => setSelectedCampaign(null)}>
						<span>Cancel</span>
					</ButtonSecondary>
					<DarkButton
						type='button'
						size='sm'
						disabled={selectedCampaing === null || !isSelectionEnabled}
						onClick={() => {
							addSelectionToCampaignhandler(selectedCampaing!.addInfluencersUrl, selectedCampaing!.id, selectedCampaing!.itemName);
							setSelectedCampaign(null);
						}}
					>
						{props.isSaving ? <LoadingSpinner size='sm' /> : <span>Add</span>}
					</DarkButton>
				</Styled.MenuFooter>
			</Styled.MenuItemsWrapper>
		</Styled.CustomMenu>
	);
};

export default CampaignListMenuItems;
