import classNames from 'classnames';

import Icon from 'components/Icon';

import Styled from './ExpandableContentRight.style';

export type ExpandableContentProps = {
	children: JSX.Element;
	onCancel: () => void;
	onDone: () => void;
	drawerIsExpanded: boolean;
	doneButtonText?: string;
};

/**
 * Expandable content component
 * @returns {JSX.Element}
 */
const ExpandableContentRight = ({ children, onDone, drawerIsExpanded }: ExpandableContentProps): JSX.Element => {
	return (
		<Styled.ContentWrapper data-testid='content-right' className={classNames({ show: drawerIsExpanded })}>
			<Styled.Divider />
			<Styled.Section>
				<Styled.Content>{children}</Styled.Content>
				<Styled.ButtonsWrapper>
					<Styled.CloseButton role='button' onClick={onDone} data-testid='drawer-close-expcr'>
						<div>Close</div> <Icon size='18' name='collapse-filled' />
					</Styled.CloseButton>
				</Styled.ButtonsWrapper>
			</Styled.Section>
		</Styled.ContentWrapper>
	);
};

export default ExpandableContentRight;
