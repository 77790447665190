import styled from 'styled-components';

import { SidebarNavContainer, SidebarNavHeading } from 'styles/layout/three-col';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { mediaQueries } from 'styles/variables/media-queries';

const SideNavContainer = styled(SidebarNavContainer)`
	position: relative;
	height: 100%;
`;

const SideNavHeading = styled(SidebarNavHeading)`
	position: relative;
	display: flex;
	align-items: center;
	height: auto;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: ${guttersWithRem.xs};
	transition: padding-left 200ms ease-in-out;
	justify-content: space-between;

	h1 {
		font-size: 1.2rem;
		transition: color 200ms ease-in-out;
		max-width: 80%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.7;
	}

	&.back {
		padding-left: 3.875rem;
		transition: background-color 200ms ease-in-out;
	}

	.icon {
		margin-right: 10px;
	}
`;

const BackButton = styled.button`
	position: absolute;
	top: 8px;
	left: 22px;
	background-color: transparent;
	border: 0;
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: ${borderRadius.s};
	margin-top: 8px;
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 200ms ease-in-out,
		visibility 200ms ease-in-out,
		background-color 150ms ease-in-out;

	${mediaQueries.xl`
		top: 10px;
	`};

	.icon {
		margin-top: 3px;

		path {
			transition: fill 150ms ease-in-out;
		}
	}

	&.visible {
		opacity: 1;
		visibility: visible;
		cursor: pointer;
	}

	&:hover {
		background-color: ${colors.integratedInbox.campaignSection.header.hover.background};

		.icon path {
			fill: ${colors.integratedInbox.campaignSection.header.hover.color};
		}
	}
`;

const BlastIconWrapper = styled.div`
	width: 40px;
	height: 40px;
	border-radius: ${borderRadius.s};

	background-color: ${colors.integratedInbox.lightBlue};

	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 150ms ease-in-out;
	cursor: pointer;

	.icon {
		margin: 0;
		path {
			transition: fill 150ms ease-in-out;
		}
	}

	& > i {
		display: flex;
		margin: 0;
	}

	&:hover {
		background-color: ${colors.integratedInbox.campaignSection.header.blast.hover.background};
		path {
			fill: ${colors.integratedInbox.campaignSection.header.blast.hover.color};
		}
	}
`;

const Content = styled.div`
	position: relative;
	height: 100%;
	padding: 0.5rem;
`;

const ListWrapper = styled.div<{ isCampaignListOpen: boolean; isDirectConversationListOpen: boolean; isDirectConversationEmpty: boolean }>`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: scroll;
`;

const TabsWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 0 0.5rem;
	margin-bottom: 16px;
	gap: 16px;
	cursor: pointer;
	div {
		padding: 1rem 0;
		font-size: 1rem;
		color: ${newColors.oceanBlue};
		width: 100%;
		text-align: center;
		&.selected,
		&:hover {
			border-bottom: 1.5px solid ${newColors.oceanBlue};
		}
	}
`;

const Styled = {
	TabsWrapper,
	SideNavContainer,
	SideNavHeading,
	BackButton,
	BlastIconWrapper,
	Content,
	ListWrapper,
};

export default Styled;
