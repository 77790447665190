import { useRef } from 'react';

import { Button } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './DeleteModal.style';

type DeleteModalProps = {
	isModalOpen: boolean;
	toggleModal: () => void;
	title?: string;
	isFetching?: boolean;
	action: () => void;
	iconReplacement?: JSX.Element;
	children?: JSX.Element;
	buttonText?: string;
	cancelText?: string;
	id?: string;
};
/**
 * Delete modal component
 * @returns {JSX.Element}
 */
const DeleteModal = ({
	iconReplacement,
	isModalOpen,
	toggleModal,
	title,
	isFetching,
	action,
	children,
	buttonText,
	cancelText,
	id,
}: DeleteModalProps): JSX.Element => {
	const cancelRef = useRef<HTMLButtonElement>(null);

	const onShown = () => {
		setTimeout(() => {
			cancelRef.current && cancelRef.current.focus();
		}, 0);
	};

	return (
		<Styled.CustomModal size={'xs'} open={isModalOpen} handleClose={toggleModal} onShown={() => onShown()}>
			<Styled.ModalDeleteContainer data-testid={id ? `delete-modal-${id}` : 'delete-modal'}>
				{iconReplacement ? (
					iconReplacement
				) : (
					<Styled.ModalDeleteIconContainer>
						<Icon name='trash-bin' size='20' />
					</Styled.ModalDeleteIconContainer>
				)}
				<h3>{title}</h3>
				{children}
				{isFetching && (
					<Styled.SpinnerWrapper>
						<LoadingSpinner />
					</Styled.SpinnerWrapper>
				)}
				<Styled.ModalButtonsContainer>
					<Button btnRef={cancelRef} className='cancel' onClick={() => toggleModal()}>
						<div>{cancelText ?? 'Cancel'}</div>
					</Button>
					<Styled.DeleteButton onClick={action}>
						<div data-testid='delete-forever-btn'>{buttonText ?? 'Delete Forever'}</div>
					</Styled.DeleteButton>
				</Styled.ModalButtonsContainer>
			</Styled.ModalDeleteContainer>
		</Styled.CustomModal>
	);
};

export default DeleteModal;
