import { useRef, useContext, useEffect } from 'react';

import { InfluencerModel } from 'api-models';
import Avatar from 'components/Avatar';
import BlastChatInput from 'components/IntegratedInbox/Components/BlastChatInput';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';

import Styled from './MessageContent.style';

/**
 * @todo test
 */
const MessageContent = (props: {
	onSend: (message: string) => Promise<boolean>;
	onCloseModal: () => void;
	messageTargetInfluencer?: InfluencerListItemType;
	influencer?: InfluencerModel;
	isList?: boolean;
	getMessage: (message: string) => void;
}) => {
	const { messageTargetInfluencer: contextMessageTargetInfluencer } = useContext(DiscoveryContext);
	const messageEndRef = useRef<HTMLDivElement>(null);
	const messagesRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = () => {
		if (messagesRef.current) {
			const topPos = messageEndRef.current ? messageEndRef.current.offsetTop : 0;
			messagesRef.current.scrollTop = topPos;
		}
	};

	const sendMessageHandler = async (message: string) => {
		return await props.onSend(message);
	};

	const renderInfluencerTag = (influencer: InfluencerListItemType | InfluencerModel, isModel: boolean = false) => {
		const imageUrl = isModel
			? (influencer as InfluencerModel).attributes.profilePictureUrl ?? ''
			: (influencer as InfluencerListItemType).profileImageUrl || (influencer as InfluencerListItemType).links?.profilePictureUrl || '';
		const username = isModel ? (influencer as InfluencerModel).attributes.username ?? '' : (influencer as InfluencerListItemType).username;
		return (
			<Styled.SelectedInfluencerTag key={influencer.id}>
				<Avatar imageUrl={imageUrl} name={username} size='md' />
				<div className='name'>{username}</div>
			</Styled.SelectedInfluencerTag>
		);
	};

	const selectedInfluencer = props.messageTargetInfluencer || props.influencer || contextMessageTargetInfluencer;

	useEffect(() => {
		scrollToBottom();
	}, [selectedInfluencer]);

	return (
		<>
			<Styled.SelectedInfluencers>
				{selectedInfluencer && (
					<>
						{props.messageTargetInfluencer && renderInfluencerTag(props.messageTargetInfluencer)}
						{props.influencer && renderInfluencerTag(props.influencer, true)}
						{!props.messageTargetInfluencer && !props.influencer && contextMessageTargetInfluencer && renderInfluencerTag(contextMessageTargetInfluencer)}
					</>
				)}
			</Styled.SelectedInfluencers>
			<BlastChatInput onSend={sendMessageHandler} onScrollToBottom={scrollToBottom} onCloseModal={props.onCloseModal} getMessage={props.getMessage} />
			<Styled.HelpText>Message will be sent to the influencer's inbox if they have a Collabs account as well as an email.</Styled.HelpText>
		</>
	);
};

export default MessageContent;
