import classNames from 'classnames';

import { AssignmentType } from 'components/Assigment/types';
import { CampaignInstagramOwnerStatusText, getAssignmentIcon } from 'components/ContentManagement/Utils';
import { AssignmentStatus, AssignmentReviewStatus } from 'shared/Types/Assignment';

import Styled from './AssignmentItem.style';

type AssignmentItemProps = {
	id: string;
	type: string;
	status: AssignmentStatus | AssignmentReviewStatus;
	name: string;
	isActive: boolean;
};

/**
 * AssignmentItem
 * @param {AssignmentItemProps} props
 * @returns {JSX.Element}
 */
const AssignmentItem = ({ id, type, status, name, isActive }: AssignmentItemProps): JSX.Element => {
	const statusLabelText = CampaignInstagramOwnerStatusText(status) || 'No content';

	return (
		<Styled.ListItem>
			<Styled.Wrapper className={classNames({ selected: isActive })}>
				<Styled.ImageWrapper>{getAssignmentIcon(type)}</Styled.ImageWrapper>
				<Styled.NameContainer>
					<Styled.Name>
						<span data-testid={`ai-${id}`}>{name}</span>
						<Styled.StatusText>
							<Styled.ReviewStatusLabel>{type !== AssignmentType.AFFILIATE_TASK ? statusLabelText : 'Affiliate'}</Styled.ReviewStatusLabel>
						</Styled.StatusText>
					</Styled.Name>
				</Styled.NameContainer>
			</Styled.Wrapper>
		</Styled.ListItem>
	);
};

export default AssignmentItem;
