import classNames from 'classnames';
import { Model } from 'json-api-models';
import React, { useState } from 'react';

import Icon from 'components/Icon';
import Pill from 'components/Pill';
import SearchInput from 'components/SearchInput';
import { AssignmentReviewStatus, AssignmentStatus } from 'shared/Types/Assignment';

import InfluencerItem from './InfluencerItem';
import Styled from './InfluencerList.style';

type Props = {
	isVisible?: boolean;
	owners: Model[];
	selectedCampaign?: Model;
};

const InfluencerList = ({ isVisible, owners }: Props) => {
	const Titles = {
		NOT_UPLOADED: 'Awaiting content upload',
		NEED_REVIEW: 'Content to review',
		REVIEW_APPROVED: 'Approved for posting',
		NEED_UPDATE: 'Changes requested',
		STATS_ADDED: 'Stats added',
		APPROVED: 'Approved for payment',
		ALL: 'All',
	};

	// States & hooks
	const [selectedItem, setSelectedItem] = useState(Titles.ALL);
	const [assignmentStatus, setAssigmentStatus] = useState<AssignmentStatus | AssignmentReviewStatus | null>(null);
	const [influencerSearch, setInfluencerSearch] = useState('');

	type AssignmentStatusType = AssignmentStatus | AssignmentReviewStatus;
	type AssignmentsByStatusType = Record<AssignmentStatusType, { owner: Model; assignment: Model }[]>;

	const influencers: Model[] = [];
	const assignmentsByStatus: AssignmentsByStatusType = {
		in_review: [],
		approved: [],
		[AssignmentReviewStatus.REJECTED]: [],
		[AssignmentReviewStatus.CHANGE_REQUESTED]: [],
		[AssignmentStatus.WAITING_FOR_UPLOAD]: [],
		[AssignmentStatus.REVIEW_APPROVED]: [],
		[AssignmentStatus.STATISTICS_UPLOADED]: [],
		[AssignmentStatus.DECLINED]: [],
		[AssignmentStatus.DISPUTED]: [],
		[AssignmentStatus.COMPENSATED]: [],
	};

	// Define the statuses
	const statuses = [
		AssignmentStatus.STATISTICS_UPLOADED,
		AssignmentStatus.APPROVED,
		AssignmentStatus.IN_REVIEW,
		AssignmentStatus.REVIEW_APPROVED,
		AssignmentReviewStatus.CHANGE_REQUESTED,
		AssignmentStatus.WAITING_FOR_UPLOAD,
	];

	statuses.forEach((status) => {
		assignmentsByStatus[status] = [];
	});

	owners.forEach((owner) => {
		owner.campaignInstagramOwnerAssignments?.forEach((assignment: Model) => {
			const status = assignment.newFancyStatus as AssignmentStatus;

			if (assignmentsByStatus[status]) {
				assignmentsByStatus[status]?.push({ owner, assignment });
			} else {
				assignmentsByStatus[status] = [{ owner, assignment }];
			}

			const isSelectedStatus = !assignmentStatus || assignmentStatus === status;
			if (isSelectedStatus) {
				influencers?.push(owner);
			}
		});
	});

	return (
		<Styled.Wrapper className={classNames({ visible: isVisible })}>
			<Styled.InnerWrapper>
				<ul data-testid='assignment-filters'>
					<Styled.FilterItem
						className={classNames({ selected: selectedItem === Titles.NOT_UPLOADED })}
						onClick={() => {
							setAssigmentStatus(AssignmentStatus.WAITING_FOR_UPLOAD);
							setSelectedItem(Titles.NOT_UPLOADED);
						}}
					>
						<Styled.FilterTitle>
							<Styled.IconContainer>
								<Icon name='image' />
							</Styled.IconContainer>
							<span>{Titles.NOT_UPLOADED}</span>
							<Pill title={`${assignmentsByStatus[AssignmentStatus.WAITING_FOR_UPLOAD].length}`} className='status-count' />
						</Styled.FilterTitle>
					</Styled.FilterItem>
					<Styled.FilterItem
						className={classNames({ selected: selectedItem === Titles.NEED_REVIEW })}
						onClick={() => {
							setAssigmentStatus(AssignmentStatus.IN_REVIEW);
							setSelectedItem(Titles.NEED_REVIEW);
						}}
					>
						<Styled.FilterTitle>
							<Styled.IconContainer>
								<Icon name='hourglass' />
							</Styled.IconContainer>
							<span>{Titles.NEED_REVIEW}</span>
							<Pill title={`${assignmentsByStatus[AssignmentStatus.IN_REVIEW].length}`} className='status-count' />
						</Styled.FilterTitle>
					</Styled.FilterItem>

					<Styled.FilterItem
						className={classNames({ selected: selectedItem === Titles.NEED_UPDATE, iconUpdate: true })}
						onClick={() => {
							setAssigmentStatus(AssignmentReviewStatus.CHANGE_REQUESTED);
							setSelectedItem(Titles.NEED_UPDATE);
						}}
					>
						<Styled.FilterTitle>
							<Styled.IconContainer>
								<Icon name='changes' size='18' />
							</Styled.IconContainer>
							<span>{Titles.NEED_UPDATE}</span>
							<Pill title={`${assignmentsByStatus[AssignmentReviewStatus.CHANGE_REQUESTED].length}`} className='status-count' />
						</Styled.FilterTitle>
					</Styled.FilterItem>
					<Styled.FilterItem
						className={classNames({ selected: selectedItem === Titles.REVIEW_APPROVED, iconUpdate: true })}
						onClick={() => {
							setAssigmentStatus(AssignmentStatus.REVIEW_APPROVED);
							setSelectedItem(Titles.REVIEW_APPROVED);
						}}
					>
						<Styled.FilterTitle>
							<Styled.IconContainer>
								<Icon name='check-circle' />
							</Styled.IconContainer>
							<span>{Titles.REVIEW_APPROVED}</span>
							<Pill title={`${assignmentsByStatus[AssignmentStatus.REVIEW_APPROVED].length}`} className='status-count' />
						</Styled.FilterTitle>
					</Styled.FilterItem>
					<Styled.FilterItem
						className={classNames({ selected: selectedItem === Titles.APPROVED })}
						onClick={() => {
							setAssigmentStatus(AssignmentStatus.APPROVED);
							setSelectedItem(Titles.APPROVED);
						}}
					>
						<Styled.FilterTitle>
							<Styled.IconContainer>
								<Icon name='money' />
							</Styled.IconContainer>
							<span>{Titles.APPROVED}</span>
							<Pill title={`${assignmentsByStatus[AssignmentStatus.APPROVED].length}`} className='status-count' />
						</Styled.FilterTitle>
					</Styled.FilterItem>
					<Styled.FilterItem
						className={classNames({ selected: selectedItem === Titles.ALL })}
						onClick={() => {
							setAssigmentStatus(null);
							setSelectedItem(Titles.ALL);
						}}
					>
						<Styled.FilterTitle>
							<Styled.IconContainer>
								<Icon name='influencer' />
							</Styled.IconContainer>
							<span>See all influencers</span>
						</Styled.FilterTitle>
					</Styled.FilterItem>
				</ul>
				<Styled.InfluencersTitle>
					<span>{assignmentStatus ? 'Assignments' : 'Influencers'}</span>
				</Styled.InfluencersTitle>
				<Styled.SearchInputWrapper>
					<SearchInput
						value={influencerSearch}
						onChange={({ target }) => setInfluencerSearch(target.value)}
						placeholder='Search influencers'
						onReset={() => setInfluencerSearch('')}
					/>
				</Styled.SearchInputWrapper>
				<Styled.ListWrapper>
					{assignmentStatus ? (
						assignmentsByStatus[assignmentStatus].length > 0 &&
						assignmentsByStatus[assignmentStatus].map(({ owner, assignment }, index) =>
							owner.influencer.username.includes(influencerSearch) ? (
								<React.Fragment key={index}>
									{owner.influencer && (
										<InfluencerItem
											username={owner.influencer.username}
											profileImage={owner.influencer.links.profilePictureUrl}
											cioId={owner.id}
											assignment={assignment}
										/>
									)}
								</React.Fragment>
							) : null,
						)
					) : (
						<>
							{owners
								.filter((owner) => owner.influencer.username.includes(influencerSearch))
								.map((owner, index) => {
									return (
										<React.Fragment key={index}>
											{owner.influencer && (
												<InfluencerItem username={owner.influencer.username} profileImage={owner.influencer.links.profilePictureUrl} cioId={owner.id} />
											)}
										</React.Fragment>
									);
								})}
						</>
					)}
				</Styled.ListWrapper>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default InfluencerList;
