import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/variables/colors';
import { gutters, guttersWithRem } from 'styles/variables/gutter';

type P = { theme: DefaultThemeV2 };

const Wrapper = styled.div``;

const HashtagsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: ${gutters.xxs};
	row-gap: ${gutters.xxs};
	margin-top: ${gutters.xxs};
`;

const hashtagItemPadding = `${guttersWithRem.xs} ${guttersWithRem.xs}`;
const HashtagItem = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	padding: ${hashtagItemPadding};
	border: 1px solid ${colors.black};
	border-radius: ${({ theme }: P) => theme.radius.small};
	background-color: ${colors.black};
	color: ${colors.white};
	padding-top: 0.4rem;
	cursor: pointer;

	& path {
		fill: ${colors.white};
	}
`;

const EnterLabel = styled.div`
	color: ${colors.gray7};
`;

const IconContainer = styled.div`
	display: flex;
	cursor: pointer;
`;

const ContentAfterContainter = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;

	.icon {
		line-height: 0;
	}
`;

const Styled = {
	Wrapper,
	HashtagsWrapper,
	HashtagItem,
	EnterLabel,
	IconContainer,
	ContentAfterContainter,
};

export default Styled;
