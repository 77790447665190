import CampaignService from 'services/Statistics/Campaign';

const useCampaignData = () => {
	const getCampaigns = (props?: { statuses?: string[] }) => {
		let statuses: Array<string> = [];

		if (props && props.statuses) {
			statuses = props.statuses;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return CampaignService.fetchCampaigns({ statuses: statuses }).then((res: any) => {
			const result: { id: string; itemName: string; addInfluencersUrl: string }[] = res.data.map(
				(data: { type: string; id: string; links: { addInfluencers: string }; attributes: { name: string } }) => {
					return { id: data.id, itemName: data.attributes.name, addInfluencersUrl: data.links.addInfluencers };
				},
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			result.sort((a: any, b: any) => Number(b.id) - Number(a.id));
			return result;
		});
	};

	const setInfluencersToCampaign = (url: string, influencers: string[]) => {
		return CampaignService.setInfluencersToCampaign(url, influencers).then((res) => {
			return res;
		});
	};

	return {
		getCampaigns,
		setInfluencersToCampaign,
	};
};

export default useCampaignData;
