import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { rem } from 'styles/variables/spacings';

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	cursor: pointer;
	font-weight: 600;
	color: ${colors.AccordionV2.headerColor};
	padding: ${rem[2]}rem ${rem[3]}rem;

	span {
		padding-right: ${rem[0]}rem;
	}

	div {
		margin-left: 0;
	}

	.icon {
		display: flex;
		align-items: center;
		width: auto;
		height: auto;
		transition: all 0.2s ease;
		transform: rotateZ(180deg);
	}
	.accordion__title {
		width: auto;
	}
`;

const ToggleIcon = styled.div`
	margin-left: auto;
`;

const Body = styled.div`
	padding-right: ${rem[3]}rem;
	padding-left: ${rem[3]}rem;
	padding-bottom: ${rem[2]}rem;
	position: relative;
	overflow: hidden;
	max-height: 10000px;
	transition: max-height 1s ease-in-out;
	p:last-child {
		margin-bottom: 0;
	}

	p {
		margin-top: 0;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.AccordionV2.background};
	border-radius: 10px;

	&[aria-expanded='false'] {
		${Body} {
			padding-bottom: 0;
			max-height: 0;
			transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
		}

		${Header} {
			.icon {
				transform: rotateZ(0deg);
			}
		}
		&:hover {
			background-color: ${colors.AccordionV2.hoverBackground};
		}
	}
`;

const Styled = {
	Wrapper,
	Header,
	ToggleIcon,
	Body,
};

export default Styled;
