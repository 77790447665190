import { Store } from 'json-api-models';
import { useEffect } from 'react';

import ProgramCampaignDetail from 'components/AffiliateMarketplace/Components/ProgramCampaignDetail';
import { getGeneratedTrackingLink } from 'components/AffiliateMarketplace/utils';
import { LIST_TRACKING_LINK } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setSelectedAMCampaignGeneratedTrackingLinks } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';

import Styled from './DetailContent.style';

type Props = {
	title: string;
	URL: string;
	background: string;
	guidelines: string;
	compensationValue: number;
	links: { [key: string]: string };
	isNotInfluencer?: boolean;
};

const DetailContent = ({ title, URL, links, compensationValue, background, guidelines, isNotInfluencer }: Props) => {
	const { userCan } = useFeaturePermissions(links);
	const dispatch = useAppDispatch();
	const influencerId = useAppSelector((state) => state.user.influencer?.id);

	useEffect(() => {
		const contentContainer = document.getElementById('content-container');
		if (contentContainer && contentContainer.scrollIntoView) {
			contentContainer.scrollIntoView();
		}

		const models = new Store();

		if (userCan(LIST_TRACKING_LINK) && !isNotInfluencer) {
			getGeneratedTrackingLink(links[LIST_TRACKING_LINK], influencerId).then((res) => {
				models.sync(res.data);
				const sortedTrackingLinks = models.findAll('affiliateTrackingLink').sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
				dispatch(setSelectedAMCampaignGeneratedTrackingLinks(sortedTrackingLinks));
			});
		}
	}, [links]);

	return (
		<Styled.Wrapper>
			<Styled.Header id='content-container'>
				<h5 className='title'>{title}</h5>
			</Styled.Header>

			<ProgramCampaignDetail
				URL={URL}
				links={links}
				influencerCommission={compensationValue}
				background={background}
				guidelines={guidelines}
				isNotInfluencer={isNotInfluencer}
			/>
		</Styled.Wrapper>
	);
};

export default DetailContent;
