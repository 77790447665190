import { InfluencerListItemType } from 'contexts/Discovery/types';
import { formatNumber, formatNumberWithDecimals } from 'shared/helpers/Chart/chart-util';

import Styled from './ProfileCard.style';

const ProfileCard = (props: { data: InfluencerListItemType }) => {
	return (
		<Styled.Wrapper>
			<Styled.InnerWrapper>
				<Styled.Item>
					<Styled.LabelText>Average view</Styled.LabelText>
					<Styled.DataText>{props.data.averageViews ? formatNumber(props.data.averageViews) : '-'}</Styled.DataText>
				</Styled.Item>
				<Styled.Item>
					<Styled.LabelText>Paid post ratio</Styled.LabelText>
					<Styled.DataText>
						{props.data.paidPostsRatio && props.data.paidPostsRatio !== null ? formatNumberWithDecimals(props.data.paidPostsRatio, '%') : '-'}
					</Styled.DataText>
				</Styled.Item>
				<Styled.Item>
					<Styled.LabelText>Organic Engagement</Styled.LabelText>
					<Styled.DataText>
						{props.data.engagementOrganic && props.data.engagementOrganic !== null ? formatNumberWithDecimals(props.data.engagementOrganic, '%') : '-'}
					</Styled.DataText>
				</Styled.Item>
				<Styled.Item>
					<Styled.LabelText>Paid Engagement</Styled.LabelText>
					<Styled.DataText>
						{props.data.engagementPaid && props.data.engagementPaid !== null ? formatNumberWithDecimals(props.data.engagementPaid, '%') : '-'}
					</Styled.DataText>
				</Styled.Item>
				<Styled.Item>
					<Styled.LabelText>Estimated story views</Styled.LabelText>
					<Styled.DataText>
						{props.data.estimatedStoryViews && props.data.estimatedStoryViews !== null ? formatNumber(props.data.estimatedStoryViews) : '-'}
					</Styled.DataText>
				</Styled.Item>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default ProfileCard;
