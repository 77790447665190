import AMCampaignListContainer from 'components/AMCampaignListContainer';
import PageHeader from 'components/PageHeader';

import Styled from './AMCampaignOverview.style';

const AMCampaignOverview = () => {
	return (
		<Styled.Wrapper>
			<PageHeader headline='Affiliate marketplace campaigns' showBreadcrumb={true} showCurrentDate={false} />
			<Styled.Content>
				<AMCampaignListContainer />
			</Styled.Content>
		</Styled.Wrapper>
	);
};

export default AMCampaignOverview;
