import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const CheckboxContainer = styled.div`
	display: flex;
	cursor: pointer;
	width: fit-content;
	align-items: center;

	& > label {
		margin-left: ${guttersWithRem.xxs};
	}
`;

const HiddenCheckbox = styled.input`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const Icon = styled.svg`
	fill: none;
	stroke: ${colors.checkbox.border};
	stroke-width: 2px;
`;

const Checkbox = styled.div<InputHTMLAttributes<HTMLInputElement>>`
	min-width: ${guttersWithRem.m};
	min-height: ${guttersWithRem.m};
	width: ${guttersWithRem.m};
	height: ${guttersWithRem.m};
	background-color: ${(props) => (props.disabled ? colors.gray4 : colors.transparent)};
	border: 1px solid ${colors.checkbox.border};
	border-radius: 0;
	transition: all 150ms;

	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	opacity: ${(props) => (props.disabled ? '0.75' : '1')};

	${Icon} {
		display: ${(props) => (props.checked ? 'block' : 'none')};
	}
`;

const Label = styled.label`
	user-select: none;
`;

const Styled = {
	CheckboxContainer,
	Checkbox,
	HiddenCheckbox,
	Label,
	Icon,
};

export default Styled;
