import classNames from 'classnames';
import { FormikErrors, FormikValues, useField } from 'formik';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import FullscreenModal from 'components/ContentManagement/Components/FullscreenModal';
import MediaStatusMessage from 'components/ContentManagement/Components/MediaStatusMessage';
import TrackingLink from 'components/ContentManagement/Components/TrackingLink';
import { PostMedia, MediaStatus, CIOAType } from 'components/ContentManagement/types';
import Copy from 'components/Copy/Copy';
import DownloadableUrl from 'components/DownloadableUrl';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import InputText from 'components/Form/FormikElements/Text';
import Field from 'components/Forms/Field';
import Icon from 'components/Icon';
import { VIMEO_PLAYER_URL } from 'constants/enviroments';
import { CREATE_DOWNLOAD_URL } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { UserUploadStatus } from 'shared/Types/Assignment';
import { CampaignType } from 'shared/Types/Campaign/Campaign.type';
import { isValidUrl } from 'shared/utils/url';

import Styled from './StoryItem.style';

type StoryItemType = {
	isInfluencer: boolean;
	index: number;
	media: PostMedia;
	canEdit: boolean;
	isEditing: boolean;
	isUploading: boolean;
	isMoveEnabled: boolean;
	canDelete: boolean;
	selectedCampaign: Pick<CampaignType, 'hashtags' | 'mentions'>;
	selectedCIOA: CIOAType;
	onClickDelete: (item: Pick<PostMedia, 'id' | 'status'>) => void;
	onClickMove: (direction: 'up' | 'down', index: number) => void;
	errors: FormikErrors<{
		medias: {
			id: string;
			file: File | undefined;
			sort: number;
			fileUrl: string;
			originalFilename: string;
			links: { [key: string]: string };
			type: string;
			vimeoId: string | undefined;
			url: string;
			text: string;
			trackingUrl: string;
			status: MediaStatus;
			uploadStatus: UserUploadStatus;
		}[];
	}>;
	values: FormikValues;
	setFieldValue: (field: string, value: PostMedia[] | string, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<PostMedia[]>>;
};

const StoryItem = (props: StoryItemType) => {
	const [isFullscreenOpen, setIsFullscreenOpen] = useState<boolean>(false);
	const [_file, setFile] = useState(props.media && props.media);
	const [field, meta, helpers] = useField({ name: `medias.${props.index}.url` });
	const { setValue } = helpers;

	const { userCan } = useFeaturePermissions(props.media.links);

	const CAN_DOWNLOAD_MEDIA = userCan(CREATE_DOWNLOAD_URL);

	// Caption preparation
	const tags =
		props.selectedCIOA.assignment.hashtags?.length > 0
			? props.selectedCIOA.assignment.hashtags.map((hashtag) => `#${hashtag}`)
			: props.selectedCampaign.hashtags;
	const mentions =
		props.selectedCIOA.assignment.mentions?.length > 0
			? props.selectedCIOA.assignment.mentions.map((mention) => `@${mention}`)
			: props.selectedCampaign.mentions;

	const onClickFullScreen = () => {
		setIsFullscreenOpen(true);
	};

	const oldVimeoLink = (url?: string) => {
		if (_.isNil(url)) {
			return '';
		}

		return `${VIMEO_PLAYER_URL}/${url.split('/')[2]}`;
	};

	const renderMediaItem = (media: PostMedia) => {
		if (media.fileUrl) {
			if (media.type === 'video' && (media.vimeoPlaybackUrl || media.vimeoId)) {
				return (
					<Styled.Iframe
						src={media.vimeoPlaybackUrl || oldVimeoLink(media.vimeoId)}
						frameBorder='0'
						allow='autoplay; fullscreen; picture-in-picture'
						allowFullScreen
					/>
				);
			} else {
				return (
					<>
						<Styled.Image imageUrl={media.fileUrl} />
						<Styled.ShowFullSizeButton onClick={onClickFullScreen}>
							<Icon name='zoom' size='18' />
						</Styled.ShowFullSizeButton>
					</>
				);
			}
		}

		//@TEMP_FIX: When video was uploaded to Vimeo successfully but didn't get vimeoPlaybackUrl
		if (media.type === 'video' && !_.isNil(media.vimeoId) && _.isNil(media.vimeoPlaybackUrl)) {
			return <Styled.Iframe src={oldVimeoLink(media.vimeoId)} frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen />;
		}

		if (media.file && media.file.type.includes('video')) {
			return (
				<Styled.Video controls>
					<source src={URL.createObjectURL(media.file)} />
				</Styled.Video>
			);
		}

		if (media.file && media.file.type.includes('image')) {
			return (
				<>
					<Styled.Image imageUrl={URL.createObjectURL(media.file)} />
					<Styled.ShowFullSizeButton onClick={onClickFullScreen}>
						<Icon name='zoom' size='18' />
					</Styled.ShowFullSizeButton>
				</>
			);
		}
	};

	// memo to prevent flashing images with base64 onchange / blur
	const _renderMediaFile = useMemo(() => {
		return renderMediaItem(_file);
	}, [_file]);

	useEffect(() => {
		_file.file !== (props.media && props.media.file) && setFile(props.media);
		props.setFieldValue(`medias.${props.index}.text`, props.media.text);
	}, [props.media]);

	useEffect(() => {
		return () => {
			setIsFullscreenOpen(false);
			setFile(props.media);
		};
	}, []);

	return (
		<>
			<Styled.Wrapper>
				<Styled.Header>
					<Styled.ItemIndex>
						<span>Story frame </span>
						{(props.index + 1).toString().padStart(2, '0')}
					</Styled.ItemIndex>
					{props.isEditing && props.isMoveEnabled && (
						<>
							<Styled.ArrowButtons>
								<Styled.ArrowButton onClick={() => props.onClickMove('up', props.index)}>
									<Icon name='chevron-up' size='24' />
								</Styled.ArrowButton>
								<Styled.ArrowButton onClick={() => props.onClickMove('down', props.index)}>
									<Icon name='chevron-down' size='24' />
								</Styled.ArrowButton>
							</Styled.ArrowButtons>
						</>
					)}
					{CAN_DOWNLOAD_MEDIA || (props.canDelete && props.isEditing) ? (
						<Styled.CustomDropdown icon='options' size='18'>
							<DropdownMenu>
								{CAN_DOWNLOAD_MEDIA && (
									<DropdownItem>
										<DownloadableUrl url={props.media.links.createDownloadUrl} originalFilename={props.media.originalFilename}>
											<Styled.DropdownItemIcon name='download' size='18' />
											Download content
										</DownloadableUrl>
									</DropdownItem>
								)}
								{props.canDelete && props.isEditing && (
									<DropdownItem onClick={() => props.onClickDelete && props.onClickDelete(props.media)}>
										<Styled.DropdownItemIcon name='trash-bin' size='18' />
										Delete
									</DropdownItem>
								)}
							</DropdownMenu>
						</Styled.CustomDropdown>
					) : (
						<div />
					)}
				</Styled.Header>
				<Styled.Form>
					<Styled.MediaWrapper>
						{props.media.uploadStatus === UserUploadStatus.SUCCESS ? <>{_renderMediaFile}</> : <MediaStatusMessage uploadStatus={props.media.uploadStatus} />}
					</Styled.MediaWrapper>

					<Styled.FieldsContainer>
						<h6>Caption</h6>
						<Styled.CopyWrapper>
							<Copy value={props.media.text} description='caption' />
						</Styled.CopyWrapper>
						<Styled.InputGroup>
							<Field label={`Caption frame ${props.index + 1}`} disabled={!props.isEditing && !props.isUploading}>
								<InputText
									placeholder='Write your caption here'
									size='md'
									id={`medias.${props.index}.text`}
									name={`medias.${props.index}.text`}
									rows={5}
									as='textarea'
									disabled={!props.isEditing && !props.isUploading}
									required
								/>
							</Field>
							<Styled.TagWrapper>
								{tags
									.filter((tag) => !props.media.text?.includes(`${tag}`))
									.map((tag, index) => {
										return (
											<Styled.Tag key={index} onClick={() => props.setFieldValue(`medias.${props.index}.text`, `${props.media.text ?? ''} ${tag}`)}>
												{tag}
											</Styled.Tag>
										);
									})}
								{mentions
									.filter((mention) => !props.media.text?.includes(`${mention}`))
									.map((mention, index) => {
										return (
											<Styled.Tag key={index} onClick={() => props.setFieldValue(`medias.${props.index}.text`, `${props.media.text ?? ''} ${mention}`)}>
												{mention}
											</Styled.Tag>
										);
									})}
							</Styled.TagWrapper>
						</Styled.InputGroup>
						<Styled.InputGroup>
							{props.media.trackingUrl ? (
								<Styled.ProductLinkBlockWrapper>
									<div className='title'>Product request</div>
									<div className='help'>Copy the tracking link and add it to your posts.</div>
									<TrackingLink label='Your tracking link' url={props.media.trackingUrl} className='frame__tacking-link' isNotAffiliate={true} />

									<Styled.OriginalLinkWrapper>
										<div className='original-link'>
											<span>{field.value}</span>
										</div>
										<div className={classNames('link-button icon-wrapper')}>
											<a href={field.value} target='_blank' rel='noreferrer'>
												<Icon name='external-link' size='16' />
											</a>
										</div>
									</Styled.OriginalLinkWrapper>
								</Styled.ProductLinkBlockWrapper>
							) : (
								<Styled.ProductLinkBlockWrapper>
									<div className='title'>Product request</div>
									<div className='help'>
										{props.isInfluencer
											? 'If you wish to promote a specific product or page for this assignment, add the URL.'
											: 'The influencer would like to promote this specific URL.'}
									</div>
									<div className='input-wrapper'>
										<InputText
											id={`medias.${props.index}.url`}
											data-testid={`medias.${props.index}.url`}
											name={`medias.${props.index}.url`}
											disabled={!props.isEditing && !props.isUploading}
											placeholder='Paste URL here'
											contentBefore={<Icon name='url-link' size='16' />}
											contentAfter={
												props.media.url && props.isEditing ? (
													<Styled.ActionWrapper
														onClick={() => {
															setValue('');
														}}
													>
														<Icon name='cancel-circle' size='16' />
													</Styled.ActionWrapper>
												) : !props.isInfluencer && props.media.url ? (
													<Styled.ActionWrapper className='url-link-wrapper'>
														<a href={props.media.url} target='_blank' rel='noreferrer'>
															<Icon name='external-link' size='16' />
														</a>
													</Styled.ActionWrapper>
												) : null
											}
										/>
										{isValidUrl(field.value) && _.isNil(meta.error) && (
											<div>
												<Icon name='checkmark' size='16' />
											</div>
										)}
									</div>
								</Styled.ProductLinkBlockWrapper>
							)}
						</Styled.InputGroup>
					</Styled.FieldsContainer>
				</Styled.Form>
			</Styled.Wrapper>
			{props.media.status !== MediaStatus.PENDING && (
				<FullscreenModal file={props.media.file || props.media.fileUrl || ''} isOpen={isFullscreenOpen} handleClose={() => setIsFullscreenOpen(false)} />
			)}
		</>
	);
};

export default StoryItem;
