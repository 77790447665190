import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Icon from 'components/Icon';

import Styled from './Accordion.style';
import { IAccordionProps } from './types';

const Accordion = (props: IAccordionProps) => {
	const { title, children, open, border, className, iconSize, hideChevron, icon, hoverBackgroundColor, hoverIconColor, plusCloseIcon } = props;
	const [isOpen, setIsOpen] = useState<boolean>(open);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		isOpen ? props.onOpen && props.onOpen() : props.onClose && props.onClose();
	}, [isOpen]);

	return (
		<Styled.Wrapper
			className={classNames(className, { collapsed: !isOpen })}
			aria-expanded={isOpen}
			border={border}
			data-testid='accordion'
			onClick={() => props.onClick && props.onClick()}
		>
			<Styled.Header
				className='accordion__header'
				data-testid='accordion-header'
				onClick={() => setIsOpen(!isOpen)}
				iconPosition={props.toggleIconPosition || 'right'}
				backgroundColor={hoverBackgroundColor}
				hoverIconColor={hoverIconColor}
			>
				<span className={classNames(className, 'accordion__title')}>{title}</span>
				{!hideChevron &&
					(hoverBackgroundColor ? (
						<Styled.IconWrapper>
							<Icon name={icon ?? 'chevron-down'} size={iconSize ?? '16'} />
						</Styled.IconWrapper>
					) : plusCloseIcon ? (
						<Styled.ToggleCloseIcon id='close'>
							<Icon name='cancel-circle' />
						</Styled.ToggleCloseIcon>
					) : (
						<Styled.ToggleIcon id='chevron'>
							<Icon name={icon ?? 'chevron-down'} size={iconSize ?? '20'} />
						</Styled.ToggleIcon>
					))}
			</Styled.Header>
			<Styled.Body data-testid='accordion-body' className='accordion__body'>
				{children}
			</Styled.Body>
		</Styled.Wrapper>
	);
};

Accordion.defaultProps = {
	title: 'Accordion title',
	open: false,
};

export default Accordion;
