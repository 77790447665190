import styled, { DefaultThemeV2 } from 'styled-components';

import { GhostButton } from 'components/Button';

type P = { theme: DefaultThemeV2 };

const Wrapper = styled.div`
	margin-bottom: 16px;
	display: flex;
	row-gap: 8px;
	flex-direction: column;
	padding-bottom: 2rem;
	border-bottom: 1px solid ${({ theme }) => theme.borders.secondary};
	p {
		font-weight: ${(props: P) => props.theme.fontWeights.bold};
		font-size: ${(props: P) => props.theme.fontSizes.smallInput};
	}
`;

const InnerWrapper = styled.div`
	display: flex;
	column-gap: 16px;
	row-gap: 16px;
	flex-direction: row;
	flex-wrap: wrap;
`;

const PlatformItem = styled(GhostButton)`
	padding: 0.5rem 1rem;
	column-gap: 10px;
	font-size: ${(props: P) => props.theme.fontSizes.smallInput};
	font-weight: ${(props: { theme: DefaultThemeV2 }) => props.theme.fontWeights.bold};
	border: none;
	border-radius: ${(props: P) => props.theme.radius.default};
	background-color: ${(props: P) => props.theme.colors.inputs};

	&:hover:not(:disabled, .disabled) {
		.icon {
			rect {
				stroke: none;
			}
		}
	}
`;

const Styled = {
	Wrapper,
	PlatformItem,
	InnerWrapper,
};

export default Styled;
