import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Icon = styled.svg`
	fill: none;
	stroke: ${colors.checkbox.checkMark};
	stroke-width: 2px;
	background-color: ${colors.checkbox.selectedBackground};
`;

const Checkbox = styled.div<InputHTMLAttributes<HTMLInputElement>>`
	min-width: ${guttersWithRem.m};
	min-height: ${guttersWithRem.m};
	width: 1.125rem;
	height: 1.125rem;
	background-color: ${(props) => (props.disabled ? colors.gray4 : colors.transparent)};
	border: 1px solid ${colors.checkbox.border};
	border-radius: 4px;
	transition: all 150ms;
	margin-right: ${spacing[1].px};
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	opacity: ${(props) => (props.disabled ? '0.75' : '1')};

	${Icon} {
		display: ${(props) => (props.checked ? 'block' : 'none')};
	}

	&.checked {
		border-color: ${colors.checkbox.selectedBackground};
	}
`;

const CheckboxContainer = styled.div`
	display: flex;
	cursor: pointer;
	width: fit-content;
	align-items: center;

	& > label {
		margin-left: ${guttersWithRem.xxs};
		cursor: pointer;

		&.isFirstUppercase {
			text-transform: capitalize;
		}
	}
	&.textFirst {
		width: 100%;
		justify-content: space-between;
		flex-direction: row-reverse;
		${Checkbox} {
			margin-right: 0;
		}
	}
`;

const HiddenCheckbox = styled.input`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const Label = styled.label`
	user-select: none;
	font-family: ${typography.BaseFontFamiliy} !important;
	font-size: 0.875rem;
`;

const Styled = {
	CheckboxContainer,
	Checkbox,
	HiddenCheckbox,
	Label,
	Icon,
};

export default Styled;
