import moment from 'moment';
import { useState, useEffect } from 'react';

import { DiscoveryContainer } from 'components/Discovery';
import { PageNotFound } from 'components/ErrorPages';
import LoadingSpinner from 'components/LoadingSpinner';
import { DiscoveryContextProvider } from 'contexts/Discovery/DiscoveryContext';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import { StoredDiscoveryToken } from 'services/Authentication/Discovery-api/types';
import { StatusCode } from 'services/Response.types';

import Styled from './DiscoveryPage.style';

const DiscoveryPage = () => {
	const [loading, setLoading] = useState(true);
	const { isGranted } = usePermissions();

	useEffect(() => {
		setLoading(true);
		const discoveryToken: null | StoredDiscoveryToken = DiscoveryAuthService.getDiscoveryToken();
		if (discoveryToken) {
			const expiresAt = moment(new Date(discoveryToken.expiresAt.toString()));
			const now = moment();
			if (expiresAt < now) {
				DiscoveryAuthService.requestDiscoveryToken().then((res) => {
					if (res === StatusCode.OK) {
						setLoading(false);
					}
				});
			} else {
				setLoading(false);
			}
		} else {
			DiscoveryAuthService.requestDiscoveryToken().then((res) => {
				if (res === StatusCode.OK) {
					setLoading(false);
				}
			});
		}
	}, []);

	if (!isGranted(ROLE_PROJECT_MANAGER)) {
		return <PageNotFound />;
	}

	return (
		<DiscoveryContextProvider>
			<Styled.Wrapper>{loading ? <LoadingSpinner size={'lg'} position={'center'} /> : <DiscoveryContainer />}</Styled.Wrapper>
		</DiscoveryContextProvider>
	);
};

export default DiscoveryPage;
