import { AxiosError } from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LoginAsButton } from 'components';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { SideDrawer } from 'components/SideDrawer';
import Table, { Column } from 'components/Table/V2/Table';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';
import UserService from 'services/User/User.service';
import { ClientUser } from 'shared/Types/User';
import Styled from 'views/admin/Components/Table.style';
import { getBrand } from 'views/admin/hooks';

import UserDetails from './UserDetails';

/**
 * @returns {JSX.Element}
 */
const UserTable = (props: { data: ClientUser[]; search: () => void }): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<ClientUser>();

	const navigate = useNavigate();
	const { userId } = useParams();

	const disableUser = () => {
		setIsLoading(true);
		selectedUser?.links?.disable &&
			UserService.disableUser(selectedUser?.links?.disable)
				.then((res) => {
					if (isSuccessfulResponse(res.status)) {
						setIsLoading(false);
						setIsModalOpen(false);
						toast.success('User disabled');
					}
				})
				.catch((error: AxiosError) => {
					console.error(error);
					toast.error(getErrorMessageOnPost('disabling the user'));
				});
	};

	const columns: Column<ClientUser>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Cell: ({ row }) => (
					<Styled.Title className={classNames({ disabled: row.original.disabledAt })}>
						{row.original.name}
						{row.original.disabledAt && <div>Disabled at {moment(row.original.disabledAt).format('YYYY-MM-DD')}</div>}
					</Styled.Title>
				),
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Brand',
				accessor: 'brandPrivileges',
				disableSortBy: true,
				Cell: ({ row }) => <Styled.Td minWidth='170px'>{getBrand(row.original as unknown as ClientUser)}</Styled.Td>,
			},
			{
				Header: '',
				accessor: 'loginas',
				width: 50,
				disableSortBy: true,
				Cell: ({ row }) => <LoginAsButton uuid={row.original.uuid} />,
			},
			{
				Header: '',
				accessor: 'options',
				width: 50,
				disableSortBy: true,
				Cell: ({ row }) =>
					!row.original.disabledAt && (
						<Dropdown icon='options' size='16'>
							<DropdownMenu>
								<DropdownItem
									onClick={() => {
										setIsModalOpen(true), setSelectedUser(row.original);
									}}
								>
									<Styled.Text>Disable User</Styled.Text>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					),
			},
		],
		[],
	);

	const handleSelectedRow = (values: ClientUser) => {
		setSelectedUser(values);
		navigate(`/admin/users/${values.id}`);
		setIsSideDrawerOpen(true);
	};

	useEffect(() => {
		selectedUser ? setIsSideDrawerOpen(true) : setIsSideDrawerOpen(false);
	}, [selectedUser]);

	return (
		<>
			<Table
				className='scroll-y admin-table'
				columns={columns}
				data={props.data}
				enableSorting
				getSelectedRow={(selectedRow) => handleSelectedRow(selectedRow)}
			/>
			<ConfirmModal
				isModalOpen={isModalOpen}
				toggleModal={() => {
					setIsModalOpen(!isModalOpen), setSelectedUser(undefined);
				}}
				title={'Disable user'}
				isFetching={isLoading}
				action={disableUser}
				buttonText={'Disable'}
				confirmButtonAsDanger
			>
				<p>Are you sure that you want to disable {selectedUser?.name}?</p>
			</ConfirmModal>
			{selectedUser && userId && (
				<SideDrawer
					sidebarIsOpen={isSideDrawerOpen}
					onClose={() => {
						setIsSideDrawerOpen(false), navigate('/admin/users');
					}}
					dataTestId={''}
					title={selectedUser?.name}
					isExpandable
					saveButtonText='Save'
					canOnlyCloseModal
				>
					<Styled.UserDetailsWrapper>
						<UserDetails item={selectedUser!} />
					</Styled.UserDetailsWrapper>
				</SideDrawer>
			)}
		</>
	);
};
export default UserTable;
