import { useEffect } from 'react';

const useArrowKeyNavigation = (onArrowKey: (event: KeyboardEvent) => void) => {
	useEffect(() => {
		const handleArrowKey = (event: KeyboardEvent) => {
			onArrowKey(event);
		};

		document.addEventListener('keyup', handleArrowKey);

		return () => {
			document.removeEventListener('keyup', handleArrowKey);
		};
	}, [onArrowKey]);
};

export default useArrowKeyNavigation;
