import { ByInfluencerContainer, ByCountryContainer, ByAssignmentType } from 'components/DataLibrary/Graphs/GraphComponents';
import ByCampaignContainer from 'components/DataLibrary/Graphs/GraphComponents/ByCampaign/ByCampaignContainer';
import { MEDIA_OBJECTS } from 'constants/data-library';
/**
 * @returns object
 */
const MediaObjectGraphs = (): object => {
	const graphItems = {
		category: 'Activations',
		items: [
			{
				title: MEDIA_OBJECTS.COUNTRY_TITLE,
				icon: MEDIA_OBJECTS.CAMPAIGN_BAR_ICON,
				id: MEDIA_OBJECTS.COUNTRY_BAR,
				component: <ByCountryContainer displayBarTotal title={MEDIA_OBJECTS.COUNTRY_TITLE} chartType={MEDIA_OBJECTS.COUNTRY_BAR} />,
			},
			{
				title: MEDIA_OBJECTS.COUNTRY_TITLE,
				icon: MEDIA_OBJECTS.COUNTRY_CIRCLE_ICON,
				id: MEDIA_OBJECTS.COUNTRY_CIRCLE,
				component: <ByCountryContainer displayCircle title={MEDIA_OBJECTS.COUNTRY_TITLE} chartType={MEDIA_OBJECTS.COUNTRY_CIRCLE} />,
			},
			{
				title: MEDIA_OBJECTS.CAMPAIGN_TITLE,
				icon: MEDIA_OBJECTS.CAMPAIGN_BAR_ICON,
				id: MEDIA_OBJECTS.CAMPAIGN_BAR,
				component: <ByCampaignContainer displayBar title={MEDIA_OBJECTS.CAMPAIGN_TITLE} chartType={MEDIA_OBJECTS.CAMPAIGN_BAR} />,
			},
			{
				title: MEDIA_OBJECTS.CAMPAIGN_TITLE,
				icon: MEDIA_OBJECTS.CAMPAIGN_CIRCLE_ICON,
				id: MEDIA_OBJECTS.CAMPAIGN_CIRCLE,
				component: <ByCampaignContainer displayCircle title={MEDIA_OBJECTS.CAMPAIGN_TITLE} chartType={MEDIA_OBJECTS.CAMPAIGN_CIRCLE} />,
			},
			{
				title: MEDIA_OBJECTS.NETWORK_TITLE,
				icon: MEDIA_OBJECTS.NETWORK_BAR_ICON,
				id: MEDIA_OBJECTS.NETWORK_BAR,
				component: <ByAssignmentType displayBar title={MEDIA_OBJECTS.NETWORK_TITLE} chartType={MEDIA_OBJECTS.NETWORK_BAR} />,
			},
			{
				title: MEDIA_OBJECTS.NETWORK_TITLE,
				icon: MEDIA_OBJECTS.NETWORK_CIRCLE_ICON,
				id: MEDIA_OBJECTS.NETWORK_CIRCLE,
				component: <ByAssignmentType displayCircle title={MEDIA_OBJECTS.NETWORK_TITLE} chartType={MEDIA_OBJECTS.NETWORK_CIRCLE} />,
			},
			{
				title: MEDIA_OBJECTS.BY_INFLUENCER_TITLE,
				icon: MEDIA_OBJECTS.BY_INFLUENCER_ICON,
				id: MEDIA_OBJECTS.BY_INFLUENCER,
				component: (
					<ByInfluencerContainer title={MEDIA_OBJECTS.BY_INFLUENCER_TITLE} isTopThree={true} chartType={MEDIA_OBJECTS.BY_INFLUENCER} valueType='mediaObjects' />
				),
			},
		],
	};
	return graphItems;
};
export default MediaObjectGraphs;
