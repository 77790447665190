import { isNil, isEmpty } from 'lodash';
import * as Yup from 'yup';

import { createClient } from 'shared/ApiClient/ApiClient';

import { OgMetaResponse } from './types';

export const CREATION_FORM_VALIDATION = Yup.object().shape({
	name: Yup.string().required('Title is a required field'),
	adtractionId: Yup.string().required('Program Id is a required field'),
	storeLink: Yup.string().url('Please add a valid store link.').required('Store Link is a required field'),
	ogMetaImage: Yup.string().nullable(),
	coverImage: Yup.string().when('ogMetaImage', {
		is: (ogMetaImage?: string) => isNil(ogMetaImage) || isEmpty(ogMetaImage),
		then: (schema) => schema.required('Cover image is a required field'),
		otherwise: (schema) => schema.nullable(),
	}),
	storeCommissionPercentage: Yup.number().min(0.01, 'Total commission should be bigger than 0').max(100).required('Total commission is a required field'),
	influencerCommissionPercentage: Yup.number()
		.min(0.01, 'Influencer commission should be bigger than 0')
		.test('max-validation', "Influencer commission can't exceed Total commission", (value, context) => {
			const { storeCommissionPercentage } = context.parent;
			if (isNil(storeCommissionPercentage)) return true;
			if (storeCommissionPercentage >= (value || 0)) return true;
			return false;
		})
		.required('Influencer commission is a required field'),
	background: Yup.string()
		.matches(/^(?!<p><br><\/p>$).*/, 'Background is a required field')
		.required('Background is a required field'),
	guidelines: Yup.string()
		.matches(/^(?!<p><br><\/p>$).*/, 'Guideline is a required field')
		.required('Guideline is a required field'),
	sort: Yup.number().min(0, 'Sort should be bigger than 0'),
});

export const fetchOgMeta = async (url: string) => {
	const client = createClient();
	try {
		const { data } = await client.get<OgMetaResponse>('/tools/og-metadata', { params: { url } });
		const { image } = data.data.attributes;
		return image;
	} catch (e) {
		console.error('Meta fetching failed: %O', e);
		return undefined;
	}
};

export const calculateCollabsCut = (storeCommission?: number, influencerCommission?: number) => {
	const store = storeCommission ?? 0;
	const influencer = influencerCommission ?? 0;
	return (store - influencer).toFixed(2);
};
