import { useState } from 'react';

import { TrackingLinkType } from 'components/AffiliateMarketplace/types';

import GeneratedTrackingLinkItem from './GeneratedTrackingLinkItem';
import Styled from './GeneratedTrackingLinks.style';

type Props = {
	generatedTrackingLinks: TrackingLinkType[];
};

const GeneratedTrackingLinks = ({ generatedTrackingLinks }: Props) => {
	const [isShowAll, setIsShowAll] = useState<boolean>(false);

	return (
		<Styled.Wrapper items={generatedTrackingLinks.filter((_, index) => (isShowAll ? true : index < 5)).length}>
			{generatedTrackingLinks
				.filter((_, index) => (isShowAll ? true : index < 5))
				.map(({ id, trackingUrl, originalUrl }) => {
					return <GeneratedTrackingLinkItem key={id} trackingUrl={trackingUrl} originalUrl={originalUrl} />;
				})}

			{generatedTrackingLinks.length > 5 && (
				<Styled.ShowAllButton
					type='button'
					onClick={() => {
						setIsShowAll((prev) => !prev);
					}}
				>
					See {isShowAll ? 'less' : 'all'}
				</Styled.ShowAllButton>
			)}
		</Styled.Wrapper>
	);
};

export default GeneratedTrackingLinks;
