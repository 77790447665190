import styled from 'styled-components';

import { Table, TableBody, TableHeader, Td, Th, Tr } from 'styles/table';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';

const Wrapper = styled.div``;

const TodoTable = styled.table`
	${Table};
	table-layout: fixed;
`;

const THead = styled.thead`
	${TableHeader};
`;

const TBody = styled.tbody`
	${TableBody};
`;

const Row = styled.tr`
	${Tr};
	background-color: ${colors.mainWhite};
	border: none;

	th {
		${Th};

		&.wide {
			width: 35%;
		}

		@media (max-width: ${breakpoints.sm}) {
			padding-right: ${guttersWithRem.xxs};
			padding-left: ${guttersWithRem.xxs};

			&.wide {
				width: 50%;
			}
			&.campaigns {
				display: none;
			}
			&.deadline {
				display: none;
			}
			&.status {
			}
			&.view {
				display: none;
			}
		}
	}

	td {
		${Td};
	}
`;

const Styled = {
	Wrapper,
	TodoTable,
	THead,
	TBody,
	Row,
};

export default Styled;
