import { HTMLAttributes, InputHTMLAttributes } from 'react';

import Styled from './Checkbox.style';

const Checkbox = (props: InputHTMLAttributes<HTMLInputElement> & HTMLAttributes<HTMLDivElement> & { label?: string | JSX.Element }) => {
	return (
		<Styled.CheckboxContainer className={props.className} onClick={props.disabled ? () => {} : props.onChange}>
			<Styled.HiddenCheckbox type='checkbox' name={props.name} id={props.id} checked={props.checked} {...props} />
			<Styled.Checkbox className='custom_checkbox' checked={props.checked} disabled={props.disabled}>
				<Styled.Icon viewBox='0 0 24 24'>
					<polyline points='20 6 9 17 4 12' />
				</Styled.Icon>
			</Styled.Checkbox>
			{props.label ? <Styled.Label id={props.id}>{props.label}</Styled.Label> : null}
		</Styled.CheckboxContainer>
	);
};

export default Checkbox;
