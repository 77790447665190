import { JsonApiDocument } from 'json-api-models';
import { useState, useEffect } from 'react';

import useCampaignData from 'hooks/Chart/useCampaignData';
import useListData from 'hooks/Chart/useListData';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';

import CampaignListMenuItems from './CampaignListMenuItems';
import DefaultDropdownMenuItems from './DefaultDropdownMenuItems';
import Styled from './DiscoveryDropdown.style';
import FolderListMenuItems from './FolderListMenuItems';

const DROPDOWN_OPTION = {
	NULL: '',
	OPEN_SUB_FOLDER: 'folder',
	ADD_TO_LIST: 'list',
	ADD_TO_CAMPAIGN: 'campaign',
};

const DiscoveryDropdown = (props: { selectedItems: string[]; onResetSelection?: () => void; size?: '16' | '24'; isList?: boolean; helpText?: string }) => {
	const [isInstanceOpen, setIsInstanceOpen] = useState(false);
	const [isLoadingLists, setIsLoadingLists] = useState(false);
	const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false);
	const [selectedOption, setSelectedOption] = useState(DROPDOWN_OPTION.NULL);
	const [campaigns, setCampaigns] = useState<Array<{ id: string; addInfluencersUrl: string; itemName: string }>>([]);
	const [foldersAndLists, setFoldersAndLists] = useState<{ folders: JsonApiDocument; lists: JsonApiDocument } | null>(null);

	const { getCampaigns, setInfluencersToCampaign } = useCampaignData();
	const { getAllFoldersAndLists, addInfluencersToList } = useListData();

	const getAllCampaignList = () => {
		setIsLoadingCampaigns(true);
		getCampaigns()
			.then((res) => {
				setCampaigns(res);
				setSelectedOption(DROPDOWN_OPTION.ADD_TO_CAMPAIGN);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoadingCampaigns(false);
			});
	};

	const handleSuccessToast = (influencers: string[], targetName: string | undefined, itemId: string) => {
		const number = influencers.length <= 3 ? ['One', 'Two', 'Three'][influencers.length - 1] || `${influencers.length}` : `${influencers.length}`;
		const url = selectedOption === DROPDOWN_OPTION.ADD_TO_LIST ? `/influencer-management/lists/${itemId}` : `/campaigns/${itemId}`;
		toast.successWithLink(`${number} influencer${influencers.length > 1 ? 's' : ''} added to`, `${targetName}`, `${url}`);
	};

	const saveInfluencersHandler = (influencers: string[]) => {
		return (addInfluencersUrl: string, itemId: string, targetName?: string) => {
			selectedOption === DROPDOWN_OPTION.ADD_TO_CAMPAIGN
				? setInfluencersToCampaign(addInfluencersUrl, influencers).then((res) => {
						if (isSuccessfulResponse(res)) {
							handleSuccessToast(influencers, targetName, itemId);
							if (props.onResetSelection) {
								props.onResetSelection();
							}
						} else {
							toast.error(res !== 0 ? res.toString() : getSomethingWentWrongMessage());
						}
					})
				: addInfluencersToList(itemId, influencers).then((res) => {
						if (isSuccessfulResponse(res)) {
							handleSuccessToast(influencers, targetName, itemId);
							if (props.onResetSelection) {
								props.onResetSelection();
							}
						} else {
							toast.error(getSomethingWentWrongMessage());
						}
					});
		};
	};

	const getAllInfluencerList = () => {
		setIsLoadingLists(true);
		getAllFoldersAndLists()
			.then((res) => {
				if (res) {
					// @ts-ignore
					setFoldersAndLists(res as { folders: JsonApiDocument; lists: JsonApiDocument });
					setSelectedOption(DROPDOWN_OPTION.ADD_TO_LIST);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoadingLists(false);
			});
	};

	const close = () => setIsInstanceOpen(false);

	useEffect(() => {
		if (!isInstanceOpen) {
			setSelectedOption(DROPDOWN_OPTION.NULL);
		}
	}, [isInstanceOpen]);

	if (props.isList) {
		return (
			<>
				{selectedOption === DROPDOWN_OPTION.NULL && (
					<DefaultDropdownMenuItems
						onAddCampaign={getAllCampaignList}
						onAddList={getAllInfluencerList}
						onClose={close}
						isLoadingLists={isLoadingLists}
						isLoadingCampaigns={isLoadingCampaigns}
					></DefaultDropdownMenuItems>
				)}
				{selectedOption === DROPDOWN_OPTION.ADD_TO_CAMPAIGN && (
					<>
						<CampaignListMenuItems
							data-testid='campaign-menu'
							items={campaigns}
							isCancellable
							onCancel={() => {
								setSelectedOption(DROPDOWN_OPTION.NULL);
							}}
							onClick={(addInfluencersUrl: string, _campaignId: string, campaignName: string) =>
								saveInfluencersHandler(props.selectedItems)(addInfluencersUrl, '', campaignName)
							}
							onClose={close}
							isSaving={false}
						/>
					</>
				)}
				{selectedOption === DROPDOWN_OPTION.ADD_TO_LIST && (
					<FolderListMenuItems
						items={foldersAndLists}
						isCancellable
						onCancel={() => {
							setSelectedOption(DROPDOWN_OPTION.NULL);
						}}
						onClick={(_url: string, listId: string, listName: string) => saveInfluencersHandler(props.selectedItems)('', listId, listName)}
						onClose={close}
						updateFoldersAndLists={getAllInfluencerList}
						isSaving={false}
					/>
				)}
			</>
		);
	}

	return (
		<Styled.CustomDropdown
			open={isInstanceOpen}
			setOpen={(value) => setIsInstanceOpen(value)}
			icon='add-new-folder'
			size='20'
			position={props.size ? 'left' : 'right'}
			className={props.size ? 'lg' : ''}
			tooltip={props.helpText}
		>
			<div onClick={(e) => e.stopPropagation()}>
				{selectedOption === DROPDOWN_OPTION.NULL && (
					<DefaultDropdownMenuItems
						onAddCampaign={getAllCampaignList}
						onAddList={getAllInfluencerList}
						isLoadingLists={isLoadingLists}
						isLoadingCampaigns={isLoadingCampaigns}
						onClose={close}
					/>
				)}
				{selectedOption === DROPDOWN_OPTION.ADD_TO_CAMPAIGN && (
					<>
						<CampaignListMenuItems
							items={campaigns}
							isCancellable={true}
							onCancel={() => {
								setSelectedOption(DROPDOWN_OPTION.NULL);
							}}
							onClick={(url: string, campaignId: string, campaignName: string) => {
								saveInfluencersHandler(props.selectedItems)(url, campaignId, campaignName);
							}}
							onClose={close}
							isSaving={false}
						/>
					</>
				)}
				{selectedOption === DROPDOWN_OPTION.ADD_TO_LIST && (
					<FolderListMenuItems
						items={foldersAndLists}
						isCancellable={true}
						onCancel={() => {
							setSelectedOption(DROPDOWN_OPTION.NULL);
						}}
						onClick={(url: string, listId: string, listName: string) => {
							saveInfluencersHandler(props.selectedItems)(url, listId, listName);
						}}
						onClose={close}
						updateFoldersAndLists={getAllInfluencerList}
						isSaving={false}
					/>
				)}
			</div>
		</Styled.CustomDropdown>
	);
};

export default DiscoveryDropdown;
