import styled from 'styled-components';

import { TrackingLinkStyle } from 'components/AffiliateMarketplace/shared.style';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	${TrackingLinkStyle};

	margin-bottom: ${spacing[1].px};
	position: relative;

	& > div {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div {
			display: flex;
			align-items: center;
			column-gap: ${spacing[1].px};
		}
	}
`;

const OriginalLink = styled.a`
	font-size: 0.625rem;
	color: ${colors.CTALinks.color} !important;
	border-bottom: none !important;
	white-space: nowrap;
	display: inline-block;
	overflow: hidden;
	width: 100%;
	text-overflow: ellipsis;
	transition: color 0.2s ease-in-out 0s;

	&:hover {
		color: ${colors.black} !important;
	}
`;

const Tooltip = styled.div`
	position: absolute;
	background-color: ${colors.dropdown.tooltip.background};
	box-shadow: 0 3px 6px ${colors.dropdown.tooltip.boxShadowColor};
	border-radius: 4px;
	opacity: 0;
	transition: opacity 0.2s linear;

	white-space: nowrap;

	top: -50%;
	right: 20%;
	left: 20%;

	&.visible {
		opacity: 1;
	}
`;

const TooltipText = styled.span`
	padding-top: ${spacing[0].rem};
	padding-bottom: ${spacing[0].rem};

	color: ${colors.dropdown.tooltip.color};
	font-size: 0.875rem;
	width: 100%;
	text-align: center;

	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 119%;
		left: 50%;

		border-width: 6px;
		border-style: solid;
		border-color: ${colors.dropdown.tooltip.background} transparent transparent transparent;
	}
`;

const Styled = {
	Wrapper,
	OriginalLink,
	Tooltip,
	TooltipText,
};

export default Styled;
