import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	padding-left: ${guttersWithRem.m};
	padding-right: ${guttersWithRem.m};
	max-height: calc(100vh + -225px);
	overflow-y: auto;
	${scrollbarY};
`;

const FirstLineWrapper = styled.div`
	width: 100%;
	text-align: center;
	color: ${colors.integratedInbox.chatSection.date.color};
	margin-bottom: 40px;
	line-height: 1.5;
`;

const DateWrapper = styled.div`
	width: 100%;
	text-align: center;
	color: ${colors.integratedInbox.chatSection.date.color};
	font-size: 0.75rem;
	margin-bottom: ${guttersWithRem.xs};
`;

const Styled = {
	Wrapper,
	FirstLineWrapper,
	DateWrapper,
};

export default Styled;
