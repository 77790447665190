import classNames from 'classnames';
import { Model, Store } from 'json-api-models';
import moment from 'moment';
import { useState, useEffect, useRef, KeyboardEvent } from 'react';

import IconButton from 'components/Discovery/Components/IconButton';
import { useListItemData } from 'components/Discovery/Components/InfluencerListItem/hooks';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import InfluencerAvatar from 'components/InfluencerAvatar';
import Modal from 'components/Modals/Modal';
import Pill from 'components/Pill';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';
import { isMobile } from 'shared/utils/navigator';
import colors from 'styles/theme/colors';

import Styled from './InfluencerListItemComments.style';

/**
 */
const InfluencerListItemComments = (props: { listItem: Model }) => {
	const { listItem } = props;
	const { commentOnListItem, deleteListItemComment } = useListItemData();

	const user = useAppSelector((state) => state.user);
	const commentsEndRef = useRef<HTMLDivElement>(null);
	const textAreaRef = useRef<HTMLDivElement>(null);

	const [isOpen, setIsOpen] = useState(false);
	const [userHasWrittenComments, setUserHasWrittenComments] = useState(false);
	const [commentsToDisplay, setCommentsToDisplay] = useState<Model[]>([]);
	const [newComment, setNewComment] = useState('');

	useEffect(() => {
		setCommentsToDisplay(listItem.comments);
		return () => {
			setCommentsToDisplay([]);
		};
	}, [listItem]);

	const sendComment = () => {
		const models = new Store();
		if (newComment?.length < 4) {
			return toast.error('Comment is too short');
		}
		if (newComment?.length > 1500) {
			return toast.error('Comment is too long');
		}
		commentOnListItem(listItem.links.addComment, newComment).then((res) => {
			if (res && res.status === 200) {
				toast.success('Comment saved');
				models.sync(res.data);
				const comment = models.find('listItemComment', res.data.data.id);
				setCommentsToDisplay([comment, ...commentsToDisplay]);
				setUserHasWrittenComments(true);
				setNewComment('');
				if (textAreaRef && textAreaRef.current) {
					textAreaRef.current.textContent = '';
				}
			}
		});
	};

	const deleteThisComment = (comment: Model) => {
		const commentsAfterDelete = commentsToDisplay.filter((remove: Model) => remove.id !== comment.id);
		setCommentsToDisplay(commentsAfterDelete);
		deleteListItemComment(comment.links.delete).then((res) => {
			if (res && isSuccessfulResponse(res.status ?? 0)) {
				toast.success('Comment removed');
			}
		});
	};

	useEffect(() => {
		if (listItem.comments) {
			setCommentsToDisplay(listItem.comments);
			const userComments = listItem.comments.filter((comment: Model) => comment.createdBy.id === user.id);
			if (userComments.length > 0) {
				setUserHasWrittenComments(true);
			}
		}
	}, []);

	useEffect(() => {
		const userComments = commentsToDisplay?.filter((comment: Model) => comment.createdBy?.id === user.id || !comment.createdBy);
		setUserHasWrittenComments(userComments?.length > 0 ? true : false);
	}, [commentsToDisplay]);

	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!/\S/.test(newComment) && e.key === 'Enter') {
			e.preventDefault();
			return false;
		}

		if (!isMobile() && e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			sendComment();
			return false;
		}

		return true;
	};

	return (
		<>
			<Styled.CommentsIconWrapper>
				<IconButton
					className='influencer-list-item'
					iconName={userHasWrittenComments ? 'comment-filled' : 'comment'}
					iconSize='20'
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(!isOpen);
					}}
					helpText='Comment'
				/>
				{commentsToDisplay?.length > 0 ? <Pill className='icons' title={`${commentsToDisplay?.length}`} /> : null}
			</Styled.CommentsIconWrapper>
			<Modal open={isOpen} size='xs'>
				<Modal.Header>
					<Styled.ModalHeader>
						<Styled.ModalHeaderTitle>
							<Styled.AvatarWrapper>
								<InfluencerAvatar userName={listItem.attributes.username} displayNetwork={false} profileImageUrl={listItem.links.profilePictureUrl ?? ''} />
								<h5>{listItem.attributes.username}</h5>
								<Pill className='icons' title={`${commentsToDisplay?.length}`} />
							</Styled.AvatarWrapper>
						</Styled.ModalHeaderTitle>
						<Styled.DropdownClose onClick={() => setIsOpen(false)}>
							<Icon name={'cross'} size='16' />
						</Styled.DropdownClose>
					</Styled.ModalHeader>
				</Modal.Header>
				<Modal.Body>
					<Styled.CommentsContainer data-testid='cm-comment-box'>
						<Styled.CommentsList className='cm-comments'>
							{commentsToDisplay.map((comment: Model, index: number) => (
								<Styled.MessageRow
									role='row'
									key={comment.id}
									data-testid={`fc-comment-${index}`}
									className={classNames({ latest: index === commentsToDisplay.length - 1 })}
								>
									<Styled.ContentWrapper>
										<Styled.UserAvatar role='presentation'>
											<Styled.CommentAvatar
												name={comment.createdBy?.attributes?.initials ?? user.initials}
												backgroundColor={
													comment.createdBy?.attributes?.uuid === user.uuid ? colors.paleGreenTint : comment.createdBy ? colors.ash : colors.paleGreenTint
												}
											/>
										</Styled.UserAvatar>
										<Styled.UserMessage role='presentation'>
											<div data-testid='cm-comment-text'>{comment.comment}</div>
											<Styled.MessageStatus>
												{comment.createdBy
													? comment.createdBy?.attributes?.firstName + ' ' + comment.createdBy?.attributes?.lastName
													: user.firstName + ' ' + user.lastName}{' '}
												- {moment(comment.createdAt).format('YYYY-MM-DD')}
											</Styled.MessageStatus>
										</Styled.UserMessage>
									</Styled.ContentWrapper>
									{comment.links?.delete && (
										<Dropdown icon='options' size='16'>
											<DropdownMenu>{comment.links?.delete && <DropdownItem onClick={() => deleteThisComment(comment)}>Delete</DropdownItem>}</DropdownMenu>
										</Dropdown>
									)}
								</Styled.MessageRow>
							))}
							<div ref={commentsEndRef} />
						</Styled.CommentsList>
						<Styled.ThreadTool>
							<Styled.AvatarContainer>
								<Styled.CommentAvatar name={user.name!} backgroundColor={colors.paleGreenTint} />
							</Styled.AvatarContainer>
							<Styled.MessageFieldContainer>
								<Styled.MessageField
									aria-label='message'
									contentEditable='true'
									role='textbox'
									data-testid='input-comment'
									spellCheck='true'
									ref={textAreaRef}
									onInput={(e) => setNewComment(e.currentTarget.textContent ?? '')}
									onKeyDown={onKeyDown}
								/>
								<Styled.SendMessage>
									<Styled.SendButton onClick={sendComment} disabled={false} data-testid='submit-comment'>
										<Icon name='send' size='20' />
									</Styled.SendButton>
								</Styled.SendMessage>
							</Styled.MessageFieldContainer>
						</Styled.ThreadTool>
					</Styled.CommentsContainer>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InfluencerListItemComments;
