import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin-bottom: 80px;
`;

const HeadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > div {
		&.left {
			display: flex;
			align-items: center;
			column-gap: ${guttersWithRem.xs};

			font-size: ${typography.headings.h5.fontSize};
			font-weight: ${typography.headings.h5.fontWeight};
			line-height: 1;

			& > i {
				line-height: 1;
			}
		}

		&.right {
			width: 100%;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;

			margin-right: ${guttersWithRem.xs};
			& > div > span {
				font-weight: 600;
			}
		}
	}

	@media (max-width: ${breakpoints.sm}) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const PeriodSelector = styled.div`
	display: flex;
	justify-items: flex-start;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	margin-left: ${guttersWithRem.xs};

	font-size: ${typography.paragraphs.fontSize};
	font-weight: 400;

	& > div {
		& > div > div {
			min-width: 180px;
		}
		button {
			width: 150px;

			&:hover,
			&.show {
				background-color: ${colors.transparent} !important;
			}
		}
	}
`;

const DropdownItemInner = styled.div`
	width: 100%;
	padding: ${guttersWithRem.xxs} 0;
`;

const TodoListWrapper = styled.div`
	margin-bottom: ${guttersWithRem.xxl};
`;

const BottomWrapper = styled.div`
	width: 100%;
	line-height: 1;
`;

const SeeAll = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	.icon {
		margin-right: 8px;
	}
	&:before,
	&:after {
		content: ' ';
		flex-grow: 1;
		height: 1px;
		background-color: ${colors.black};
	}

	&:before {
		margin-right: ${guttersWithRem.m};
	}

	&:after {
		margin-left: ${guttersWithRem.m};
	}
`;

const Styled = {
	Wrapper,
	HeadingWrapper,
	PeriodSelector,
	DropdownItemInner,
	TodoListWrapper,
	BottomWrapper,
	SeeAll,
};

export default Styled;
