import { InputHTMLAttributes } from 'react';

import Icon from 'components/Icon';

import Styled from './SearchInput.style';

interface ISearchInput extends InputHTMLAttributes<HTMLInputElement> {
	onReset: () => void;
	isEnterLabelVisible?: boolean;
	className?: string;
}

const SearchInput = (props: ISearchInput) => {
	return (
		<Styled.SearchInputWrapper className={props.className}>
			<Styled.AiIconContainer>
				<Icon name='ai-icon' />
			</Styled.AiIconContainer>
			<Styled.Input {...props} />
			{props.value && (
				<>
					{props.isEnterLabelVisible && <Styled.EnterLabel title='Press "enter" to submit'>[enter]</Styled.EnterLabel>}
					<Styled.IconContainer onClick={props.onReset}>
						<Styled.CustomIcon name='cancel-circle' size={props.className === 'brand-mentions' ? '16' : '20'} />
					</Styled.IconContainer>
				</>
			)}
		</Styled.SearchInputWrapper>
	);
};

export default SearchInput;
