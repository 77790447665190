import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AMCampaignCard, TrackingLinkType } from 'components/AffiliateMarketplace/types';

export type AffiliateMarketplaceSliceState = {
	dashboardCampaignCards: AMCampaignCard[];
	isLeftSidebarHover: boolean;
	isShowLeftSidebar: boolean;
	isShowDetailSidebar: boolean;
	selectedAMCampaign?: AMCampaignCard;
	selectedAMCampaignGeneratedLinks: TrackingLinkType[];
	searchText: string;
};

const initialState: AffiliateMarketplaceSliceState = {
	dashboardCampaignCards: [],
	isLeftSidebarHover: false,
	isShowLeftSidebar: false,
	isShowDetailSidebar: false,
	selectedAMCampaign: undefined,
	selectedAMCampaignGeneratedLinks: [],
	searchText: '',
};

const AffiliateMarketplaceSlice = createSlice({
	name: 'affiliateMarketplace',
	initialState,
	reducers: {
		setSearchText(state, action: PayloadAction<string>) {
			state.searchText = action.payload;
		},
		setIsLeftSidebarHover(state, action: PayloadAction<boolean>) {
			state.isLeftSidebarHover = action.payload;
		},
		setIsShowLeftSidebar(state, action: PayloadAction<boolean>) {
			state.isShowLeftSidebar = action.payload;
		},
		setIsShowDetailSidebar(state, action: PayloadAction<boolean>) {
			state.isShowDetailSidebar = action.payload;
		},
		setSelectedAMCampaign(state, action: PayloadAction<AMCampaignCard>) {
			state.selectedAMCampaign = action.payload;
		},
		setSelectedAMCampaignGeneratedTrackingLinks(state, action: PayloadAction<TrackingLinkType[]>) {
			state.selectedAMCampaignGeneratedLinks = action.payload;
		},
		setDashboardCampaignCards(state, action: PayloadAction<Array<AMCampaignCard>>) {
			state.dashboardCampaignCards = action.payload;
		},
		resetAMSlice() {
			return initialState;
		},
	},
});

export const {
	setSearchText,
	setIsLeftSidebarHover,
	setIsShowLeftSidebar,
	setIsShowDetailSidebar,
	setSelectedAMCampaign,
	setSelectedAMCampaignGeneratedTrackingLinks,
	setDashboardCampaignCards,
	resetAMSlice,
} = AffiliateMarketplaceSlice.actions;

export default AffiliateMarketplaceSlice.reducer;
