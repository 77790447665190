import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	max-height: 584px;
	height: auto;
	padding: 0.5rem 0;
`;

const Header = Modal.Header;
const ModalHeader = styled(Header)`
	display: flex;
	margin-bottom: 0;
	padding-right: ${guttersWithRem.m};
	justify-content: flex-end;
	padding: 2rem 2.5rem 0;

	& > button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;

		& > i {
			display: flex;
		}
	}
`;

const UserListSection = styled.div`
	background-color: ${newColors.mist};
	border-radius: ${borderRadius.s};
	max-height: 525px;
	overflow-y: auto;
`;

const TabsWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 0.5rem;
	margin-bottom: 16px;
	gap: 16px;
	cursor: pointer;
	div {
		padding: 0.5rem 0;
		font-size: 1rem;
		color: ${newColors.oceanBlue};
		width: 100%;
		text-align: center;
		&.selected,
		&:hover {
			border-bottom: 1.5px solid ${newColors.oceanBlue};
		}
	}
`;

const SearchWrapper = styled.div`
	margin-bottom: 8px;
`;

const Styled = {
	TabsWrapper,
	Wrapper,
	ModalHeader,
	UserListSection,
	SearchWrapper,
};

export default Styled;
