import styled from 'styled-components';

import colors from 'styles/theme/colors';

const NavContainer = styled.div`
	background-color: ${colors.mist};
`;

const SearchWrapper = styled.div`
	width: calc(100% - 48px);
	height: 100%;
	margin-left: 32px;
	margin-right: 16px;
	padding: 1rem 0;
`;

const Nav = styled.div`
	height: 100%;
	position: relative;
`;

const Styled = {
	SearchWrapper,
	NavContainer,
	Nav,
};

export default Styled;
