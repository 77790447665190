import classNames from 'classnames';
import { first, isNil } from 'lodash';
import React, { forwardRef, useImperativeHandle } from 'react';
import { generatePath } from 'react-router';
import { Link, useLocation, useParams } from 'react-router-dom';

import useInjection from 'hooks/useInjection';
import { UserType } from 'reducers/UserReducers/types';
import GenericManager from 'services/ApiManager/Generic.manager';
import { AssignmentReviewStatus, AssignmentStatus, CampaignInstagramOwnerAssignment, Review, Assignment } from 'shared/Types/Assignment';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import AssignmentItem from './AssignmentItem';
import Styled from './AssignmentList.style';

type AssignmentListProps = {
	assignmentsUrl: string;
	ownerId?: string;
	user: UserType | undefined;
};

function getStatus(assignment: CampaignInstagramOwnerAssignment, review?: Review) {
	switch (review?.attributes.newStatus) {
		case AssignmentReviewStatus.CHANGE_REQUESTED:
		case AssignmentReviewStatus.REJECTED:
			return review!.attributes.newStatus as AssignmentReviewStatus;
		default:
			return assignment.attributes.newFancyStatus as AssignmentStatus;
	}
}

export const ManagerCampaignInstagramOwnerStatusPriority = (status: string): number => {
	switch (status) {
		case AssignmentStatus.APPROVED:
			return 5;
		case AssignmentStatus.REVIEW_APPROVED:
			return 4;
		case AssignmentStatus.DECLINED:
		case AssignmentStatus.DISPUTED:
		case AssignmentReviewStatus.REJECTED:
			return 20;
		case AssignmentReviewStatus.CHANGE_REQUESTED:
			return 2;
		case AssignmentStatus.IN_REVIEW:
			return 1;
		case AssignmentStatus.STATISTICS_UPLOADED:
			return 3;
		default:
			return 14;
	}
};
const AssignmentList = forwardRef((props: AssignmentListProps, ref) => {
	const location = useLocation();
	const manager = useInjection<GenericManager>(GenericManager);
	const { campaignId, ownerId, assignmentId } = useParams();
	let path = '/content-management/:campaignId/:ownerId/:assignmentId';
	const queryBuilder = RequestQueryBuilder.create().withInclude('assignment').withInclude('reviews');

	const { isLoading, repository, error, mutate } = manager.get(props.assignmentsUrl, queryBuilder);
	const instagramOwnerAssignments = repository?.findAll<CampaignInstagramOwnerAssignment>('campaignInstagramOwnerAssignment') ?? [];

	if (location.pathname.startsWith('/influencer')) {
		path = '/influencer' + path;
	}

	useImperativeHandle(ref, () => ({
		refreshAssignments: () => mutate(),
	}));

	return (
		<Styled.Wrapper className={classNames({ visible: true })}>
			<Styled.InnerWrapper>
				<Styled.ListWrapper>
					{instagramOwnerAssignments
						.sort(
							(a, b) =>
								ManagerCampaignInstagramOwnerStatusPriority(a.attributes.newFancyStatus) -
								ManagerCampaignInstagramOwnerStatusPriority(b.attributes.newFancyStatus),
						)
						.map((item, index) => {
							const assignment = repository?.findOneByRelation<Assignment, CampaignInstagramOwnerAssignment>(item, 'assignment');
							const reviews = repository?.findByRelation<Review, CampaignInstagramOwnerAssignment>(item, 'reviews');
							const latestReview = first(reviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));

							if (isNil(assignment)) {
								return null;
							}
							const active = assignment.id === assignmentId;
							const assignmentProps = {
								id: assignment.id,
								name: assignment.attributes.name,
								type: assignment.attributes.kind,
								status: getStatus(item, latestReview),
								isActive: active,
								disabled: active,
								user: props.user,
							};

							return (
								<React.Fragment key={index}>
									{active ? (
										<AssignmentItem {...assignmentProps} />
									) : (
										<Link
											to={generatePath(path, {
												campaignId,
												ownerId: ownerId ?? props.ownerId,
												assignmentId: assignment.id,
											})}
										>
											<AssignmentItem {...assignmentProps} />
										</Link>
									)}
									<Styled.Divider />
								</React.Fragment>
							);
						})}
					{!isLoading && instagramOwnerAssignments.length === 0 && (
						<li className='no-assignments'>
							<span>No assignments found!</span>
						</li>
					)}
				</Styled.ListWrapper>
				{error && <span>Cannot load assignments</span>}
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
});

export default AssignmentList;
