import styled from 'styled-components';

import { Button } from 'components/Button';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import { PALETTE } from 'styles/variables/original-colors';
import { spacing } from 'styles/variables/spacings';

const SummaryWrapper = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	margin: 0 auto;
	display: block;
	height: 106px;
	border-top-left-radius: ${borderRadius.m};
	border-top-right-radius: ${borderRadius.m};
	background-color: ${colors.mainBlack};
	transition: all 0.2s;
	z-index: 3;
	&.collapse {
		height: 0;
		overflow: hidden;
	}
	@media screen and (${devices.lg}) {
		display: none;
		overflow: hidden;
	}
`;

const Wrapper = styled.div`
	background-color: ${PALETTE.concret};
	display: flex;
	gap: 8px;
	border-top-left-radius: ${borderRadius.m};
	border-top-right-radius: ${borderRadius.m};
	padding: ${spacing[2].rem} ${spacing[3].rem};
	button {
		width: 100%;
	}
`;

const CampaignSummary = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};
	color: ${colors.mainBlack};

	& div > i {
		display: flex;
		line-height: 0;
		& > svg {
			& > path {
				fill: ${colors.mainBlack};
			}
		}
	}

	&.detail-open {
		width: 100%;
		flex-direction: column;
		row-gap: ${guttersWithRem.xl};
	}
`;

const Buttons = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};

	&.mobile-view {
		flex-direction: column;
		row-gap: ${guttersWithRem.m};
		margin-bottom: ${guttersWithRem.m};
	}
`;

const DetailButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const CustomButton = styled(Button)`
	.icon path {
		fill: ${colors.button.primary.background};
	}

	&:hover {
		color: ${colors.button.primary.color};
		border-color: ${colors.button.primary.borderColor};
		background-color: ${colors.button.primary.background};
	}

	&.white-button {
		background-color: ${colors.button.primary.background};
		color: ${colors.button.primary.color};
		border: 2px solid ${colors.button.primary.borderColor};

		.icon path {
			fill: ${colors.button.primary.color};
		}
		&:hover {
			color: ${colors.button.primary.background};
			border-color: ${colors.button.primary.background};
			background-color: ${colors.transparent};

			& > i {
				fill: ${colors.button.primary.background};
			}
		}
	}
`;

const PublisherLogotype = styled.div`
	margin-bottom: 8px;

	img {
		max-width: 150px;
	}

	&.logo--small {
		padding-left: 1.75rem;

		img {
			max-width: 100px;
		}
	}
`;

const ChatOption = styled.div`
	color: ${colors.mainWhite};
	padding: ${spacing[1].rem} ${spacing[2].rem} ${spacing[1].rem} ${spacing[3].rem};
	font-size: 15px;
`;

const Styled = {
	ChatOption,
	Wrapper,
	CampaignSummary,
	Buttons,
	DetailButtonWrapper,
	CustomButton,
	PublisherLogotype,
	SummaryWrapper,
};

export default Styled;
