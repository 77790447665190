import styled from 'styled-components';

import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Grid from 'styles/grid/grid';
import colors from 'styles/theme/colors';
import _colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const dl = _colors.dataLibrary;

interface ICustomAccordionProps {
	bold?: boolean;
}
const CustomAccordion = styled(Accordion)<ICustomAccordionProps>`
	border-bottom: 1px solid ${dl.black};
	.accordion__title {
		border-bottom: transparent;
	}
	.accordion__header {
		padding: 1rem;
		padding-top: 1.2rem;
		font-family: ${typography.BaseFontFamiliy} !important;
		margin-bottom: 0;
		&:hover {
			background-color: ${dl.blackHover};
			color: ${dl.white};
			.icon path {
				stroke: ${dl.white};
			}
		}
	}
	& > input {
		border: none;
	}
	label {
		font-family: ${typography.BaseFontFamiliy} !important;
		font-size: 0.875rem;
		font-weight: 400;
		font-weight: ${(props) => props.bold && 700};
		letter-spacing: 0em;
		text-align: left;
	}
`;

const AccordionContent = styled.div`
	& > div {
		padding: 1rem;
	}
`;

const FilterOptionListItem = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	text-align: center;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0em;
	margin-bottom: 3px;
	border-radius: 4px;
	padding: 0rem 0.5rem;
	border: 1px solid ${colors.smoke};
	&:hover {
		cursor: pointer;
	}
	&.selected {
		background-color: ${dl.black};
		color: ${dl.white};
		input > div {
			border: 1px solid ${dl.white};
		}
		svg {
			stroke: ${dl.white};
		}
	}
	img {
		height: 30px;
		width: 30px;
		border-radius: 100%;
		margin-left: 5px;
		margin-right: 5px;
	}
	div {
		padding-top: 0.2rem;
	}
`;

const CustomGridContainer = styled(Grid.Container)`
	margin-top: 15px;
	width: 100%;
	margin-bottom: 15px;
`;

const CalendarContainer = styled.div`
	display: flex;
	button {
		background: transparent;
		border: none;
		cursor: pointer;
	}
`;

const CustomInput = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	background: transparent;
	border: 1px solid ${colors.smoke};
	border-radius: 10px;
	align-items: center;
	padding: 1rem;
	height: 56px;
	&.selected {
		background: ${dl.white};
		border: 2px solid ${colors.smoke};
	}
	input {
		background-color: transparent;
		border: none;
		width: 100%;
		height: 56px;
		&:focus {
			outline: none;
		}
		&::placeholder {
			font-size: 1rem;
			font-weight: 500;
			font-family: ${typography.BaseFontFamiliy} !important;
		}
	}
`;

const Styled = {
	CustomAccordion,
	AccordionContent,
	FilterOptionListItem,
	CustomGridContainer,
	CalendarContainer,
	Icon,
	CustomInput,
};

export default Styled;
