import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 40px auto;

	position: relative;

	&.has-selected-influencer {
		grid-template-rows: 40px auto 40px auto;
	}
`;

const SelectedInfluencers = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	width: 100%;
	padding: 1rem 0;
	& > span {
		color: ${colors.integratedInbox.blastModal.color.label};
	}
`;

const SelectedInfluencerTag = styled.div`
	padding: ${guttersWithRem.xxs};
	height: 30px;

	display: flex;
	column-gap: ${guttersWithRem.xxs};
	align-items: center;

	font-weight: 500;
	font-size: 0.875rem;

	& > figure {
		margin-left: 0;
		margin-right: 4px;
	}

	& > div {
		&.name {
			margin-top: 1px;
		}
	}

	& > div > i {
		display: flex;
		margin-left: ${guttersWithRem.xs};
		cursor: pointer;
	}
`;

const MessageWindow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;

	& > div {
		&.window {
			flex: 1;
			overflow: hidden;

			& > div {
				&.image-wrapper {
					display: flex;
					justify-content: center;
					padding: 0;
					padding-top: 32px;
				}

				&.text {
					display: flex;
					flex-direction: column;
					row-gap: ${guttersWithRem.m};

					margin-top: 32px;
					text-align: center;
					& > span {
						font-size: 0.875rem;
						color: ${colors.directMessageModal.content.subTextColor};
						&.strong {
							font-weight: 700;
							font-size: 1rem;
							color: ${colors.directMessageModal.content.color};
						}
					}
				}
			}
		}
	}
`;

const HelpText = styled.div`
	text-align: start;
	padding: 1rem 0;
	font-size: 0.875rem;
	line-height: 1.5;
`;

const Styled = {
	HelpText,
	Wrapper,
	SelectedInfluencers,
	SelectedInfluencerTag,
	MessageWindow,
};

export default Styled;
