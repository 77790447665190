import { Model } from 'json-api-models';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import ChatHeader from 'components/IntegratedInbox/Components/ChatHeader';
import ChatInput from 'components/IntegratedInbox/Components/ChatInput';
import ChatMessages from 'components/IntegratedInbox/Components/ChatMessages';
import { DirectConversationData } from 'components/IntegratedInbox/Components/DirectConversationList/DirectConversationData';
import EmptyChatV2 from 'components/IntegratedInbox/Components/EmptyChatv2/EmptyChatV2';
import { FileItem, IntegratedInboxCampaign } from 'components/IntegratedInbox/types';
import { SkeletonEmptyState } from 'components/SkeletonLoaders';
import { useAppSelector } from 'hooks/useUserAppSelector';
import Grid from 'styles/grid/grid';

import Styled from './ChatSection.style';
import { sendMessageHandler, startNewConversationHandler } from './utils';

type Props = {
	displaySkeletonLoader?: boolean;
	publisher?: Model;
	isFetchingCampaigns?: boolean;
	campaign: IntegratedInboxCampaign | null;
	campaignInstagramOwner?: Model | null;
	messages: Model[];
	selectedDirectConversation: DirectConversationData | null;
	isDirectConversation: boolean;
	noCampaigns: boolean;
	isNoConversation: boolean;
	onSend: (conversationId: string, message: string, files?: Array<File>, influencerId?: string) => Promise<boolean>;
	onRead: (conversationMessageId: string) => Promise<void>;
	onStartChat: (createConversationData: {
		message: string;
		userIds?: string[];
		influencerId: string;
		campaignId: string;
		files?: Array<File>;
	}) => Promise<boolean>;
	onBack: () => void;
	fileUploadProgress: { [key: string]: number };
	tempUserMessageValue: { message: string; files: Array<FileItem> };
	isInfluencer?: boolean;
	isDisabled: boolean;
};

/**
 * It renders chat history or different type of empty states depending on the conditions (no campaign, no conversation or no influencer)
 *
 */
const ChatSection = ({
	displaySkeletonLoader,
	publisher,
	campaign,
	campaignInstagramOwner,
	messages,
	selectedDirectConversation,
	isDirectConversation,
	isNoConversation,
	onSend,
	onRead,
	onStartChat,
	onBack,
	fileUploadProgress,
	tempUserMessageValue,
	isInfluencer,
	isDisabled,
}: Props) => {
	const messageEndRef = useRef<HTMLDivElement>(null);
	const messagesRef = useRef<HTMLDivElement>(null);

	const user = useAppSelector((state) => state.user);
	const params = useParams();

	const directConversationForPublisher = !isInfluencer && isDirectConversation;
	const directConversationForInfluencer = isInfluencer && isDirectConversation;
	const campaignForInfluencer = isInfluencer && campaign;

	const publisherNoCampaignInstagramOwner = !isInfluencer && campaignInstagramOwner !== null;

	const readMessageHandler = (conversationMessageId: string) => {
		return onRead(conversationMessageId);
	};

	const scrollToBottom = () => {
		if (messagesRef.current) {
			messagesRef.current.scrollTop = messageEndRef.current ? messageEndRef.current.offsetTop : 0;
		}
	};

	const getCampaignName = () => {
		if (directConversationForPublisher || directConversationForInfluencer) {
			return 'Direct message';
		} else if (campaignForInfluencer) {
			return campaign!.name;
		} else if (publisherNoCampaignInstagramOwner) {
			return campaign!.name;
		} else return '';
	};

	const getRecipientName = () => {
		if (directConversationForPublisher) {
			return selectedDirectConversation!.name;
		} else if (directConversationForInfluencer) {
			return 'Direct message';
		} else if (campaignForInfluencer) {
			return campaign.name;
		} else if (publisherNoCampaignInstagramOwner) {
			return campaignInstagramOwner!.influencer.username;
		} else if (isInfluencer && campaign) {
			return campaign.name;
		} else return '';
	};

	const getProfilePictureUrl = () => {
		if (directConversationForPublisher) {
			return selectedDirectConversation!.profilePictureUrl;
		} else if (directConversationForInfluencer) {
			return '';
		} else if (campaignForInfluencer) {
			return '';
		} else if (publisherNoCampaignInstagramOwner) {
			return campaignInstagramOwner!.influencer.links.profilePictureUrl;
		} else return '';
	};

	const onSendHandler = async (message: string, attachments?: File[]) => {
		if (isNewConversation()) {
			return await startNewConversationHandler(user, onStartChat, message, {
				attachments: attachments,
				campaign: campaign,
				campaignInstagramOwner: campaignInstagramOwner,
				isInfluencer: user.permissions.isInfluencer,
			});
		}

		return await sendMessageHandler(user, onSend, message, {
			attachments: attachments,
			conversationId: params.conversationId,
		});
	};

	const isNewConversation = () => {
		return !directConversationForPublisher && isNoConversation;
	};

	return (
		<Grid.Column md={6} className='three-col hidden'>
			<Styled.Wrapper data-testid='inbox-chat-section'>
				{displaySkeletonLoader ? (
					<SkeletonEmptyState />
				) : getRecipientName()?.length < 1 ? (
					<EmptyChatV2 />
				) : (
					<>
						<ChatHeader
							campaignName={getCampaignName()}
							recipientName={getRecipientName()}
							profilePictureUrl={getProfilePictureUrl()}
							goBack={onBack}
							isInfluencer={isInfluencer}
						/>
						<Styled.Content>
							<ChatMessages
								publisher={publisher}
								messages={messages}
								onRead={readMessageHandler}
								tempValue={tempUserMessageValue}
								messagesRef={messagesRef}
								messageEndRef={messageEndRef}
								onScrollToBottom={scrollToBottom}
								fileUploadProgress={fileUploadProgress}
								isInfluencer={isInfluencer}
							/>
							<div className='input-wrapper'>
								<ChatInput onSend={onSendHandler} onScrollToBottom={scrollToBottom} isDisabled={isDisabled} />
							</div>
						</Styled.Content>
					</>
				)}
			</Styled.Wrapper>
		</Grid.Column>
	);
};

export default ChatSection;
