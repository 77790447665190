import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { mediaQueries } from 'styles/variables/media-queries';

const ListItem = styled.div`
	display: flex;
	align-items: center;
	width: calc(100% - 16px);
	box-sizing: border-box;
	position: relative;
	margin: 0 8px;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0;
	height: 70px;
	width: 100%;
	border-radius: ${borderRadius.s};
	cursor: pointer;
	transition:
		background-color 200ms ease-in-out,
		color 200ms ease-in-out;
	&:hover {
		background-color: ${colors.integratedInbox.campaignSection.listItem.hover.background};
		color: ${colors.integratedInbox.campaignSection.listItem.hover.color};
	}

	&.selected {
		background-color: ${colors.integratedInbox.campaignSection.listItem.hover.background};
		color: ${colors.integratedInbox.campaignSection.listItem.hover.color};

		& > div:nth-child(2) {
			border-bottom-color: transparent;
		}
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 40px;
	height: 40px;
	margin-left: 8px;
	& > figure {
		margin: 0;
	}
`;

const CampaignTitle = styled.div`
	font-weight: 600;
	height: 80px;
	display: flex;
	align-items: center;
	padding-right: ${guttersWithRem.m};
	div {
		max-width: 170px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const UnreadMark = styled.div`
	position: absolute;
	right: 0;
	top: 0;

	width: 12px;
	height: 12px;
	background-color: ${colors.integratedInbox.campaignSection.listItem.unreadMark.background};
	border: 2px solid ${colors.integratedInbox.campaignSection.listItem.unreadMark.border};
	border-radius: 50%;
`;

const TextContainer = styled.div`
	flex: 1;
	margin-left: 10px;
`;

const FullNameText = styled.span`
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.3;
`;

const TruncatedText = styled.span`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	font-size: 0.875rem;
	font-weight: 600;
	max-width: 288px;
	line-height: 1.3;

	${mediaQueries.small`
		max-width: 100%;
	`};

	${mediaQueries.xl`
		max-width: 205px;
	`};
`;

const Styled = {
	TextContainer,
	ListItem,
	Wrapper,
	ImageWrapper,
	CampaignTitle,
	UnreadMark,
	FullNameText,
	TruncatedText,
};

export default Styled;
