import { AxiosResponse, AxiosInstance } from 'axios';
import { JsonApiDocument } from 'json-api-models';
import { isNil } from 'lodash';

import { StatusCode } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';

/**
 * @deprecated use InboxManager instead. The InboxManager is part of our new ApiManager pattern.
 */
class IntegratedInboxService {
	private client: AxiosInstance;

	constructor() {
		this.client = createClient();
	}

	getCampaign = async (campaignId: string): Promise<AxiosResponse> => {
		const params = new URLSearchParams({
			includes: `campaignInstagramOwners,campaignInstagramOwners.campaignInstagramOwnerAssignments,campaignInstagramOwners.campaignInstagramOwnerAssignments.assignment,campaignInstagramOwners.campaignInstagramOwnerAssignments.assignment.groups,campaignInstagramOwners.campaignInstagramOwnerCommissions,campaignInstagramOwners.campaignInstagramOwnerCommissions.commission,campaignInstagramOwners.campaignInstagramOwnerProducts,campaignInstagramOwners.campaignInstagramOwnerProducts.product,campaignInstagramOwners.influencer`,
		});

		const url = `/campaigns/${campaignId}?${params}`;

		const response = await this.client.get<JsonApiDocument>(url);
		return response;
	};

	getCampaigns = async (selectedProfileId?: string, statuses?: string): Promise<AxiosResponse> => {
		const conversationMetaData = isNil(selectedProfileId)
			? 'conversationMetaData:hateoas(false)'
			: `conversationMetaData:influencer(${selectedProfileId}):hateoas(false)`;

		const params = new URLSearchParams({
			include: `:hateoas(false),${conversationMetaData}`,
			exclude: 'defaultIncludes',
		});

		if (selectedProfileId) {
			params.append('influencer', selectedProfileId);
		}

		if (statuses) {
			params.append('statuses', statuses);
		}
		const url = `/campaigns?${params}`;

		const response = await this.client.get<JsonApiDocument>(url);
		return response;
	};

	getCampaignInfluencers = async (campaignId: string): Promise<AxiosResponse> => {
		return await this.client
			.get(
				`/campaigns/${campaignId}/instagram-owners?includes=latestMessage,unreadMessages,campaign,campaign.invites,campaign.invites.influencer,campaignInstagramOwnerAssignments,campaignInstagramOwnerCommissions,campaignInstagramOwnerProducts,group,influencer,invite`,
			)
			.then((res) => {
				if (res.data) {
					return res.data;
				}
			});
	};

	getCampaignConversations = async (campaignId: string, selectedProfileId?: string): Promise<AxiosResponse> => {
		const params = new URLSearchParams({
			includes: 'publisher',
		});
		if (selectedProfileId) {
			params.append('influencer', selectedProfileId);
		}

		const response = await this.client.get(`/campaigns/${campaignId}/conversations?${params}`);
		return response.data;
	};

	getConversationMessages = async (conversationId: string, includes?: string): Promise<AxiosResponse> => {
		return await this.client.get(`/conversations/${conversationId}/messages${includes ? `?includes=${includes}` : ''}`).then((res) => {
			if (res.data) {
				return res.data;
			}
		});
	};

	createNewConversation = async (createConversationData: {
		message: string;
		userIds?: string[];
		influencerId: string;
		campaignId?: string;
		files?: Array<string>;
	}) => {
		return await this.client.post('/conversations', {
			message: createConversationData.message,
			users: createConversationData.userIds,
			influencer: createConversationData.influencerId,
			campaign: createConversationData.campaignId,
			files: createConversationData.files,
		});
	};

	createDirectMessage = async (directMessageData: { message: string; influencerId: string }) => {
		return await this.client.post(`/influencers/${directMessageData.influencerId}/conversation-messages`, {
			message: directMessageData.message,
		});
	};

	getInfluencer = async (collabsId: string) => {
		return await this.client.get(`/influencers/discovery/${collabsId}`).then((res: AxiosResponse) => {
			if (res.status === StatusCode.OK) {
				return res.data.data;
			}
		});
	};

	getConversations = async (selectedProfileId?: string) => {
		const params = new URLSearchParams({
			direct: 'true',
			includes: 'influencer,latestMessage,latestMessage.influencer,latestMessage.user,unreadMessages,user,user.publisher',
		});
		if (selectedProfileId) {
			params.append('influencer', selectedProfileId);
		}
		const url = `/conversations?${params}`;

		return await this.client.get(url).then((res) => {
			if (res) {
				return res.data;
			}
		});
	};

	getCampaignsInfluencer = async () => {
		const response = await this.client.get<JsonApiDocument>(`/campaigns?includes=campaignInstagramOwners`);
		return response;
	};
}

export default new IntegratedInboxService();
