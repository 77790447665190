import classNames from 'classnames';
import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import Icon from 'components/Icon';

import Styled from './Select.style';
import { SelectProps } from './types';

/**
 * Select component using forwardRef to allow ref forwarding
 * @param {SelectProps} props - Input field properties
 * @param {React.Ref<HTMLInputElement>} ref - Ref forwarded to the input element
 * @returns {JSX.Element}
 */
const Select = forwardRef<HTMLSelectElement, SelectProps>(
	({ scale = 'medium', isWarning, isError, disabled, readOnly, onFocus, onBlur, ...props }, externalRef): JSX.Element => {
		const [isFocused, setIsFocused] = useState(false);
		const internalRef = useRef<HTMLSelectElement>(null);

		useImperativeHandle(externalRef, () => internalRef && internalRef.current!, [internalRef]);

		const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(true);
			onFocus?.(e);
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(false);
			onBlur?.(e);
		};

		return (
			<Styled.InputContainer
				className={classNames(
					{
						'has-warning': isWarning,
						'has-error': isError,
						disabled: disabled,
						readonly: readOnly,
						'has-focus': isFocused,
					},
					'input-container',
				)}
				scale={scale}
			>
				{props.contentBefore && <Styled.ContentBefore>{props.contentBefore}</Styled.ContentBefore>}
				<Styled.Select
					data-testid='input-field'
					scale={scale}
					onFocus={handleFocus}
					onBlur={handleBlur}
					disabled={disabled}
					readOnly={readOnly}
					ref={internalRef}
					{...props}
				/>
				<Styled.ContentAfter>
					<Icon name='chevron-down' />
				</Styled.ContentAfter>
			</Styled.InputContainer>
		);
	},
);

export default Select;
