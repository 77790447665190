import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setShowFilter } from 'views/DataLibrary/reducers/FilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './PageHeaderButtons.style';

/**
 * @returns {JSX.Element}
 */
const PageHeaderButtons = (): JSX.Element => {
	const filter = useAppSelector((state) => state.filter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);

	const dispatch = useAppDispatch();
	const [numberOfSelectedFilters, setNumberOfSelectedFilters] = useState<number>(1);

	const updateTotal = () => {
		let total = 1; // For the date that always is pre selected

		const FilterCategories = ['assignments', 'assignmentTypes', 'brands', 'clients', 'campaigns', 'countries', 'influencers'];
		for (const key in dashboardFilter) {
			// @ts-ignore
			if (FilterCategories.includes(key) && dashboardFilter[key]?.length > 0) {
				total++;
			}
		}
		return setNumberOfSelectedFilters(total);
	};

	useEffect(() => {
		updateTotal();
	}, [dashboardFilter]);

	return (
		<Styled.Wrapper>
			{dashboard.id && (
				<Styled.NotificationContainer>
					<Styled.TotalNotification className={classNames({ visible: !filter.isOpen })}>
						<span>{numberOfSelectedFilters}</span>
					</Styled.TotalNotification>
					<Styled.FilterButton
						onClick={() => {
							dispatch(setShowFilter(!filter.isOpen));
						}}
						className={classNames({ showFilter: filter.isOpen })}
					>
						<span>Filter</span> <Icon name='filters' />
					</Styled.FilterButton>
				</Styled.NotificationContainer>
			)}
		</Styled.Wrapper>
	);
};
export default PageHeaderButtons;
