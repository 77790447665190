import styled from 'styled-components';

import Accordion from 'components/Accordion';
import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';
const dl = colors.dataLibrary;

const CustomAccordion = styled(Accordion)`
	.accordion__header {
		font-family: ${typography.BaseFontFamiliy};
		font-size: 1rem;
		letter-spacing: 0em;
		border-radius: 4px;
		padding: 0.6rem;
		transition: all 200ms ease-in-out;
		font-weight: 700;
		&:hover {
			background: ${dl.darkHoverColor};
			color: ${dl.white};
			.icon path {
				stroke: ${dl.white};
			}
		}
	}
`;

const AccordionList = styled.ul`
	list-style: none;
`;

const AccordionListItem = styled.ul`
	cursor: pointer;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0em;
	padding: 0.6rem;
	padding-top: 0.8rem;
	border-radius: 4px;
	&.selected {
		background: ${dl.darkHoverColor};
		color: ${dl.white};
	}
	a {
		color: ${dl.black};
	}
	&:hover {
		background: #5c5c5c;
		color: ${dl.white};
	}
`;

const GraphTitle = styled.div`
	margin-top: 15px;
`;

const GraphList = styled.ul`
	height: 100%;
	list-style: none;
	li {
		padding: 1rem 0.5rem 1rem 0rem;
	}
`;

const Styled = {
	GraphList,
	GraphTitle,
	CustomAccordion,
	AccordionList,
	AccordionListItem,
};

export default Styled;
