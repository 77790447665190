import styled from 'styled-components';

import { Button } from 'components/Button';
import Icon from 'components/Icon';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const CustomIcon = styled(Icon)``;

const CustomButton = styled(Button)`
	color: ${colors.white};
	background-color: ${colors.briefPage.joinCampaign.infoBlue};
	border: 2px solid ${colors.briefPage.joinCampaign.infoBlue};
	width: 100%;
	@media screen and (${devices.md}) {
		width: fit-content;
		white-space: normal;
		word-wrap: break-word;
	}
	&:hover:not(:disabled) {
		background: ${colors.mainBlack} !important;
		border: 2px solid ${colors.mainBlack};
		color: ${colors.white};
	}

	&:disabled {
		opacity: 0.7;
	}
`;

const Wrapper = styled.div`
	&.join-now {
		${CustomButton} {
			border: none;

			&:hover:not(:disabled) {
				background-color: ${colors.transparent};
				color: ${colors.white};
			}
		}
	}

	&.join-now-link {
		${CustomButton} {
			border: none;
			color: ${colors.button.sencondary.color};
			background-color: ${colors.transparent};

			&:hover:not(:disabled) {
				background-color: ${colors.transparent};
				color: ${colors.white};
			}
		}

		${CustomIcon} {
			fill: ${colors.button.sencondary.color};
		}
	}
`;

const ErrorModalInnerWrapper = styled.div`
	line-height: 1.5;

	& > div > strong > span {
		text-transform: capitalize;
	}
`;

const Styled = {
	Wrapper,
	CustomButton,
	CustomIcon,

	ErrorModalInnerWrapper,
};

export default Styled;
