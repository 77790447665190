export enum FileType {
	ALL = 'All',
	FOLDERS = 'Folders',
	LISTS = 'Lists',
}

export enum Filter {
	ALL = 'All',
	SHARED = 'Shared',
	SHARED_WITH_ME = 'Shared-with-me',
	PRIVATE = 'private',
}
