import styled from 'styled-components';

import { Scale } from 'styles/theme/types';

const InputContainer = styled.span<{ scale: Scale }>`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.inputs};
	border-radius: ${({ theme }) => theme.radius.small};
	border: 1px solid ${({ theme }) => theme.borders.secondary};
	transition: border-color 0.2s ease-in-out;

	padding: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '0.4375rem 0.5rem';
			case 'large':
				return '1rem 1rem';
			default:
				return '0.55rem 0.625rem';
		}
	}};

	min-height: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '44px';
			case 'large':
				return '114px';
			default:
				return '84px';
		}
	}};

	&.has-warning {
		margin: -1px;
		border-color: ${({ theme }) => theme.borders.warning};
		border-width: 2px;
	}

	&.has-error {
		margin: -1px;
		border-color: ${({ theme }) => theme.borders.error};
		border-width: 2px;
	}

	&:hover:not(.disabled):not(.readonly):not(.has-error) {
		border-color: ${({ theme }) => theme.borders.hover};
	}

	&.has-focus:not(.readonly) {
		border-color: ${({ theme }) => theme.borders.focus} !important;
		outline: none;
	}
`;

const Textarea = styled.textarea<{ scale: Scale }>`
	background-color: transparent;
	border: none;
	width: 100%;
	color: ${({ theme }) => theme.colors.text};
	font-size: ${({ theme, scale }) => {
		switch (scale) {
			case 'small':
				return theme.fontSizes.smallInput;
			case 'large':
				return theme.fontSizes.largeInput;
			default:
				return theme.fontSizes.input;
		}
	}};

	min-height: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '44px';
			case 'large':
				return '114px';
			default:
				return '84px';
		}
	}};

	&::placeholder {
		color: ${({ theme }) => theme.colors.placeholder};
	}

	&:disabled::placeholder {
		color: ${({ theme }) => theme.colors.textDisabled};
	}

	&:read-only {
		cursor: default;
	}

	&:focus,
	&:focus-visible {
		border: 0;
		box-shadow: none;
		outline: none;
	}
`;

const Styled = {
	InputContainer,
	Textarea,
};

export default Styled;
