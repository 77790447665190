import classNames from 'classnames';

import Icon from 'components/Icon';

import Styled from './Tabs.style';
import { TabItem, TabsProps } from './types';

const Tabs = ({ tabs, selectedTab, setSelectedTab, id }: TabsProps) => {
	return (
		<Styled.TabContainer id={id}>
			{tabs.map((tab: TabItem, index: number) => {
				return (
					!tab.omit && (
						<Styled.Tab
							data-testid={tab.testId}
							key={index}
							className={classNames({ selected: selectedTab === tab.title, disabled: tab.disabled })}
							onClick={() => {
								!tab.disabled && setSelectedTab(tab.title);
							}}
						>
							{tab.icon && <Icon name={tab.icon} size='18' />} {tab.title} {tab.amount && <span>({tab.amount})</span>}
						</Styled.Tab>
					)
				);
			})}
		</Styled.TabContainer>
	);
};

export default Tabs;
