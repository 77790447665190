import classNames from 'classnames';
import { isNil } from 'lodash';

import Copy from 'components/Copy/Copy';
import Icon from 'components/Icon';

import Styled from './TrackingLink.style';

type TrackingLinkProps = {
	headline?: string;
	isLoading?: boolean;
	errorMessage?: string;
	url: string;
	className?: string;
	label?: string;
	isNotAffiliate?: boolean;
	subText?: string;
};

/**
 * TrackingLink
 * Component to show the tracking/affiliate link for the influencer
 * @param {string} url
 * @returns {JSX.Element}
 */
const TrackingLink = ({ headline, isLoading, errorMessage = '', url, className, label, isNotAffiliate, subText }: TrackingLinkProps): JSX.Element => {
	return (
		<Styled.Wrapper className={classNames(className)}>
			{isNil(label) && headline && <Styled.Headline className='tracking-link__headline'>{headline}</Styled.Headline>}
			{!isNil(subText) && <p>{subText}</p>}
			{label && <Styled.Label>{label}</Styled.Label>}
			<Styled.Inner className={classNames({ 'tracking-link__link-container': !isNotAffiliate }, { 'is-not-affiliate': true })}>
				{!isLoading && errorMessage === '' && (
					<>
						<span className='url' data-testid='affiliate-link'>
							{isNotAffiliate && <Icon name='url-link' size='16' />}
							{url}
						</span>
						<Copy id='tracking-link' description='tracking link' value={url} />
					</>
				)}

				{!isLoading && errorMessage && <p>{errorMessage}</p>}
				{isLoading && <p>Loading...</p>}
			</Styled.Inner>
		</Styled.Wrapper>
	);
};

export default TrackingLink;
