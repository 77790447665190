import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	padding: ${spacing[4].rem} ${spacing[2].rem};
	background-color: ${colors.buttonGray};
	border-radius: ${borderRadius.m};
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;
	h5 {
		margin-top: ${spacing[3].px};
		font-weight: 700;
		font-size: 0.938rem;
		margin: ${spacing[3].px};
	}
	p {
		font-size: 0.938rem;
		margin: 0;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
