import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDirectMessage(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_8_107648)'>
				<path
					d='M19.07 6.79004H7.77003C7.26003 6.79004 6.84003 7.21004 6.84003 7.71004V15.72C6.84003 16.23 7.25003 16.64 7.77003 16.64H13.38C13.9 17.52 15.01 19 16.48 19C16.71 19 16.92 18.89 17.06 18.71C17.2 18.53 17.25 18.29 17.19 18.07C17.06 17.6 17.04 16.92 17.2 16.7C17.22 16.68 17.25 16.63 17.46 16.63H19.08C19.59 16.63 20.01 16.22 20.01 15.71V7.71004C20.01 7.20004 19.6 6.79004 19.08 6.79004H19.07ZM18.54 15.18H17.4C17.4 15.18 17.37 15.18 17.36 15.18C16.66 15.2 16.26 15.54 16.04 15.82C15.75 16.2 15.65 16.67 15.63 17.11C15.21 16.74 14.81 16.19 14.52 15.66C14.36 15.36 14.05 15.18 13.71 15.18H8.33003V8.25004H18.55V15.18H18.54Z'
					fill='#4D4B4C'
				/>
				<path
					d='M5.67 7.84H4.83C4.55 7.84 4.3 8 4.18 8.24C4.02 8.56 3.76 8.95 3.47 9.26C3.46 9.02 3.43 8.78 3.37 8.57C3.19 7.89 2.65 7.84 2.5 7.84H1.48V2.46H9.54V5.3C9.54 5.7 9.87 6.03 10.27 6.03C10.67 6.03 11 5.7 11 5.3V1.9C11 1.4 10.6 1 10.1 1H0.9C0.4 1 0 1.4 0 1.9V8.4C0 8.9 0.4 9.3 0.9 9.3H1.99C2.01 9.66 1.97 10.11 1.93 10.37C1.9 10.58 1.96 10.8 2.1 10.96C2.24 11.12 2.44 11.22 2.66 11.22C3.91 11.22 4.84 9.98 5.26 9.3H5.67C6.07 9.3 6.4 8.97 6.4 8.57C6.4 8.17 6.07 7.84 5.67 7.84Z'
					fill='#4D4B4C'
				/>
			</g>
			<defs>
				<clipPath id='clip0_8_107648'>
					<rect width='20' height='18' fill='white' transform='translate(0 1)' />
				</clipPath>
			</defs>
		</svg>
	);
}
