/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	CreatePublisherInvitePayload,
	UpdatePublisherPayload,
	CreateInvitationToUserPayload,
	UpdatePublisherLogoPayload,
	UpdatePublisherInvitePayload,
	UpdateUserPrivilegePayload,
} from 'api-payloads';
import {
	ListPublishersQuery,
	ViewPublisherQuery,
	UpdatePublisherQuery,
	UpdatePublisherLogoQuery,
	ListPublisherPrivilegesQuery,
	UpdateUserPrivilegeQuery,
} from 'api-queries';
import {
	PublisherInviteResponse,
	PublisherCollectionResponse,
	PublisherResponse,
	PublisherInviteCollectionResponse,
	PublisherPrivilegeCollectionResponse,
	PublisherPrivilegeResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import PublisherApiClientInterface from './PublisherApiClientInterface';

@injectable()
export default class PublisherApiClient implements PublisherApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Create publisher invite
	 */
	async createInvite(payload: CreatePublisherInvitePayload, params: { include?: string }): Promise<PublisherInviteResponse> {
		const response = await this.client.post<PublisherInviteResponse>(`/publisher-invites`, payload, { params });
		return response.data;
	}

	/**
	 * List publishers
	 */
	async listPublishers(params: ListPublishersQuery): Promise<PublisherCollectionResponse> {
		const response = await this.client.get<PublisherCollectionResponse>(`/publishers`, { params });
		return response.data;
	}

	/**
	 * View publisher
	 */
	async view(id: string, params: ViewPublisherQuery): Promise<PublisherResponse> {
		const response = await this.client.get<PublisherResponse>(`/publishers/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete publisher
	 */
	async delete(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/publishers/${id}`);
		return response.data;
	}

	/**
	 * Update publisher
	 */
	async update(id: string, payload: UpdatePublisherPayload, params: UpdatePublisherQuery): Promise<PublisherResponse> {
		const response = await this.client.patch<PublisherResponse>(`/publishers/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * List invitations to publisher
	 */
	async listInvitationsTo(id: string, params: { include?: string }): Promise<PublisherInviteCollectionResponse> {
		const response = await this.client.get<PublisherInviteCollectionResponse>(`/publishers/${id}/invites`, { params });
		return response.data;
	}

	/**
	 * Create invitation to user
	 */
	async createInvitationToUser(id: string, payload: CreateInvitationToUserPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/publishers/${id}/invites`, payload, { params });
		return response.data;
	}

	/**
	 * Update publisher logo
	 */
	async updateLogo(id: string, payload: UpdatePublisherLogoPayload, params: UpdatePublisherLogoQuery): Promise<PublisherResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<PublisherResponse>(`/publishers/${id}/logo`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Delete publisher logo
	 */
	async deleteLogo(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/publishers/${id}/logo`);
		return response.data;
	}

	/**
	 * List privileges
	 */
	async listPrivileges(id: string, params: ListPublisherPrivilegesQuery): Promise<PublisherPrivilegeCollectionResponse> {
		const response = await this.client.get<PublisherPrivilegeCollectionResponse>(`/publishers/${id}/privileges`, { params });
		return response.data;
	}

	/**
	 * Delete publisher invite
	 */
	async deleteInvite(publisherId: string, publisherInviteId: string): Promise<void> {
		const response = await this.client.delete<void>(`/publishers/${publisherId}/invites/${publisherInviteId}`);
		return response.data;
	}

	/**
	 * Update publisher invite
	 */
	async updateInvite(
		publisherId: string,
		publisherInviteId: string,
		payload: UpdatePublisherInvitePayload,
		params: { include?: string },
	): Promise<PublisherInviteResponse> {
		const response = await this.client.patch<PublisherInviteResponse>(`/publishers/${publisherId}/invites/${publisherInviteId}`, payload, { params });
		return response.data;
	}

	/**
	 * Delete publisher privilege
	 */
	async deletePrivilege(publisherId: string, userId: string): Promise<void> {
		const response = await this.client.delete<void>(`/publishers/${publisherId}/users/${userId}`);
		return response.data;
	}

	/**
	 * Update user privilege
	 */
	async updateUserPrivilege(
		publisherId: string,
		userId: string,
		payload: UpdateUserPrivilegePayload,
		params: UpdateUserPrivilegeQuery,
	): Promise<PublisherPrivilegeResponse> {
		const response = await this.client.patch<PublisherPrivilegeResponse>(`/publishers/${publisherId}/users/${userId}`, payload, { params });
		return response.data;
	}
}
