import React, { useEffect, useRef, useState } from 'react';

import useArrowKeyNavigation from './useArrowKeyNavigation';

const useCardFocus = (isShowDetailSidebar: boolean, hasSearchResults: boolean) => {
	const [isCardFocused, setIsCardFocused] = useState(false);

	const focusedCardIdRef = useRef<string | null>(null);
	const cardsRef = useRef<Array<HTMLDivElement>>([]);

	//handle setting focus whenever sidebar is closed
	useEffect(() => {
		if (!isShowDetailSidebar) {
			if (focusedCardIdRef.current) {
				const selectedCard = cardsRef.current.find((card) => card.id === focusedCardIdRef.current);
				selectedCard?.focus();
			}
		}
	}, [isShowDetailSidebar]);

	const handleFocus = () => {
		setIsCardFocused(true);
	};

	const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
		//if the target about to get focus i not a card, set flag to false
		if (!cardsRef.current?.some((card: HTMLDivElement) => card.contains(event.relatedTarget))) {
			setIsCardFocused(false);
		}
	};

	const handleArrowKeyNavigation = (event: KeyboardEvent) => {
		if (isCardFocused) {
			event.preventDefault();
			const selectedCard = document.activeElement as HTMLDivElement;
			const selectedCardIndex = cardsRef.current?.findIndex((card) => card.id === selectedCard?.id) ?? 0;

			if (event.key === 'ArrowLeft' && cardsRef.current?.length) {
				const newIndex = (selectedCardIndex - 1 + cardsRef.current.length) % cardsRef.current.length;
				cardsRef.current[newIndex].focus();
			}

			if (event.key === 'ArrowRight' && cardsRef.current?.length) {
				const newIndex = (selectedCardIndex + 1) % cardsRef.current.length;
				cardsRef.current[newIndex].focus();
			}
		}
	};

	useArrowKeyNavigation(handleArrowKeyNavigation);

	useEffect(() => {
		if (hasSearchResults) {
			cardsRef.current?.forEach((cardRef: HTMLDivElement) => {
				cardRef.addEventListener('focus', handleFocus);
				cardRef.addEventListener('blur', (event: Event) => handleBlur(event as unknown as React.FocusEvent<HTMLDivElement>));
			});
		}

		return () => {
			cardsRef.current?.forEach((cardRef: HTMLDivElement) => {
				//if navigating away from the page, there is no cardRef, and the below will cause error
				if (cardRef) {
					cardRef.removeEventListener('focus', handleFocus);
					cardRef.removeEventListener('blur', (event: Event) => handleBlur(event as unknown as React.FocusEvent<HTMLDivElement>));
				}
			});
		};
	}, [hasSearchResults]);

	return {
		cardsRef,
		focusedCardIdRef,
	};
};

export default useCardFocus;
