import { useState, useRef, useEffect } from 'react';

import Icon from 'components/Icon';
import useAutosizeTextArea from 'hooks/useAutosizeTextArea';
import toast from 'services/Toast';

import Styled from './BlastChatInput.style';

/**
 * @todo test
 */
const BlastChatInput = (props: {
	onSend: (message: string) => Promise<boolean>;
	onScrollToBottom?: () => void;
	targetInfluencersNumber?: number;
	onCloseModal: () => void;
	getMessage?: (message: string) => void;
}) => {
	const [value, setValue] = useState<string>('');
	const [isSendDisabled, setIsSendDisabled] = useState<boolean>(true);

	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	useAutosizeTextArea(textAreaRef.current ? textAreaRef.current : null, value);

	const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (evt.target) {
			const val = evt.target.value;
			setValue(val);
			props.getMessage && props.getMessage(val);
		}
	};

	const onClickSend = () => {
		setIsSendDisabled(true);
		props
			.onSend(value)
			.then((res) => {
				if (res) {
					toast.success(`Message has been sent`);
					setValue('');
					props.onCloseModal();
				}
			})
			.catch((error) => {
				toast.error(error ? error.message : 'Something went wrong');
			})
			.finally(() => {
				setIsSendDisabled(false);
			});
	};

	useEffect(() => {
		setIsSendDisabled(!/\S/.test(value) || props.targetInfluencersNumber === 0);
	}, [value, props.targetInfluencersNumber]);

	useEffect(() => {
		if (!isSendDisabled && props.onScrollToBottom) {
			props.onScrollToBottom();
		}
	}, [isSendDisabled]);

	return (
		<Styled.MessageWrapper
			onClick={() => {
				if (textAreaRef.current) {
					textAreaRef.current.focus();
				}
			}}
		>
			<Styled.MessageBar className='msg-bar'>
				<Styled.TextAreaWrapper>
					<Styled.TextArea rows={1} placeholder='Message' onChange={handleChange} ref={textAreaRef} value={value} data-testid='blast-message-textarea' />
				</Styled.TextAreaWrapper>

				<Styled.MessageBarControlsWrapper>
					<Styled.MessageBarControls>
						{props.targetInfluencersNumber !== undefined ? (
							<div className='notify'>{`${props.targetInfluencersNumber ? props.targetInfluencersNumber : 0} people will be notified`}</div>
						) : null}
						<Styled.Send onClick={onClickSend} disabled={isSendDisabled || value.trim().length === 0} data-testid='blast-message-send-btn'>
							<span>
								<Icon name='send' />
							</span>
						</Styled.Send>
					</Styled.MessageBarControls>
				</Styled.MessageBarControlsWrapper>
			</Styled.MessageBar>
		</Styled.MessageWrapper>
	);
};

export default BlastChatInput;
