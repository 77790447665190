import styled from 'styled-components';

import { Button, DarkButton } from 'components/Button';
import { InputField } from 'styles/formElements/input';
import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import _colors from 'styles/variables/colors';

const dl = _colors.dataLibrary;
const sm = _colors.shareModal;

const ListContainer = styled.div`
	width: 100%;
	height: 270px;
	display: flex;
	flex-direction: column;
	padding: 1.5rem 0rem 0rem 0rem;
	text-align: left;
	border-bottom: 1px solid ${dl.modal.grayBorder};
	label {
		color: ${sm.gray};
		padding: 0rem 1rem;
		font-size: 0.875rem;
	}
`;

const Input = styled.input`
	${InputField};
	&::placeholder {
		color: ${dl.input.placeholderText};
		font-size: 1rem;
	}
`;

const CancelContainer = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 10;
	top: 10.5px;
	right: 16px;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	.icon {
		margin-top: 4px;
	}
	&:hover {
		background: ${dl.button.cancelBackground};
	}
`;

const CreateButton = styled(Button)`
	font-size: 1rem;
	line-height: 1;
	width: 170px;
	height: 44px;
	border: none;
	cursor: pointer;
	box-sizing: border-box;
	color: ${dl.button.createText};
	background-color: ${dl.button.createBackground};
	.icon {
		margin-left: 10px;
	}
	&:disabled {
		background-color: ${sm.button.disabledBackground};
		color: ${sm.button.disabledText};
	}
	&:hover {
		background-color: ${dl.blackHover} !important;
		color: ${dl.white} !important;
		.icon path {
			stroke: ${dl.white} !important;
		}
	}
`;

const DashboardList = styled.ul`
	max-height: 212px;
	list-style: none;
	padding-left: 0;
	overflow: auto;
	margin-bottom: 0;
	${scrollbarY};
`;

const SelectDiv = styled.div`
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0;
	text-align: right;
	color: ${dl.input.focus};
	visibility: hidden;
	.keyDownSelect {
		visibility: unset;
	}
`;

const DashboardListItem = styled.li`
	padding: 1.2rem 1rem 1rem 1rem;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 6px;
	width: 100%;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.7;
	letter-spacing: 0em;
	border-radius: 4px;
	cursor: pointer;
	span {
		max-width: 80%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&.keyDownSelect {
		background-color: ${dl.list.hoverBackground};
		${SelectDiv} {
			visibility: unset;
		}
	}
	&:hover {
		background-color: ${colors.ash};
		${SelectDiv} {
			visibility: unset;
		}
	}
	&.sharedWith {
		pointer-events: none;
		opacity: 0.4;
	}
`;

const NameContainer = styled.div`
	max-width: 80%;
`;

const DashboardTitle = styled(DarkButton)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	span {
		width: fit-content;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 3px;
	}
	button {
		width: 300px;
	}
`;

const Dropdown = styled.div`
	box-shadow: rgb(92 104 128 / 16%) 0px 10px 20px 0px;
	padding: 2.5rem 1.5rem;
	width: 400px;
	height: 454px;
	background-color: ${dl.white};
	position: absolute;
	top: 50px;
	border-radius: 10px;
	border: 1px solid ${dl.gray};
	z-index: 10;
	label {
		color: ${dl.labelGray};
		font-size: 0.938rem;
	}
`;

const DropdownInnerContainer = styled.div`
	position: relative;
	width: 100%;
`;

const DropdownFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.5rem 0rem;
`;

const CustomCreateButton = styled(DarkButton)``;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	.icon {
		margin-left: 10px;
		margin-top: 3px;
	}
	button {
		width: fit-content;
		white-space: nowrap;
	}
`;

const Styled = {
	ListContainer,
	Input,
	CancelContainer,
	CreateButton,
	DashboardList,
	DashboardListItem,
	SelectDiv,
	NameContainer,
	DashboardTitle,
	Dropdown,
	DropdownInnerContainer,
	DropdownFooter,
	CustomCreateButton,
	TitleWrapper,
};

export default Styled;
