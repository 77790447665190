import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
`;

const Content = styled.div`
	padding: 0 2em;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 1.875rem;
`;

const Styled = {
	Wrapper,
	Content,
};

export default Styled;
