import styled from 'styled-components';

import { devices } from 'styles/variables/media-queries';
import { rem, px, spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 ${spacing[4].rem};
`;

const TopBar = styled.div`
	position: fixed;
	top: 72px;
	z-index: 100;
	left: 0;
	right: 0;
	padding-top: ${rem[1]}rem;
	padding-bottom: ${rem[1]}rem;
	padding-right: ${rem[4]}rem;
	flex: 1;
	background-color: ${({ theme }) => theme.colors.background};

	@media screen and (${devices.lg}) {
		background-color: transparent;
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	column-gap: ${px[2]}px;
	justify-content: flex-end;
`;

const ComfirmModalInner = styled.div`
	padding-top: ${spacing[5].rem};
	text-align: center;
	font-weight: 700;

	& > div {
		&.title {
			padding-bottom: ${spacing[4].rem};
		}

		& > p {
			margin: 0;
		}
	}
`;

const Styled = {
	Wrapper,
	TopBar,
	ButtonsWrapper,
	ComfirmModalInner,
};

export default Styled;
