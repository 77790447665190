/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	TotalAssignmentPerAssignmentTypeQuery,
	TotalAssignmentPerCampaignQuery,
	TotalAssignmentPerCountryQuery,
	TotalAssignmentPerInfluencerQuery,
	StatisticsAudienceAgeQuery,
	StatisticsAudienceCountryQuery,
	StatisticsAudienceGenderQuery,
	CampaignTotalQuery,
	ClickDailyQuery,
	ClickTotalQuery,
	ConversationDailyQuery,
	ConversationTotalQuery,
	CpmTotalQuery,
	EngagementDailyQuery,
	EngagementTotalQuery,
	ImpressionDailyQuery,
	ImpressionMonthlyQuery,
	ImpressionsTotalQuery,
	InfluencerTotalQuery,
	InfluencerListAssignmentsQuery,
	ListInfluencerAssignmentsQuery,
	CountryQuery,
	InvoiceDailyQuery,
	InvoiceTotalQuery,
	TotalMediaObjectsPerAssignmentTypeQuery,
	TotalPerAssignmentQuery,
	TotalMediaObjectsPerCampaignQuery,
	TotalMediaObjectsPerCountryQuery,
	TotalMediaObjectsPerInfluencerQuery,
	ReachDailyQuery,
	StatisticsReachGrosstotalQuery,
	ReachMonthlyQuery,
	ReachTotalQuery,
	SummaryQuery,
} from 'api-queries';
import {
	StatisticsAdminStatisticsPerMonthCollectionResponse,
	StatisticsNameAndCountCollectionResponse,
	StatisticsAgeSummaryResponse,
	StatisticsCountrySummaryCollectionResponse,
	StatisticsGenderSummaryResponse,
	StatisticsCampaignSummaryCollectionResponse,
	StatisticsDateAndCountCollectionResponse,
	CountResponse,
	StatisticsDateAndConversionCollectionResponse,
	StatisticsConversionSummaryResponse,
	MoneyResponse,
	StatisticsDateAndPostEngagementCollectionResponse,
	StatisticsEngagementResponse,
	StatisticsYearMonthAndCountCollectionResponse,
	StatisticsInfluencerWithStatisticsCollectionResponse,
	StatisticsAssignmentSummaryCollectionResponse,
	StatisticsInfluencerAssignmentSummaryCollectionResponse,
	StatisticsCountryAndMoneyCollectionResponse,
	StatisticsDateAndMoneyCollectionResponse,
	StatisticsDecimalResponse,
	StatisticsSummaryResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import StatisticsApiClientInterface from './StatisticsApiClientInterface';

@injectable()
export default class StatisticsApiClient implements StatisticsApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Monthly
	 */
	async monthly(params: { include?: string }): Promise<StatisticsAdminStatisticsPerMonthCollectionResponse> {
		const response = await this.client.get<StatisticsAdminStatisticsPerMonthCollectionResponse>(`/statistics/admin/monthly`, { params });
		return response.data;
	}

	/**
	 * Total per assignment type
	 */
	async totalAssignmentPerAssignmentType(params: TotalAssignmentPerAssignmentTypeQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/assignments/assignment-types`, { params });
		return response.data;
	}

	/**
	 * Total per campaign
	 */
	async totalAssignmentPerCampaign(params: TotalAssignmentPerCampaignQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/assignments/campaigns`, { params });
		return response.data;
	}

	/**
	 * Total per country
	 */
	async totalAssignmentPerCountry(params: TotalAssignmentPerCountryQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/assignments/countries`, { params });
		return response.data;
	}

	/**
	 * Total per influencer
	 */
	async totalAssignmentPerInfluencer(params: TotalAssignmentPerInfluencerQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/assignments/influencers`, { params });
		return response.data;
	}

	/**
	 * Age
	 */
	async audienceAge(params: StatisticsAudienceAgeQuery): Promise<StatisticsAgeSummaryResponse> {
		const response = await this.client.get<StatisticsAgeSummaryResponse>(`/statistics/audience/age`, { params });
		return response.data;
	}

	/**
	 * Country
	 */
	async audienceCountry(params: StatisticsAudienceCountryQuery): Promise<StatisticsCountrySummaryCollectionResponse> {
		const response = await this.client.get<StatisticsCountrySummaryCollectionResponse>(`/statistics/audience/country`, { params });
		return response.data;
	}

	/**
	 * Gender
	 */
	async audienceGender(params: StatisticsAudienceGenderQuery): Promise<StatisticsGenderSummaryResponse> {
		const response = await this.client.get<StatisticsGenderSummaryResponse>(`/statistics/audience/gender`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async campaignTotal(params: CampaignTotalQuery): Promise<StatisticsCampaignSummaryCollectionResponse> {
		const response = await this.client.get<StatisticsCampaignSummaryCollectionResponse>(`/statistics/campaigns/total`, { params });
		return response.data;
	}

	/**
	 * Daily
	 */
	async clickDaily(params: ClickDailyQuery): Promise<StatisticsDateAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsDateAndCountCollectionResponse>(`/statistics/clicks/daily`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async clickTotal(params: ClickTotalQuery): Promise<CountResponse> {
		const response = await this.client.get<CountResponse>(`/statistics/clicks/total`, { params });
		return response.data;
	}

	/**
	 * Daily
	 */
	async conversationDaily(params: ConversationDailyQuery): Promise<StatisticsDateAndConversionCollectionResponse> {
		const response = await this.client.get<StatisticsDateAndConversionCollectionResponse>(`/statistics/conversions/daily`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async conversationTotal(params: ConversationTotalQuery): Promise<StatisticsConversionSummaryResponse> {
		const response = await this.client.get<StatisticsConversionSummaryResponse>(`/statistics/conversions/total`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async cpmTotal(params: CpmTotalQuery): Promise<MoneyResponse> {
		const response = await this.client.get<MoneyResponse>(`/statistics/cpm/total`, { params });
		return response.data;
	}

	/**
	 * Daily
	 */
	async engagementDaily(params: EngagementDailyQuery): Promise<StatisticsDateAndPostEngagementCollectionResponse> {
		const response = await this.client.get<StatisticsDateAndPostEngagementCollectionResponse>(`/statistics/engagement/daily`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async engagementTotal(params: EngagementTotalQuery): Promise<StatisticsEngagementResponse> {
		const response = await this.client.get<StatisticsEngagementResponse>(`/statistics/engagement/total`, { params });
		return response.data;
	}

	/**
	 * Age
	 */
	async audienceAge3(params: StatisticsAudienceAgeQuery): Promise<StatisticsAgeSummaryResponse> {
		const response = await this.client.get<StatisticsAgeSummaryResponse>(`/statistics/impression/age`, { params });
		return response.data;
	}

	/**
	 * Country
	 */
	async audienceCountry3(params: StatisticsAudienceCountryQuery): Promise<StatisticsCountrySummaryCollectionResponse> {
		const response = await this.client.get<StatisticsCountrySummaryCollectionResponse>(`/statistics/impression/country`, { params });
		return response.data;
	}

	/**
	 * Gender
	 */
	async audienceGender3(params: StatisticsAudienceGenderQuery): Promise<StatisticsGenderSummaryResponse> {
		const response = await this.client.get<StatisticsGenderSummaryResponse>(`/statistics/impression/gender`, { params });
		return response.data;
	}

	/**
	 * Age
	 */
	async audienceAge4(params: StatisticsAudienceAgeQuery): Promise<StatisticsAgeSummaryResponse> {
		const response = await this.client.get<StatisticsAgeSummaryResponse>(`/statistics/impressions/age`, { params });
		return response.data;
	}

	/**
	 * Country
	 */
	async audienceCountry4(params: StatisticsAudienceCountryQuery): Promise<StatisticsCountrySummaryCollectionResponse> {
		const response = await this.client.get<StatisticsCountrySummaryCollectionResponse>(`/statistics/impressions/country`, { params });
		return response.data;
	}

	/**
	 * Daily
	 */
	async impressionDaily(params: ImpressionDailyQuery): Promise<StatisticsDateAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsDateAndCountCollectionResponse>(`/statistics/impressions/daily`, { params });
		return response.data;
	}

	/**
	 * Gender
	 */
	async audienceGender4(params: StatisticsAudienceGenderQuery): Promise<StatisticsGenderSummaryResponse> {
		const response = await this.client.get<StatisticsGenderSummaryResponse>(`/statistics/impressions/gender`, { params });
		return response.data;
	}

	/**
	 * Monthly
	 */
	async impressionMonthly(params: ImpressionMonthlyQuery): Promise<StatisticsYearMonthAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsYearMonthAndCountCollectionResponse>(`/statistics/impressions/monthly`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async impressionsTotal(params: ImpressionsTotalQuery): Promise<CountResponse> {
		const response = await this.client.get<CountResponse>(`/statistics/impressions/total`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async influencerTotal(params: InfluencerTotalQuery): Promise<StatisticsInfluencerWithStatisticsCollectionResponse> {
		const response = await this.client.get<StatisticsInfluencerWithStatisticsCollectionResponse>(`/statistics/influencers/total`, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async influencerListAssignments(id: string, params: InfluencerListAssignmentsQuery): Promise<StatisticsAssignmentSummaryCollectionResponse> {
		const response = await this.client.get<StatisticsAssignmentSummaryCollectionResponse>(`/statistics/influencers/${id}/assignments`, { params });
		return response.data;
	}

	/**
	 * List influencer assignments
	 */
	async listInfluencerAssignments(id: string, params: ListInfluencerAssignmentsQuery): Promise<StatisticsInfluencerAssignmentSummaryCollectionResponse> {
		const response = await this.client.get<StatisticsInfluencerAssignmentSummaryCollectionResponse>(`/statistics/influencers/${id}/influencer-assignments`, {
			params,
		});
		return response.data;
	}

	/**
	 * Country
	 */
	async country(params: CountryQuery): Promise<StatisticsCountryAndMoneyCollectionResponse> {
		const response = await this.client.get<StatisticsCountryAndMoneyCollectionResponse>(`/statistics/invoice/country`, { params });
		return response.data;
	}

	/**
	 * Daily
	 */
	async invoiceDaily(params: InvoiceDailyQuery): Promise<StatisticsDateAndMoneyCollectionResponse> {
		const response = await this.client.get<StatisticsDateAndMoneyCollectionResponse>(`/statistics/invoice/daily`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async invoiceTotal(params: InvoiceTotalQuery): Promise<MoneyResponse> {
		const response = await this.client.get<MoneyResponse>(`/statistics/invoice/total`, { params });
		return response.data;
	}

	/**
	 * Total per assignment type
	 */
	async totalMediaObjectsPerAssignmentType(params: TotalMediaObjectsPerAssignmentTypeQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/media-objects/assignment-types`, { params });
		return response.data;
	}

	/**
	 * Total per assignment
	 */
	async totalPerAssignment(params: TotalPerAssignmentQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/media-objects/assignments`, { params });
		return response.data;
	}

	/**
	 * Total per campaign
	 */
	async totalMediaObjectsPerCampaign(params: TotalMediaObjectsPerCampaignQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/media-objects/campaigns`, { params });
		return response.data;
	}

	/**
	 * Total per country
	 */
	async totalMediaObjectsPerCountry(params: TotalMediaObjectsPerCountryQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/media-objects/countries`, { params });
		return response.data;
	}

	/**
	 * Total per influencer
	 */
	async totalMediaObjectsPerInfluencer(params: TotalMediaObjectsPerInfluencerQuery): Promise<StatisticsNameAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsNameAndCountCollectionResponse>(`/statistics/media-objects/influencers`, { params });
		return response.data;
	}

	/**
	 * Age
	 */
	async audienceAge2(params: StatisticsAudienceAgeQuery): Promise<StatisticsAgeSummaryResponse> {
		const response = await this.client.get<StatisticsAgeSummaryResponse>(`/statistics/reach/age`, { params });
		return response.data;
	}

	/**
	 * Country
	 */
	async audienceCountry2(params: StatisticsAudienceCountryQuery): Promise<StatisticsCountrySummaryCollectionResponse> {
		const response = await this.client.get<StatisticsCountrySummaryCollectionResponse>(`/statistics/reach/country`, { params });
		return response.data;
	}

	/**
	 * Daily
	 */
	async reachDaily(params: ReachDailyQuery): Promise<StatisticsDateAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsDateAndCountCollectionResponse>(`/statistics/reach/daily`, { params });
		return response.data;
	}

	/**
	 * Gender
	 */
	async audienceGender2(params: StatisticsAudienceGenderQuery): Promise<StatisticsGenderSummaryResponse> {
		const response = await this.client.get<StatisticsGenderSummaryResponse>(`/statistics/reach/gender`, { params });
		return response.data;
	}

	/**
	 * Gross total
	 */
	async reachGrosstotal(params: StatisticsReachGrosstotalQuery): Promise<StatisticsDecimalResponse> {
		const response = await this.client.get<StatisticsDecimalResponse>(`/statistics/reach/gross-total`, { params });
		return response.data;
	}

	/**
	 * Monthly
	 */
	async reachMonthly(params: ReachMonthlyQuery): Promise<StatisticsYearMonthAndCountCollectionResponse> {
		const response = await this.client.get<StatisticsYearMonthAndCountCollectionResponse>(`/statistics/reach/monthly`, { params });
		return response.data;
	}

	/**
	 * Gross total
	 */
	async reachGrosstotal1(params: StatisticsReachGrosstotalQuery): Promise<StatisticsDecimalResponse> {
		const response = await this.client.get<StatisticsDecimalResponse>(`/statistics/reach/per-followers`, { params });
		return response.data;
	}

	/**
	 * Total
	 */
	async reachTotal(params: ReachTotalQuery): Promise<CountResponse> {
		const response = await this.client.get<CountResponse>(`/statistics/reach/total`, { params });
		return response.data;
	}

	/**
	 * Summary
	 */
	async summary(params: SummaryQuery): Promise<StatisticsSummaryResponse> {
		const response = await this.client.get<StatisticsSummaryResponse>(`/statistics/summary`, { params });
		return response.data;
	}

	/**
	 * Age
	 */
	async audienceAge1(params: StatisticsAudienceAgeQuery): Promise<StatisticsAgeSummaryResponse> {
		const response = await this.client.get<StatisticsAgeSummaryResponse>(`/statistics/traffic/age`, { params });
		return response.data;
	}

	/**
	 * Country
	 */
	async audienceCountry1(params: StatisticsAudienceCountryQuery): Promise<StatisticsCountrySummaryCollectionResponse> {
		const response = await this.client.get<StatisticsCountrySummaryCollectionResponse>(`/statistics/traffic/country`, { params });
		return response.data;
	}

	/**
	 * Gender
	 */
	async audienceGender1(params: StatisticsAudienceGenderQuery): Promise<StatisticsGenderSummaryResponse> {
		const response = await this.client.get<StatisticsGenderSummaryResponse>(`/statistics/traffic/gender`, { params });
		return response.data;
	}
}
