import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty, isNil, isEqual } from 'lodash';
import { useState, ChangeEvent, useEffect } from 'react';

import NumberInput from 'components/AffiliateMarketplaceCampaignForm/NumberInput';
import TextareaV2 from 'components/AffiliateMarketplaceCampaignForm/TextareaV2';
import UploadImage from 'components/AffiliateMarketplaceCampaignForm/UploadImage';
import { CreationFormData } from 'components/AffiliateMarketplaceCampaignForm/types';
import { calculateCollabsCut, fetchOgMeta } from 'components/AffiliateMarketplaceCampaignForm/utils';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './CreationForm.style';

type Props = {
	isLoading?: boolean;
	setIsEdited?: (value: boolean) => void;
};

/**
 * @returns {JSX.Element}
 */
const CreationForm = ({ setIsEdited, isLoading }: Props) => {
	const [isFetchingOgData, setIsFetchingOgData] = useState<boolean>(false);
	const { initialValues, values, setFieldValue, setFieldTouched, errors, touched, isSubmitting } = useFormikContext<CreationFormData>();

	useEffect(() => {
		if (!isNil(setIsEdited)) {
			if (isEqual(initialValues, values)) {
				setIsEdited(false);
			} else {
				setIsEdited(true);
			}
		}
	}, [values]);

	const changeNumberValueWithButton = (fieldName: string, enteredValue: number) => {
		setFieldValue(fieldName, enteredValue);
	};

	useEffect(() => {
		if (isSubmitting) {
			setIsFetchingOgData(true);
		} else {
			setIsFetchingOgData(false);
		}
	}, [isSubmitting]);

	return (
		<Styled.Wrapper>
			{isLoading ? (
				<LoadingSpinner position='center' />
			) : (
				<>
					<Styled.BlockTitle>Background</Styled.BlockTitle>
					<Styled.BlockCard>
						<Styled.InputContainer>
							<Field
								label='Name your campaign (visible to the influencer)'
								validationState={errors.name && touched.name ? 'error' : undefined}
								validationMessage={errors.name}
							>
								<Input
									placeholder='Add title'
									data-testid='name'
									name='name'
									value={values.name}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setFieldValue('name', e.target.value);
									}}
									onBlur={() => {
										setFieldTouched('name', true);
									}}
								/>
							</Field>
						</Styled.InputContainer>

						<Styled.Divider />

						<Styled.InputContainer>
							<h6>Campaign links</h6>
							<div className='program-id'>
								<Field
									label='Add program ID (Adtraction)'
									validationState={errors.adtractionId && touched.adtractionId ? 'error' : undefined}
									validationMessage={errors.adtractionId}
								>
									<Input
										placeholder='Add program ID here'
										name='adtractionId'
										data-testid='adtractionId'
										value={values.adtractionId}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setFieldValue('adtractionId', e.target.value);
										}}
										onBlur={() => {
											setFieldTouched('adtractionId', true);
										}}
									/>
								</Field>
							</div>

							<div className='store-link'>
								<Field
									label='Add store link'
									validationState={errors.storeLink && touched.storeLink ? 'error' : undefined}
									validationMessage={errors.storeLink}
								>
									<Input
										placeholder='Add URL here'
										name='storeLink'
										data-testid='storeLink'
										value={values.storeLink}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setFieldValue('storeLink', e.target.value);
										}}
										onBlur={async (e) => {
											setFieldTouched('storeLink', true);
											if (values.storeLink) {
												if (!values.coverImage) {
													setIsFetchingOgData(true);
												}
												await fetchOgMeta(e.target.value)
													.then((image) => {
														setFieldValue('ogMetaImage', image);
														if (isEmpty(image) || isNil(image)) {
															setFieldTouched('coverImage', true);
														}
													})
													.finally(() => {
														setIsFetchingOgData(false);
													});
											}
										}}
									/>
								</Field>
							</div>
						</Styled.InputContainer>
						<Styled.Divider />

						<div className='cover-image'>
							<UploadImage
								isLoading={isFetchingOgData}
								ogMetaImage={values.ogMetaImage}
								value={values.coverImage as string}
								setValue={setFieldValue}
								fieldName='coverImage'
								onDelete={async () => {}}
								helpText={<Styled.HelpText>Supported formats include JPEG, PNG and HEIC.</Styled.HelpText>}
							/>
							{errors.coverImage && touched.coverImage ? <Styled.ErrorMessage className='error'>{errors.coverImage}</Styled.ErrorMessage> : null}
						</div>
						<Styled.Divider />

						<Styled.InputContainer>
							<h6>Commission</h6>

							<Styled.CommissionWrapper>
								<div>
									<Styled.Label>Total commission</Styled.Label>
									<NumberInput
										id='storeCommissionPercentage'
										name='storeCommissionPercentage'
										value={values.storeCommissionPercentage}
										onChangeWithButton={changeNumberValueWithButton}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setFieldValue('storeCommissionPercentage', e.target.value);
										}}
										min={0}
										max={100}
										unit='%'
										onBlur={() => {
											setFieldTouched('storeCommissionPercentage', true);
										}}
										step='0.01'
									/>
									{errors.storeCommissionPercentage && touched.storeCommissionPercentage ? (
										<Styled.ErrorMessage className='error'>{errors.storeCommissionPercentage}</Styled.ErrorMessage>
									) : null}
								</div>

								<div>
									<Styled.Label>Influencer commission</Styled.Label>

									<NumberInput
										id='influencerCommissionPercentage'
										name='influencerCommissionPercentage'
										value={values.influencerCommissionPercentage}
										onChangeWithButton={changeNumberValueWithButton}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setFieldValue('influencerCommissionPercentage', e.target.value);
										}}
										min={0}
										max={values.storeCommissionPercentage || 0}
										unit='%'
										onBlur={() => {
											setFieldTouched('influencerCommissionPercentage', true);
										}}
										step='0.01'
									/>
									{errors.influencerCommissionPercentage && touched.influencerCommissionPercentage ? (
										<Styled.ErrorMessage className='error'>{errors.influencerCommissionPercentage}</Styled.ErrorMessage>
									) : null}
								</div>

								<div className='commission-result'>
									<div className='equal-sign'>=</div>
									<div>
										<Styled.Label>Collabs Cut</Styled.Label>
										<Styled.CollabsCutBox>
											{calculateCollabsCut(values.storeCommissionPercentage, values.influencerCommissionPercentage)} %
										</Styled.CollabsCutBox>
									</div>
								</div>
							</Styled.CommissionWrapper>
						</Styled.InputContainer>
					</Styled.BlockCard>

					<Styled.BlockTitle>Details</Styled.BlockTitle>
					<Styled.BlockCard>
						<Styled.InputContainer>
							<Styled.Label>Campaign background</Styled.Label>
							<TextareaV2
								value={values.background || ''}
								setValue={(enteredValue: string) => setFieldValue('background', enteredValue)}
								placeholder='Describe the purpose for the influencer'
								onBlur={() => {
									setFieldTouched('background', true);
								}}
							/>
							{errors.background && touched.background ? <Styled.ErrorMessage className='error'>{errors.background}</Styled.ErrorMessage> : null}
						</Styled.InputContainer>

						<Styled.InputContainer>
							<Styled.Label>Posting Guidelines</Styled.Label>
							<TextareaV2
								value={values.guidelines || ''}
								setValue={(enteredValue: string) => setFieldValue('guidelines', enteredValue)}
								placeholder='Write posting guidelines for the influencer'
								onBlur={() => {
									setFieldTouched('guidelines', true);
								}}
							/>
							{errors.guidelines && touched.guidelines ? <Styled.ErrorMessage className='error'>{errors.guidelines}</Styled.ErrorMessage> : null}
						</Styled.InputContainer>

						<Styled.InputContainer>
							<Styled.Label>Sorting (The lower value the higher in the list)</Styled.Label>
							<div className='input--number'>
								<Styled.InputFieldContainer>
									<Styled.InputField
										placeholder='0'
										name='sort'
										type='number'
										data-testid='sort'
										value={values.sort}
										className={classNames({ 'white-background': !isNil(values.sort) })}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setFieldValue('sort', parseInt(e.target.value));
										}}
										onBlur={() => {
											setFieldTouched('sort', true);
										}}
									/>
								</Styled.InputFieldContainer>
							</div>
						</Styled.InputContainer>
					</Styled.BlockCard>
					<Styled.EmptyDiv />
				</>
			)}
		</Styled.Wrapper>
	);
};

export default CreationForm;
