import styled from 'styled-components';

import { CTALink } from 'styles/utils/CTALink';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.m};
`;

const ModalHeader = styled.div`
	display: flex;

	button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;
	}
`;

const PostItemWrapper = styled.div`
	max-height: 400px;
	overflow-y: auto;
`;

const PostItem = styled.div`
	width: 100%;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DateInfo = styled.div`
	font-size: ${typography.label.small.fontSize};
	color: ${colors.labelColor};
	padding: 0 ${guttersWithRem.xs};
`;

const LinkToPost = styled.a`
	text-decoration: none;
	border: none;
	${CTALink};
	color: ${colors.button.sencondary.color};
`;

const Reason = styled.div`
	span {
		font-size: 0.9375rem;
	}
`;

const Styled = {
	ContentWrapper,
	ModalHeader,
	PostItemWrapper,
	PostItem,
	DateInfo,
	LinkToPost,
	Reason,
};

export default Styled;
