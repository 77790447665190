import { Total, OverTime, ByInfluencerContainer } from 'components/DataLibrary/Graphs/GraphComponents';
import { TRAFFIC } from 'constants/data-library';
import colors from 'styles/variables/colors';

/**
 * @returns object
 */
const TrafficGraphs = (): object => {
	const graphItems = {
		category: 'Traffic',
		items: [
			{
				title: TRAFFIC.TOTAL_CLICKS_TITLE,
				icon: TRAFFIC.TOTAL_CLICKS_ICON,
				id: TRAFFIC.TOTAL_CLICKS,
				component: <Total title={TRAFFIC.TOTAL_CLICKS_TITLE} chartType={TRAFFIC.TOTAL_CLICKS} />,
			},
			{
				title: TRAFFIC.CLICKS_OVERTIME_TITLE,
				icon: TRAFFIC.CLICKS_OVERTIME_ICON,
				id: TRAFFIC.CLICKS_OVERTIME,
				component: <OverTime title={TRAFFIC.CLICKS_OVERTIME_TITLE} color={colors.chartLineTraffic} isHoverable={true} chartType={TRAFFIC.CLICKS_OVERTIME} />,
			},
			{
				title: TRAFFIC.CTR_TITLE,
				icon: TRAFFIC.CTR_ICON,
				id: TRAFFIC.CTR,
				component: <Total title={TRAFFIC.CTR_TITLE} chartType={TRAFFIC.CTR} />,
			},
			{
				title: TRAFFIC.BY_INFLUENCER_TITLE,
				icon: TRAFFIC.BY_INFLUENCER_ICON,
				id: TRAFFIC.BY_INFLUENCER,
				component: <ByInfluencerContainer title={TRAFFIC.BY_INFLUENCER_TITLE} isTopThree={true} chartType={TRAFFIC.BY_INFLUENCER} valueType='clicks' />,
			},
			{
				title: TRAFFIC.CTR_BY_INFLUENCER_TITLE,
				icon: TRAFFIC.CTR_BY_INFLUENCER_ICON,
				id: TRAFFIC.CTR_BY_INFLUENCER,
				component: <ByInfluencerContainer title={TRAFFIC.CTR_BY_INFLUENCER_TITLE} isTopThree={true} chartType={TRAFFIC.CTR_BY_INFLUENCER} valueType='clicks' />,
			},
		],
	};
	return graphItems;
};
export default TrafficGraphs;
