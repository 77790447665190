import styled from 'styled-components';

import { ButtonsWrapperStyle } from 'components/AffiliateMarketplace/shared.style';
import colors from 'styles/variables/colors';
import { devices, devicesMax } from 'styles/variables/media-queries';
import { px, rem } from 'styles/variables/spacings';

const AffiliateCard = styled.div`
	cursor: pointer;
	border-radius: 10px;
	border: 1px solid ${colors.AffiliateCard.border};
	background: ${colors.AffiliateCard.background};
	overflow: hidden;

	&.disabled {
		cursor: unset;
	}

	&:focus {
		outline: none;
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	}
`;

const ImageWrapper = styled.div`
	width: 100%;
	height: 150px;
	margin-bottom: ${px[3]}px;
	overflow: hidden;

	@media screen and (${devices.md}) {
		height: 304px;
	}

	img {
		border-radius: 10px;
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 100%;
	}
`;

const Body = styled.div`
	background-color: ${colors.AffiliateCard.bodyBackground};
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding-bottom: ${rem[2]}rem;
`;

const BodyInner = styled.div`
	padding: 0 ${rem[2]}rem;
`;

const NameWrapper = styled.div`
	display: flex;
	font-weight: 600;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${px[1]}px;
	width: 100%;

	.text {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.5;
	}

	.save {
		margin-left: auto;
		border: 0;
		padding: 0;
		background-color: transparent;
		cursor: pointer;
	}

	@media screen and (${devices.md}) {
		margin-bottom: ${px[3]}px;
	}
`;

const PercentageText = styled.div`
	.percentage-text__value {
		font-weight: 600;
	}
`;

const DetailsWrapper = styled.div`
	padding: ${rem[2]}rem;
	background-color: ${colors.AffiliateCard.detailsBackground};
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	text-align: center;
	font-size: 0.875rem;
	font-weight: 500;

	@media screen and (${devices.md}) {
		padding: ${rem[3]}rem ${rem[2]}rem;
	}
`;

const ButtonWrapper = styled.div`
	${ButtonsWrapperStyle};
	margin-bottom: 0;

	button {
		@media screen and (${devicesMax.sm}) {
			height: auto;
			font-size: 0.875rem;
		}
	}
`;

const Styled = {
	AffiliateCard,
	Body,
	ImageWrapper,
	BodyInner,
	NameWrapper,
	ButtonWrapper,
	DetailsWrapper,
	PercentageText,
};

export default Styled;
