import { PropsWithChildren } from 'react';

import Styled from './StatusTag.styles';

type Props = {
	status: string;
};

const StatusTag = (props: PropsWithChildren<Props>) => {
	const { status, children } = props;
	const getStatus = (status: string) => {
		return status === 'unpaidInvoice' ? 'Invoice not paid' : status ? status.charAt(0).toUpperCase() + status.slice(1) : null;
	};

	return (
		<>
			{status && (
				<Styled.CustomStatusTag status={status} data-testid='status-tag'>
					{children ?? getStatus(status)}
				</Styled.CustomStatusTag>
			)}
		</>
	);
};

export default StatusTag;
