const fontFamilies = {
	Epilogue: `'Epilogue', sans-serif`,
	SpaceMono: `'Space Mono', monospace`,
};

const typography = {
	fontFamily: `"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
  sans-serif`,
	BaseFontFamiliy: fontFamilies.Epilogue,
	SecondaryFontFamiliy: fontFamilies.SpaceMono,

	//
	// Headings
	//
	headings: {
		fontFamily: fontFamilies.Epilogue,
		h1: {
			fontSize: '3.583rem',
			lineHeight: '0.84',
			fontWeight: '700',
		},
		h2: {
			fontSize: '2.986rem',
			lineHeight: '1.34',
			fontWeight: '500',
		},
		h3: {
			fontSize: '2.488rem',
			lineHeight: '1.41',
			fontWeight: '500',
		},
		h4: {
			fontSize: '2.074rem',
			lineHeight: '0.81',
			fontWeight: '700',
		},
		h5: {
			fontSize: '1.44rem',
			lineHeight: '1.17',
			fontWeight: '700',
		},
		h6: {
			fontSize: '1rem',
			lineHeight: '1.5',
			fontWeight: '600',
		},
	},

	//
	// Paragraphs
	//
	paragraphs: {
		fontFamily: fontFamilies.Epilogue,
		fontSize: '1rem',
		lineHeight: '1.5',
	},

	//
	// Button
	//
	button: {
		fontFamiliy: fontFamilies.Epilogue,

		small: {
			fontSize: '1rem',
		},
		medium: {
			fontSize: '1.25rem',
		},
		large: {
			fontSize: '1.375rem',
		},
	},

	//
	// Label
	//
	label: {
		fontFamily: fontFamilies.SpaceMono,
		medium: {
			fontSize: '1.25rem',
			lineHeight: '1.35',
		},
		small: {
			fontSize: '0.875rem',
			lineHeight: '1.5',
		},
	},

	//
	// List
	//
	list: {
		fontFamily: fontFamilies.Epilogue,
		fontSize: '1.2rem',
		fontWeight: '700',
		lineHeight: '1.41',
		medium: {
			fontSize: '1rem',
		},
		small: {
			fontSize: '0.875rem',
		},
		accessory: {
			fontFamilies: fontFamilies.Epilogue,
			fontWeight: '500',
		},
	},

	//
	// Input
	//
	input: {
		fontFamilies: fontFamilies.Epilogue,
		fontSize: '1rem',
		fontWeight: '500',
		lineHeight: '1.688rem',
	},

	//
	// Tab
	//
	dataLibraryTab: {
		fontFamilies: fontFamilies.Epilogue,
		fontSize: '1rem',
		fontWeight: '700',
	},
};

export default typography;
