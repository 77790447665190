import styled from 'styled-components';

import colors from 'styles/variables/colors';

const Wrapper = styled.div<{ items: number }>`
	display: flex;
	flex-direction: column;
	height: ${(prev) => `${prev.items * 60}px`};

	transition: height 0.5s ease-in-out;
	position: relative;
`;

const ShowAllButton = styled.button`
	position: absolute;
	bottom: -25px;

	background-color: transparent;
	align-self: center;
	width: fit-content;
	font-size: 0.875rem;
	border-bottom: 1px solid transparent;

	&:hover {
		border-color: ${colors.black};
	}
`;

const Styled = {
	Wrapper,
	ShowAllButton,
};

export default Styled;
