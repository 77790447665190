import classNames from 'classnames';
import { countries } from 'country-data';
import { isString } from 'lodash';
import { useState, useEffect, useContext } from 'react';

import Accordion from 'components/Accordion';
import Checkbox from 'components/Checkbox';
import Input from 'components/Discovery/Components/Input';
import DiscoveryContext from 'contexts/Discovery';
import toast from 'services/Toast';

import BrandAffiliationsFilter from './Components/BrandAffiliationsFilter';
import HashtagsFilter from './Components/HashtagsFilter';
import Styled from './FilterDrawer.style';

const rawCountriesData = countries.all;

const getTheCountryData = () => {
	const availableCountries = ['SWE', 'NOR', 'DNK', 'FIN', 'NLD', 'GRC', 'ESP', 'PRT', 'FRA'];
	const countryData: { [key: string]: { name: string; selected: boolean; show: boolean } } = {};

	rawCountriesData.forEach((country) => {
		if (country.alpha3 !== '' && availableCountries.includes(country.alpha3)) {
			const name = country.name;
			const code = country.alpha3;
			countryData[code] = { name: name, selected: false, show: true };
		}
	});
	return countryData;
};

/**
 */
const FilterDrawer = () => {
	const { filter, setFilter, changeBrandAffiliations, removeBrandAffiliations, updateGenderHandler, updateAudienceAgeRange, changeHashtags, removeHashtags } =
		useContext(DiscoveryContext);
	const [influencerMinAge, setInfluencerMinAge] = useState<number | null>(null);
	const [influencerMaxAge, setInfluencerMaxAge] = useState<number | null>(null);
	const [minEngagement, setMinEngagement] = useState<number | null>(null);
	const [maxEngagement, setMaxEngagement] = useState<number | null>(null);
	const [influencerGenderFilter, setInfluencerGenderFilter] = useState<{ [key: string]: boolean }>({
		female: false,
		male: false,
	});
	const [influencerCountryFilter, setInfluencerCountryFilter] = useState<{ [key: string]: { name: string; selected: boolean; show: boolean } }>(
		getTheCountryData(),
	);
	const [minFollower, setMinFollower] = useState<number | null>(null);
	const [maxFollower, setMaxFollower] = useState<number | null>(null);
	const [brandAffiliationFilter, setBrandAffiliationFilter] = useState<{ [key: string]: string[] }>({
		include: [],
		exclude: [],
	});
	const [hashtags, setHashtags] = useState<string[]>([]);

	const [audienceAgeRanges, setAudienceAgeRanges] = useState<{
		[key: string]: boolean;
	}>({
		'13-17': false,
		'18-24': false,
		'25-34': false,
		'35-44': false,
		'45-54': false,
		'55-64': false,
		'65+': false,
	});
	const [audienceGenderFilter, setAudienceGenderFilter] = useState<{ [key: string]: boolean }>({
		female: false,
		male: false,
	});
	const [audienceCountryFilter, setAudienceCountryFilter] = useState<{ [key: string]: { name: string; selected: boolean; show: boolean } }>(
		getTheCountryData(),
	);

	const selectOneCountry = (
		updateCountryFilter: (newValue: { [key: string]: { name: string; selected: boolean; show: boolean } }) => void,
		countryCode: string,
		countryFilter: { [key: string]: { name: string; selected: boolean; show: boolean } },
	) => {
		const newCountryFilter = { ...countryFilter };
		newCountryFilter[countryCode].selected = !newCountryFilter[countryCode].selected;
		updateCountryFilter(newCountryFilter);

		return updateFilter;
	};

	const updateFilter = () => {
		setFilter({
			...filter,
			minAge: influencerMinAge,
			maxAge: influencerMaxAge,
			genders: getGenders(influencerGenderFilter),
			countries: Object.keys(influencerCountryFilter)
				.filter((countryCode) => influencerCountryFilter[countryCode].selected)
				.join(','),
			minFollowers: minFollower,
			maxFollowers: maxFollower,
			minEI: minEngagement,
			maxEI: maxEngagement,
			brandAffiliations: brandAffiliationFilter.include ? brandAffiliationFilter.include.join(',') : null,
			excludedBrandAffiliations: brandAffiliationFilter.exclude ? brandAffiliationFilter.exclude.join(',') : null,
			audienceAgeRanges: getObjectKeys(audienceAgeRanges),
			audienceGenders: getGenders(audienceGenderFilter),
			audienceCountries: Object.keys(audienceCountryFilter)
				.filter((countryCode) => audienceCountryFilter[countryCode].selected)
				.join(','),
		});
	};

	const updateCountryFilter = (
		queryCountries: string[],
		currentFilter: { [key: string]: { name: string; selected: boolean; show: boolean } },
		newCountryData: { [key: string]: { name: string; selected: boolean; show: boolean } },
	) => {
		const activeCountries: { [key: string]: { name: string; selected: boolean; show: boolean } } = {};

		queryCountries.forEach((key: string) => {
			const countryCode = key.toLocaleUpperCase();
			const countryData = currentFilter[countryCode];

			if (countryData) {
				activeCountries[countryCode] = { name: countryData.name, selected: true, show: false };
			} else {
				console.warn(`Country code ${countryCode} not found in the filter`);
			}
		});

		return { ...newCountryData, ...activeCountries };
	};

	const getGenders = (value: { [key: string]: boolean } | null) => {
		if (value) {
			return Object.keys(value)
				.filter((genderKey) => genderKey !== 'unknown')
				.filter((genderKey) => value[genderKey])
				.map((gender) => {
					return getGender(gender);
				})
				.join(',');
		} else {
			return null;
		}
	};

	const getGender = (gender: string) => {
		switch (gender) {
			case 'female':
				return 'f';
			case 'male':
				return 'm';
			default:
				return '';
		}
	};

	const getGenderLabel = (gender: string) => {
		switch (gender) {
			case 'female':
				return 'Woman';
			case 'male':
				return 'Man';
			default:
				return '';
		}
	};

	const getObjectKeys = (value: { [key: string]: boolean }) => {
		return Object.keys(value)
			.filter((key) => value[key])
			.join(',');
	};

	const setDefaultFilter = (param: { [key: string]: string | number | null }) => {
		const { key, value } = param;
		if (key === 'minAge') {
			setInfluencerMinAge(value ? Number(value) : null);
		}
		if (key === 'maxAge') {
			setInfluencerMaxAge(value ? Number(value) : null);
		}
		if (key === 'genders') {
			const genders = value && isString(value) ? value.split(',') : [];
			const newGenderFilter = {
				female: genders.some((gender: string) => gender === 'f'),
				male: genders.some((gender: string) => gender === 'm'),
			};
			setInfluencerGenderFilter(newGenderFilter);
		}
		if (key === 'countries' && influencerCountryFilter) {
			const queryCountries = value && isString(value) ? value.split(',') : [];
			const updatedFilter = updateCountryFilter(queryCountries, influencerCountryFilter, getTheCountryData());
			setInfluencerCountryFilter(updatedFilter);
		}
		if (key === 'minFollowers') {
			setMinFollower(value ? Number(value) : null);
		}
		if (key === 'maxFollowers') {
			setMaxFollower(value ? Number(value) : null);
		}
		if (key === 'minEI') {
			setMinEngagement(value ? Number(value) : null);
		}
		if (key === 'maxEI') {
			setMaxEngagement(value ? Number(value) : null);
		}
		if (key === 'brandAffiliations') {
			setBrandAffiliationFilter((prev) => {
				return { ...prev, include: value && isString(value) ? value.split(',') : [] };
			});
		}
		if (key === 'excludedBrandAffiliations') {
			setBrandAffiliationFilter((prev) => {
				return { ...prev, exclude: value && isString(value) ? value.split(',') : [] };
			});
		}
		if (key === 'hashtags') {
			setHashtags(() => {
				return value && isString(value) ? value.split(',') : [];
			});
		}
		if (key === 'audienceAgeRanges') {
			const ages = value && isString(value) ? value.split(',') : [];
			setAudienceAgeRanges((prev) => {
				const updateAges: { [key: string]: boolean } = {
					'13-17': false,
					'18-24': false,
					'25-34': false,
					'35-44': false,
					'45-54': false,
					'55-64': false,
					'65+': false,
				};
				ages.forEach((age: string) => {
					if (age === '65') {
						updateAges['65+'] = true;
					} else {
						updateAges[age] = true;
					}
				});
				return { ...prev, ...updateAges };
			});
		}
		if (key === 'audienceGenders') {
			const genders = value && isString(value) ? value.split(',') : [];
			setAudienceGenderFilter({
				female: genders.some((gender: string) => gender === 'f'),
				male: genders.some((gender: string) => gender === 'm'),
			});
		}
		if (key === 'audienceCountries' && audienceCountryFilter) {
			const queryCountries = value && isString(value) ? value.split(',') : [];
			const updatedFilter = updateCountryFilter(queryCountries, audienceCountryFilter, getTheCountryData());
			setAudienceCountryFilter(updatedFilter);
		}
	};

	useEffect(() => {
		if (filter) {
			Object.entries(filter).forEach(([key, value]) => {
				setDefaultFilter({ key: key, value: value });
			});
		} else {
			setInfluencerGenderFilter({ female: false, male: false });
		}
	}, [filter]);

	return (
		<Styled.Wrapper>
			<Styled.InnerWrapper>
				<Styled.FilterItemWapper>
					<Accordion title='Audience age' className={classNames({ active: filter?.audienceAgeRanges })}>
						{Object.keys(audienceAgeRanges).map((ageRange) => {
							return (
								<div key={ageRange} className='checkbox-container'>
									<Checkbox
										className='textFirst'
										label={ageRange}
										onChange={() => {
											updateAudienceAgeRange(ageRange);
										}}
										checked={audienceAgeRanges[ageRange]}
									/>
								</div>
							);
						})}
					</Accordion>
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<Accordion title='Audience genders' className={classNames({ active: filter?.audienceGenders })}>
						{Object.keys(audienceGenderFilter).map((gender) => {
							return (
								<div className='checkbox-container' key={gender}>
									<Checkbox
										className='textFirst'
										label={getGenderLabel(gender)}
										onChange={() => {
											updateGenderHandler(gender, 'audience');
										}}
										checked={audienceGenderFilter[gender]}
									/>
								</div>
							);
						})}
					</Accordion>
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<Accordion title='Audience countries' className={classNames({ active: filter?.audienceGenders })}>
						<Styled.AccordionInnerWrapper className='countries-list'>
							{audienceCountryFilter &&
								Object.keys(audienceCountryFilter).map((item) => {
									return (
										<div className='checkbox-container' key={item}>
											<Checkbox
												className='textFirst'
												key={item}
												label={audienceCountryFilter[item].name}
												checked={audienceCountryFilter[item].selected}
												onChange={() => {
													const updateFilterFunction = selectOneCountry(setAudienceCountryFilter, item, audienceCountryFilter);
													updateFilterFunction();
												}}
											/>
										</div>
									);
								})}
						</Styled.AccordionInnerWrapper>
					</Accordion>
				</Styled.FilterItemWapper>

				{/* Influencer */}
				<Styled.FilterItemWapper>
					<Accordion title='Influencer age' className={classNames({ active: filter?.minAge || filter?.maxAge })}>
						<Styled.FollowersInputsWrapper>
							<Input
								label='min'
								type='text'
								min='16'
								pattern='[0-9]*'
								value={isNaN(Number(influencerMinAge)) ? '' : Number(influencerMinAge)}
								onChange={(e) => {
									setInfluencerMinAge(+e.target.value);
								}}
								onBlur={updateFilter}
							/>
							<Styled.BetweenMinMaxDiv>-</Styled.BetweenMinMaxDiv>
							<Input
								label='max'
								type='text'
								pattern='[0-9]*'
								value={isNaN(Number(influencerMaxAge)) ? '' : Number(influencerMaxAge)}
								onChange={(e) => {
									setInfluencerMaxAge(+e.target.value);
								}}
								onBlur={updateFilter}
							/>
						</Styled.FollowersInputsWrapper>
					</Accordion>
				</Styled.FilterItemWapper>

				<Styled.FilterItemWapper>
					<Accordion title='Influencer gender' className={classNames({ active: filter?.genders })}>
						{Object.keys(influencerGenderFilter).map((gender) => {
							return (
								<div key={gender} className='checkbox-container'>
									<Checkbox
										className='textFirst'
										label={getGenderLabel(gender)}
										onChange={() => {
											updateGenderHandler(gender, 'influencer');
										}}
										checked={influencerGenderFilter[gender]}
									/>
								</div>
							);
						})}
					</Accordion>
				</Styled.FilterItemWapper>

				<Styled.FilterItemWapper>
					<Accordion title='Influencer country' className={classNames({ active: filter?.countries })}>
						<Styled.AccordionInnerWrapper className='countries-list'>
							{influencerCountryFilter &&
								Object.keys(influencerCountryFilter).map((item) => {
									return (
										<div key={item} className='checkbox-container'>
											<Checkbox
												className='textFirst'
												label={influencerCountryFilter[item].name}
												checked={influencerCountryFilter[item].selected}
												onChange={() => {
													const updateFilterFunction = selectOneCountry(setInfluencerCountryFilter, item, influencerCountryFilter);
													updateFilterFunction();
												}}
											/>
										</div>
									);
								})}
						</Styled.AccordionInnerWrapper>
					</Accordion>
				</Styled.FilterItemWapper>

				<Styled.FilterItemWapper>
					<Accordion title='Followers' className={classNames({ active: filter?.maxFollowers || filter?.minFollowers })}>
						<Styled.FollowersInputsWrapper>
							<Input
								label='min'
								type='text'
								pattern='[0-9]*'
								value={isNaN(Number(minFollower)) ? '' : Number(minFollower)}
								onChange={(e) => {
									setMinFollower(+e.target.value);
								}}
								onBlur={updateFilter}
							/>
							<Styled.BetweenMinMaxDiv>-</Styled.BetweenMinMaxDiv>
							<Input
								label='max'
								type='text'
								pattern='[0-9]*'
								value={isNaN(Number(maxFollower)) ? '' : Number(maxFollower)}
								onChange={(e) => {
									setMaxFollower(+e.target.value);
								}}
								onBlur={updateFilter}
							/>
						</Styled.FollowersInputsWrapper>
					</Accordion>
				</Styled.FilterItemWapper>

				<Styled.FilterItemWapper>
					<Accordion title='Engagement' className={classNames({ active: filter?.minEI || filter?.maxEI })}>
						<Styled.FollowersInputsWrapper>
							<Input
								label='min'
								type='text'
								pattern='[0-9]*'
								value={isNaN(Number(minEngagement)) ? '' : Number(minEngagement)}
								onChange={(e) => {
									if (maxEngagement && +e.target.value > maxEngagement) {
										toast.error(`Min engagement can't be higher than max engagement`);
									} else {
										setMinEngagement(+e.target.value);
									}
								}}
								onBlur={updateFilter}
							/>
							<Styled.BetweenMinMaxDiv>-</Styled.BetweenMinMaxDiv>
							<Input
								label='max'
								type='text'
								pattern='[0-9]*'
								value={isNaN(Number(maxEngagement)) ? '' : Number(maxEngagement)}
								onChange={(e) => {
									if (minEngagement && +e.target.value < minEngagement) {
										toast.error(`Max engagement can't be lower than min engagement`);
									} else {
										setMaxEngagement(+e.target.value);
									}
								}}
								onBlur={updateFilter}
							/>
						</Styled.FollowersInputsWrapper>
					</Accordion>
				</Styled.FilterItemWapper>

				<Styled.FilterItemWapper>
					<Accordion title='Brand collaboration' className={classNames({ active: filter?.brandAffiliations })}>
						<BrandAffiliationsFilter brandAffiliations={brandAffiliationFilter} onSetFilter={changeBrandAffiliations} onRemoveBrand={removeBrandAffiliations} />
					</Accordion>
				</Styled.FilterItemWapper>

				<Styled.FilterItemWapper>
					<Accordion title='Hashtags'>
						<HashtagsFilter hashtags={hashtags} onSetFilter={changeHashtags} onRemoveHashtag={removeHashtags} />
					</Accordion>
				</Styled.FilterItemWapper>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default FilterDrawer;
