import classNames from 'classnames';
import { ReactNode } from 'react';

import Icon from 'components/Icon';

import Styled from './ErrorMessage.style';

export const ErrorMessage = ({ children, state = 'error' }: { children?: ReactNode; state?: 'error' | 'warning' }) => (
	<Styled.Wrapper
		className={classNames({
			'is-error': state === 'error',
			'is-warning': state === 'warning',
		})}
		data-testid='error-message'
	>
		<Icon name={state === 'warning' ? 'alert' : 'alert'} size='16' />
		<span>{children}</span>
	</Styled.Wrapper>
);

export default ErrorMessage;
