import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding-bottom: 1.875rem;
	background-color: ${({ theme }) => theme.colors.background};
`;

const Styled = {
	Wrapper,
};

export default Styled;
