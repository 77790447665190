import classNames from 'classnames';
import { useState, useEffect } from 'react';

import Icon from 'components/Icon';

import Styled from './AccordionV2.style';

type Props = {
	title: string;
	children: JSX.Element;
	open: boolean;
	className?: string;
	openIconName?: string;
	openedIconName?: string;
};

const AccordionV2 = ({ title, children, open, className, openIconName = 'add-circle', openedIconName = 'circle-cross' }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(open);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<Styled.Wrapper data-testid='accordion-v2-wrapper' className={classNames(className, { collapsed: !isOpen })} aria-expanded={isOpen}>
			<Styled.Header className='accordion__header' onClick={() => setIsOpen((prev) => !prev)}>
				<span className='accordion__title'>{title}</span>
				<Styled.ToggleIcon>
					<Icon name={isOpen ? openedIconName : openIconName} size='20' />
				</Styled.ToggleIcon>
			</Styled.Header>
			<Styled.Body className='accordion__body' onClick={(e) => e.stopPropagation()}>
				{children}
			</Styled.Body>
		</Styled.Wrapper>
	);
};

export default AccordionV2;
