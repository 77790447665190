import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import { InputFieldV2 } from 'styles/formElements/input';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import _colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const dl = _colors.dataLibrary;

const SummaryContainer = styled.div``;

const SummaryToggle = styled.div`
	padding: 2rem 0rem 1rem 0rem;
	display: flex;
	justify-content: center;
`;

const SummarToggleButton = styled.span`
	position: relative;
	font-size: 1rem;
	text-align: center;
	width: 100%;
	margin: auto;
	display: flex;
	cursor: pointer;
	justify-content: center;
	span {
		display: flex;
		align-items: center;
		width: 95px;
		justify-content: space-between;
	}
	.icon {
		transition-duration: 200ms;
		transition-property: transform;
	}
	&.expand {
		.icon {
			transform: rotate(-180deg);
		}
	}
	&::before {
		content: '';
		display: block;
		width: calc((100% / 2) - 70px);
		height: 0.5px;
		background: ${dl.black};
		left: 0;
		top: 50%;
		position: absolute;
	}
	&::after {
		content: '';
		display: block;
		width: calc((100% / 2) - 70px);
		height: 0.5px;
		background: ${dl.black};
		right: 0;
		top: 50%;
		position: absolute;
	}
`;

const SummaryContainerRow = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	min-height: 155px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease-in-out;
	&.expand {
		max-height: 1000px;
	}
	&.inPreview {
		margin-bottom: 3rem;
		height: auto;
		max-height: unset;
	}
`;

const SummaryContainerItem = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5rem 0rem;
	&.unAvailable {
		opacity: 0.5;
	}
`;

const EditButtonContainer = styled.div`
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${colors.gentleMeadow};
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 200ms ease-in-out;

	button {
		width: 100%;
		height: 100%;
		border: 0;
		background-color: transparent;
		line-height: 0;
	}
`;

const SummaryContainerItemBox = styled.div`
	position: relative;
	overflow: hidden;
	min-height: 56px;
	min-width: 56px;
	background-color: ${colors.paleGreenTint};
	border: 1px solid ${colors.lightMist};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${borderRadius.m};

	.icon {
		margin-top: 3px;
	}

	.editable {
		button {
			cursor: pointer;
		}
		&.editing,
		&:hover {
			${EditButtonContainer} {
				opacity: 1;
			}
		}
	}
`;

const SummaryContainerItemData = styled.div`
	display: flex;
	flex-direction: column;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	color: ${dl.black};
	margin-left: 1rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: default;
`;

const Title = styled.div`
	font-family: Epilogue;
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.4;
	letter-spacing: 0em;
	text-align: left;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	margin-bottom: 0.688rem;
`;

const Body = styled.div`
	overflow: hidden;
	max-height: 1000px;
	transition: max-height 1s ease-in-out;
	p:last-child {
		margin-bottom: 0;
	}
`;

const Wrapper = styled.div<{ border?: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	&[aria-expanded='false'] {
		${Body} {
			max-height: 250px;
			transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
		}
	}
	&.inPublic {
		${Body} {
			max-height: auto;
		}
	}
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const ToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	p {
		margin-right: 10px;
		margin-bottom: 0;
		font-family: ${typography.BaseFontFamiliy};
	}
`;

const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${_colors.gray11};
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 26px;
	&.checked {
		background-color: ${colors.gentleMeadow} !important;
		&::before {
			-webkit-transform: translateX(16px);
			-ms-transform: translateX(16px);
			transform: translateX(16px);
		}
	}
	&::before {
		border-radius: 50%;
		position: absolute;
		content: '';
		height: 16px;
		width: 16px;
		left: 4px;
		bottom: 4px;
		background-color: ${_colors.white};
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
`;

const ToggleSwitch = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
`;

const TextField = styled.input`
	${InputFieldV2};
	border: 1px solid #333;
	margin-bottom: 16px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	-moz-appearance: textfield;
`;

const CustomModal = styled(Modal)``;

const EditModalForm = styled.div`
	padding: 1rem 1.5rem;
`;

const ButtonGroup = styled.div`
	display: flex;
	width: 100%;
	gap: 8px;

	button:first-child {
		margin-left: auto;
	}
`;

const ErrorMessages = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const ErrorMessageItem = styled.li`
	color: ${_colors.error};
	font-size: 0.875rem;
`;

const Styled = {
	SummaryContainer,
	SummaryContainerRow,
	SummaryContainerItem,
	SummaryContainerItemBox,
	SummaryContainerItemData,
	SummaryToggle,
	SummarToggleButton,
	Title,
	Wrapper,
	Header,
	Body,
	SpinnerWrapper,
	ToggleWrapper,
	ToggleSwitch,
	Slider,
	TextField,
	EditModalForm,
	ButtonGroup,
	EditButtonContainer,
	CustomModal,
	ErrorMessages,
	ErrorMessageItem,
};

export default Styled;
