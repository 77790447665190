import { Field } from 'formik';
import styled from 'styled-components';

import SelectMenu from 'components/SelectMenu';
import { InputFieldContainer, InputFieldTooltip, InputFieldIcon } from 'styles/formElements/input';
import { InputType } from 'styles/formElements/types';
import { Scale } from 'styles/theme/types';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

export const Input = styled(Field)<InputType & { scale: Scale }>`
	background-color: transparent;
	border: none;
	width: 100%;
	color: ${({ theme }) => theme.colors.text};
	padding-top: 0.25rem;
	font-size: ${({ theme, scale }) => {
		switch (scale) {
			case 'small':
				return theme.fontSizes.smallInput;
			case 'large':
				return theme.fontSizes.largeInput;
			default:
				return theme.fontSizes.input;
		}
	}};

	&::placeholder {
		color: ${({ theme }) => theme.colors.placeholder};
	}

	&:disabled::placeholder {
		color: ${({ theme }) => theme.colors.textDisabled};
	}

	&:read-only {
		cursor: default;
	}

	&:focus,
	&:focus-visible {
		border: 0;
		box-shadow: none;
		outline: none;
	}
`;

const InputContainer = styled.div<InputType>`
	${InputFieldContainer};
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	textarea {
		display: flex;
		align-items: center;
		background-color: ${({ theme }) => theme.colors.inputs};
		border-radius: ${({ theme }) => theme.radius.small};
		border: 1px solid ${({ theme }) => theme.borders.secondary};
		transition: border-color 0.2s ease-in-out;
		padding: 0.55rem 0.625rem;

		&:hover:not(:disabled) {
			border-color: ${({ theme }) => theme.borders.hover};
		}

		&:focus {
			border-color: ${({ theme }) => theme.borders.focus} !important;
			outline: none;
		}
	}
`;

const Tooltip = styled.div`
	${InputFieldTooltip};
`;

const Icon = styled.div<InputType>`
	${InputFieldIcon};
`;

const Action = styled.div<InputType>``;

const ErrorMessage = styled.div`
	margin-top: 5px;
	background-color: ${colors.errorLight};
	border-radius: ${borderRadius.s};
	padding: ${spacing[1].rem};
	color: ${colors.errorDarkRed};
	font-size: 0.75rem;
	display: flex;
	span {
		margin-top: 2px;
	}
	.icon {
		margin-right: 5px;
	}
`;

const BorderBefore = styled.div`
	&.showBorder {
		border-right: 1px solid ${colors.black};
		width: 1px;
		height: 50%;
	}
`;

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	&.w-50 {
		width: 50%;
	}
	&.mb-24 {
		margin-bottom: 24px;
	}
`;

const Label = styled.label`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	line-height: 1;
	margin-bottom: 8px;

	:first-letter {
		text-transform: capitalize;
	}
`;

const CustomSelectMenu = styled(SelectMenu)`
	.select-field__input {
		border: none !important;
		width: 100%;
	}
	.select-field__menu {
		margin-top: 4px;
	}

	.select-field__single-value {
		padding-top: 0.25rem;
	}

	.select-field__control {
		border: none !important;
		background: transparent;
		outline: none;
		height: 100%;
		width: 122px;

		padding-top: 0;
		padding-bottom: 0;

		&::placeholder {
			display: none;
		}
		&:hover {
			border: none !important;
			box-shadow: none !important;
		}
	}
`;

const InputNextToDropdown = styled.input`
	border: none;
	width: 100%;
	outline: none;
	background-color: ${({ theme }) => theme.colors.inputs};
	margin-left: 8px;
	padding-top: 0.25rem;
	line-height: 1.6;
	border-radius: ${({ theme }) => theme.radius.default};

	&:-internal-autofill-selected {
		background-color: none !important;
	}
	&:focus-within {
		appearance: none;
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
`;

const InputContainerWithSelect = styled.div`
	position: relative;
	display: flex;
	background-color: ${({ theme }) => theme.colors.inputs};
	border-radius: ${({ theme }) => theme.radius.small};
	border: 1px solid ${({ theme }) => theme.borders.secondary};
	transition: border-color 0.2s ease-in-out;
	height: 46px;

	&:hover:not(:disabled) {
		border-color: ${({ theme }) => theme.borders.hover};
	}

	&:focus-within {
		border-color: ${({ theme }) => theme.borders.focus} !important;
		outline: none;
	}
`;

const HoverSection = styled.div`
	display: flex;
	border-radius: ${borderRadius.s};

	&:hover {
		background-color: ${colors.form.hoverSelectDropdown};
	}
	position: relative;
	&:after {
		position: absolute;
		top: 5px;
		bottom: 5px;
		right: 0;
		width: 1px;

		content: ' ';
		border-right: 1px solid ${colors.gray11};
	}

	.select-field__dropdown-indicator .icon svg {
		width: 20px;
		height: 20px;
	}
`;

const HelpText = styled.div`
	margin-top: 8px;
	font-size: 0.75rem;
	color: ${({ theme }) => theme.colors.placeholder};
`;

const FieldContainer = styled.div`
	width: 100%;
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const Styled = {
	Row,
	CustomSelectMenu,
	InputNextToDropdown,
	InputContainerWithSelect,
	Input,
	Label,
	InputContainer,
	InputWrapper,
	Tooltip,
	Icon,
	ErrorMessage,
	BorderBefore,
	HoverSection,
	HelpText,
	Action,
	FieldContainer,
};

export default Styled;
