import { Model } from 'json-api-models';
import moment from 'moment';
import { useState } from 'react';

import { UserModel, InfluencerListFolderModel, InfluencerListListModel } from 'api-models';
import Icon from 'components/Icon';
import Styled from 'components/InfluencerManagement/InfluencerManagement.style';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { SideDrawer } from 'components/SideDrawer';
import { UserType } from 'reducers/UserReducers/types';

import Search from './Components/Search';

type ShareDrawerProps = {
	title: string;
	sidebarIsOpen: boolean;
	setSidebarIsOpen: (sidebarIsOpen: boolean) => void;
	name: string;
	nonShared: string;
	createdByMe: boolean;
	createdBy?: InfluencerListListModel;
	updatedAt: string;
	allUsers: Array<Model>;
	selectedIds: Array<string>;
	sharedWithUsers: Array<InfluencerListListModel | InfluencerListFolderModel | UserModel>;
	parentIsSharedWithUsers?: Array<InfluencerListListModel | InfluencerListFolderModel>;
	setSelectedUsers: (ids: Array<string>) => void;
	onSave: () => void;
	user: Pick<UserType, 'id' | 'name'>;
};

/**
 * Share drawer
 * @param ShareDrawerProps
 * @returns {JSX.Element}
 */
const ShareDrawer = ({
	title,
	sidebarIsOpen,
	setSidebarIsOpen,
	name,
	nonShared,
	createdByMe,
	createdBy,
	updatedAt,
	allUsers,
	sharedWithUsers,
	setSelectedUsers,
	selectedIds,
	onSave,
	parentIsSharedWithUsers,
	user,
}: ShareDrawerProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const parentIsSharedWithIds = parentIsSharedWithUsers?.map((user) => user.id);

	const select = (item: Model) => {
		setIsDisabled(false);
		const updatedIds = () => {
			if (selectedIds.includes(item.id)) {
				return selectedIds.filter((id) => id !== item.id);
			} else {
				return [...selectedIds, item.id];
			}
		};
		setSelectedUsers(updatedIds());
	};

	return (
		<SideDrawer
			isExpandable
			title={title}
			sidebarIsOpen={sidebarIsOpen}
			onClose={() => setSidebarIsOpen(false)}
			saveButtonText='Save'
			onSave={() => onSave()}
			dataTestId='list-drawer'
			isDisabled={isDisabled}
		>
			<Styled.DrawerContent>
				<Styled.ShareTitle>{name}</Styled.ShareTitle>
				{sharedWithUsers.filter((x) => user.id !== x.id).length === 0 && <Styled.Properties>{nonShared}</Styled.Properties>}
				<Search
					sharedWithUsers={sharedWithUsers.concat(parentIsSharedWithUsers ?? []) as unknown as Model[]}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					onSelect={select}
					allUsers={allUsers.filter((u) => u.id !== user.id)}
					selectedIds={selectedIds}
				/>
				<Styled.ScrollContent>
					{selectedIds.length > 0 && !isOpen && (
						<>
							<Styled.Properties>Members:</Styled.Properties>
							<Styled.TeamMembersContainer>
								{allUsers
									?.filter((u) => u.id !== user.id)
									.map((user, i) => {
										if (selectedIds.includes(user.id) && !parentIsSharedWithIds?.includes(user.id)) {
											return (
												<Styled.SelectedTeamMember key={i}>
													<UserAvatar fullName={user.name} displayThisRole='' small />
													<Styled.IconContainer
														data-testid={`delete-${i}`}
														onClick={() => {
															select(user);
														}}
													>
														<Icon name='cross' size='10' />
													</Styled.IconContainer>
												</Styled.SelectedTeamMember>
											);
										}
									})}
								{allUsers
									?.filter((u) => u.id !== user.id)
									.map((user, i) => {
										if (parentIsSharedWithIds?.includes(user.id)) {
											return (
												<Styled.SelectedTeamMember key={i}>
													<UserAvatar fullName={user.name} displayThisRole={user.role ?? ''} small />
												</Styled.SelectedTeamMember>
											);
										}
									})}
							</Styled.TeamMembersContainer>
							{parentIsSharedWithUsers && parentIsSharedWithUsers?.length > 0 && (
								<Styled.InfoText>
									<Icon name='info' size='16' /> Edit member access at the top-level folder. Preferences apply to the entire folder, not to individual items
									within.
								</Styled.InfoText>
							)}
							<Styled.Hr />
						</>
					)}
					<Styled.PropertiesContainer>
						<Styled.Properties>Properties</Styled.Properties>
						<Styled.Info>
							<div>Created By</div> <span>{createdByMe ? 'Me' : createdBy?.attributes.name ?? '-'}</span>
						</Styled.Info>
						<Styled.Info>
							<div>Last modified</div> <span>{moment(updatedAt).format('YYYY-MM-DD')}</span>
						</Styled.Info>
					</Styled.PropertiesContainer>
				</Styled.ScrollContent>
			</Styled.DrawerContent>
		</SideDrawer>
	);
};

export default ShareDrawer;
