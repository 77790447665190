import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';
import { px } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const InnerWrapper = styled.div``;

const Wrapper = styled.div`
	min-width: 0px;
	position: relative;
	margin-top: 32px;

	${InnerWrapper} {
		width: 0px;
		overflow-y: auto;
	}

	min-width: 350px;
	margin-right: ${guttersWithRem.m};

	${InnerWrapper} {
		width: 350px;

		@media (min-width: ${breakpoints.lg}) {
			top: 345px;
		}
	}
`;

const FilteringSummaryWrapper = styled(InnerWrapper)`
	padding: ${guttersWithRem.s} 0;
`;

const filterItemPadding = `${guttersWithRem.l} ${guttersWithRem.xs} ${guttersWithRem.s}`;
const FilterItemWapper = styled.div`
	padding: ${filterItemPadding};
	border-bottom: 1px solid ${newColors.ash};
	.checkbox-container {
		padding: 0.625rem 0;
	}

	.checkbox-container + .checkbox-container {
		margin-bottom: ${px[1]}px;
	}

	.checkbox-container:first-child {
		margin-bottom: ${px[1]}px;
	}

	.checkbox-container:last-child {
		margin-bottom: 0;
	}
`;

const AccordionInnerWrapper = styled.div`
	font-family: ${typography.BaseFontFamiliy} !important;

	&.selected-countries {
		max-height: 220px;
		overflow-y: auto;
		margin-top: ${guttersWithRem.m};
		margin-bottom: ${guttersWithRem.m};
	}

	&.countries-list {
		max-height: 220px;
		overflow-y: auto;
	}
`;

const FlexDiv = styled.div`
	display: flex;
`;

const FollowersInputsWrapper = styled(FlexDiv)`
	align-items: flex-end;
	justify-content: center;
	column-gap: ${guttersWithRem.s};
`;

const BetweenMinMaxDiv = styled(FlexDiv)`
	height: 38px;
	align-items: center;
`;

const PlatformWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	padding: 4px 0;
`;

const PlatformItem = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 8px;
	align-items: center;

	label {
		font-size: ${({ theme }) => theme.fontSizes.text};
	}
`;

const Styled = {
	InnerWrapper,
	Wrapper,
	FilteringSummaryWrapper,
	FilterItemWapper,
	AccordionInnerWrapper,
	FlexDiv,
	FollowersInputsWrapper,
	BetweenMinMaxDiv,
	PlatformWrapper,
	PlatformItem,
};

export default Styled;
