import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GhostButton } from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import colors from 'styles/variables/colors';

import Styled from './DeclineButton.style';

type DeclineButtonProps = {
	className?: string;
	link: string;
};
/**
 * @returns JSX.Element
 */
const DeclineButton = ({ className, link }: DeclineButtonProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const Client = createClient();
	const navigate = useNavigate();

	const handleInvite = async (link: string) => {
		try {
			return await Client.post(link);
		} catch (err) {
			if (!axios.isAxiosError(err)) {
				return;
			}
			console.error(err);
			if (err.response && err.response.data.errors) {
				toast.error(`Error: ${err.response.data.errors[0].source.message}`);
			} else {
				toast.error(getSomethingWentWrongMessage());
			}
		}
	};

	const declineHandler = async () => {
		setIsLoading(true);
		await handleInvite(link).finally(() => {
			setIsLoading(false);
			setIsModalOpen(false);
			navigate('/influencer/dashboard');
		});
	};

	return (
		<>
			<GhostButton onClick={() => setIsModalOpen(true)} className={className}>
				{isLoading ? <LoadingSpinner size='sm' /> : 'Decline'}
			</GhostButton>
			<ConfirmModal
				title='Decline invite'
				buttonText='Yes, decline'
				icon='thumbs-down'
				IconBackgroundColor={colors.contentManagement.iconBackground}
				action={declineHandler}
				isModalOpen={isModalOpen}
				toggleModal={() => setIsModalOpen(!isModalOpen)}
				isFetching={isLoading}
			>
				<>
					<p>Declining will remove the invite and you will no longer be able to join this campaign.</p>
					<Styled.ModalTextWrapper>
						<p>(You will be redirected to your dashboard)</p>
					</Styled.ModalTextWrapper>
				</>
			</ConfirmModal>
		</>
	);
};

export default DeclineButton;
