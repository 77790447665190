import styled from 'styled-components';

import Icon from 'components/Icon';
import colors from 'styles/theme/colors';

const CustomIcon = styled(Icon)`
	line-height: 0;
	cursor: pointer;
	display: flex;
	clip-path: {
		fill: ${colors.slate};
	}
`;

const CloseDiv = styled.div`
	color: ${colors.slate};
	display: flex;
	justify-content: space-between;
	gap: 16px;
	cursor: pointer;
`;

const Styled = {
	CustomIcon,
	CloseDiv,
};

export default Styled;
