import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import { IntegratedInboxCampaign } from 'components/IntegratedInbox/types';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';
import Tabs from 'components/Tabs/Tabs';

import InfluencerItem from './InfluencerItem';
import Styled from './InfluencerList.style';

type Props = {
	campaignInstagramOwners: Model[];
	conversations: Model[];
	isVisible: boolean;
	selectedCampaign: IntegratedInboxCampaign | null;
	isLoading: boolean;
	onSelect: (influencer: InfluencerConversation) => void;
	selectedInfluencer?: Model | null;
};

enum Tabs {
	JOINED = 'Joined',
	NOT_JOINED = 'Not joined',
}

const InfluencerList = ({ campaignInstagramOwners, conversations, isVisible, selectedCampaign, isLoading, onSelect, selectedInfluencer }: Props) => {
	const [search, setSearch] = useState('');
	const [selectedTab, setSelectedTab] = useState<string>(Tabs.JOINED);

	const matchesSearch = (conversation: InfluencerConversation) => {
		const toLower = search.toLowerCase().trim();
		const username: string | null = conversation.influencer.influencer?.username.toLowerCase().trim();

		if (toLower.length < 2 || !username) {
			return true;
		}

		return username.includes(search);
	};

	const sortedInfluencerItems = (selectedTab: string) => {
		/**
		 * We don't have influencer relations in invites for legacy campaigns.
		 * Return a empty array if the campaign is legacy
		 */
		if (selectedCampaign?.campaign.legacy) {
			return [];
		}

		let influencerConverationList = campaignInstagramOwners
			.map((influencer) => {
				return new InfluencerConversation(influencer, conversations);
			})
			.filter((influencer) => influencer.isInvited);

		switch (selectedTab) {
			case Tabs.JOINED:
				influencerConverationList = influencerConverationList.filter((influencer) => influencer.joined).filter(matchesSearch);
				break;
			case Tabs.NOT_JOINED:
				influencerConverationList = influencerConverationList.filter((influencer) => !influencer.joined).filter(matchesSearch);
				break;
			default:
				influencerConverationList = influencerConverationList.filter(matchesSearch);
		}

		const unreadMessageInfluencerConversationList = influencerConverationList.filter((influencerConversation) => influencerConversation.hasUnreadMessages);
		const readMessageInfluencerConversationList = influencerConverationList.filter(
			(influencerConversation) => influencerConversation.conversation !== undefined && !influencerConversation.hasUnreadMessages,
		);
		const noMessageInfluencerConversationList = influencerConverationList.filter((influencerConversation) => influencerConversation.conversation === undefined);

		unreadMessageInfluencerConversationList.sort((a, b) => new Date(b.latestMessageCreatedAt!).getTime() - new Date(a.latestMessageCreatedAt!).getTime());
		readMessageInfluencerConversationList.sort((a, b) => new Date(b.latestMessageCreatedAt!).getTime() - new Date(a.latestMessageCreatedAt!).getTime());
		noMessageInfluencerConversationList.sort((a, b) => a.name.localeCompare(b.name));

		return [...unreadMessageInfluencerConversationList, ...readMessageInfluencerConversationList, ...noMessageInfluencerConversationList];
	};

	useEffect(() => {
		setSearch('');
		setSelectedTab(Tabs.JOINED);
	}, [selectedCampaign]);

	return (
		<Styled.Wrapper className={classNames({ visible: isVisible })}>
			<Styled.InnerWrapper>
				{isLoading ? (
					<LoadingSpinner position='center' />
				) : (
					<Styled.ListWrapper>
						<Styled.SearchWrapper>
							<Styled.TabsWrapper>
								<div className={classNames({ selected: selectedTab === Tabs.JOINED })} onClick={() => setSelectedTab(Tabs.JOINED)}>
									<span>Joined</span>
								</div>
								<div
									className={classNames({ selected: selectedTab === Tabs.NOT_JOINED })}
									onClick={() => setSelectedTab(Tabs.NOT_JOINED)}
									data-testid='direct-message'
								>
									<span>Not joined</span>
								</div>
							</Styled.TabsWrapper>
							<Styled.InputWrapper>
								<SearchInput value={search} onChange={({ target }) => setSearch(target.value)} placeholder='Search influencers' onReset={() => setSearch('')} />
							</Styled.InputWrapper>
						</Styled.SearchWrapper>
						{sortedInfluencerItems(selectedTab).map((influencerConversation) => {
							const isSelected = selectedInfluencer ? selectedInfluencer.influencer.id === influencerConversation.id : false;
							return (
								<InfluencerItem
									data-testid={`conv-${influencerConversation.id}`}
									key={influencerConversation.id}
									name={influencerConversation.name}
									profileImage={influencerConversation.profileImage}
									onClick={() => {
										onSelect(influencerConversation);
									}}
									isSelected={isSelected}
									conversation={influencerConversation.conversation}
									hasUnreadMessages={influencerConversation.hasUnreadMessages}
								/>
							);
						})}
						{!sortedInfluencerItems(selectedTab).length && (
							<li className='text-center'>
								{search.length > 0 ? (
									<>
										Sorry, no match for <strong>&quot;{search}&quot;</strong>
									</>
								) : (
									'No influencers available'
								)}
							</li>
						)}
					</Styled.ListWrapper>
				)}
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export class InfluencerConversation {
	id: string;
	name: string;
	profileImage: string;
	conversation: Model | undefined;
	hasUnreadMessages: boolean;
	latestMessageCreatedAt: string | undefined;
	influencer: Model;
	isInvited: boolean;
	joined: boolean;

	constructor(campaignInstagramOwner: Model, conversations: Model[]) {
		this.id = campaignInstagramOwner.influencer.id;
		this.name = campaignInstagramOwner.influencer.username;
		this.profileImage = campaignInstagramOwner.influencer.links.profilePictureUrl;
		this.conversation = conversations.find((conversation) => conversation.influencer.collabsId === campaignInstagramOwner.influencer.collabsId);
		this.hasUnreadMessages = this.conversation ? this.conversation.unreadMessages.length > 0 : false;
		this.latestMessageCreatedAt = this.conversation ? this.conversation.latestMessage.createdAt : undefined;
		this.influencer = campaignInstagramOwner;
		this.isInvited =
			campaignInstagramOwner.campaign && campaignInstagramOwner.campaign.invites ? this.checkInvite(campaignInstagramOwner.campaign.invites) : false;
		this.joined = campaignInstagramOwner.attributes.joined || false;
	}

	checkInvite(invites: Model[]) {
		if (invites.length > 0) {
			return invites.some((invite) => invite.influencer?.id === this.id);
		} else {
			return false;
		}
	}
}

export default InfluencerList;
