import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { breakpoints, devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	position: relative;

	h1 {
		font-size: 1.1875rem;
		font-weight: 700;
	}

	.page-header-inner {
		grid-template-columns: repeat(1, 1fr);
	}

	.page-header-left-container {
		grid-template-columns: 1fr;

		@media only screen and (${devices.md}) {
			grid-template-columns: auto auto;
		}
	}

	&.show-sidebar {
		.page-header-left-container {
			@media only screen and (${devices.md}) {
				grid-template-columns: 300px auto;
			}
		}
	}

	@media only screen and (${devices.md}) {
		.influencer-affiliate-header {
			position: sticky;
			top: 0;
			background-color: ${colors.mainWhite};
		}
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: ${spacing[2].px};
	h1 {
		margin-bottom: 16px;
		font-size: 1.0625rem;
		font-weight: 600;
		@media (min-width: ${breakpoints.md}) {
			font-weight: 700;
			font-size: 1.5rem;
			margin-bottom: 0;
		}
	}
`;

const IconWrapper = styled.div`
	width: 28px;
	height: 28px;

	padding: ${spacing[0].rem};
	cursor: pointer;
	border-radius: 4px;

	transition: background-color 0.2s ease-in-out 0s;

	& > i > svg {
		&.icon-sidebar-right-arrow {
			transform: translate3d(-2px, -2px, -2px);
		}

		path {
			fill: ${colors.affiliate.icon.fill};
			transition: fill 0.2s ease-in-out 0s;
		}
	}

	&.disabled {
		opacity: 0.7s;
		cursor: default;
	}

	&:hover:not(.disabled) {
		background-color: ${colors.affiliate.icon.hoverBackground};

		& > i > svg {
			path {
				fill: ${colors.affiliate.icon.hoverFill};
			}
		}
	}

	&.fill-darker {
		& > i > svg {
			path {
				fill: revert;
			}
		}
	}
`;

const Styled = {
	Wrapper,
	HeaderWrapper,
	IconWrapper,
};

export default Styled;
