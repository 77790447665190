import moment from 'moment';

import { AssignmentType } from 'components/Assigment/types';
import ListScreenshots from 'components/ContentManagement/Components/Views/Statistics/Components/ListScreenshots/ListScreenshots';
import StoryFrameList from 'components/ContentManagement/Components/Views/Statistics/Components/StoryFrameList/StoryFrameList';
import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { Scenario, StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import { InstagramUserStory } from 'components/ContentManagement/types';
import Grid from 'styles/grid/grid';

type ApprovedStatisticsProps = {
	values: StatisticsFormValues;
	contentType: AssignmentType;
	frames: InstagramUserStory[];
	screenshots: InstagramUserStory[];
	LastInsightDataUpdateAt?: string;
	isManagedByApi?: boolean;
};
/**
 * Statistics for approved assignment av read only overview
 * @returns {JSX.Element}
 */
const StatisticsOverview = ({ contentType, values, frames, screenshots, LastInsightDataUpdateAt, isManagedByApi }: ApprovedStatisticsProps): JSX.Element => {
	const IS_STORY = contentType === AssignmentType.INSTAGRAM_STORY;
	const IS_TIKTOK = contentType === AssignmentType.TIKTOK_VIDEO;
	const IS_REEL = contentType === AssignmentType.INSTAGRAM_REEL;

	const renderInfo = () => {
		return (
			<Styled.InfoSection>
				<p data-testid='date-posted'>
					Date posted: <strong>{values.postedAt}</strong>
				</p>
				{values.url && (
					<a href={values.url} target='_blank' rel='noreferrer'>
						{values.url}
					</a>
				)}
			</Styled.InfoSection>
		);
	};

	return (
		<>
			<h5 className='mb-24'>Statistics</h5>
			<Styled.AutomaticStats>
				{IS_STORY ? (
					<>
						<Grid.Column lg={12}>{renderInfo()}</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Reach (first frame)</p>
								<span>{values.reach}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Reach (last frame)</p>
								<span>{values.reachLastFrame}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Impressions (first frame)</p>
								<span>{values.impressions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Total sticker link clicks</p>
								<span>{values.stickerLinkClicks}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Total sticker taps</p>
								<span>{values.stickerTaps}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Other interactions</p>
								<span>{values.otherInteractions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
					</>
				) : (
					<>
						<Grid.Column lg={12}>{renderInfo()}</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-reach'>
								<p>Reach</p>
								<span>{values.reach}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-impressions'>
								<p>{IS_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'}</p>
								<span>{values.impressions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-comments'>
								<p>Comments</p>
								<span>{values.comments}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-likes'>
								<p>Likes</p>
								<span>{values.likes}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-saves'>
								<p>Saves</p>
								<span>{values.saves}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							{IS_TIKTOK && (
								<Styled.AutomaticStatsValue>
									<p>Shares</p>
									<span>{values.shares}</span>
								</Styled.AutomaticStatsValue>
							)}
						</Grid.Column>
					</>
				)}
				<Grid.Column lg={12}>
					{isManagedByApi ? (
						LastInsightDataUpdateAt && <p className='gray-text'>Statistics last updated: {moment(LastInsightDataUpdateAt).format('YYYY-MM-DD HH:mm')}</p>
					) : (
						<p className='gray-text'>These stats have been entered manually</p>
					)}
				</Grid.Column>
			</Styled.AutomaticStats>
			{frames?.length > 0 && (
				<Styled.Overview>
					<hr />
					<h5>Frames</h5>
					<Styled.ScreenShotSection>
						<StoryFrameList
							scenario={Scenario.ASSIGNMENT_APPROVED}
							frames={frames}
							isLoading={false}
							selectedStoryItems={[]}
							values={values}
							noResult={false}
						/>
					</Styled.ScreenShotSection>
				</Styled.Overview>
			)}
			{screenshots?.length > 0 && (
				<Styled.Overview>
					<hr />
					<h5>Screenshots</h5>
					<Styled.ScreenShotSection>
						<ListScreenshots fetchedScreenShots={screenshots} assignmentApproved={true} />
					</Styled.ScreenShotSection>
				</Styled.Overview>
			)}
		</>
	);
};

export default StatisticsOverview;
