import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { rem, px, spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	background-color: transparent;

	&.show {
		background-color: ${colors.AffiliateMarketplace.rightContainerBackground};

		width: 100vw;
		height: 100vh;

		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3000;

		transition: background-color 0.3s;
	}
`;

const InnerWrapper = styled.div`
	display: none;

	&.show {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		animation: slideIn 0.3s ease-in-out;
	}

	@keyframes slideIn {
		from {
			transform: translateX(100%);
			display: flex;
		}
		to {
			transform: translateX(0);
			display: flex;
		}
	}
`;

const DetailDrawer = styled.div`
	width: 100vw;
	height: 100vh;
	padding-top: 98px;
	position: relative;

	border-radius: 10px 0px 0px 10px;

	background-color: ${colors.CampaignDetailSidebar.background};

	@media screen and (${devices.md}) {
		width: 423px;
	}
`;

const DrawerHeader = styled.div`
	position: absolute;
	top: 0;

	height: 98px;
	width: 100%;
	padding: ${rem[4]}rem;

	display: flex;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid ${colors.CampaignDetailSidebar.headerBorder};

	& > div {
		&.header {
			font-weight: 600;
		}
	}
`;

const CloseAction = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${px[3]}px;
	cursor: pointer;
	color: ${colors.CampaignDetailSidebar.closeText};
	transition: color ease-in-out 0.2s;

	i {
		line-height: 0;
	}

	& > span {
		&.icon-wrapper {
			padding: ${spacing[0].rem};
			display: flex;
			border-radius: 4px;
			transition: background-color ease-in-out 0.2s;
		}
	}

	&:hover {
		color: ${colors.CampaignDetailSidebar.hoverCloseText};
		& > span {
			&.icon-wrapper {
				background-color: ${colors.CampaignDetailSidebar.hoverIconBackground};
			}
		}
	}
`;

const DrawerBody = styled.div`
	height: calc(100vh - 98px);
	padding: ${rem[4]}rem;
	padding-top: 0;
	overflow-y: auto;

	${scrollbarY};
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	DetailDrawer,
	DrawerHeader,
	CloseAction,
	DrawerBody,
};

export default Styled;
