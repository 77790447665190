import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, GhostButton } from 'components/Button';
import Card from 'components/Card';
import { InputField } from 'styles/formElements/input';
import Grid from 'styles/grid/grid';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import { px, spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

import { StyledCard } from './Components/ClientDetails/ClientDetails.style';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const InputGroup = styled.div`
	display: flex;
	width: auto;
	background-color: ${({ theme }) => theme.InputField.background};
	border: ${({ theme }) => theme.InputField.border};
	border-radius: 4px;
	&:hover {
		border: ${({ theme }) => theme.InputField.hover.border};
		box-shadow: 0 0 0 1px ${colors.borderGray};
	}

	input,
	select {
		border: 0 none;
		&:hover,
		&:focus {
			border: 0 none;
			box-shadow: none;
		}
	}

	&.phone-number {
		& > div {
			& > input,
			select {
				&:hover,
				&:focus {
					border: none;
					outline: none;
					box-shadow: none;
				}
			}
		}
	}
`;

const UploadInput = styled.input`
	background-color: transparent;
	border: none;
	margin-top: 1rem;
`;

const Label = styled.label`
	font-size: 0.875rem;
`;

const FormWrapper = styled.div`
	padding-top: 1.5rem;
`;

const ButtonGroup = styled.div`
	display: flex;
	margin-top: 16px;
	align-items: center;
	justify-content: flex-end;
`;

const LoadingOverlay = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.75);
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out;

	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;

const ValidationMessageWrapper = styled.div`
	margin-top: 0.25rem;
`;

const ValidationMessageText = styled.span`
	font-size: 0.75rem;
	padding-left: 1rem;
	color: ${colors.form.validation.error.color};
`;

const LogotypeWrapper = styled.div`
	img {
		max-width: 150px;
	}
`;

const FormDescription = styled.div`
	margin-bottom: 1.5rem;
`;

const StyledGridContainer = styled(Grid.Container)`
	flex-direction: column;
	position: relative;
	margin: 16px;
	width: calc(100% - 32px);
	${mediaQueries.medium`
		flex-direction: row;
	`};
`;

const StyledSideBar = styled(Grid.Column)`
	border-right: 1px solid ${colors.settings.border};
	height: auto;
	${mediaQueries.medium`
		max-width: 300px;
		height: 100vh;
	`};
`;

const IconContainer = styled.div`
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 0.5rem;
`;

const NavigationItemInnerContainer = styled.div`
	padding: 1rem 0 0 1rem;
	display: flex;
	align-items: center;
	width: 100%;
`;

const FormSubTitle = styled.div`
	margin-top: ${spacing[3].px};
	margin-bottom: ${spacing[3].px};
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
`;

const TeamSection = styled.div`
	margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100px;
`;

const CreateWrapper = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;

	label {
		text-transform: none;
	}
`;

const HelperText = styled.div`
	margin-top: 3px;
	background-color: ${colors.errorLight};
	border-radius: ${borderRadius.s};
	padding: ${spacing[1].rem};
	color: ${colors.errorDarkRed};
	font-size: 0.75rem;
	display: flex;
	width: 100%;
	span {
		margin-top: 3px;
	}
	.icon {
		margin-right: 5px;
		margin-top: 2px;
	}
	.icon path {
		fill: ${colors.errorDarkRed};
	}
`;

const CreateButtons = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
	margin-top: 16px;
`;

const AlignButtonEnd = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 80px;
`;

const Content = styled.div`
	padding-bottom: 5rem;
	img {
		margin-bottom: 40px;
	}
	h5 {
		font-size: 1.063rem;
		font-weight: 600;
	}
	p {
		color: ${colors.settings.textGray};
		margin-bottom: 1rem;
	}
	hr {
		border-top: 1px solid ${colors.settings.textGray};
		margin: 1rem 0 2.5rem;
	}
	.mb {
		margin-bottom: 1.2rem;
	}
	ul {
		padding-left: 1rem;
		color: ${colors.settings.textGray};
		li {
			padding: 0.5rem 0;
		}
	}
`;

const ButtonsSpaceBetween = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
`;

const ZipAndCity = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;

	${mediaQueries.medium`
		flex-direction: row;
		.left {
			margin-right: 5px;
			width: 40%;
		}
		.right {
			margin-left: 5px;
			width: 60%;
		}
	`};
`;

const Cancel = styled.div`
	cursor: pointer;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 0.438rem 1rem;
	border: solid 2px ${colors.black};
	&:hover {
		background-color: ${colors.button.primary.backgroundHover};
		color: ${colors.button.primary.colorHover};
	}
`;

const NameWrapper = styled.div`
	display: flex;

	& > div {
		width: 100%;
		margin-right: 5px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const InstagramAvatarWrapper = styled.div`
	margin-bottom: 16px;
`;

const InstagramAvatar = styled.figure`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 40px;
	min-width: 40px;
	height: 40px;
	background: ${colors.mainBlack};
	border-radius: 50px;
	overflow: hidden;

	> figcaption {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		margin-top: 3px;
		z-index: 999;
	}
`;

const ModalContent = styled.div`
	text-align: left;
	margin-top: 8px;
	margin-bottom: -16px;
	.mb {
		margin-bottom: 19px;
	}
	ul {
		padding-left: 1rem;
	}
`;

const SocialMediaButtonContainer = styled.div`
	display: flex;
	margin-bottom: ${px[3]}px;
	${Grid.Container} {
		width: 100%;
	}
`;

const SocialMediaButton = styled(Button)`
	border: 2px solid ${colors.settings.buttonBorder};
	width: 100%;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	padding: 1rem;
	&.selected {
		${colors.button.dark};
		color: ${colors.mainWhite};
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
	.icon {
		margin-top: 3px;
	}
	span {
		margin-top: 3px;
	}
	&:hover {
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
`;

const ProfileLabel = styled.span`
	font-family: ${typography.BaseFontFamiliy} !important;
	line-height: 1.7;
	font-size: 0.938rem;
	${mediaQueries.medium`
		font-size: 0.875rem;
	`};
	color: ${colors.settings.labelGray};
`;

const FacebookButton = styled(Button)`
	background: ${colors.settings.facebookButton};
	color: ${colors.white};
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.938rem;
	border: 1px solid ${colors.settings.facebookButton};
	span {
		margin-top: 3px;
	}
`;

const CustomCard = styled(Card)`
	background-color: ${colors.settings.cardBackground};
	border: transparent;
	margin-bottom: ${px[2]}px;
	padding: 0 1rem;
`;

const CustomCardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: ${px[2]}px;
`;

const UserAvatarWrapper = styled.div`
	margin: 34px 0 40px;
`;

const fullNameSpan = styled.span`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.938rem;
	font-weight: 500;
	letter-spacing: 0em;
	line-height: 1.6;
	margin-bottom: 5px;
`;

const RoleSpan = styled.span`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	letter-spacing: 0em;
	color: ${colors.settings.textGray};
`;

const FacebookNameWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const DisconnectButton = styled(GhostButton)`
	width: 100%;
	${mediaQueries.medium`
			width: auto;
	`};
`;

const Title = styled.h5`
	font-size: 1.063rem;
	${mediaQueries.medium`
			font-size: 1rem;
	`};
`;

const ToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	p {
		margin-right: 10px;
		margin-bottom: ${px[2]}px;
		font-family: ${typography.BaseFontFamiliy};
	}
`;

const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 3px;
	bottom: 0;
	background-color: ${colors.gray3};
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 26px;
	border: 1px solid ${colors.settings.facebookButton};
	&.checked {
		background-color: ${colors.settings.slider.checked} !important;
		&::before {
			-webkit-transform: translateX(-10px);
			-ms-transform: translateX(-10px);
			transform: translateX(-10px);
		}
	}
	&::before {
		border-radius: 50%;
		position: absolute;
		content: '';
		height: 14px;
		width: 14px;
		right: 3px;
		bottom: 2px;
		background-color: ${colors.settings.facebookButton};
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
`;

const ToggleSwitch = styled.label`
	position: relative;
	display: inline-block;
	width: 35px;
	height: 20px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
`;

const ToggleTitleWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: ${px[4]}px;
	h5 {
		font-size: 0.938rem;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
	}
`;

const FacebookConnected = styled.div`
	padding: 1rem;
	display: flex;
	align-items: center;
	background-color: ${colors.settings.cardBackground};
	margin-bottom: 24px;
	border-top: 1px dotted ${colors.settings.textGray};
	border-bottom: 1px dotted ${colors.settings.textGray};
	h4 {
		font-size: 0.875rem;
		font-weight: 500;
		margin-bottom: 0%;
		line-height: 1.5;
		margin-top: 5px;
	}
	.icon {
		margin-right: 16px;
		margin-top: 5px;
	}
`;

const NotConnectedPages = styled.div`
	border-top: 1px solid ${colors.settings.avatarBackground};
	border-bottom: 1px solid ${colors.settings.avatarBackground};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.5rem;
	cursor: pointer;
	p {
		font-size: 0.875rem;
		color: ${colors.black1};
		margin-top: 18px;
	}
`;

const ConnectSection = styled.div`
	display: flex;
	flex-direction: column;
	p {
		text-align: center;
	}
	button {
		width: fit-content;
		margin: auto;
	}
`;

const CustomLink = styled(Link)`
	display: flex;
	color: ${colors.info} !important;
	align-items: center;
	border-bottom: transparent !important;
	text-decoration: underline;
	margin: 2rem 0;
	.icon {
		margin-right: 16px;
		path {
			fill: ${colors.info};
		}
	}
`;

const UploadWrapper = styled.div`
	padding-bottom: 5rem;
`;

const InviteWrapper = styled.div`
	margin-top: ${spacing[3].px};
`;

const TableContainer = styled.div`
	width: 100%;
	margin-bottom: 60px;
`;

const Select = styled.select`
	${InputField};
	width: 200px;
`;

const FixedColumn = styled(Grid.Column)`
	@media screen and (${devices.md}) {
		position: fixed;
		max-width: 318px;
		top: 210px;
	}
`;

const ScrollableColumn = styled(Grid.Column)`
	@media screen and (${devices.md}) {
		margin-left: 350px;
		margin-top: 50px;
	}
`;

const HeaderColumn = styled(Grid.Column)`
	@media screen and (${devices.md}) {
		position: fixed;
		top: 110px;
	}
`;

const ImgContainer = styled.div`
	width: 100%;
	img {
		height: 125px;
	}
`;

const EditLink = styled.div`
	margin: 16px 0;
	color: ${colors.CTALinks.color};
	cursor: pointer;
	width: fit-content;
	float: right;
`;

const ListWrapper = styled.div`
	margin-top: 24px;
	max-height: 600px;
	${scrollbarY};
`;

const InfoText = styled.div`
	width: 100%;
	border-radius: ${borderRadius.m};
	padding: 1rem;
	background-color: #e2eee6;
	display: flex;
	align-items: start;
	color: ${colors.mainBlack};
	.icon {
		margin-right: 8px;
	}
	ul {
		padding-left: 1rem;
		list-style: disc;
		margin-bottom: 8px;
		margin-top: 8px;
		li {
			padding: 0.25rem 0;
			line-height: 1.5;
			color: ${newColors.forestGreen};
		}
	}
	p {
		line-height: 1.2;
		color: ${newColors.forestGreen};
	}
	span {
		font-weight: 600;
		font-size: 0.875rem;
		color: ${newColors.forestGreen};
	}
`;

const ErrorMessage = styled.div`
	font-size: 0.75rem;
	margin: 0;
	padding: ${spacing[1].rem};
	border-radius: ${borderRadius.s};
	background-color: ${colors.errorLight};
	margin-top: -20px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	span {
		color: ${colors.errorDarkRed};
		margin-top: 3px;
	}
	.icon {
		margin-right: ${spacing[1].px};
		margin-top: 3px;
	}
	.icon path {
		fill: ${colors.errorDarkRed};
	}
`;

const Value = styled.div`
	display: flex;
	justify-content: space-between;
	height: 44px;
	color: ${colors.gray7};
	background-color: ${colors.payment.mediumGray};
	border-radius: ${borderRadius.s};
	align-items: center;
	padding: 0.2rem ${spacing[2].rem} 0;
`;

const VatInput = styled.div`
	label {
		font-family: ${typography.BaseFontFamiliy};
		font-size: 0.875rem;
		line-height: 2;
	}
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: ${spacing[3].px};
`;

const InfoBoxWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 5px;
`;

const IconSection = styled.div`
	display: flex;
	align-items: center;
`;

const NeedToConfirmVat = styled.div`
	height: 0;
	overflow: hidden;
	transition: height 0.2s;
	background-color: ${colors.buttonGray};
	border-radius: ${borderRadius.m};
	&.needToConfirmVat {
		height: 200px;
		padding: ${spacing[2].rem};
		margin-bottom: ${spacing[3].px};
	}
	p {
		&.vat {
			color: ${colors.mainBlack} !important;
		}
	}
	button {
		margin: ${spacing[2].px} 0;
		width: 100%;
	}
`;

const ErrorIcon = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem;
	border-radius: 50%;
	.icon path {
		fill: ${colors.gray7};
	}
	&:hover {
		background-color: ${colors.gray10};
	}
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;

const Styled = {
	IconWrapper,
	ErrorIcon,
	NeedToConfirmVat,
	IconSection,
	InfoBoxWrapper,
	Value,
	VatInput,
	ErrorMessage,
	InfoText,
	ListWrapper,
	EditLink,
	ImgContainer,
	HeaderColumn,
	ScrollableColumn,
	FixedColumn,
	Select,
	TableContainer,
	InviteWrapper,
	UploadWrapper,
	CustomLink,
	ConnectSection,
	NotConnectedPages,
	FacebookConnected,
	Slider,
	ToggleTitleWrapper,
	ToggleSwitch,
	ToggleWrapper,
	Title,
	DisconnectButton,
	FacebookNameWrapper,
	fullNameSpan,
	RoleSpan,
	UserAvatarWrapper,
	CustomCardHeader,
	CustomCard,
	FacebookButton,
	SocialMediaButton,
	NameWrapper,
	Cancel,
	ZipAndCity,
	ButtonsSpaceBetween,
	Content,
	AlignButtonEnd,
	CreateButtons,
	HelperText,
	CreateWrapper,
	Wrapper,
	InputGroup,
	UploadInput,
	Label,
	FormWrapper,
	ButtonGroup,
	LoadingOverlay,
	ValidationMessageWrapper,
	ValidationMessageText,
	LogotypeWrapper,
	FormDescription,
	StyledGridContainer,
	StyledSideBar,
	IconContainer,
	FormSubTitle,
	TeamSection,
	SpinnerWrapper,
	NavigationItemInnerContainer,
	InstagramAvatar,
	ModalContent,
	SocialMediaButtonContainer,
	ProfileLabel,
	InstagramAvatarWrapper,
	StyledCard,
};

export default Styled;
