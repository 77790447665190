import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import { DropdownMenu } from 'components/Dropdown/Dropdown';
import InputText from 'components/Form/FormikElements/Text';
import Icon from 'components/Icon';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const cm = colors.contentManagement;
type P = { theme: DefaultThemeV2 };

const Wrapper = styled.section`
	padding: 1rem;
	margin-bottom: 16px;
	border-radius: 10px;
	border: 1px solid ${newColors.ash};
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	div:last-child {
		margin-left: auto;
	}
`;

const Form = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 32px;

	${mediaQueries.xl`
		flex-direction: row;
	`};
`;

const MediaWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 212px;
	margin-bottom: 24px;

	${mediaQueries.xl`
		width: 204px;
		height: 258px;
		margin-bottom: 0;
	`};
`;

const Image = styled.div<{ imageUrl: string }>`
	position: absolute;
	top: 0;
	left: 0;
	background: url(${(p) => p.imageUrl});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
`;

const FieldsContainer = styled.div`
	flex: 1 1;
	h6 {
		font-weight: 600;
	}
`;

const InputGroup = styled.div`
	&:not(:last-child) {
		margin-bottom: 16px;
	}

	textarea {
		min-height: 150px;
		padding: 1rem 0;
		line-height: 1.5;
	}

	.frame__tacking-link {
		.tracking-link__headline {
			font-size: 0.875rem;
		}

		.tracking-link__link-container {
			background-color: ${colors.contentManagement.comments.commentsButton.background};
		}
	}
`;

const Label = styled.label`
	display: block;
	font-size: 0.875rem;

	&.odd {
		font-size: 0.875rem;
		color: ${cm.card.oddLabelColor};
		margin-bottom: 8px;
	}
`;

const ItemIndex = styled.div`
	font-size: 1.5rem;
	font-weight: 700;
	color: ${newColors.smoke};
`;

const Video = styled.video`
	height: auto;
	width: 100%;
	max-height: 213px;
`;

const ShowFullSizeButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: ${colors.contentReview.fullscreenButtonBackground};
	cursor: pointer;
	border-radius: 2px;
	padding: 0.5rem;

	.icon {
		line-height: 0;

		svg path {
			fill: ${colors.white};
		}
	}
`;

const ImageWrapper = styled.div`
	text-align: center;

	& > img {
		max-width: 500px;
	}
`;

const FullSizeImage = styled.img`
	width: 100%;

	&.full-size-image {
		object-fit: unset;
	}
`;

const PendingMedia = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
	background-color: ${cm.pendingMedia.backgroundColor};
	padding: 1rem;
`;

const ArrowButtons = styled.div`
	margin-left: 24px;
`;

const ArrowButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 0.25rem;

	.icon {
		display: inline-block;
		line-height: 0;
	}
`;

const DropdownItemIcon = styled(Icon)`
	margin-right: 8px;
`;

const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
`;

const ProcessText = styled.p`
	margin-bottom: 16px;
	color: ${colors.error};
`;

const CustomDropdown = styled(Dropdown)`
	.icon {
		path {
			fill: ${colors.gray7};
		}
	}
`;

const TrackingLink = styled.p`
	font-size: 0.875rem;
	font-weight: 700;
	margin-top: 16px;

	.link {
		font-weight: normal;
	}
`;

const CaptionInput = styled(InputText)`
	${scrollbarY};

	&:disabled {
		border: ${({ theme }) => theme.InputField.border};
		color: inherit;
	}
`;

const ProductLinkInput = styled(InputText)`
	border: 1px solid ${colors.form.input.disabledBorder};
	color: ${colors.form.input.border};
	border-radius: 8px;

	&:hover {
		border: ${({ theme }) => theme.InputField.border};
	}

	&:disabled {
		background: ${colors.form.input.disabledBackgroundColor};
		border: 1px solid ${colors.form.input.disabledBorder};
		color: ${colors.form.input.border};
	}

	&.inactive {
		color: ${colors.form.input.border};
		font-weight: 500;
	}
	.icon path {
		fill: #888888;
	}
`;

const ActionWrapper = styled.div`
	cursor: pointer;

	&.url-link-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		background-color: ${colors.contentManagement.story.originalLink.editModeIconBackground};

		& > a {
			border: none;

			&:hover {
				border: none;
			}
		}
	}
`;

const ProductLinkBlockWrapper = styled.div`
	& > div {
		&.title {
			font-weight: 600;
			margin-bottom: 8px;
		}

		&.help {
			font-size: 0.875rem;
			color: ${newColors.steel};
			margin-bottom: 8px;
		}

		&.input-wrapper {
			display: flex;
			align-items: center;
			column-gap: 8px;

			& > div {
				margin-bottom: 0;
			}
		}
	}
`;

const OriginalLinkWrapper = styled.div`
	margin-top: 1rem;
	min-height: 64px;
	padding: 0.75rem 1rem;
	border-radius: 10px;
	background-color: ${colors.contentManagement.story.originalLink.background};
	font-weight: 600;

	display: flex;
	align-items: center;
	column-gap: 16px;

	& > div {
		&.icon-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			&:hover {
				background-color: ${colors.contentManagement.story.originalLink.iconBackground};
			}
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.original-link {
			flex: 1;
			overflow-x: hidden;

			& > span {
				display: block;
				margin: 0;
				width: 100%;
				color: ${colors.contentManagement.story.originalLink.color};
				max-width: 550px;
			}
		}

		&.link-button {
			& > a {
				border: none;
			}
		}
	}
`;

const Caption = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 8px;
	height: 221px;
`;

const CaptionTopBar = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem 1rem;
	position: absolute;
	background-color: ${({ theme }: P) => theme.colors.snow};
	color: ${({ theme }: P) => theme.colors.placeholder};
	z-index: 1;
`;

const TagMenu = styled(DropdownMenu)`
	border: none;
	padding: 0;
	border-radius: ${({ theme }: P) => theme.radius.default};
`;

const TagWrapper = styled.div`
	display: flex;
	gap: 8px;
	margin-top: 8px;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 40px;
`;

const Tag = styled.div`
	margin-bottom: 5px;
	display: inline-block;
	font-size: ${typography.label.small.fontSize};
	background-color: ${colors.buttonGray};
	border: 1px solid ${colors.gray10};
	border-radius: ${borderRadius.m};
	padding: 0.5rem;
	padding-top: 0.6rem;
	&.error {
		border: 1px solid ${colors.errorRed};
	}
	cursor: pointer;
	&:hover {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
	}
`;

const CopyWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: -20px;
`;

const Styled = {
	CopyWrapper,
	TagWrapper,
	Tag,
	TagMenu,
	Caption,
	CaptionTopBar,
	Wrapper,
	Header,
	Form,
	MediaWrapper,
	FieldsContainer,
	InputGroup,
	Label,
	ItemIndex,
	Image,
	Video,
	ShowFullSizeButton,
	ImageWrapper,
	FullSizeImage,
	PendingMedia,
	ArrowButtons,
	ArrowButton,
	DropdownItemIcon,
	Iframe,
	ProcessText,
	CustomDropdown,
	TrackingLink,
	CaptionInput,
	ProductLinkInput,
	ProductLinkBlockWrapper,
	ActionWrapper,
	OriginalLinkWrapper,
};

export default Styled;
