import { Total, OverTime, ByCountryContainer, ByInfluencerContainer } from 'components/DataLibrary/Graphs/GraphComponents';
import { BUDGET, SALES } from 'constants/data-library';
import colors from 'styles/variables/colors';

/**
 * @returns object
 */
const SalesAndBudgetGraphs = (): object => {
	const graphItems = {
		category: 'Sales and Budget',
		items: [
			{
				title: SALES.TOTAL_TITLE,
				icon: SALES.TOTAL_ICON,
				id: SALES.TOTAL,
				component: <Total isAmount title={SALES.TOTAL_TITLE} chartType={SALES.TOTAL} />,
			},
			{
				title: SALES.TOTAL_CONVERSIONS_TITLE,
				icon: SALES.TOTAL_CONVERSIONS_ICON,
				id: SALES.TOTAL_CONVERSIONS,
				component: <Total title={SALES.TOTAL_CONVERSIONS_TITLE} chartType={SALES.TOTAL_CONVERSIONS} />,
			},
			{
				title: SALES.OVERTIME_TITLE,
				icon: SALES.OVERTIME_ICON,
				id: SALES.OVERTIME,
				component: <OverTime isAmount color={colors.chartLineBudget} title={SALES.OVERTIME_TITLE} isHoverable={true} chartType={SALES.OVERTIME} />,
			},
			{
				title: SALES.CONVERSIONS_BY_INFLUENCER_TITLE,
				icon: SALES.CONVERSIONS_BY_INFLUENCER_ICON,
				id: SALES.CONVERSIONS_BY_INFLUENCER,
				component: (
					<ByInfluencerContainer
						title={SALES.CONVERSIONS_BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartType={SALES.CONVERSIONS_BY_INFLUENCER}
						valueType='conversions'
					/>
				),
			},
			{
				title: SALES.ORDER_VALUE_BY_INFLUENCER_TITLE,
				icon: SALES.ORDER_VALUE_BY_INFLUENCER_ICON,
				id: SALES.ORDER_VALUE_BY_INFLUENCER,
				component: (
					<ByInfluencerContainer
						title={SALES.ORDER_VALUE_BY_INFLUENCER_TITLE}
						isTopThree={true}
						chartType={SALES.ORDER_VALUE_BY_INFLUENCER}
						valueType='orderValue'
					/>
				),
			},
			{
				title: BUDGET.TOTAL_TITLE,
				icon: BUDGET.TOTAL_ICON,
				id: BUDGET.TOTAL,
				component: <Total isAmount title={BUDGET.TOTAL_TITLE} chartType={BUDGET.TOTAL} />,
			},
			{
				title: BUDGET.OVERTIME_TITLE,
				icon: BUDGET.OVERTIME_ICON,
				id: BUDGET.OVERTIME,
				component: <OverTime color={colors.chartLineBudget} title={BUDGET.OVERTIME_TITLE} isHoverable={true} chartType={BUDGET.OVERTIME} />,
			},
			{
				title: BUDGET.BY_COUNTRY_TITLE,
				icon: BUDGET.BY_COUNTRY_ICON,
				id: BUDGET.BY_COUNTRY,
				component: <ByCountryContainer isAmount displayBar title={BUDGET.BY_COUNTRY_TITLE} chartType={BUDGET.BY_COUNTRY} />,
			},
			{
				title: BUDGET.CPM_TITLE,
				icon: BUDGET.CPM_ICON,
				id: BUDGET.CPM,
				component: <Total isAmount title={BUDGET.CPM_TITLE} chartType={BUDGET.CPM} />,
			},
			{
				title: BUDGET.CPC_TITLE,
				icon: BUDGET.CPC_ICON,
				id: BUDGET.CPC,
				component: <Total isAmount title={BUDGET.CPC_TITLE} chartType={BUDGET.CPC} />,
			},
		],
	};

	return graphItems;
};
export default SalesAndBudgetGraphs;
