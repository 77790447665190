import React, { useContext, useState } from 'react';

import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import DiscoveryContext from 'contexts/Discovery/DiscoveryContext';

import Styled from './HashtagsFilter.style';

type Props = {
	hashtags: string[];
	onSetFilter: (value: string) => void;
	onRemoveHashtag: (value: string) => void;
};

const HashtagsFilter = ({ hashtags, onSetFilter, onRemoveHashtag }: Props) => {
	const { filter } = useContext(DiscoveryContext);

	const [enteredText, setEnteredText] = useState<string>('');

	const setHashtag = (e: React.KeyboardEvent) => {
		if (e.code === 'Enter' && enteredText.length > 0) {
			// Check for multiple hashtags or words
			const hasMultipleHashtag = enteredText.split(/#/).length > 2;
			const hasMultipleWords = enteredText.indexOf(' ') >= 0;

			// Values in filter as array
			const valuesInFilter = filter?.hashtags?.split(',');

			if (hasMultipleHashtag) {
				enteredText.split(/#/).forEach((hashtag) => {
					const hashtagToSave = hashtag.replace('#', '').replace('@', '').trim();
					if (hashtagToSave.length > 0 && !valuesInFilter?.includes(hashtagToSave)) {
						onSetFilter(hashtagToSave);
					}
				});
			} else if (hasMultipleWords) {
				enteredText.split(' ').forEach((hashtag) => {
					const hashtagToSave = hashtag.replace('#', '').replace('@', '').trim();
					if (hashtagToSave.length > 0 && !valuesInFilter?.includes(hashtagToSave)) {
						onSetFilter(hashtagToSave);
					}
				});
			} else {
				const valueWithoutHashtag = enteredText.replace('#', '').replace('@', '').trim();
				if (!valuesInFilter?.includes(valueWithoutHashtag)) {
					onSetFilter(valueWithoutHashtag);
				}
			}
			setEnteredText('');
		}
	};

	const removeHashtag = (value: string) => {
		onRemoveHashtag(value);
	};

	return (
		<Styled.Wrapper>
			<Input
				onReset={() => {
					setEnteredText('');
				}}
				value={enteredText}
				onChange={(e) => {
					setEnteredText(e.target.value);
				}}
				onKeyDown={setHashtag}
				contentAfter={
					enteredText ? (
						<Styled.ContentAfterContainter>
							<Styled.EnterLabel title='Press "enter" to submit'>[enter]</Styled.EnterLabel>
							<Styled.IconContainer onClick={() => setEnteredText('')}>
								<Icon name='cancel-circle' size='16' />
							</Styled.IconContainer>
						</Styled.ContentAfterContainter>
					) : undefined
				}
			/>

			<Styled.HashtagsWrapper>
				{hashtags.map((hashtag, index) => {
					return (
						<Styled.HashtagItem
							key={index}
							onClick={() => {
								removeHashtag(hashtag);
							}}
						>
							{hashtag}
							<Icon name='cross' size='8' />
						</Styled.HashtagItem>
					);
				})}
			</Styled.HashtagsWrapper>
		</Styled.Wrapper>
	);
};

export default HashtagsFilter;
