import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	p {
		margin-bottom: 0;
	}
`;

const Title = styled.h5`
	font-size: 1rem;
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	width: 100%;
	margin-top: 4px;

	a {
		border-bottom: none;
	}

	button {
		height: 46px;
	}

	.input-container {
		background-color: ${({ theme }) => theme.colors.background};
	}

	.form-field-container {
		margin-bottom: 0;
	}

	.icon.disabled svg path {
		fill: ${({ theme }) => theme.colors.textDisabled};
	}
`;

const IconLink = styled.a`
	cursor: pointer;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease;

	&:hover {
		background-color: ${({ theme }) => theme.colors.inputsDarker};
	}
`;

export { Wrapper, Title, InputWrapper, IconLink };
