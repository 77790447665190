import { AxiosError } from 'axios';
import { FormikProps, FormikValues } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PublisherModel } from 'api-models';
import { ViewPublisherQuery } from 'api-queries';
import { SideDrawer } from 'components/SideDrawer';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import PublisherForm from 'views/admin/Components/Publishers/Components/PublisherForm/PublisherForm';
import { PublisherType } from 'views/admin/Components/Publishers/Components/PublisherOptions/PublisherOptions';

const HandlePublisher = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
	const [initialValues, setInitialValues] = useState<{ name: string; email: string; kind: PublisherType; mediaAgencyFeePercentage: number }>({
		name: '',
		email: '',
		kind: PublisherType.AGENCY,
		mediaAgencyFeePercentage: 0,
	});

	const navigate = useNavigate();

	const manager = useInjection<PublisherManager>(PublisherManager);
	const formRef = useRef<FormikProps<{ name: string; email: string; kind: PublisherType; mediaAgencyFeePercentage: number }> | null>(null);
	const { publisherId } = useParams();

	const { repository, isLoading, mutate } = manager.view(
		publisherId!,
		RequestQueryBuilder.create<ViewPublisherQuery>(['logo', 'edit', 'logo', 'createPublisher', 'deleteLogo'])
			.withInclude('clients', ['logo'])
			.withInclude('invites'),
	);

	const publisher = repository.find<PublisherModel>('publisher', publisherId!);

	const createPublisher = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	const savePublisher = (values: FormikValues) => {
		setValidateAfterSubmit(true);
		setIsSaving(true);
		const payload = { name: values.name, kind: values.kind, mediaAgencyFeePercentage: parseFloat(values.mediaAgencyFeePercentage) };
		return (
			publisherId &&
			manager
				.update(publisherId, payload)
				.then(() => {
					mutate();
					toast.success(`Publisher updated`);
					navigate('/admin/publishers');
					setValidateAfterSubmit(false);
					setIsModalOpen(false);
					setIsSaving(false);
				})
				.catch((error: AxiosError) => {
					toast.error(getErrorMessageOnPost('updating the publisher'));
					console.error('Error update publisher', error);
					setValidateAfterSubmit(false);
					setIsSaving(false);
				})
		);
	};

	useEffect(() => {
		publisher &&
			setInitialValues({
				name: publisher?.attributes.name,
				email: '',
				kind: publisher.attributes.kind as PublisherType,
				mediaAgencyFeePercentage: publisher.attributes.mediaAgencyFeePercentage ?? 0,
			});
	}, [publisher]);

	useEffect(() => {
		publisherId ? setIsModalOpen(true) : (setIsModalOpen(false), navigate('/admin/publishers'));
	}, [publisherId]);

	return (
		<SideDrawer
			sidebarIsOpen={isModalOpen}
			expandedTitle='Handle publisher'
			onClose={() => {
				setIsModalOpen(false), navigate('/admin/publishers');
			}}
			dataTestId={''}
			title='Publisher management'
			isExpandable
			saveButtonText='Save'
			onSave={createPublisher}
			isSubmitting={isSaving}
		>
			<PublisherForm
				savePublisher={savePublisher}
				initialValues={initialValues}
				formRef={formRef}
				validateAfterSubmit={validateAfterSubmit}
				isLoading={isLoading}
				publisher={publisher}
				mutate={mutate}
			/>
		</SideDrawer>
	);
};

export default HandlePublisher;
