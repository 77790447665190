import { FieldAttributes, useField } from 'formik';
import { isArray, isObject } from 'lodash';

import Styled from 'components/Form/FormikElements/FormikElements.style';
import SelectMenu from 'components/SelectMenu';

export type SelectOption = {
	label: string;
	value: string;
};

type ValueType =
	| {
			value: string;
			label: string;
			options?: SelectOption[] | undefined;
	  }
	| undefined;

const Select = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props: FieldAttributes<any> & {
		label?: string;
		options: SelectOption[];
		isSearchable: boolean;
		isMulti: boolean;
		onChangeCallable?: () => void;
		size: 'md' | 'lg';
		testid?: string;
	},
) => {
	const { options, isSearchable, id, name, label, disabled } = props;
	const [field, , { setValue }] = useField({ name, id });

	const getValue = (options: Array<{ value: string; label: string; options?: SelectOption[] }>): ValueType => {
		if (options) {
			// Ignore group top level.
			return options.find((option) =>
				option.options === undefined && isObject(field.value) ? option.value === (field as { value: { id: string } }).value.id : option.value === field.value,
			);
		}
	};

	const capitalizeFirstLetter = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	return (
		<Styled.InputWrapper className='select-input-wrapper' data-testid={props.testid ?? 'select-input-wrapper'}>
			{label && <Styled.Label htmlFor={id}>{capitalizeFirstLetter(label)}</Styled.Label>}
			<SelectMenu
				{...props}
				isDisabled={disabled}
				isSearchable={isSearchable !== undefined ? isSearchable : false}
				options={options}
				value={getValue(options)}
				onChange={(option: SelectOption | SelectOption[] | null) => {
					let value = null;
					if (isArray(option)) {
						value = option.map(({ value }) => value);
					} else if (isObject(option)) {
						value = option.value;
					}
					setValue(value);
					if (props.onChangeCallable) {
						props.onChangeCallable(value);
					}
				}}
			/>
		</Styled.InputWrapper>
	);
};

export default Select;
