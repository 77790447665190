import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import { FEATURE_FLAG_INBOX_UPLOAD_ATTATCHMENTS } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useAutosizeTextArea from 'hooks/useAutosizeTextArea';
import toast from 'services/Toast';
import { isMobile } from 'shared/utils/navigator';

import Styled from './ChatInput.style';

type Props = {
	onSend: (message: string, attachments?: Array<File>) => Promise<boolean>;
	onScrollToBottom: () => void;
	isDisabled: boolean;
};

const ChatInput = ({ onSend, onScrollToBottom, isDisabled }: Props) => {
	const [value, setValue] = useState<string>('');
	const [attachments, setAttachments] = useState<Array<File>>([]);
	const [isSendDisabled, setIsSendDisabled] = useState<boolean>(true);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [isEnabled] = useFeatureToggle();

	const { conversationId } = useParams();

	useAutosizeTextArea(textAreaRef.current ? textAreaRef.current : null, value);

	const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (evt.target) {
			const val = evt.target.value;
			setValue(val);
		}
	};

	const handleOnKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			if (!/\S/.test(value) && attachments.length === 0) {
				e.preventDefault();
				setValue('');
				return false;
			} else if (!isMobile() && !e.shiftKey) {
				e.preventDefault();
				onClickSend();
				return false;
			}
		}
		return true;
	};

	const onClickSend = () => {
		setIsSendDisabled(true);
		setValue('');
		setAttachments([]);

		if (inputRef.current) {
			inputRef.current.value = '';
		}

		onSend(value, attachments)
			.then()
			.catch((e) => {
				toast.error(getErrorMessageOnPost('sending the message'));
				console.error(e);
			});
	};

	const onChangeAttachFile = (e: React.FormEvent<HTMLInputElement>) => {
		const files: FileList | null = e.currentTarget.files;
		if (files) {
			const fileArray = Array.from(files);
			setAttachments(attachments.concat(fileArray));
			setIsDropdownOpen(false);
		}
	};

	const onClickDeleteAttachment = (file: File) => {
		const filterFiles = attachments.filter((a) => a.name !== file.name);
		setAttachments(filterFiles);
		return filterFiles;
	};

	const getAttachmentIconName = (type: string) => {
		switch (true) {
			case type.includes('image'):
				return 'image';
			case type.includes('pdf'):
				return 'pdf-file';
			default:
				return 'other-file';
		}
	};

	const renderAttachments = (fileList: Array<File>) => {
		const files = fileList ? Array.from(fileList) : [];

		return (
			<Styled.AttachmentList>
				{files.map((file: File, index: number) => {
					return (
						<Styled.Attachment key={index} data-testid='inbox-attached-file-item'>
							<Icon className='attachment__type' name={getAttachmentIconName(file.type)} />
							<span className='attachment__name'>{file.name}</span>
							<span className='attachment__delete' onClick={() => onClickDeleteAttachment(file)}>
								x
							</span>
						</Styled.Attachment>
					);
				})}
			</Styled.AttachmentList>
		);
	};

	useEffect(() => {
		setAttachments([]);
		setValue('');
		setIsDropdownOpen(false);
	}, [conversationId]);

	useEffect(() => {
		setIsSendDisabled(!/\S/.test(value));
	}, [value]);

	useEffect(() => {
		setIsSendDisabled(attachments.length === 0);
	}, [attachments]);

	useEffect(() => {
		if (!isSendDisabled) {
			onScrollToBottom();
		}
	}, [isSendDisabled]);

	return (
		<div>
			<Styled.MessageWrapper>
				<Styled.MessageBar className='msg-bar'>
					<Styled.TextAreaWrapper>
						<Styled.TextArea rows={2} placeholder='Message' onChange={handleChange} ref={textAreaRef} onKeyDown={handleOnKeyPress} value={value} />
					</Styled.TextAreaWrapper>
					<Styled.MessageBarControlsWrapper>
						<Styled.MessageBarControls>
							{isEnabled(FEATURE_FLAG_INBOX_UPLOAD_ATTATCHMENTS) && (
								<Styled.CustomDropdown icon='custom' size='18' open={isDropdownOpen} position='left' data-testid='inbox-input-actions-dropdown'>
									<DropdownMenu>
										<DropdownItem
											data-testid='inbox-input-actions-dropdown-item'
											onClick={() => {
												inputRef?.current?.click();
												setIsDropdownOpen(true);
											}}
										>
											<span>
												<Icon name='add-document' /> Attach file...
											</span>
											<Styled.InvisibleInput ref={inputRef} multiple type='file' onChange={(e) => onChangeAttachFile(e)} data-testid='inbox-file-input' />
										</DropdownItem>
									</DropdownMenu>
								</Styled.CustomDropdown>
							)}
							<Styled.Send
								data-testid='send-msg-btn'
								onClick={onClickSend}
								disabled={isSendDisabled || (isSendDisabled && isDisabled) || (isSendDisabled && attachments.length === 0)}
							>
								<span>
									<Icon name='send' />
								</span>
							</Styled.Send>
						</Styled.MessageBarControls>
					</Styled.MessageBarControlsWrapper>
				</Styled.MessageBar>
			</Styled.MessageWrapper>
			<Styled.AttachedFilesWrapper>{renderAttachments(attachments)}</Styled.AttachedFilesWrapper>
		</div>
	);
};

export default ChatInput;
