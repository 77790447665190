import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { RichText } from 'styles/utils/RichText';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	.quill {
		min-height: 250px;
		border: 1px solid ${colors.AffiliateMarketplaceCreation.textareaBorder};
		border-radius: 4px;
		background-color: ${colors.AffiliateMarketplaceCreation.textareaBackground};
		transition: border 0.2s ease-in-out;
		&:hover {
			border: 1px solid ${newColors.steel};
		}
	}

	.ql-toolbar.ql-snow,
	.ql-container.ql-snow {
		border: none;
	}

	.ql-editor,
	.ql-blank {
		height: 200px;
	}

	&.white-background {
		.quill {
			background-color: ${colors.AffiliateMarketplaceCreation.inputFocusedBackground};
		}
	}
	&.description {
		.quill {
			background-color: ${colors.AffiliateMarketplaceCreation.textareaBackground};
		}
	}
	&.assignment-creation {
		.quill {
			height: auto;
			min-height: 300px;
			@media screen and (${devices.md}) {
				min-height: 400px;
			}
			background-color: ${colors.AffiliateMarketplaceCreation.textareaBackground};
		}
	}
	&.campaign-creation {
		.quill {
			height: auto;
			min-height: 200px;
			max-height: 78vh;
			border: 1px solid ${colors.AffiliateMarketplaceCreation.textareaBorder};
			border-radius: 10px;
			background-color: ${colors.AffiliateMarketplaceCreation.textareaBackground};
		}
		.ql-editor {
			height: auto;
			max-height: 70vh;
			min-height: 200px;
			${scrollbarY};
		}
		.ql-blank {
			min-height: 200px;
		}
	}

	.ql-editor {
		${RichText}
	}
`;

const ReadOnly = styled.div`
	${RichText}
`;

const Styled = {
	Wrapper,
	ReadOnly,
};

export default Styled;
