import { init } from '@fullstory/browser';
import { useEffect } from 'react';

import { useAppSelector } from 'hooks/useUserAppSelector';

const INTERNAL_EMAIL_PATTERN = /@collabs.se$/i;

const FullStoryInit = () => {
	const userEmail = useAppSelector((state) => state.user.email);
	const env = process.env.NODE_ENV;
	const orgId = process.env.VITE_APP_FULLSTORY_ORG_ID;

	useEffect(() => {
		if (env !== 'production' || !orgId || INTERNAL_EMAIL_PATTERN.test(userEmail)) {
			return;
		}

		init({ orgId });
	}, [userEmail, env, orgId]);

	return null;
};

export default FullStoryInit;
