import { inject, injectable } from 'inversify';

import {
	CampaignTotalQuery,
	ClickDailyQuery,
	ClickTotalQuery,
	ConversationDailyQuery,
	ConversationTotalQuery,
	CountryQuery,
	CpmTotalQuery,
	EngagementDailyQuery,
	EngagementTotalQuery,
	ImpressionDailyQuery,
	ImpressionsTotalQuery,
	InfluencerTotalQuery,
	InvoiceDailyQuery,
	InvoiceTotalQuery,
	ListActiveCountriesQuery,
	ReachDailyQuery,
	ReachTotalQuery,
	StatisticsAudienceAgeQuery,
	StatisticsAudienceCountryQuery,
	StatisticsAudienceGenderQuery,
	SummaryQuery,
	TotalAssignmentPerAssignmentTypeQuery,
	TotalAssignmentPerCampaignQuery,
	TotalAssignmentPerCountryQuery,
} from 'api-queries';
import {
	CountResponse,
	CountryCollectionResponse,
	MoneyResponse,
	StatisticsAdminStatisticsPerMonthCollectionResponse,
	StatisticsAgeSummaryResponse,
	StatisticsCampaignSummaryCollectionResponse,
	StatisticsConversionSummaryResponse,
	StatisticsCountryAndMoneyCollectionResponse,
	StatisticsCountrySummaryCollectionResponse,
	StatisticsDateAndConversionCollectionResponse,
	StatisticsDateAndCountCollectionResponse,
	StatisticsDateAndMoneyCollectionResponse,
	StatisticsDateAndPostEngagementCollectionResponse,
	StatisticsEngagementResponse,
	StatisticsGenderSummaryResponse,
	StatisticsInfluencerWithStatisticsCollectionResponse,
	StatisticsNameAndCountCollectionResponse,
	StatisticsSummaryResponse,
} from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type CountryApiClientInterface from 'services/ApiClient/CountryApiClientInterface';
import type StatisticsApiClientInterface from 'services/ApiClient/StatisticsApiClientInterface';

@injectable()
class StatisticsManager extends ApiManager {
	private readonly client: StatisticsApiClientInterface;
	private readonly countryClient: CountryApiClientInterface;
	private readonly defaultConfig = {
		revalidateOnFocus: false,
		revalidateIfStale: false,
	};

	constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.StatisticsApiClientInterface) client: StatisticsApiClientInterface,
		@inject(ApiClientService.CountryApiClientInterface) countryClient: CountryApiClientInterface,
	) {
		super(cacheManager, new ResourceManager());
		this.client = client;
		this.countryClient = countryClient;
	}

	public showSummary(queryBuilder = RequestQueryBuilder.create<SummaryQuery>()) {
		const key = `showSummary::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.summary(queryBuilder.toQuery());

		return this.swr<StatisticsSummaryResponse>(key, fetcher, this.defaultConfig);
	}

	public listAdminMonthlyStatistics() {
		const key = `listAdminMonthlyStatistics`;
		const fetcher = () => this.client.monthly({ include: ':hateoas(false)' });

		return this.swr<StatisticsAdminStatisticsPerMonthCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listActiveCountries(queryBuilder = RequestQueryBuilder.create<ListActiveCountriesQuery>()) {
		const key = `listActiveCountries::${queryBuilder.toHash()}`;
		const fetcher = () => this.countryClient.listActiveCountries(queryBuilder.toQuery());

		return this.swr<CountryCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listAudienceCountry(queryBuilder = RequestQueryBuilder.create<StatisticsAudienceCountryQuery>()) {
		const key = `listAudienceCountry::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.audienceCountry(queryBuilder.toQuery());

		return this.swr<StatisticsCountrySummaryCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public showAudienceAge(queryBuilder = RequestQueryBuilder.create<StatisticsAudienceAgeQuery>()) {
		const key = `showAudienceAge::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.audienceAge(queryBuilder.toQuery());

		return this.swr<StatisticsAgeSummaryResponse>(key, fetcher, this.defaultConfig);
	}

	public showAudienceGender(queryBuilder = RequestQueryBuilder.create<StatisticsAudienceGenderQuery>()) {
		const key = `showAudienceGender::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.audienceGender(queryBuilder.toQuery());

		return this.swr<StatisticsGenderSummaryResponse>(key, fetcher, this.defaultConfig);
	}

	public listAssignmentsCountries(queryBuilder = RequestQueryBuilder.create<TotalAssignmentPerCountryQuery>()) {
		const key = `listAssignmentsCountries::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.totalAssignmentPerCountry(queryBuilder.toQuery());

		return this.swr<StatisticsNameAndCountCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listAssignmentsCampaigns(queryBuilder = RequestQueryBuilder.create<TotalAssignmentPerCampaignQuery>()) {
		const key = `listAssignmentsCampaigns::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.totalAssignmentPerCampaign(queryBuilder.toQuery());

		return this.swr<StatisticsNameAndCountCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	/**
	 * A type is "instagram_post", "instagram_story", "tiktok_post" etc
	 */
	public listAssignmentsByType(queryBuilder = RequestQueryBuilder.create<TotalAssignmentPerAssignmentTypeQuery>()) {
		const key = `listAssignmentsByType::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.totalAssignmentPerAssignmentType(queryBuilder.toQuery());

		return this.swr<StatisticsNameAndCountCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listConversationDaily(queryBuilder = RequestQueryBuilder.create<ConversationDailyQuery>()) {
		const key = `listConversationDaily::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.conversationDaily(queryBuilder.toQuery());

		return this.swr<StatisticsDateAndConversionCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public showConversationTotal(queryBuilder = RequestQueryBuilder.create<ConversationTotalQuery>()) {
		const key = `showConversationTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.conversationTotal(queryBuilder.toQuery());

		return this.swr<StatisticsConversionSummaryResponse>(key, fetcher, this.defaultConfig);
	}

	public listInfluencerTotal(queryBuilder = RequestQueryBuilder.create<InfluencerTotalQuery>()) {
		const key = `listInfluencerTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.influencerTotal(queryBuilder.toQuery());

		return this.swr<StatisticsInfluencerWithStatisticsCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listInvoiceDaily(queryBuilder = RequestQueryBuilder.create<InvoiceDailyQuery>()) {
		const key = `listInvoiceDaily::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.invoiceDaily(queryBuilder.toQuery());

		return this.swr<StatisticsDateAndMoneyCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listInvoiceCountry(queryBuilder = RequestQueryBuilder.create<CountryQuery>()) {
		const key = `listInvoiceCountry::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.country(queryBuilder.toQuery());

		return this.swr<StatisticsCountryAndMoneyCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public showInvoiceTotal(queryBuilder = RequestQueryBuilder.create<InvoiceTotalQuery>()) {
		const key = `showInvoiceTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.invoiceTotal(queryBuilder.toQuery());

		return this.swr<MoneyResponse>(key, fetcher, this.defaultConfig);
	}

	public showCpmTotal(queryBuilder = RequestQueryBuilder.create<CpmTotalQuery>()) {
		const key = `showCpmTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.cpmTotal(queryBuilder.toQuery());

		return this.swr<MoneyResponse>(key, fetcher, this.defaultConfig);
	}

	public showClickTotal(queryBuilder = RequestQueryBuilder.create<ClickTotalQuery>()) {
		const key = `showClickTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.clickTotal(queryBuilder.toQuery());

		return this.swr<CountResponse>(key, fetcher, this.defaultConfig);
	}

	public listClickDaily(queryBuilder = RequestQueryBuilder.create<ClickDailyQuery>()) {
		const key = `listClickDaily::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.clickDaily(queryBuilder.toQuery());

		return this.swr<StatisticsDateAndCountCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public showEngagementTotal(queryBuilder = RequestQueryBuilder.create<EngagementTotalQuery>()) {
		const key = `showEngagementTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.engagementTotal(queryBuilder.toQuery());

		return this.swr<StatisticsEngagementResponse>(key, fetcher, this.defaultConfig);
	}

	public listEngagementDaily(queryBuilder = RequestQueryBuilder.create<EngagementDailyQuery>()) {
		const key = `listEngagementDaily::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.engagementDaily(queryBuilder.toQuery());

		return this.swr<StatisticsDateAndPostEngagementCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public showImpressionsTotal(queryBuilder = RequestQueryBuilder.create<ImpressionsTotalQuery>()) {
		const key = `showImpressionsTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.impressionsTotal(queryBuilder.toQuery());

		return this.swr<CountResponse>(key, fetcher, this.defaultConfig);
	}

	public listImpressionsDaily(queryBuilder = RequestQueryBuilder.create<ImpressionDailyQuery>()) {
		const key = `listImpressionsDaily::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.impressionDaily(queryBuilder.toQuery());

		return this.swr<StatisticsDateAndCountCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public showReachTotal(queryBuilder = RequestQueryBuilder.create<ReachTotalQuery>()) {
		const key = `showReachTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.reachTotal(queryBuilder.toQuery());

		return this.swr<CountResponse>(key, fetcher, this.defaultConfig);
	}

	public listReachDaily(queryBuilder = RequestQueryBuilder.create<ReachDailyQuery>()) {
		const key = `listReachDaily::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.reachDaily(queryBuilder.toQuery());

		return this.swr<StatisticsDateAndCountCollectionResponse>(key, fetcher, this.defaultConfig);
	}

	public listCampaignTotal(queryBuilder = RequestQueryBuilder.create<CampaignTotalQuery>()) {
		const key = `listCampaignTotal::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.campaignTotal(queryBuilder.toQuery());

		return this.swr<StatisticsCampaignSummaryCollectionResponse>(key, fetcher, this.defaultConfig);
	}
}

export default StatisticsManager;
