import styled from 'styled-components';

import { InputFieldContainerWithIconV2, InputFieldV2 } from 'styles/formElements/input';
import { InputType } from 'styles/formElements/types';
import colors from 'styles/variables/colors';
import { breakpoints } from 'styles/variables/media-queries';
import { rem, px, spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	height: calc(100vh - 100px);
	padding: ${rem[5]}rem ${rem[3]}rem;

	@media (max-width: ${breakpoints.sm}) {
		padding-left: 0;
		padding-right: 0;
	}
`;

const BlockTitle = styled.h6`
	margin-top: 0;
	margin-bottom: ${px[3]}px;
	font-size: 1rem;
	line-height: 0.5;
`;

const BlockCard = styled.div`
	width: 100%;
	padding: ${rem[4]}rem;
	margin-bottom: ${px[5]}px;

	border: 1px solid ${colors.AffiliateMarketplaceCreation.blockCardBorder};
	border-radius: 0.625rem;
	background-color: ${colors.AffiliateMarketplaceCreation.blockCardBackground};

	@media (max-width: ${breakpoints.sm}) {
		padding-left: ${rem[2]}rem;
		padding-right: ${rem[2]}rem;
	}
`;

const InputContainer = styled.div`
	margin-bottom: ${spacing[3].px};

	& > h6 {
		line-height: 0.5;
		margin: 0;
		font-size: 1rem;
		margin-bottom: ${px[3]}px;

		& > span {
			&.optional {
				color: ${colors.AffiliateMarketplaceCreation.optionBoxTextColor};
				font-weight: 500;
				font-size: 0.875rem;
			}
		}
	}

	& > a {
		display: flex;
		justify-content: flex-end;
		margin-top: ${px[2]}px;
	}

	& > div {
		&.program-id {
			width: 50%;
			margin-bottom: ${spacing[4].px};
		}

		&.store-link {
			margin-bottom: ${spacing[3].px};
		}

		&.cover-image {
		}

		&.input--number {
			max-width: 312px;
		}
		&.inner-wrapper {
			border-radius: 10px;
			background-color: ${colors.AffiliateMarketplaceCreation.blockCardBorder};
			padding: ${rem[3]}rem;
			display: grid;
			grid-template-columns: 2fr 1fr;
			column-gap: ${px[3]}px;

			@media (max-width: ${breakpoints.sm}) {
				grid-template-columns: 1fr;
				row-gap: ${px[3]}px;
			}
		}
	}
`;

const InputFieldContainer = styled.div<InputType>`
	${InputFieldContainerWithIconV2};

	background-color: ${colors.AffiliateMarketplaceCreation.inputBackground};
	border-radius: 10px;
	overflow: hidden;

	&.influencer-commission-box {
		max-width: 200px;
	}
`;

const InputField = styled.input`
	${InputFieldV2};
	border-left: none;
	font-size: 1rem;

	background-color: ${colors.AffiliateMarketplaceCreation.inputBackground};

	&:focus {
		background-color: ${colors.AffiliateMarketplaceCreation.inputFocusedBackground};
	}

	&.white-background {
		background-color: ${colors.AffiliateMarketplaceCreation.inputFocusedBackground};
	}
`;

const Label = styled.label`
	display: block;
	font-size: 0.875rem;
	margin-bottom: calc(${px[2]}px - 3px);
	color: ${colors.AffiliateMarketplaceCreation.inputLabelColor};
`;

const EmptyDiv = styled.div`
	height: 1px;
`;

const HelpText = styled.div`
	font-size: 0.75rem;
	color: ${colors.AffiliateMarketplaceCreation.helpText};
	margin-bottom: ${px[2]}px;
`;

const CommissionWrapper = styled.div`
	border-radius: 10px;
	background-color: #f2f2f2;
	padding: ${rem[3]}rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: ${px[3]}px;

	& > div {
		&.commission-result {
			display: flex;
			align-items: center;
			& > div {
				&.equal-sign {
					margin-right: ${px[3]}px;
					font-size: 1.2rem;
					font-weight: 700;
				}
			}
		}
	}

	@media (max-width: ${breakpoints.sm}) {
		grid-template-columns: 1fr;
		column-gap: 0;
		row-gap: ${px[3]}px;

		& > div {
			&.commission-result {
				flex-direction: column;
				align-items: flex-start;

				& > div {
					&.equal-sign {
						display: none;
					}
				}
			}
		}
	}
`;

const CollabsCutBox = styled.div`
	height: 56px;
	align-items: center;
	width: 100%;
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.65;
`;

const ErrorMessage = styled.p`
	width: 100%;
	font-size: 0.75rem;
	margin-top: 3px;
	color: ${colors.error};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.AffiliateMarketplaceCreation.dividerLine};
	margin-bottom: ${spacing[3].px};
`;

const Styled = {
	Wrapper,
	BlockTitle,
	BlockCard,
	InputContainer,
	InputFieldContainer,
	InputField,
	Label,
	EmptyDiv,
	CommissionWrapper,
	HelpText,
	CollabsCutBox,
	ErrorMessage,
	Divider,
};

export default Styled;
