import { useContext, useEffect, useState } from 'react';

import CountrySummaryList from 'components/Discovery/Components/CountrySummaryList';
import GenderData from 'components/Discovery/Components/GenderData';
import { convertDateFormat } from 'components/Discovery/Components/InfluencerDetailCard/types';
import PlatformTab from 'components/Discovery/Components/PlatformTab';
import ProfileCard from 'components/Discovery/Components/ProfileCard';
import WhyDoIGetThisResultModal from 'components/Discovery/Components/WhyDoIGetThisResultModal';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import { ICollabsResponse } from 'services/Response.types';
import { formatNumberWithDecimals } from 'shared/helpers/Chart/chart-util';
import Grid from 'styles/grid/grid';
import colors from 'styles/variables/colors';

import Styled from './InfluencerDetailCard.style';

const InfluencerDetailCard = (props: {
	influencer: InfluencerListItemType;
	onSelect: () => void;
	isSelected: boolean;
	isList?: boolean;
	whyDoIGetLink?: string;
	onFetchInfluencerData: (id: string) => void;
}) => {
	const [datasets, setDatasets] = useState<{ total: number; data: number[]; labels: string[] }>({
		total: 0,
		labels: ['Women', 'Men'],
		data: [100, 200],
	});
	const [countryData, setCountryData] = useState<{ name: string; followers: number }[]>([]);
	const { getReason } = useContext(DiscoveryContext);
	const [showReasonModal, setShowReasonModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reasons, setReasons] = useState<ICollabsResponse | null>(null);

	const showReasonHandler = (url: string) => {
		setShowReasonModal(true);
		setLoading(true);
		getReason(url).then((res: ICollabsResponse) => {
			if (res) {
				setReasons(res);
			} else {
				setReasons(null);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (props.influencer.audienceDetail) {
			if (props.influencer.audienceDetail.genders) {
				const removedUnknownData: { [key: string]: { count: number | null; ratio: number | null } } = {};
				Object.entries(props.influencer.audienceDetail.genders)
					.filter(([genderKey]) => genderKey !== 'unknown')
					.forEach(([genderKey, value]) => {
						removedUnknownData[genderKey] = value;
					});
				const data = Object.values(removedUnknownData).map((genderValue: { count: number | null; ratio: number | null }) =>
					genderValue.ratio !== null ? genderValue.ratio : 0,
				);
				const labels = Object.keys(removedUnknownData)
					.filter((genderKey) => genderKey !== 'unknown')
					.map((el) => {
						switch (el) {
							case 'male':
							case 'man':
							case 'men':
								return 'Men';
							case 'female':
							case 'woman':
							case 'women':
								return 'Women';
							default:
								return '';
						}
					});
				setDatasets({
					total: props.influencer.followersCount,
					labels: labels,
					data: data,
				});
			}
			if (props.influencer.audienceDetail.countries) {
				const countries = props.influencer.audienceDetail.countries;
				const data = Object.keys(countries).map((country) => {
					return { name: country.toLocaleUpperCase(), followers: countries[country].count || 0 };
				});
				setCountryData(data);
			}
		}
	}, [props.influencer]);

	return (
		<>
			<Styled.Wrapper>
				{props.influencer.related.length > 0 ? (
					<PlatformTab
						relatedAccounts={props.influencer.related.map((account) => {
							if (account.direction === 'outgoing') {
								return account.to;
							} else if (account.direction === 'incoming') {
								return account.from;
							}
						})}
						defaultInfluencerData={props.influencer}
						onSelectPlatform={(id: string) => props.onFetchInfluencerData(id)}
					/>
				) : null}
				<Grid.Container
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Grid.Column md={12}>
						<Styled.FlexDiv marginBottom='0.5rem' className='influencer-info'>
							<Styled.Div className='divide-line'>
								<Styled.InfluencerData className='label'>Influencer gender</Styled.InfluencerData>
								<Styled.InfluencerData className='value'>{props.influencer.gender ? props.influencer.gender : 'unknown'}</Styled.InfluencerData>
							</Styled.Div>
							<Styled.Div className='divide-line'>
								<Styled.InfluencerData className='label'>Influencer age</Styled.InfluencerData>
								<Styled.InfluencerData className='value'>{props.influencer.age ? props.influencer.age : 'unknown'}</Styled.InfluencerData>
							</Styled.Div>
							<Styled.Div>
								<Styled.InfluencerData className='label'>Influencer country</Styled.InfluencerData>
								<Styled.InfluencerData className='value'>{props.influencer.country ? props.influencer.country.toUpperCase() : 'unknown'}</Styled.InfluencerData>
							</Styled.Div>
						</Styled.FlexDiv>
					</Grid.Column>
					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Statistics</Styled.DataBlockTitle>
							<ProfileCard data={props.influencer} />
						</Styled.DataContainer>
					</Grid.Column>

					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Age span of followers</Styled.DataBlockTitle>
							{props.influencer.audienceDetail
								? Object.entries(props.influencer.audienceDetail.ages).map(([key, value], index) => {
										return (
											<Styled.AgeSpanDataWrapper key={`${key}-${index}`} className={index % 2 ? '' : 'odd'}>
												<span>{key}</span>
												<span className='value'>{value.ratio !== null ? formatNumberWithDecimals(value.ratio, '%') : '-'}</span>
											</Styled.AgeSpanDataWrapper>
										);
									})
								: null}
						</Styled.DataContainer>
					</Grid.Column>

					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Top 5 countries</Styled.DataBlockTitle>
							<CountrySummaryList items={countryData} totalFollowers={props.influencer.followersCount} sign='%' isTopThree={false} />
						</Styled.DataContainer>
					</Grid.Column>

					<Grid.Column md={3}>
						<Styled.DataContainer minHeight='230px'>
							<Styled.DataBlockTitle>Gender of followers</Styled.DataBlockTitle>
							<Styled.GenderDataContainer>
								<GenderData data={datasets} colors={[colors.chartPrimary, colors.chartSecondary, colors.chartTertiary]} />
							</Styled.GenderDataContainer>
						</Styled.DataContainer>
					</Grid.Column>
					<Grid.Column>
						{props.influencer.brandAffiliations && props.influencer.brandAffiliations.length > 0 ? (
							<Styled.DataContainer minHeight='85px' className='brand-affiliations'>
								<Styled.DataBlockTitle>Brand affiliations</Styled.DataBlockTitle>
								<Grid.Container>
									{props.influencer.brandAffiliations.map(({ username, url, postedAt }, index) => {
										if (index < 8) {
											return (
												<Grid.Column lg={3} md={6} sm={12} key={`${username}-${index}`}>
													<Styled.FlexDiv className='brand-affiliation-item' align-items='center'>
														<div className='brand-affiliation-item-username'>{username}</div>
														{url !== null ? (
															<div>
																<Styled.DateInfo>{convertDateFormat(postedAt)}</Styled.DateInfo>
																<Styled.LinkToPost href={url} target='_blank' className='post-link'>
																	View post
																</Styled.LinkToPost>
															</div>
														) : (
															<div>
																<Styled.DateInfo>{convertDateFormat(postedAt)}</Styled.DateInfo>
																<span className='missing-link'>Link missing</span>
															</div>
														)}
													</Styled.FlexDiv>
												</Grid.Column>
											);
										}
									})}
								</Grid.Container>
							</Styled.DataContainer>
						) : null}
					</Grid.Column>
					{props.whyDoIGetLink && (
						<Grid.Column>
							<Styled.FooterWrapper
								onClick={() => {
									// @ts-ignore
									showReasonHandler(props.whyDoIGetLink);
								}}
							>
								<Styled.CustomIcon name='help' size='20' />
								<span id='reasonLinkText'>Why do I get this result?</span>
							</Styled.FooterWrapper>
						</Grid.Column>
					)}
				</Grid.Container>
			</Styled.Wrapper>
			{showReasonModal && (
				<WhyDoIGetThisResultModal
					isOpen={showReasonModal}
					isLoading={loading}
					onClose={() => {
						setShowReasonModal(false);
					}}
					reasons={reasons}
				/>
			)}
		</>
	);
};

export default InfluencerDetailCard;
