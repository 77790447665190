import { Model, Store } from 'json-api-models';
import { isNil } from 'lodash';
import moment from 'moment';

import { AssignmentType } from 'components/Assigment/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { UserType } from 'reducers/UserReducers/types';
import { AssignmentStatus, AssignmentReviewStatus } from 'shared/Types/Assignment';
import { getLatestReview } from 'shared/utils/getLatestReview';

import { TODO_STATUS, TODO_TYPE, TodoType } from './types';

export const formatDateToDDMMM = (enteredDate: string) => {
	if (enteredDate) {
		return moment(enteredDate).format('DD MMM');
	} else {
		return '-';
	}
};

export const isTodoWithinPeriod = (todo: Todo, period: number) => {
	const selectedPeriod = new Date();
	const todoDeadlineDate = new Date(todo.deadlineDate);

	selectedPeriod.setDate(selectedPeriod.getDate() + period);

	return selectedPeriod >= todoDeadlineDate ? true : false;
};

export const isTodoDone = (todo: Todo) => {
	return todo?.status === TODO_STATUS.DONE;
};

export const generateTodos = (assignment: Model, campaign: Model, campaignInstagramOwner: Model) => {
	const uploadContentTodo = new Todo(assignment, campaign, TODO_TYPE.UPLOAD_CONTENT, campaignInstagramOwner);
	const publishContentTodo = new Todo(assignment, campaign, TODO_TYPE.PUBLISH_CONTENT, campaignInstagramOwner);
	const updateStatisticsTodo = new Todo(assignment, campaign, TODO_TYPE.UPDATE_STATISTICS, campaignInstagramOwner);
	const deadlines =
		assignment.groups.length === 0
			? []
			: assignment.groups[0].deadlines.map((deadline: Model) => {
					return new Todo(assignment, campaign, TODO_TYPE.CUSTOM_DEADLINE, campaignInstagramOwner, deadline);
				});

	return [uploadContentTodo, publishContentTodo, updateStatisticsTodo, ...deadlines];
};

export const getCampaignInvitesUri = () => {
	const user = useAppSelector((state) => state.user);
	const includes = ['commission'];

	const query = new URLSearchParams({
		includes: includes.join(','),
		used: 'false',
		influencer: user.influencer.id,
	});

	return `/campaign-invites?${query}`;
};

export const getCampaignsUri = () => {
	const user = useAppSelector((state) => state.user);

	const includes = [
		'invites',
		'assignments',
		'assignments.groups.deadlines',
		'assignments.reviews.media',
		'campaignInstagramOwners',
		'campaignInstagramOwners.influencer',
		'campaignInstagramOwners.campaign',
		'campaignInstagramOwners.campaignInstagramOwnerAssignments.instagramPosts',
		'campaignInstagramOwners.campaignInstagramOwnerAssignments.instagramStories',
		'campaignInstagramOwners.campaignInstagramOwnerAssignments.assignment.reviews',
		'campaignInstagramOwners.campaignInstagramOwnerAssignments.assignment.groups',
		'campaignInstagramOwners.campaignInstagramOwnerCommissions.commission',
		'campaignInstagramOwners.campaignInstagramOwnerProducts.product',
		'brand',
	];

	const statuses = ['done', 'active'];

	const query = new URLSearchParams({
		includes: includes.join(','),
		statuses: statuses.join(','),
		influencer: user.influencer.id,
		joined: 'true',
	});

	return `/campaigns?${query}`;
};

/**
 * assignment.reviews[0].newStatus << is status of content upload (0 - waiting, 1 - approved, 2 - declined)
 * campaign.campaignInstagramOwners[0].campaignInstagramOwnerAssignments[0].newFancyStatus << 'accepted'(default), 'in_review', 'posted_on_instagram'
 */

export class Todo {
	id: string;
	campaignName: string;
	campaignId: string;
	brandName: string;
	assignmentName: string;
	start: string;
	end: string;
	status: string;
	formatDeadlineDate: string;
	deadlineDate: string;
	todoType: TodoType;
	isCustomTodo: boolean;
	assignmentType?: string;
	deadlineName?: string;
	campaignInstagramOwnerAssignmentId: string;
	campaignInstagramOwnerId: string;

	constructor(assignment: Model, campaign: Model, todoType: TodoType, campaignInstagramOwner: Model, deadline?: Model) {
		const CIOAssignment = campaignInstagramOwner?.campaignInstagramOwnerAssignments?.find((x: Model) => x.assignment?.id === assignment?.id);
		this.id = assignment?.id;
		this.campaignName = campaign.name;
		this.campaignId = campaign.shortId;
		this.brandName = campaign.brand?.name;
		this.assignmentName = assignment?.name;
		this.start = assignment?.groups[0]?.start;
		this.end = assignment?.groups[0]?.end;
		this.todoType = todoType;
		this.assignmentType = assignment?.kind;
		this.isCustomTodo = false;
		this.campaignInstagramOwnerAssignmentId = CIOAssignment?.id || '';
		this.campaignInstagramOwnerId = campaignInstagramOwner?.id;

		if (deadline) {
			this.isCustomTodo = true;
			this.deadlineName = deadline.name;
			this.formatDeadlineDate = formatDateToDDMMM(deadline.date);
			this.deadlineDate = deadline.date;
		} else if (this.end === undefined) {
			this.deadlineDate = '';
			this.formatDeadlineDate = '-';
		} else {
			this.deadlineDate = '';
			this.formatDeadlineDate = '-';
		}

		this.status = this.setStatus(assignment, assignment?.reviews, this.deadlineDate, this.todoType, CIOAssignment);
	}

	private setStatus(assignment: Model, reviews: Model[], deadlineDate: string | undefined, todoType: TodoType, CIOAssignment: Model) {
		const latestReview = getLatestReview(CIOAssignment?.assignment?.reviews);

		// mark todo as done if affiliate type
		// get type from attributes to make sure we don't mix with the model type
		if (assignment.kind === AssignmentType.AFFILIATE_TASK) {
			return TODO_STATUS.DONE;
		}

		const isChangesRequested = (review: Model | null) => {
			if (isNil(review)) {
				return false;
			}
			return review.newStatus === AssignmentReviewStatus.CHANGE_REQUESTED;
		};

		const uploadContentStatus = () => {
			switch (CIOAssignment?.newFancyStatus) {
				case AssignmentStatus.WAITING_FOR_UPLOAD:
					return TODO_STATUS.UPCOMING;
				case AssignmentStatus.IN_REVIEW:
					return isChangesRequested(latestReview) ? TODO_STATUS.CHANGE_REQUESTED : TODO_STATUS.PENDING;
				case AssignmentStatus.APPROVED:
				case AssignmentStatus.REVIEW_APPROVED:
				case AssignmentStatus.DECLINED:
				case AssignmentReviewStatus.REJECTED:
				case AssignmentStatus.STATISTICS_UPLOADED:
					return TODO_STATUS.DONE;
				case AssignmentReviewStatus.CHANGE_REQUESTED && typeof deadlineDate === 'string':
					return this.isLate(deadlineDate!) ? TODO_STATUS.LATE : TODO_STATUS.CHANGE_REQUESTED;
				default:
					return '';
			}
		};

		const publishContentStatus = () => {
			const approved = this.isPosted(CIOAssignment) ? TODO_STATUS.DONE : TODO_STATUS.READY_TO_PUBLISH;
			switch (CIOAssignment?.newFancyStatus) {
				case AssignmentStatus.WAITING_FOR_UPLOAD:
					return TODO_STATUS.UPCOMING;
				case AssignmentStatus.IN_REVIEW:
					return this.isLate(deadlineDate ?? '') ? TODO_STATUS.LATE : TODO_STATUS.UPCOMING;
				case AssignmentStatus.REVIEW_APPROVED:
					return approved;
				case AssignmentReviewStatus.CHANGE_REQUESTED:
					return this.isLate(deadlineDate ?? '') ? TODO_STATUS.LATE : TODO_STATUS.CHANGE_REQUESTED;
				case AssignmentStatus.STATISTICS_UPLOADED:
				case AssignmentStatus.DECLINED:
				case AssignmentStatus.APPROVED:
				case AssignmentReviewStatus.REJECTED:
					return TODO_STATUS.DONE;
				default:
					return '';
			}
		};

		const updateStatisticStatus = () => {
			const approved = this.isPosted(CIOAssignment) ? TODO_STATUS.DONE : this.isLate(deadlineDate ?? '') ? TODO_STATUS.LATE : TODO_STATUS.UPCOMING;
			switch (CIOAssignment?.newFancyStatus) {
				case AssignmentStatus.WAITING_FOR_UPLOAD:
					return TODO_STATUS.UPCOMING;
				case AssignmentStatus.IN_REVIEW:
					return this.isLate(deadlineDate ?? '') ? TODO_STATUS.LATE : TODO_STATUS.UPCOMING;
				case AssignmentStatus.REVIEW_APPROVED:
					return approved;
				case AssignmentReviewStatus.CHANGE_REQUESTED:
					return this.isLate(deadlineDate ?? '') ? TODO_STATUS.LATE : TODO_STATUS.CHANGE_REQUESTED;
				case AssignmentStatus.APPROVED:
				case AssignmentStatus.STATISTICS_UPLOADED:
				case AssignmentStatus.DECLINED:
				case AssignmentReviewStatus.REJECTED:
					return TODO_STATUS.DONE;
				default:
					return '';
			}
		};

		if (reviews && Array.isArray(reviews) && reviews.length > 0) {
			switch (todoType) {
				case TODO_TYPE.UPLOAD_CONTENT:
					return uploadContentStatus();
				case TODO_TYPE.PUBLISH_CONTENT:
					return publishContentStatus();
				case TODO_TYPE.UPDATE_STATISTICS:
					return updateStatisticStatus();
				case TODO_TYPE.CUSTOM_DEADLINE:
					return this.isLate(deadlineDate ?? '') ? TODO_STATUS.LATE : TODO_STATUS.UPCOMING;
				default:
					return '';
			}
		} else {
			if (this.isLate(deadlineDate ?? '')) {
				return TODO_STATUS.LATE;
			} else {
				return TODO_STATUS.UPCOMING;
			}
		}
	}

	private isPosted(campaignInstagramOwnerAssignment: Model | null) {
		if (campaignInstagramOwnerAssignment !== null) {
			return campaignInstagramOwnerAssignment?.newFancyStatus === AssignmentStatus.STATISTICS_UPLOADED;
		} else {
			return false;
		}
	}

	private isLate(deadlineDate: string) {
		const now = new Date();
		const today = new Date(now.toISOString().split('T')[0]).getTime();
		const deadline = new Date(deadlineDate).getTime();

		return today > deadline ? true : false;
	}

	public getTagClassName(status: string) {
		switch (status) {
			case TODO_STATUS.LATE:
				return 'late';
			case TODO_STATUS.PENDING:
				return 'pending';
			case TODO_STATUS.CHANGE_REQUESTED:
				return 'change_requested';
			case TODO_STATUS.READY_TO_PUBLISH:
				return 'publish';
			case TODO_STATUS.UPCOMING:
				return 'upcoming';
			case TODO_STATUS.DONE:
				return 'done';
			default:
				return '';
		}
	}
}

export const getSelectedProfile = (campaign: Model, user?: UserType) => {
	return campaign.campaignInstagramOwners.find((CIO: Model) => CIO.influencer?.id === user?.influencer?.id);
};

export const profileIsAssignedToThisAssignment = (selectedprofile: Model, assignment: Model) => {
	const assignmentIds = selectedprofile.campaignInstagramOwnerAssignments.map((cioa: Model) => cioa.assignment?.id);
	return assignmentIds.includes(assignment.id);
};
export class ActiveCampaign {
	campaign: Model;
	affiliate: boolean;
	name: string;
	id: string;
	shortId: string;
	brand: string;
	image: string;
	compensation: CampaignCompensation;
	campaignInstagramOwner: Model;
	publishingDate: string;
	assignments: AssignmentObject[];

	constructor(campaign: Model, campaignStore: Store, user?: UserType) {
		this.campaign = campaign;
		this.affiliate = campaign.affiliate;
		this.name = campaign.name;
		this.id = campaign.id;
		this.shortId = campaign.shortId;
		this.campaignInstagramOwner = getSelectedProfile(campaign, user);
		this.brand = campaign.brand?.name;
		this.image = campaign.links.smallCoverPhoto;
		this.compensation = new CampaignCompensation(getSelectedProfile(campaign, user));
		this.assignments = campaign.assignments
			?.filter((assignment: Model) => profileIsAssignedToThisAssignment(getSelectedProfile(campaign, user), assignment))
			.map((assignment: Model) => {
				return new AssignmentObject(assignment, campaignStore.find('assignment', assignment.id));
			});
		this.publishingDate = campaign.firstAssignmentGroupDate;
	}
}

export class CampaignCompensation {
	commissions: Model[];
	products: Model[];
	totalCommission: number;
	currency: string;

	constructor(campaignInstagramOwner: Model) {
		if (campaignInstagramOwner && campaignInstagramOwner.campaignInstagramOwnerCommissions.length > 0) {
			this.commissions = campaignInstagramOwner.campaignInstagramOwnerCommissions;
			this.totalCommission = campaignInstagramOwner.campaignInstagramOwnerCommissions?.reduce(
				(prev: number, current: Model) => prev + current.commission.fixedAmount,
				0,
			);
			this.currency = campaignInstagramOwner.campaignInstagramOwnerCommissions[0]?.commission?.fixedAmountCurrency;
			this.products = campaignInstagramOwner.campaignInstagramOwnerProducts ? campaignInstagramOwner.campaignInstagramOwnerProducts : [];
		} else {
			this.commissions = [];
			this.totalCommission = 0;
			this.currency = '';
			this.products = [];
		}
	}
}

export class AssignmentObject {
	id: string;
	name: string;
	type: string;
	reviews: Model[];
	groups: Model[];

	constructor(targetAssignment: Model, originalAssignment: Model) {
		this.id = targetAssignment.id;
		this.name = targetAssignment.name;
		this.type = targetAssignment.kind;
		this.reviews = targetAssignment.reviews;
		this.groups = originalAssignment.groups;
	}
}

export const getActiveCampaign = (campaignStore: Store, user: UserType) => {
	const campaignModels = campaignStore.findAll('campaign');

	return campaignModels
		.map((campaign: Model) => {
			return new ActiveCampaign(campaign, campaignStore, user);
		})
		.sort((a, b) => {
			if (a.publishingDate && b.publishingDate) {
				return new Date(a.publishingDate).getTime() - new Date(b.publishingDate).getTime();
			} else {
				return Number(a.id) - Number(b.id);
			}
		});
};
