import React from 'react';
import { RouteObject } from 'react-router-dom';

import { PageNotFound } from 'components/ErrorPages';
import LoadingSpinner from 'components/LoadingSpinner';
import InfluencerLayout from 'layouts/influencer';
import NoPermissionsUserView from 'views/NoPermissionUser';

import { ProtectedRoute } from './layout-routes/ProtectedRoute';

/**
 * No user permissions Routes
 *
 * @returns {Array<RouteObject>}
 */

const routes: Array<RouteObject> = [
	{
		path: '',
		element: (
			<React.Suspense fallback={<LoadingSpinner />}>
				<ProtectedRoute>
					<InfluencerLayout />
				</ProtectedRoute>
			</React.Suspense>
		),
		children: [{ index: true, element: <NoPermissionsUserView /> }],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
