/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { EditPasswordPayload, CreateResetPasswordTokenPayload, ReportSalePayload, CreateUserPayload } from 'api-payloads';
import { OpenViewCampaignInviteControllerQuery, CreateUserQuery } from 'api-queries';
import { CampaignInviteResponse, ToolConfigCurrenciesResponse, OpenInviteResponse, OpenUserResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import PublicApiClientInterface from './PublicApiClientInterface';

@injectable()
export default class PublicApiClient implements PublicApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * View campaign invite
	 */
	async openViewCampaignInviteController(inviteToken: string, params: OpenViewCampaignInviteControllerQuery): Promise<CampaignInviteResponse> {
		const response = await this.client.get<CampaignInviteResponse>(`/public/campaigns/${inviteToken}`, { params });
		return response.data;
	}

	/**
	 * Currency config
	 */
	async currencyConfig(params: { include?: string }): Promise<ToolConfigCurrenciesResponse> {
		const response = await this.client.get<ToolConfigCurrenciesResponse>(`/public/config/currency`, { params });
		return response.data;
	}

	/**
	 * View publisher invite
	 */
	async viewPublisherInvite(inviteToken: string, params: { include?: string }): Promise<OpenInviteResponse> {
		const response = await this.client.get<OpenInviteResponse>(`/public/invites/${inviteToken}`, { params });
		return response.data;
	}

	/**
	 * Mailgun
	 */
	async openMailgun1(params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/mailgun/conversation`, undefined, { params });
		return response.data;
	}

	/**
	 * Mailgun
	 */
	async openMailgun(params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/mailgun/self-invoice`, undefined, { params });
		return response.data;
	}

	/**
	 * Edit password
	 */
	async editPassword(payload: EditPasswordPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/passwords`, payload, { params });
		return response.data;
	}

	/**
	 * Pinebucket webhook
	 */
	async pinebucketWebhook(params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/pinebucket-webhook`, undefined, { params });
		return response.data;
	}

	/**
	 * Create reset password token
	 */
	async createResetPasswordToken(payload: CreateResetPasswordTokenPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/reset-password-tokens`, payload, { params });
		return response.data;
	}

	/**
	 * Report sale
	 */
	async reportSale(payload: ReportSalePayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/tracking/sales`, payload, { params });
		return response.data;
	}

	/**
	 * Create user
	 */
	async createUser(payload: CreateUserPayload, params: CreateUserQuery): Promise<OpenUserResponse> {
		const response = await this.client.post<OpenUserResponse>(`/public/users`, payload, { params });
		return response.data;
	}

	/**
	 * Verify email
	 */
	async verifyEmail(uuid: string, secret: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/public/verify-email/${uuid}/${secret}`, undefined, { params });
		return response.data;
	}
}
