import { useState } from 'react';

import Avatar from 'components/Avatar';
import { GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import BlastChatInput from 'components/IntegratedInbox/Components/BlastChatInput';
import { BlastMessageInfluencerModel } from 'components/Lists/Components/ListContent/types';
import useBlastMessage from 'components/Lists/hooks';
import Modal from 'components/Modals/Modal';
import { isSuccessfulResponse } from 'services/Response.types';

import Styled from './BlastDirectMessage.style';

type Props = {
	selectedInfluencers: BlastMessageInfluencerModel[];
};

const BlastDirectMessage = ({ selectedInfluencers }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { createBlastDirectMessage } = useBlastMessage();

	const sendHandler = async (message: string) => {
		const influencerIds = selectedInfluencers.map((influencer) => influencer.id);
		return await createBlastDirectMessage(influencerIds, message)
			.then((res) => {
				if (isSuccessfulResponse(res.status)) {
					return true;
				} else {
					return false;
				}
			})
			.catch(() => {
				return false;
			});
	};

	return (
		<Styled.Wrapper>
			<GhostButton
				onClick={() => {
					setIsModalOpen(true);
				}}
				data-testid='blast-icon-button'
				size='sm'
			>
				Send blast message
			</GhostButton>
			<Modal
				open={isModalOpen}
				handleClose={() => {
					setIsModalOpen(false);
				}}
				size='sm'
			>
				<Styled.ModalInnerWrapper>
					<Modal.Header>
						<Styled.ModalHeaderWrapper>
							<h5 className='header'>Blast private message</h5>
							<div
								role='button'
								className='close-button'
								onClick={() => {
									setIsModalOpen(false);
								}}
								data-testid='close-modal-button'
							>
								<Icon name='cross' size='16' />
							</div>
						</Styled.ModalHeaderWrapper>
					</Modal.Header>

					<Modal.Body>
						{selectedInfluencers.length > 0 && (
							<Styled.SelectedInfluencers>
								<div className='influencers'>
									{selectedInfluencers.map((campaignInstagramOwner) => {
										return (
											<Styled.SelectedInfluencerTag key={campaignInstagramOwner.id}>
												<Avatar imageUrl={campaignInstagramOwner.links.profilePictureUrl} name={campaignInstagramOwner.username} size='sm' />
												<div className='name'>{campaignInstagramOwner.username}</div>
											</Styled.SelectedInfluencerTag>
										);
									})}
								</div>
							</Styled.SelectedInfluencers>
						)}
						<Styled.MessageInputWrapper>
							<BlastChatInput
								onSend={(message: string) => sendHandler(message)}
								onScrollToBottom={() => {}}
								targetInfluencersNumber={selectedInfluencers.length}
								onCloseModal={() => {
									setIsModalOpen(false);
								}}
							/>
						</Styled.MessageInputWrapper>
					</Modal.Body>
				</Styled.ModalInnerWrapper>
			</Modal>
		</Styled.Wrapper>
	);
};

export default BlastDirectMessage;
