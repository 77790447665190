import classNames from 'classnames';
import { isNil } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import DetailContent from 'components/AffiliateMarketplace/Components/DetailContent';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setIsShowDetailSidebar } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';

import Styled from './CampaignDetailSidebar.style';

type Props = {
	isNotInfluencer?: boolean;
};

/**
 * CampaignDetailSidebar
 * @returns {JSX.Element}
 */
const CampaignDetailSidebar = ({ isNotInfluencer }: Props): JSX.Element => {
	const detailDrawerRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { isShowDetailSidebar, selectedAMCampaign } = useAppSelector((state) => state.amInfluencer);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const onKeyUp = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			navigate('/affiliate/marketplace', { replace: true });
			dispatch(setIsShowDetailSidebar(false));
		}
	};

	const onClickWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!detailDrawerRef?.current?.contains(e.target as Node)) {
			navigate('/affiliate/marketplace', { replace: true });
			dispatch(setIsShowDetailSidebar(false));
		}
	};

	useEffect(() => {
		if (!isNil(selectedAMCampaign)) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}, [selectedAMCampaign]);

	useEffect(() => {
		document.addEventListener('keyup', onKeyUp);

		return () => {
			document.removeEventListener('keyup', onKeyUp);
		};
	}, []);

	return (
		<Styled.Wrapper data-testid='campaign-detail-sidebar' className={classNames({ show: isShowDetailSidebar })} onClick={onClickWrapper}>
			<Styled.InnerWrapper className={classNames({ show: isShowDetailSidebar })}>
				<Styled.DetailDrawer ref={detailDrawerRef}>
					<Styled.DrawerHeader>
						<div className='header'>Campaign details</div>
						<Styled.CloseAction
							aria-label='Close'
							data-testid='close-drawer'
							onClick={() => {
								navigate('/affiliate/marketplace', { replace: true });
								dispatch(setIsShowDetailSidebar(false));
							}}
						>
							<span className='icon-wrapper'>
								<Icon name='cross' size='16' />
							</span>
						</Styled.CloseAction>
					</Styled.DrawerHeader>

					<Styled.DrawerBody>
						{isLoading ? (
							<LoadingSpinner position='center' />
						) : (
							<DetailContent
								title={selectedAMCampaign!.name}
								URL={selectedAMCampaign!.storeLink}
								compensationValue={selectedAMCampaign!.influencerCommissionPercentage}
								links={selectedAMCampaign!.links}
								background={selectedAMCampaign!.background}
								guidelines={selectedAMCampaign!.guidelines}
								isNotInfluencer={isNotInfluencer}
							/>
						)}
					</Styled.DrawerBody>
				</Styled.DetailDrawer>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignDetailSidebar;
