import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { PageNotFound } from 'components/ErrorPages';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyLayout from 'layouts/empty/';
import LandingPageByInvite from 'views/landingpage/by-invite';
import SignUpPublisherWithToken from 'views/sign-up/publisher-with-token/';

import { lazyRetry } from './utils';

//@ts-ignore
//@todo Use lazy in the children object later
const InfluencerSignUpContainer = lazy(() => lazyRetry(() => import('views/influencer/SignUp')));

/**
 * Invite Routes
 * Routes for invites
 * @returns {Array<RouteObject>}
 */

const routes: Array<RouteObject> = [
	{
		path: '',
		element: (
			<React.Suspense fallback={<LoadingSpinner />}>
				<EmptyLayout />
			</React.Suspense>
		),
		children: [
			{ index: true, element: <PageNotFound /> },
			{ path: 'influencer/sign-up/:inviteToken', element: <InfluencerSignUpContainer /> },
			{ path: 'publisher/:inviteToken', element: <SignUpPublisherWithToken /> },
			{ path: 'campaign/:inviteToken', element: <LandingPageByInvite /> },
		],
	},
	{
		path: '*',
		element: <PageNotFound />,
	},
];

export default routes;
