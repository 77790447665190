import styled from 'styled-components';

import { Button } from 'components/Button';
import { RegularLink } from 'styles/utils/Link';
import colors from 'styles/variables/colors';
import { SocialProfileConnectorColors as colorss } from 'styles/variables/colors/SocialProfileConnector';
import { px, rem, spacing } from 'styles/variables/spacings';

const buttonColors = colors.button;

const Wrapper = styled.div`
	ul {
		&.select-platform {
			display: flex;
			gap: 8px;
		}

		padding: 0;
		margin: 0;

		li {
			width: 100%;
			padding: 0;
			list-style: none;
		}
	}

	p {
		margin-top: 0;
	}
`;

const Headline = styled.span`
	display: block;
	font-size: 1rem;
	margin-bottom: ${px[2]}px;
`;

const ConnectorWrapper = styled.div`
	margin-bottom: ${px[2]}px;
`;

const ConnectedProfiles = styled.div``;

const SocialMediaPlatforms = styled.div`
	padding-bottom: ${rem[3]}rem;
	margin-bottom: ${px[3]}px;
	border-bottom: 1px solid #c5c5c5;
`;

const SocialMediaPlatformButton = styled(Button)`
	height: 56px;
	border: 2px solid ${colorss.SocialMediaPlatformButton.border};
	width: 100%;
	&.active,
	&:hover {
		border: 2px solid ${buttonColors.primary.backgroundHover};

		svg path {
			fill: ${buttonColors.primary.colorHover};
		}
	}

	&.active {
		background-color: ${buttonColors.primary.backgroundHover};
		color: ${buttonColors.primary.colorHover};
	}
`;

const ConnectSection = styled.div`
	display: flex;
	flex-direction: column;

	p {
		margin-top: 0;
		margin-bottom: ${px[3]}px;
	}
	button {
		width: fit-content;
	}
`;

const ConnectButton = styled(Button)`
	background: ${colorss.connectButton.background};
	color: ${colorss.connectButton.color};
	font-size: 0.938rem;
	border: 1px solid ${colorss.connectButton.border};

	span {
		margin-top: 3px;
	}
`;

const DisconnectButton = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	font-size: 0.875rem;
	cursor: pointer;
	${RegularLink};
`;

const ConnectLink = styled.button`
	display: block;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	font-size: 0.875rem;
	margin: 0 auto;
	${RegularLink};

	&:after {
		opacity: 1;
	}
`;

const SignUpButtonWrapper = styled.div`
	& > button {
		display: block;
		font-size: 1rem;
		width: 100%;
		margin-top: ${spacing[2].px};
	}
`;

const Styled = {
	Wrapper,
	Headline,
	ConnectorWrapper,
	ConnectedProfiles,
	SocialMediaPlatforms,
	SocialMediaPlatformButton,
	ConnectSection,
	ConnectButton,
	DisconnectButton,
	ConnectLink,
	SignUpButtonWrapper,
};

export default Styled;
