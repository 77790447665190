import Header from 'components/Header';
import Grid from 'styles/grid/grid';

import Styled from './PageNotFound.style';
import BugImage from './assets/bug.svg';

/**
 */

const FatalError = () => {
	return (
		<>
			<Header menuItems={[]} fixed={true} />
			<Styled.Container>
				<Styled.Wrapper>
					<Styled.GridContainer>
						<Grid.Column xs={1} lg={2} />
						<Grid.Column xs={10} lg={4}>
							<div>
								<Styled.Image src={BugImage} height='200px' />
								<div>
									<Styled.Title>
										<div>Sorry, but </div>we have bugs!
									</Styled.Title>
									<Styled.Description>
										<span className='d-block'>We messed up. An error has occurred and we can’t fix it for you.</span>
										Maybe going back <a href='/'>Home</a> helps?
									</Styled.Description>
								</div>
							</div>
						</Grid.Column>
						<Grid.Column xs={1} lg={1} />
						<Grid.Column md={0} lg={2}>
							<Styled.Image src={BugImage} mobile />
						</Grid.Column>
					</Styled.GridContainer>
				</Styled.Wrapper>

				<Grid.Container>
					<Grid.Column xs={1} />
					<Grid.Column xs={10}>
						<Styled.Separator />
						<Styled.LinkContainer>
							<a href='/'>Start</a>
							<a href='https://www.collabs.se'>Collabs.se</a>
						</Styled.LinkContainer>
					</Grid.Column>
					<Grid.Column xs={1} />
				</Grid.Container>
			</Styled.Container>
		</>
	);
};

export default FatalError;
