import styled from 'styled-components';

import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	p {
		margin-bottom: ${spacing[3].px};
	}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	padding: 0.875rem 1rem;

	button {
		margin-left: auto;
	}

	.url {
		display: inline-block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}

	&.is-not-affiliate {
		padding: 0;
		background-color: unset;
		border-radius: unset;

		& > span {
			&.url {
				display: flex;
				align-items: center;
				column-gap: 16px;
				background-color: ${({ theme }) => theme.colors.inputs};
				border-radius: ${({ theme }) => theme.radius.small};
				border: 1px solid ${({ theme }) => theme.borders.secondary};
				transition: border-color 0.2s ease-in-out;
				height: 44px;
				padding: 0.1875rem 0.625rem 0 0.625rem;
			}
		}

		& > button {
			margin-left: ${spacing[1].px};
			flex: 1;
			height: 44px;
		}
	}
`;

const Headline = styled.span`
	display: block;
	margin-bottom: ${spacing[1].px};
	font-size: 0.938rem;
	font-weight: 700;
`;

const Label = styled.div`
	line-height: 1.5;
	color: #5c5c5c;
	font-size: 0.875rem;
	margin-bottom: 4px;
`;

const Styled = {
	Wrapper,
	Inner,
	Headline,
	Label,
};

export default Styled;
