import styled from 'styled-components';

import { Button, DarkButton, GhostButton } from 'components/Button';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import { rem } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const cm = colors.contentManagement;

const Wrapper = styled.div``;

const StatusTag = styled.div`
	background-color: ${cm.reviewStatusTag.backgroundColor};
	border: 1px solid ${cm.reviewStatusTag.color};
	font-size: 14px;
	font-weight: 500;
	color: ${cm.reviewStatusTag.color};
	border-radius: 4px;
	padding: 0.5rem 0.75rem;
	line-height: 0.8;
	height: 28px;
	div {
		display: inline-block;
		width: fit-content;
		margin-top: 1px;
	}
`;

const ActionBar = styled.div`
	width: 100%;
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0;

	${mediaQueries.medium`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	`};
`;

const ActionsGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	button {
		font-family: ${typography.BaseFontFamiliy};
		width: fit-content;
		margin-top: 5px;
	}

	.icon {
		color: ${colors.white};
		svg path {
			transition: fill 200ms ease-in-out;
			fill: ${colors.white};
		}
	}
	${mediaQueries.medium`
		gap: 8px;
		flex-direction: row;
		button {
		width: fit-content;
		}
	`};
`;

const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	width: auto;
	button {
		margin-left: auto;
	}
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	div {
		display: flex;
		&.name-status {
			flex-direction: column;
			align-items: flex-start;
			@media screen and (${devices.lg}) {
				align-items: center;
				flex-direction: row;
			}
		}
	}
	h5 {
		margin-right: 16px;
		margin-bottom: 0;
	}
	p {
		margin: 0;
	}
`;

const ErrorMessage = styled.div`
	margin-top: 5px;
	background-color: ${colors.errorLight};
	border-radius: ${borderRadius.s};
	padding: 1rem;
	color: ${colors.errorDarkRed};
	font-size: 0.75rem;
	display: flex;
	cursor: pointer;
	span {
		margin-top: 2px;
	}
	.icon {
		margin-right: 5px;
		svg path {
			fill: ${colors.errorDarkRed} !important;
		}
	}
`;

const ErrorMessageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const SubmitSection = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: ${colors.mainBlack};
	z-index: 10;
	bottom: 0;
	width: 100%;
	padding: 1rem;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	hr {
		width: 100%;
	}
`;

const SubmitButton = styled(Button)`
	font-size: 0.938rem;
	width: 100% !important;
	height: 44px;
	border: none;
	span {
		margin-top: 3px;
	}
	&.turnBlue,
	&:hover:not(:disabled, .disabled) {
		background: ${colors.contentManagement.infoBlue};
		color: ${colors.mainWhite};
	}
	${mediaQueries.medium`
			width: fit-content !important;
		}
		`};
	@media screen and (${devices.lg}) {
		border: 2px solid ${colors.mainBlack} !important;
	}
`;

const AssignmentName = styled.div`
	color: ${colors.white};
	font-size: 0.938rem;
	font-weight: 700;
	line-height: 24px;
`;

const FileText = styled.div`
	font-weight: 500;
	font-size: 0.75rem;
	color: ${colors.borderGray};
`;

const CommentsButton = styled(Button)`
	border: none;
	background-color: ${colors.buttonGray};
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	.icon {
		margin-top: 2px;
	}
	&:hover {
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
`;

const CompletedWrapper = styled.div`
	text-align: center;
	margin-bottom: 16px;
	padding: 1rem;
	p {
		color: ${colors.contentManagement.grayText};
		margin-top: 0;
	}

	&.error {
		border: 1px solid ${cm.errorText};
		padding-top: ${rem[3]}rem;
		padding-bottom: ${rem[3]}rem;
		border-radius: 10px;

		path {
			fill: ${cm.errorText};
		}
	}
`;

const Title = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.063rem;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0em;
	margin-top: 8px;
`;

const HeadingWrapper = styled.div`
	margin-bottom: 24px;
`;

const CustomSubmitButton = styled(DarkButton)`
	width: 100%;
	font-size: 1rem;
	height: 44px;
	white-space: nowrap;
	border: none;
	.icon {
		path {
			fill: ${newColors.black} !important;
		}
	}
	&:hover {
		.icon path {
			fill: ${newColors.white} !important;
		}
	}
	span {
		margin-top: 3px;
	}
	@media screen and (${devices.md}) {
		width: fit-content !important;
		margin-left: 8px;
	}
	@media screen and (${devices.lg}) {
		border: 2px solid ${newColors.black} !important;
	}
`;

const CustomGhostButton = styled(GhostButton)`
	width: 100% !important;
	padding: 0.5rem;
	font-size: 1rem;
	height: 44px;
	color: ${colors.mainBlack};
	.icon {
		path {
			fill: ${colors.mainBlack};
		}
	}
	&:hover {
		.icon path {
			fill: ${colors.mainWhite} !important;
		}
	}
	${mediaQueries.medium`
			width: fit-content !important;
			margin-left: 8px;
		`}
`;

const CloseButton = styled(GhostButton)`
	width: 100% !important;
	padding: 0.5rem;
	font-size: 1rem;
	font-family: ${typography.BaseFontFamiliy};
	border: 2px solid ${colors.mainWhite};
	height: 44px;
	color: ${colors.mainWhite};
	.icon {
		path {
			fill: ${colors.mainBlack} !important;
		}
	}
	&:hover {
		.icon path {
			fill: ${colors.mainWhite} !important;
		}
	}
	${mediaQueries.medium`
		width: fit-content !important;;
	`}
`;

const ReviewButton = styled(Button)`
	font-size: 1rem;
	border: none;
	height: 44px;
	span {
		margin-top: 3px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`;

const DFlexW100 = styled.div`
	display: flex;
	width: 100%;
	&.min-height {
		min-height: 44px;
	}
`;

const DFlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	&.w-100 {
		width: 100%;
	}
	${mediaQueries.medium`
		align-items: end;
	`}

	& > div {
		&.buttons-wrapper {
			display: flex;
			gap: 8px;
		}
	}
`;

const SubmitSectionContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
`;

const InfoText = styled.p`
	font-size: 0.75rem;
	color: ${colors.mainWhite};
	margin: 0;
	font-weight: 500;
`;

const CustomActionButton = styled(Button)`
	font-size: 1rem;
	background-color: ${colors.buttonGray};
	border: 2px solid ${colors.buttonGray};
	border-radius: 4px;
	white-space: nowrap;
	span {
		margin-top: 3px;
		width: 100px;
	}
`;

const Styled = {
	CustomActionButton,
	CloseButton,
	InfoText,
	DFlexW100,
	DFlexColumn,
	SubmitSectionContent,
	ButtonContainer,
	ReviewButton,
	CustomSubmitButton,
	CustomGhostButton,
	HeadingWrapper,
	Title,
	SubmitSection,
	SubmitButton,
	Wrapper,
	StatusTag,
	ActionBar,
	ActionsGroup,
	Heading,
	ErrorMessage,
	ErrorMessageContainer,
	StatusContainer,
	AssignmentName,
	FileText,
	CommentsButton,
	CompletedWrapper,
};

export default Styled;
