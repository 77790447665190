import { css } from 'styled-components';

import colors from 'styles/variables/colors';
import { rem, px } from 'styles/variables/spacings';

export const HelpTextStyle = css`
	font-size: 0.875rem;
	font-weight: 400;
	color: ${colors.DetailContent.helpText};
	margin-bottom: ${px[3]}px;
`;

export const TrackingLinkSectionStyle = css`
	h6 {
		font-size: 1rem;
		margin-bottom: ${px[2]}px;
	}

	div {
		&.help-text {
			${HelpTextStyle};
		}

		&.input-wrapper {
			margin-bottom: ${px[3]}px;
		}
	}
`;

export const ExternalLinkStyle = css`
	display: flex;
	align-items: center;
	column-gap: ${px[2]}px;
	margin-bottom: ${px[3]}px;
	font-size: 0.9375rem;

	border-bottom: none !important;
	color: ${colors.DetailContent.linkColor} !important;

	path {
		fill: ${colors.DetailContent.linkColor};
	}
`;

export const TrackingLinkStyle = css<{ group: boolean }>`
	position: relative;
	display: ${(props) => (props.group ? 'flex' : 'block')};
	align-items: ${(props) => (props.group ? 'center' : '')};

	.icon {
		margin: 0 ${px[0]}px;
	}

	& > span {
		max-width: 280px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		padding-left: '3rem';
		padding-right: '3rem';
	}

	& > div {
		border-radius: 4px;
		width: unset;
		height: unset;
		padding: ${rem[0]}rem 0;
	}
`;

export const ButtonsWrapperStyle = css`
	button {
		width: 100%;

		&:disabled {
			background-color: ${colors.DetailContent.disabledButtonBackground};
		}
	}

	margin-bottom: ${px[4]}px;
`;

export const GuidelineSectionStyle = css`
	margin-bottom: ${px[4]}px;
`;
