import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const PromotionalBanner = styled.div`
	position: relative;
	border: 1px solid ${colors.gray15};
	border-radius: ${borderRadius.m};
	padding: ${spacing[2].rem};
	background-color: ${colors.gray13};
	overflow: hidden;
	width: 100%;
	z-index: 10;

	&.clickable {
		cursor: pointer;
	}

	@media screen and (min-width: 380px) {
		display: block;
		padding: 0 ${spacing[1].rem};
	}

	@media screen and (${devices.xs}) {
		height: 100px;
	}

	@media screen and (${devices.lg}) {
		height: 200px;
		padding: 0 ${spacing[4].rem} 0 ${spacing[2].rem};
	}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	overflow: hidden;
	height: 100%;

	@media screen and (${devices.lg}) {
		gap: 40px;
	}
`;

const ImageContainer = styled.div`
	display: none;
	max-width: 98px;

	@media screen and (min-width: 380px) {
		display: block;
	}

	@media screen and (${devices.lg}) {
		max-width: 198px;
	}
`;

const Image = styled.img`
	width: 100%;
`;

const TextContainer = styled.div`
	> :is(h1, h2, h3, h4, h5, h6) {
		line-height: 1.5;
		font-size: 0.875rem;
		margin-bottom: ${spacing[0].px};

		@media screen and (${devices.sm}) {
			font-size: 1.4375rem;
		}
	}

	p {
		margin: 0;
		line-height: 1.5;
		font-size: 0.875rem;

		@media screen and (${devices.sm}) {
			font-size: 1rem;
		}
	}
`;

const CTAContainer = styled.div`
	display: none;
	margin-left: auto;

	@media screen and (${devices.lg}) {
		display: block;
	}
`;

const CloseButton = styled.button`
	position: absolute;
	top: 12px;
	right: 12px;
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
	z-index: 20;

	@media screen and (${devices.sm}) {
		top: 16px;
		right: 16px;
	}

	svg {
		width: 12px;

		@media screen and (${devices.sm}) {
			width: 16px;
		}
	}
`;

const Styled = {
	PromotionalBanner,
	Inner,
	ImageContainer,
	TextContainer,
	CTAContainer,
	CloseButton,
	Image,
};

export default Styled;
