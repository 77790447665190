import * as Yup from 'yup';

import { AssignmentType } from 'components/Assigment/types';

export const validateContentUrl = (CONTENT_TYPE: string) => {
	switch (CONTENT_TYPE) {
		case AssignmentType.INSTAGRAM_STORY:
			return Yup.string().matches(/^https:\/\/www\.instagram\.com\/stories\/(.*)$/, 'Url should start with "https://www.instagram.com/stories/[username]"');
		case AssignmentType.INSTAGRAM_REEL:
			return Yup.string()
				.matches(/^https:\/\/www\.instagram\.com\/(reel|p)\/(.*)$/, 'Url should start with "https://www.instagram.com/reel/"')
				.required('Content url is required');
		case AssignmentType.TIKTOK_VIDEO:
			return Yup.string()
				.matches(/^https:\/\/(www|vm)\.tiktok\.com\/(.*)$/, 'Url should start with "https://www.tiktok.com/" or "https://vm.tiktok.com/"')
				.required('Content url is required');
		default:
			return Yup.string()
				.matches(/^https:\/\/www\.instagram\.com\/(reel|p)\/(.*)$/, 'Url should start with "https://www.instagram.com/p/"')
				.required('Content url is required');
	}
};

export const validateStatsForm = (contentType: string, requireScreenshots = true, requirePostedAt = true) => {
	switch (contentType) {
		case AssignmentType.INSTAGRAM_POST: {
			return Yup.object().shape({
				url: validateContentUrl(contentType),
				postedAt: requirePostedAt ? Yup.date().required('Post date is required') : Yup.date(),
				comments: Yup.number().min(0),
				likes: Yup.number().min(0),
				impressions: Yup.number().min(0),
				reach: Yup.number().min(0),
				saves: Yup.number().min(0),
				screenshot: requireScreenshots ? Yup.mixed().required('Screenshot is required') : Yup.mixed(),
			});
		}
		case AssignmentType.TIKTOK_VIDEO: {
			return Yup.object().shape({
				url: validateContentUrl(contentType),
				postedAt: Yup.date().required('Post date is required'),
				impressions: Yup.number().min(0),
				reach: Yup.number().min(0),
				saves: Yup.number().min(0),
				screenshot: requireScreenshots ? Yup.mixed().required('Screenshot is required') : Yup.mixed(),
			});
		}
		case AssignmentType.INSTAGRAM_REEL: {
			return Yup.object().shape({
				url: validateContentUrl(contentType),
				postedAt: requirePostedAt ? Yup.date().required('Post date is required') : Yup.date(),
				impressions: Yup.number().min(0),
				reach: Yup.number().min(0),
				saves: Yup.number().min(0),
				screenshot: requireScreenshots ? Yup.mixed().required('Screenshot is required') : Yup.mixed(),
			});
		}
		case AssignmentType.INSTAGRAM_STORY: {
			return Yup.object().shape({
				postedAt: requirePostedAt ? Yup.date().required('Post date is required') : Yup.date(),
				impressions: Yup.number().min(0),
				reach: Yup.number().min(0),
				reachLastFrame: Yup.number().min(0),
				stickerLinkClicks: Yup.number().min(0),
				stickerTaps: Yup.number().min(0),
				otherInteractions: Yup.number().min(0),
				screenshot: requireScreenshots ? Yup.mixed().required('Screenshot is required') : Yup.mixed(),
			});
		}
		default:
			return;
	}
};
