import { Network } from 'constants/socialMedia';

import Styled from './InfluencerAvatar.style';

type InfluencerProps = {
	displayNetwork: boolean;
	pulse?: boolean;
	inHeader?: boolean;
	profileImageUrl: string;
	userName: string;
	network?: Network;
	size?: 'sm' | 'md' | 'lg';
};
/**
 * Influencer avatar with network icon
 * @returns {JSX.Element}
 */
const InfluencerAvatar = ({ userName, network, displayNetwork, profileImageUrl, inHeader, pulse, size }: InfluencerProps): JSX.Element => {
	const getNetworkIcon = (network: Network) => {
		switch (network) {
			case Network.INSTAGRAM:
				return 'instagram-circle-color';
			case Network.TIKTOK:
				return 'tiktok-color';
			default:
				'';
		}
	};

	return (
		<Styled.Wrapper data-testid='avatar'>
			{profileImageUrl === '' ? (
				<Styled.LetterAvatar name={userName} size={size} backgroundColor={'#D6EAEE'} />
			) : (
				<Styled.UserAvatar size={size} img={profileImageUrl} />
			)}
			{inHeader && pulse && <Styled.PulseRing />}
			{displayNetwork && network && <Styled.NetworkIcon name={getNetworkIcon(network)} size='14' />}
		</Styled.Wrapper>
	);
};

export default InfluencerAvatar;
