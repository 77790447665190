import styled from 'styled-components';

import { ExternalLinkStyle } from 'components/AffiliateMarketplace/shared.style';
import colors from 'styles/variables/colors';
import { rem, px } from 'styles/variables/spacings';

const Wrapper = styled.div``;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${px[4]}px;
	padding-top: ${rem[4]}rem;

	& > h5 {
		&.title {
			font-size: 1.44rem;
			font-weight: 700;
			line-height: 150%;
			margin-bottom: 0;
		}
	}
	& > span {
		& > div {
			&.icon-wrapper {
				padding: 0.47rem 0.63rem;
				border-radius: 4px;
				cursor: pointer;

				&:hover {
					background-color: ${colors.DetailContent.hoverIconBackground};
				}

				i {
					display: flex;
				}
			}
		}
	}
`;

const ExternalLink = styled.a`
	${ExternalLinkStyle};
`;

const DetailsSection = styled.div`
	margin-bottom: ${px[4]}px;
	h6 {
		font-size: 1rem;
		margin-bottom: ${px[2]}px;
	}
`;

const DetailBox = styled.div`
	border-radius: 10px;
	background-color: ${colors.DetailContent.detailBoxBackground};
	padding: ${rem[2]}rem ${rem[1]}rem;
	font-size: 0.875rem;

	& > div {
		&.compensation {
			padding: 0 1rem;

			& > span {
				&.content-review-required {
					padding-left: ${rem[2]}rem;
					position: relative;

					&:before {
						position: absolute;
						content: ' ';
					}
				}
			}
		}

		&.divider {
			border-bottom: 1px solid ${colors.DetailContent.dividerBorder};
			padding-bottom: ${rem[2]}rem;
		}

		&.campaign-background {
			padding: ${rem[3]}rem ${rem[2]}rem 0;
			& > div {
				&.header {
					margin-bottom: ${px[3]}px;
					font-weight: 500;
				}
				&.content {
					line-height: 1.5rem;
					font-weight: 400;
					color: ${colors.DetailContent.detailBoxContentColor};
				}
			}
		}
	}
`;

const Styled = {
	Wrapper,
	Header,
	ExternalLink,
	DetailsSection,
	DetailBox,
};

export default Styled;
