import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { px, spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	margin-bottom: ${px[3]}px;
	font-size: 0.875rem;

	& > div {
		&.title-wrapper {
			display: flex;
			align-items: center;
			column-gap: ${spacing[1].px};
			margin-bottom: ${px[2]}px;

			& > span {
				&.icon-wrapper {
					border-radius: 10px;
					background-color: ${colors.InfoBlock.iconBackground};
					padding: ${spacing[1].rem};
				}

				i {
					display: flex;
				}
			}

			& > div {
				&.info-title {
					font-weight: 500;
				}
			}
		}

		&.info-content {
			line-height: 1.5rem;
			color: ${colors.InfoBlock.infoContentColor};
			font-weight: 400;
		}
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
