import classNames from 'classnames';
import { useState, useEffect } from 'react';

import Copy from 'components/Copy/Copy';
import Icon from 'components/Icon';

import Styled from './GeneratedTrackingLinkItem.style';

type Props = {
	trackingUrl: string;
	originalUrl: string;
};

const GeneratedTrackingLinkItem = ({ trackingUrl, originalUrl }: Props) => {
	const [isDisplayTooltip, setIsDisplayTooltip] = useState<boolean>(false);

	const renderTooltip = () => {
		return (
			<Styled.Tooltip className={classNames({ visible: isDisplayTooltip })}>
				<Styled.TooltipText>Link copied to clipboard</Styled.TooltipText>
			</Styled.Tooltip>
		);
	};

	useEffect(() => {
		if (isDisplayTooltip) {
			setTimeout(() => {
				setIsDisplayTooltip(false);
			}, 2000);
		}
	}, [isDisplayTooltip]);

	return (
		<Styled.Wrapper group={false} data-testid='generated-tracking-link'>
			{isDisplayTooltip && renderTooltip()}
			<div>
				<div>
					<Icon name='url-link' size='16' />
					<span>{trackingUrl}</span>
				</div>
				<Copy
					className='am-copy-button'
					description={trackingUrl}
					value={trackingUrl}
					iconSize='20'
					action={() => {
						setIsDisplayTooltip(true);
					}}
				/>
			</div>
			<Styled.OriginalLink target='_blank' href={originalUrl}>
				{originalUrl}
			</Styled.OriginalLink>
		</Styled.Wrapper>
	);
};

export default GeneratedTrackingLinkItem;
