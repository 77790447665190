import classNames from 'classnames';

import Styled from './FilterSection.style';

const FilterSectionOptionListItem = (props: { onChange: () => void; label: string | JSX.Element; checked: boolean }) => {
	return (
		<Styled.FilterOptionListItem className={classNames({ selected: props.checked })}>
			<Styled.CustomCheckbox className={classNames({ selected: props.checked })} checked={props.checked} label={props.label} onChange={props.onChange} />
		</Styled.FilterOptionListItem>
	);
};
export default FilterSectionOptionListItem;
