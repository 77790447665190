import styled from 'styled-components';

import { DarkButton } from 'components/Button';
import { ModalContent } from 'components/Modals/Modal/Modal.style';
import Modal from 'components/Modals/Modal/V2';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const dl = colors.dataLibrary;

const CustomModal = styled(Modal)`
	padding-right: 0;
	text-align: start;
	${ModalContent} {
		width: 100%;
		height: auto;
		max-height: 85vh;
		@media screen and (${devices.md}) {
			width: 510px;
		}

		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: 1px solid ${dl.modal.grayBorder};
		border-radius: 10px;
		overflow: hidden;
	}
`;

const ModalContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: ${spacing[3].rem} ${spacing[2].rem};

	h3 {
		font-size: 17px;
		margin-bottom: 24px;
	}

	p {
		margin: 0;
	}

	@media screen and (${devices.sm}) {
		text-align: start;
		padding: ${spacing[4].rem};

		h3 {
			font-size: 1.4375rem;
			line-height: 1.39;
		}
	}
`;

interface IconContainerColor {
	backgroundColor?: string;
}

const ModalIconContainer = styled.div<IconContainerColor>`
	height: 32px;
	width: 32px;
	border-radius: 4px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		margin-top: 5px;
	}
	background: ${(props) => props.backgroundColor};
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: 2rem;
`;

const ModalButtonsContainer = styled.div`
	width: 100%;
	margin-top: ${spacing[3].px};

	display: flex;
	flex-direction: column-reverse;
	row-gap: ${spacing[2].px};
	justify-content: space-between;

	&.center {
		justify-content: center;
	}

	.cancel {
		height: 44px;
		width: 185px;
		font-size: 1rem !important;
	}

	@media screen and (${devices.sm}) {
		flex-direction: row;
	}
`;

const ConfirmButton = styled(DarkButton)`
	font-size: 1rem;
	line-height: 1;
	width: 185px;
	@media screen and (${devices.sm}) {
		width: 185px;
	}
	&.isDangerButton {
		background: ${colors.button.danger.backgroundColor};
		color: ${colors.button.danger.color};
		&:hover {
			background: ${colors.button.danger.backgroundColor};
			border: 1px solid ${colors.button.danger.borderColor};
			color: ${colors.button.danger.color};
		}
	}
	background: ${colors.button.dark.background};
	color: ${colors.button.dark.color};
	.icon path {
		fill: ${colors.button.dark.color};
	}
`;

const ModalChildren = styled.div`
	max-height: 300px;
	overflow-y: auto;
	width: 100%;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Styled = {
	ModalContent,
	CustomModal,
	ModalContainer,
	ModalIconContainer,
	SpinnerWrapper,
	ModalButtonsContainer,
	ConfirmButton,
	ModalChildren,
	Title,
};

export default Styled;
