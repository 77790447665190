import styled from 'styled-components';

import { MidColContainer } from 'styles/layout/three-col';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled(MidColContainer)`
	background-color: ${colors.mainWhite};
	flex: 1;
	@media screen and (${devices.md}) {
		padding-right: 10%;
	}
`;

const Content = styled.div`
	display: grid;
	height: calc(100vh - 220px);
	& > div {
		&.input-wrapper {
			align-self: flex-end;
		}
	}
`;

const Styled = {
	Wrapper,
	Content,
};

export default Styled;
