import styled, { DefaultThemeV2 } from 'styled-components';

import { EmptyResultImgWrapper } from 'components/Settings/ClientManagement/ClientManagement.style';
import { StyledCard } from 'components/Settings/Components/ClientDetails/ClientDetails.style';
import colors from 'styles/theme/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { devices } from 'styles/variables/media-queries';

type T = { theme: DefaultThemeV2 };

const TopSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
`;

const ClientCard = styled.div`
	display: flex;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
	cursor: pointer;
`;

const SearchInputWrapper = styled.div`
	float: right;
	width: 350px;
	margin-top: 16px;
	@media screen and (${devices.lg}) {
		margin-top: 0;
	}
`;

const ClientsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`;

const FlexDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	@media screen and (${devices.lg}) {
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
	}
`;

const AddIcon = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.ash};
	border-radius: ${(props: T) => props.theme.radius.small};
	.icon {
		margin-top: 4px;
	}
`;

const AddSection = styled.div`
	cursor: pointer;
	display: flex;
	gap: 8px;
	align-items: center;
	p {
		margin-bottom: 0;
		color: ${colors.smoke};
		font-weight: ${fontWeights.bold};
		margin-top: 3px;
	}
`;

const Text = styled.p`
	span {
		font-weight: ${fontWeights.bold};
	}
`;

const CreateContainer = styled.div`
	width: 100%;
	button {
		width: 100%;
	}
	@media screen and (${devices.lg}) {
		width: auto;
		button {
			width: fit-content;
			white-space: nowrap;
		}
	}
`;

const ClientContainer = styled.div`
	width: 100%;
	gap: 8px;
	display: flex;
	flex-wrap: wrap;
	&.searching {
		min-height: 600px;
		overflow-y: auto;
	}
`;

const BrandLogosWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 8px;
	padding-bottom: 5rem;
	margin-top: 16px;
	&.searching {
		height: 500px;
		overflow-y: auto;
	}
`;

const BrandItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.mist};
	width: 120px;
	height: 120px;
	border-radius: ${(props: T) => props.theme.radius.default};
	cursor: pointer;
	img {
		display: block;
		margin: auto;
	}
	h5 {
		margin: auto;
	}
	&.hide {
		display: none;
		opacity: 0;
	}
`;

const Link = styled.div`
	color: ${colors.oceanBlue};
	border-bottom: 2px solid ${colors.oceanBlue};
	width: fit-content;
	cursor: pointer;
`;

export default {
	Link,
	BrandLogosWrapper,
	BrandItem,
	ClientContainer,
	SearchInputWrapper,
	StyledCard,
	TopSection,
	ClientCard,
	ClientsWrapper,
	FlexDiv,
	AddIcon,
	AddSection,
	CreateContainer,
	Text,
	EmptyResultImgWrapper,
};
