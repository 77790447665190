import classNames from 'classnames';
import { ReactNode } from 'react';

import { useUserFriendlyRoleName } from 'shared/utils/user';

import Styled from './UserAvatar.style';

export interface IUserAvatar {
	fullName?: string | null | ReactNode;
	img?: string | undefined;
	displayBorder?: boolean;
	displayBottomBorder?: boolean;
	displayRole?: boolean;
	displayFacebookName?: string;
	displayThisRole?: string;
	small?: boolean;
	medium?: boolean;
	className?: string;
}

const UserAvatar = ({
	fullName,
	img,
	displayBorder,
	displayBottomBorder,
	displayRole,
	displayFacebookName,
	displayThisRole,
	small,
	medium,
	className,
}: IUserAvatar) => {
	const role = displayThisRole ?? useUserFriendlyRoleName();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getInitals = (name: string | any) => {
		if (!name) {
			return '';
		}

		if (name.length === 2) {
			return name;
		} else if (name.indexOf(' ') >= 0) {
			return name
				.split(' ')
				.map((word: string) => word[0])
				.join('')
				.slice(0, 2);
		} else {
			return name.charAt(0);
		}
	};

	return (
		<Styled.Wrapper data-testid='facebook-connection-user-avartar'>
			<Styled.Avatar img={img} className={classNames({ small: small, medium: medium }, className)}>
				<span>{fullName && !img && getInitals(fullName)}</span>
			</Styled.Avatar>
			<Styled.NameWrapper className={classNames({ displayBorder: displayBorder, displayBottomBorder: displayBottomBorder, small: small, medium: medium })}>
				<Styled.fullNameSpan>{fullName}</Styled.fullNameSpan>
				<Styled.RoleSpan>{displayFacebookName ?? (displayRole && role)}</Styled.RoleSpan>
			</Styled.NameWrapper>
		</Styled.Wrapper>
	);
};

export default UserAvatar;
