import { useContext, useState, useCallback, memo } from 'react';

import { InfluencerModel } from 'api-models';
import MessageContent from 'components/Discovery/Components/MessageContent/MessageContent';
import useIntegratedInboxData from 'components/IntegratedInbox/hooks';
import ConfirmModal from 'components/Modals/ConfirmModal';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import IntegratedInboxService from 'services/IntegratedInbox/IntegratedInbox.service';
import { ICollabsData } from 'services/Response.types';
import toast from 'services/Toast';

const MessageModal = memo(
	(props: {
		open: boolean;
		onClose: () => void;
		influencer?: InfluencerModel;
		messageTargetInfluencer?: InfluencerListItemType;
		isList?: boolean;
		isOnCampaign?: boolean;
	}) => {
		const { messageTargetInfluencer } = useContext(DiscoveryContext);
		const { getAllConversations } = useIntegratedInboxData();
		const { mutation: conversationsMutation } = getAllConversations();
		const [message, setMessage] = useState('');
		const [isSending, setIsSending] = useState(false);

		/**
		 * POST /influencers/{id}/conversation-messages requires influencer id (not collabsId or userId)
		 * but messageTargetInfluencer's id is collabsId because discovery view uses discovery api.
		 * So when the user sends the message, it will send the request to search the influencer id with collabsId first
		 * and then call createNewConversation method to send the message
		 */
		const sendIntegratedInboxMessage = useCallback(
			(message: string): Promise<boolean> => {
				setIsSending(true);

				if (props.isOnCampaign && props.influencer) {
					return IntegratedInboxService.createDirectMessage({ message: message, influencerId: props.influencer.id! }).then(() => {
						toast.success(`Message has been sent`);
						setIsSending(false);

						conversationsMutation.refresh();
						props.onClose();

						return true;
					});
				}

				if (props.isList) {
					if (props.messageTargetInfluencer) {
						return IntegratedInboxService.getInfluencer(props.messageTargetInfluencer.id).then(async (influencerData: ICollabsData) => {
							return await IntegratedInboxService.createDirectMessage({ message: message, influencerId: influencerData.id! }).then(() => {
								toast.success(`Message has been sent`);
								setIsSending(false);

								conversationsMutation.refresh();
								props.onClose();

								return true;
							});
						});
					}
				}

				if (messageTargetInfluencer) {
					return IntegratedInboxService.getInfluencer(messageTargetInfluencer.id).then(async (influencerData: ICollabsData) => {
						return await IntegratedInboxService.createDirectMessage({ message: message, influencerId: influencerData.id! }).then(() => {
							toast.success(`Message has been sent`);
							setIsSending(false);

							conversationsMutation.refresh();
							props.onClose();

							return true;
						});
					});
				}

				setIsSending(false);
				return new Promise((resolve) => resolve(false));
			},
			[props.influencer, props.messageTargetInfluencer, messageTargetInfluencer, conversationsMutation, props.onClose, props.isList, props.isOnCampaign],
		);

		return (
			<ConfirmModal
				isModalOpen={props.open}
				toggleModal={props.onClose}
				title={'Private message'}
				buttonText={'Send'}
				action={() => sendIntegratedInboxMessage(message)}
				isFetching={isSending}
			>
				<MessageContent
					onSend={sendIntegratedInboxMessage}
					onCloseModal={props.onClose}
					messageTargetInfluencer={props.messageTargetInfluencer}
					influencer={props.influencer}
					isList={props.isList || props.isOnCampaign}
					getMessage={(message) => setMessage(message)}
				/>
			</ConfirmModal>
		);
	},
);

export default MessageModal;
