import styled from 'styled-components';

import { Td, Tr } from 'styles/table';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.tr`
	${Tr};

	width: 100%;
	height: 64px;

	background-color: ${colors.gray13};
	border: none;
	border-right: none;
	border-left: none;
	cursor: pointer;

	&.even {
		background-color: ${colors.mainWhite};
	}

	&:hover {
		background-color: ${colors.influencerDashboard.todo.item.hoverBackground};
		color: ${colors.influencerDashboard.todo.item.hoverColor};

		& > td {
			&.type {
				& > div {
					.icon path {
						fill: ${colors.influencerDashboard.todo.item.hoverColor};
						& > g > path {
							&.checkmark {
								stroke: ${colors.influencerDashboard.todo.item.hoverColor};
							}
						}
					}
				}
			}

			&.view {
				& > div {
					svg {
						fill: ${colors.influencerDashboard.todo.item.hoverColor};
					}
				}
			}
		}
	}

	td {
		${Td};

		@media (max-width: ${breakpoints.sm}) {
			padding-right: ${guttersWithRem.xxs};
			padding-left: ${guttersWithRem.xxs};

			&.type {
				font-size: 0.9rem;

				& > div > div {
					overflow: hidden;
					width: 130px;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			&.campaign {
				display: none;
			}
			&.brand {
				font-size: 0.9rem;
			}
			&.deadline {
				display: none;
			}
			&.status {
			}
			&.view {
				display: none;
			}
		}
	}
`;

const TdInnerWrapper = styled.div`
	display: flex;
	align-items: center;
	font-weight: 600;

	&.todo-type {
		padding-left: 0.6rem;
		column-gap: 0.75rem;

		& > i {
			display: flex;
		}
		@media (max-width: ${breakpoints.sm}) {
			padding-left: none;
			& > i {
				width: 20px;
			}
		}
	}

	&.view-link {
		font-family: ${typography.BaseFontFamiliy};
		font-weight: 400;

		justify-content: flex-end;
		column-gap: ${guttersWithRem.xs};
		padding-right: ${guttersWithRem.xxs};

		& > i {
			display: flex;
		}

		&:before {
			content: ' ';
			width: 2px;
			height: 48px;
			border-radius: 10px;
			margin-right: ${guttersWithRem.xxl};

			background-color: ${colors.lightGray};
		}

		&.even {
			&:before {
				background-color: ${colors.lightGray};
			}
		}
	}
`;

const Styled = {
	Wrapper,
	TdInnerWrapper,
};

export default Styled;
