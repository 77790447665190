import styled from 'styled-components';

import { Button, GhostButton } from 'components/Button';
import { ModalHeader, ModalContent } from 'components/Modals/Modal/Modal.style';
import Modal from 'components/Modals/Modal/V2';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const dl = colors.dataLibrary;

const CustomModal = styled(Modal)`
	padding-right: 0;
	${ModalContent} {
		max-width: 510px;
		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: 1px solid ${colors.mediumGray};
		border-radius: 10px;
		overflow: hidden;
	}
`;

const CloseContainer = styled.div`
	cursor: pointer;
`;

const CustomModalHeader = styled(ModalHeader)`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding: 2rem 2rem 0 2rem;
	margin-bottom: 0;
`;

const ModalHeaderTitle = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0em;
	text-align: left;
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: 2rem;
`;

const ModalButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	gap: ${spacing[2].px};
	justify-content: space-between;
	margin-top: 40px;
	.cancel {
		height: 44px;
		width: 185px;
		font-size: 1rem !important;
	}
`;

const ModalButton = styled(GhostButton)`
	font-size: 1rem;
	line-height: 1;
	width: 185px;
	height: 44px;
	border: none;
	cursor: pointer;
	button {
		font-size: 1rem !important;
	}
	&:focus {
		box-shadow: 0px 0px 8px 1px ${dl.input.focus};
	}
`;
const CancelButton = styled(GhostButton)`
	font-size: 1rem;
	line-height: 1;
	width: 185px;
	height: 44px;
	cursor: pointer;
	background: none;
	border: 1px solid ${colors.mainBlack};
	&:hover {
		background-color: ${colors.mainBlack} !important;
		color: ${colors.mainWhite} !important;
	}
	&:focus {
		box-shadow: 0px 0px 8px 1px ${dl.input.focus};
	}
`;

const DeleteButton = styled(Button)`
	font-size: 1rem;
	line-height: 1;
	width: 185px;
	height: 44px;
	border: none;
	background: ${colors.errorLight};
	color: ${colors.errorDarkRed};
	cursor: pointer;
	&:hover {
		background: ${colors.errorDarkRed} !important;
		border: 1px solid ${colors.errorDarkRed} !important;
		color: ${colors.errorLight} !important;
	}
`;

const ModalDeleteContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: start;
	align-items: start;
	padding: 2.5rem;
	h3 {
		margin-bottom: 11px;
	}
`;

interface IconContainerColor {
	backgroundColor?: string;
}

const ModalDeleteIconContainer = styled.div<IconContainerColor>`
	height: 40px;
	width: 40px;
	background: ${colors.errorLight};
	border-radius: 4px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	margin-bottom: 40px;
	.icon {
		margin-top: 5px;
	}
	&.darkMode {
		background: ${(props) => props.backgroundColor};
	}
`;

const Styled = {
	CustomModalHeader,
	ModalHeaderTitle,
	CustomModal,
	CloseContainer,
	SpinnerWrapper,
	ModalButton,
	ModalDeleteContainer,
	ModalDeleteIconContainer,
	ModalButtonsContainer,
	DeleteButton,
	CancelButton,
};

export default Styled;
