import { Model } from 'json-api-models';

import { FileItem } from 'components/IntegratedInbox/types';

export class MessageItem {
	id: string;
	senderInfo: Model;
	name: string;
	profileImage: string | undefined;
	message: string;
	messageHtml: string;
	createdAt: string;
	isUnread: boolean;
	messageModel: Model;
	firstName?: string;
	publisherName?: string;
	displaySeen: boolean;
	files: Array<FileItem>;

	isUser(user: Model | null) {
		return !!user;
	}

	constructor(messageModel: Model, publisher?: Model) {
		this.id = messageModel.id;
		this.senderInfo = this.isUser(messageModel.user) ? messageModel.user : messageModel.influencer;
		this.name =
			this.senderInfo?.type === 'influencer'
				? messageModel?.influencer?.username
				: messageModel.user?.name
					? messageModel.user?.name
					: messageModel.user?.initials
						? messageModel.user?.initials
						: '';
		this.firstName = this.senderInfo?.firstname;
		this.profileImage = this.senderInfo?.type === 'influencer' ? messageModel?.influencer?.links?.profilePictureUrl : undefined;
		this.message = messageModel.message;
		this.messageHtml = messageModel.messageHtml;
		this.createdAt = messageModel.createdAt;
		this.isUnread = messageModel.readAt === null && messageModel.inConversation;
		this.messageModel = messageModel;
		this.publisherName = this.senderInfo?.type === 'user' && publisher && publisher?.name;
		this.displaySeen = messageModel.anyoneReadAt ? true : false;
		this.files = messageModel.files;
	}
}
