// Some Tailwind-like utility classes

import { css } from 'styled-components';

import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

export const Flex = css`
	& {
		.d-flex {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.justify-center {
			justify-content: center;
		}

		.align-center {
			align-items: center;
		}

		.grow-1 {
			flex-grow: 1;
		}

		.flex-2 {
			flex: 2;
		}

		.gap-05 {
			gap: 0.5rem;
		}

		.gap-1 {
			gap: 1rem;
		}
	}
`;

export const Borders = css`
	& {
		.border-1 {
			border: 1px solid ${colors.borderColor};
		}

		.rounded {
			border-radius: 100%;
		}
	}
`;

export const Typo = css`
	& {
		.cursor-pointer {
			cursor: pointer;
		}

		.font-secondary {
			font-family: ${typography.SecondaryFontFamiliy};
		}

		.text-error {
			color: ${colors.error};
		}

		.font-bold {
			font-weight: bold;
		}

		.list-unstyled,
		.list-inline {
			padding-left: 0;
			list-style: none;
		}

		.list-inline-item {
			display: inline-block;

			&:not(:last-child) {
				margin-right: ${spacing[0].rem};
			}
		}
	}
`;

export const Background = css`
	& {
		.bg-white {
			background: ${colors.white};
		}
	}
`;

export const Spacing = css`
	& {
		.p-3 {
			padding: 1.5rem;
		}

		.px-3 {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}

		.m-0 {
			margin: 0;
		}

		.mt-3 {
			margin-top: 1.5rem;
		}
	}
`;

export const TextAlign = css`
	.align-right {
		text-align: right;
	}

	.align-left {
		text-align: right;
	}

	.align-center {
		text-align: center;
	}
`;
