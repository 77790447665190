import { useState } from 'react';

import { Icon } from 'components';
import { ButtonSecondary } from 'components/Button';
import GraphsOverview from 'components/DataLibrary/DataLibraryModal/GraphsPreview/GraphsPreview';
import {
	TrafficGraphs,
	AudienceGraphs,
	ReachGraphs,
	SalesAndBudgetGraphs,
	EngagementGraphs,
	ImpressionGraphs,
	MediaObjectGraphs,
	SummaryGraphs,
} from 'components/DataLibrary/Graphs/Categories';
import Dropdown from 'components/Dropdown';
import { TwoColumnModal } from 'components/Modals/TwoColumnModal';
import { UserType } from 'reducers/UserReducers/types';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import MainDropDown from './Components/MainDropDown';
import SearchContainer from './Components/Search/SearchContainer';
import ShareContainer from './Components/Share/ShareContainer';
import Styled from './ContentHeader.style';

const reach = ReachGraphs();
const sales = SalesAndBudgetGraphs();
const engagement = EngagementGraphs();
const impression = ImpressionGraphs();
const traffic = TrafficGraphs();
const audience = AudienceGraphs();
const summary = SummaryGraphs();
const mediaObjects = MediaObjectGraphs();

/**
 * @returns {JSX.Element}
 */
const ContentHeader = (props: { user: UserType }): JSX.Element => {
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const AllGraphs = [sales, reach, impression, traffic, audience, engagement, summary, mediaObjects];

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<Styled.ContentContainerHeader>
			<Styled.ButtonContainer>
				<SearchContainer />
				{dashboard.id && (
					<ButtonSecondary size='sm' onClick={() => setIsModalOpen(true)}>
						Add graph <Icon size='16' name='add-circle' />
					</ButtonSecondary>
				)}
			</Styled.ButtonContainer>
			<Styled.IconsContainer>
				{dashboard.id && <ShareContainer user={props.user} />}
				<Dropdown icon='options' size='20' id='dashboard-actions' data-testid='main-dropdown' keepInDOM>
					<MainDropDown />
				</Dropdown>
			</Styled.IconsContainer>
			<TwoColumnModal isModalOpen={isModalOpen} toggleModal={() => toggleModal()}>
				{isModalOpen ? <GraphsOverview graphs={AllGraphs} /> : <div />}
			</TwoColumnModal>
		</Styled.ContentContainerHeader>
	);
};
export default ContentHeader;
