import styled from 'styled-components';

import { ThreeColContainer } from 'styles/layout/three-col';
import borderRadius from 'styles/variables/border-radius';
import { devices, mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled(ThreeColContainer)`
	position: relative;
	margin: 0 16px;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 0 1fr 0;
	grid-template-rows: auto;
	overflow-x: hidden;

	${mediaQueries.xl`
		  grid-template-columns: minmax(0, 318px) 1fr 0;
			grid-template-rows: auto;
	`};

	${mediaQueries.xxl`
		  grid-template-columns: minmax(0, 318px) 1fr minmax(0, 342px);
			grid-template-rows: 88vh;
			overflow-x: visible;
	`};
`;

const LeftColumn = styled.div<{ visible: boolean }>`
	position: relative;
	grid-column: 1;
	max-width: 318px;
	align-self: stretch;
	transition: transform 0.3s ease-in-out;
	overflow: hidden;
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
	border-radius: ${borderRadius.m};
	overflow-y: hidden;

	@media (max-width: 1200px) {
		max-width: 100%;
		transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(-100%)')};
		overflow: visible;

		> div:last-child {
			display: ${({ visible }) => (visible ? 'block' : 'none')};
		}
	}
	@media (min-width: 1200px) {
		height: 100vh;
	}
`;

const MiddleColumn = styled.div<{ visible: boolean }>`
	position: relative;
	grid-column: 2;
	height: 100vh;
	width: 100%;
	overflow-y: auto;
	background-color: ${({ theme }) => theme.threeCol.mainBackground};
	align-self: stretch;
	display: ${({ visible }) => (visible ? 'block' : 'none')};
	transition: opacity 0.25s ease-in-out;
	overflow-anchor: none;
	${mediaQueries.xl`
		display: block;
	`};
`;

const RightColumn = styled.div<{ visible: boolean }>`
	position: relative;
	grid-column: 3;
	max-width: calc(100% - 32px);
	align-self: stretch;
	position: relative;
	z-index: 1;
	transition: transform 0.3s ease-in-out;
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
	border-radius: ${borderRadius.m};
	padding: 1rem;
	padding-bottom: 10rem;
	margin-left: 0 !important;
	overflow-y: hidden;
	@media screen and (${devices.lg}) {
		margin-left: 8px;
		overflow-y: auto;
	}
	@media (min-width: 1200px) {
		height: 100vh;
	}
	.toggler {
		position: absolute;
		left: -33px;
		top: 24px;

		@media (min-width: 1200px) {
			display: none;
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			background-color: #d4e3ea;
			border: 0;
			cursor: pointer;
			padding: 0.25rem;
		}
	}

	@media (max-width: 1400px) {
		grid-column: initial;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(100%)')};
		box-shadow: 0 2px 4px rgba(51, 51, 51, 0.1);
		z-index: 1010;
	}

	${mediaQueries.small`
		  max-width: 478px;
	`};
`;

const GoBackContainer = styled.div`
	padding: 0;
	${mediaQueries.medium`
		padding: 0 2rem;
	`};
	${mediaQueries.xl`
		display: none;
	`};
`;

const Styled = {
	Wrapper,
	Grid,
	LeftColumn,
	RightColumn,
	MiddleColumn,
	GoBackContainer,
};

export default Styled;
