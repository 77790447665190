import { Model, Store } from 'json-api-models';
import { isNil, isBoolean } from 'lodash';
import { createContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SORT_ORDER } from 'components/Discovery/Components/ResultTable/types';
import { Network } from 'constants/socialMedia';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import DiscoveryService from 'services/DiscoveryApi';
import { ICollabsResponse, StatusCode } from 'services/Response.types';
import toast from 'services/Toast';

import { DiscoveryContextType, DiscoveryContextProps, FilterType, InfluencerListItemType, SortByParameter, CombinedInfluencerExtraData } from './types';

/**
 */
const DiscoveryContext = createContext<DiscoveryContextType>({
	loading: false,
	isLoadingNext: false,
	filter: null,
	searchHandler: () => ({ influencers: [], categoryTags: [] }),
	searchResult: null,
	nextSearchResult: null,
	changeSearchValueHandler: () => {},
	addSearchCategoryHandler: () => {},
	searchText: null,
	setFilter: () => {},
	resetFilter: () => {},
	changeBrandAffiliations: () => {},
	removeBrandAffiliations: () => {},
	changeHashtags: () => {},
	removeHashtags: () => {},
	updateGenderHandler: () => {},
	updateAudienceAgeRange: () => {},
	updateNetworkHandler: () => {},
	searchTotalResult: null,
	// eslint-disable-next-line unused-imports/no-unused-vars
	getOneInfluencerExtraData: (_influencerId: string) => ({}) as Promise<CombinedInfluencerExtraData>,
	// eslint-disable-next-line unused-imports/no-unused-vars
	getReason: (_url: string) => ({}) as Promise<ICollabsResponse>,
	// eslint-disable-next-line unused-imports/no-unused-vars
	removeOneFilter: (_filterKey: string) => {},
	page: null,
	setPage: () => {},
	isLastPage: false,
	setIsLastPage: () => {},
	// eslint-disable-next-line unused-imports/no-unused-vars
	changeSortParameter: (_sortParameter: string, _sortOrder: SORT_ORDER) => {},
	sortByParameter: 'default',
	isMessageModalOpen: false,
	messageTargetInfluencer: null,
	// eslint-disable-next-line unused-imports/no-unused-vars
	openMessageModal: (_influencer: InfluencerListItemType) => {},
	closeMessageModal: () => {},
	setSelectedNetwork: (_network: Network) => {},
	selectedNetwork: Network.INSTAGRAM,
});

export const DiscoveryContextProvider: React.FC<DiscoveryContextProps> = ({ children }) => {
	const models = new Store();
	const { search, pathname } = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(true);
	const [isLoadingNext, setIsLoadingNext] = useState<boolean>(true);

	const [searchText, setSearchText] = useState<string | null>(null);
	const [filter, setFilter] = useState<FilterType | null>(null);
	const [page, setPage] = useState<string>('0');
	const [sortBy, setSortBy] = useState<SortByParameter>('default');
	const [selectedNetwork, setSelectedNetwork] = useState<Network>(Network.INSTAGRAM);

	const [searchResult, setSearchResult] = useState<InfluencerListItemType[] | null>(null);
	const [nextSearchResult, setNextSearchResult] = useState<InfluencerListItemType[] | null>(null);
	const [isLastPage, setIsLastPage] = useState<boolean>(false);

	const [searchTotalResult, setSearchTotalResult] = useState<{ totalResultCount: number | null; executionTimeMs: number | null } | null>(null);
	const [timer, setTimer] = useState<NodeJS.Timeout>();

	const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
	const [messageTargetInfluencer, setMessageTargetInfluencer] = useState<InfluencerListItemType | null>(null);

	const CancelSignal = new AbortController();
	const cancel = useRef<AbortController | null>(null);

	const openMessageModalHandler = (targetInfluencer: InfluencerListItemType) => {
		setMessageTargetInfluencer(targetInfluencer);
		setIsMessageModalOpen(true);
	};

	const closeMessageModalHandler = () => {
		setMessageTargetInfluencer(null);
		setIsMessageModalOpen(false);
	};

	useEffect(() => {
		parseUrlHandler();
	}, []);

	useEffect(() => {
		parseUrlHandler();
	}, [search]);

	const checkIsContactable = (searchText: string | null, canBeContact?: boolean) => {
		if (!isNil(searchText) && !searchText.includes('@')) {
			return true;
		}

		if (isNil(canBeContact) || (isBoolean(canBeContact) && canBeContact)) {
			return true;
		}

		return false;
	};

	const parseUrlHandler = () => {
		if (search) {
			const queryString = new URLSearchParams(search);
			const qFilter: FilterType = {
				minAge: numberOrNull(queryString.get('minAge')),
				maxAge: numberOrNull(queryString.get('maxAge')),
				genders: queryString.get('genders'),
				countries: queryString.get('countries'),
				minFollowers: numberOrNull(queryString.get('minFollowers')),
				maxFollowers: numberOrNull(queryString.get('maxFollowers')),
				minEI: numberOrNull(queryString.get('minEI')),
				maxEI: numberOrNull(queryString.get('maxEI')),
				brandAffiliations: queryString.get('brandAffiliations'),
				excludedBrandAffiliations: queryString.get('excludedBrandAffiliations'),
				hashtags: queryString.get('hashtags'),
				audienceAgeRanges: queryString.get('audienceAgeRanges'),
				audienceGenders: queryString.get('audienceGenders'),
				audienceCountries: queryString.get('audienceCountries'),
				networks: queryString.get('networks'),
			};
			setSearchText(queryString.get('q'));
			setFilter(qFilter);
			const newPage = queryString.get('page') ? (queryString.get('page') as string) : '0';
			setPage(newPage);
		}
	};

	const handleResult = (
		influencers: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
		totalResult: {
			totalResultCount: number | null;
			executionTimeMs: number | null;
		} | null,
	) => {
		setNextSearchResult(influencers);
		setSearchTotalResult(totalResult);
		setLoading(false);
		setIsLoadingNext(false);
		if (cancel?.current) {
			cancel.current = null;
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mapResult = (res: any) => {
		const result: {
			influencers: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
			totalResult: { totalResultCount: number | null; executionTimeMs: number | null } | null;
		} = {
			influencers: [],
			totalResult: null,
		};

		result.totalResult = res.data.attributes;
		models.sync(res);
		result.influencers = models.find('searchResult', res.data.id).influencers.map((influencer: Model) => {
			return {
				id: influencer.id,
				followersCount: influencer.followersCount,
				network: influencer.network,
				networkId: influencer.networkId,
				networkLinks: influencer.network_links,
				profileImageUrl: influencer.profileImageUrl,
				username: influencer.username,
				engagement: influencer.engagement,
				audienceBrief: influencer.audience,
				links: influencer.links,
				canBeContacted: checkIsContactable(searchText, influencer.canBeContacted),
				country: influencer.country,
			};
		});

		return result;
	};

	const numberOrNull = (value: string | null): number | null => {
		return value ? Number(value) : null;
	};

	useEffect(() => {
		if (cancel.current !== null) {
			cancel.current.abort();
			cancel.current = null;
		}

		if (searchText || filter || sortBy !== 'default') {
			setSearchResult(null);
			setPage('0');
			searchHandler();
			urlChangeHandler();
		} else {
			setLoading(false);
		}
	}, [searchText, filter, sortBy]);

	useEffect(() => {
		// If page is 0 it means it is the first request and searchResult should be set. If page is 1 or higher it means that the searchResult should get updated with the new influencers.
		if (page === '0') {
			setSearchResult(null);
			searchHandler();
		} else {
			searchHandler();
		}
	}, [page]);

	const searchHandler = () => {
		if (page === '0') {
			setLoading(true);
		}
		if (+page > 0) {
			setIsLoadingNext(true);
		}

		if (timer) {
			clearTimeout(timer);
		}

		let filterQueryParam: string | null = null;

		if (filter) {
			if (Object.values(filter).some((value) => value)) {
				filterQueryParam = Object.entries(filter)
					// eslint-disable-next-line unused-imports/no-unused-vars
					.filter(([_key, value]) => value)
					.map(([key, value]) => {
						if (key === 'minEI' || key === 'maxEI') {
							return `${key}=${(value as number) / 100}`;
						} else {
							return `${key}=${value}`;
						}
					})
					.join('&');
			}
		}

		const newTimer = setTimeout(() => {
			cancel.current = CancelSignal;

			DiscoveryService.searchInfluencer(
				{
					searchWord: searchText,
					filter: filterQueryParam,
					sortBy: sortBy,
				},
				page,
				cancel.current.signal,
			)
				.then((res) => mapResult(res))
				.then(({ influencers, totalResult }) => {
					if (influencers.length === 0) {
						DiscoveryService.searchInfluencer(
							{
								searchWord: searchText,
								filter: filterQueryParam,
								sortBy: sortBy,
							},
							page,
							null,
							true,
						)
							.then((res) => mapResult(res))
							.then(({ influencers, totalResult }) => {
								handleResult(influencers, totalResult);
							})
							.catch((err) => {
								if (err.response) {
									if (err.response.status === StatusCode.UNAUTHORIZED) {
										DiscoveryAuthService.requestDiscoveryToken();
									}
									const error = err.response.data.errors[0];
									toast.error(error ? error.title : getSomethingWentWrongMessage());
								}
							});
					} else {
						handleResult(influencers, totalResult);
					}
				})
				.catch((err) => {
					if (err.response) {
						if (err.response.status === StatusCode.UNAUTHORIZED) {
							DiscoveryAuthService.requestDiscoveryToken();
						}
						const error = err.response.data.errors[0];
						toast.error(error ? error.title : getSomethingWentWrongMessage());
					}
				});
		}, 500);

		setTimer(newTimer);
	};

	useEffect(() => {
		if (nextSearchResult && nextSearchResult.length === 0) {
			setIsLastPage(true);
		} else {
			if (searchResult !== null && nextSearchResult !== null) {
				const filteredComingResult = nextSearchResult.filter(
					(comingResult) => !searchResult.some((currentResultItem) => currentResultItem.id === comingResult.id),
				);
				const newSearchResult = [...searchResult, ...filteredComingResult];
				setSearchResult(newSearchResult);
			} else {
				setSearchResult(nextSearchResult);
			}
		}
	}, [nextSearchResult]);

	const getOneInfluencerExtraData = async (influencerId: string) => {
		const models = new Store();
		let result: CombinedInfluencerExtraData = {} as CombinedInfluencerExtraData;
		await DiscoveryService.getInfluencerExtraData(influencerId)
			.then((res) => {
				models.sync(res);
				const influencer = models.find('influencer', influencerId);

				result = {
					username: influencer.username,
					followersCount: influencer.followersCount,
					network: influencer.network,
					networkId: influencer.networkId,
					audienceBrief: {
						age: influencer.audience.age,
						country: influencer.audience.country,
						gender: influencer.audience.gender,
					},
					networkLinks: influencer.network_links,
					engagement: influencer.engagement,
					age: influencer.extra.age,
					country: influencer.country ?? influencer.extra.country,
					gender: influencer.extra.gender,
					averageViews: influencer.extra.averageViews,
					audienceDetail: influencer.extra.audience,
					brandAffiliations: influencer.extra.brandAffiliations,
					engagementOrganic: influencer.extra.engagementOrganic,
					engagementPaid: influencer.extra.engagementPaid,
					paidPostsRatio: influencer.extra.paidPostsRatio,
					estimatedStoryViews: influencer.extra.estimatedStoryViews,
					related: influencer.related,
				} as CombinedInfluencerExtraData;
			})
			.catch((err) => {
				console.error(err);
			});
		return result;
	};

	const urlChangeHandler = () => {
		const newUrlSearch = [];

		if (searchText) {
			newUrlSearch.push(`q=${searchText}`);
		}

		if (filter) {
			Object.entries(filter)
				// eslint-disable-next-line unused-imports/no-unused-vars
				.filter(([_key, value]) => value)
				.map(([key, value]) => `${key}=${value}`)
				.forEach((filterItem) => {
					newUrlSearch.push(filterItem);
				});
		}

		const newSearch = `?${newUrlSearch.join('&')}`;

		// Only navigate if search is different
		if (search !== newSearch) {
			navigate(`${pathname}${newSearch}`);
		}
	};

	const changeSearchValueHandler = (param: { text: string | null }) => {
		setSearchText(param.text);
	};

	const addSearchCategoryHandler = (param: { text: string }) => {
		setSearchText(param.text);
	};

	const changeBrandAffiliations = (value: string, option: string) => {
		const brandMetionKey = option === 'include' ? 'brandAffiliations' : 'excludedBrandAffiliations';
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setFilter((prev: any) => {
			if (prev) {
				return { ...prev, [brandMetionKey]: prev[brandMetionKey] ? prev[brandMetionKey].concat(`,${value}`) : value };
			} else {
				return { [brandMetionKey]: value };
			}
		});
	};

	const removeBrandAffiliations = (value: string, option: string) => {
		const brandMetionKey = option === 'include' ? 'brandAffiliations' : 'excludedBrandAffiliations';
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setFilter((prev: any) => {
			const newBrandMetionValue = prev[brandMetionKey]
				? prev[brandMetionKey]
						.split(',')
						.filter((brand: string) => brand !== value)
						.join(',')
				: null;
			return { ...prev, [brandMetionKey]: newBrandMetionValue };
		});
	};

	const changeHashtags = (value: string) => {
		setFilter((prev: FilterType | null) => {
			if (prev) {
				return { ...prev, hashtags: prev['hashtags'] ? prev['hashtags'].concat(`,${value}`) : value };
			}
			return { hashtags: value };
		});
	};

	const removeHashtags = (value: string) => {
		setFilter((prev: FilterType | null) => {
			const newHashtags =
				!isNil(prev) && prev['hashtags']
					? prev['hashtags']
							.split(',')
							.filter((hashtag: string) => hashtag !== value)
							.join(',')
					: null;
			return { ...prev, hashtags: newHashtags };
		});
	};

	const updateNetworkHandler = (value: Network) => {
		setSelectedNetwork(value);
		setFilter((prev: FilterType | null) => {
			return {
				...prev,
				networks: value,
			};
		});
	};

	const updateGenderHandler = (value: string, option: string) => {
		const key = option === 'influencer' ? 'genders' : 'audienceGenders';
		let newGenders: string | null = null;
		if (filter) {
			if (filter[key]) {
				const currentGenders = filter[key]!.split(',');
				if (currentGenders.some((gender) => gender === getGender(value))) {
					newGenders = currentGenders.filter((gender) => gender !== getGender(value)).join(',');
				} else {
					newGenders = currentGenders.concat([getGender(value)]).join(',');
				}
			} else {
				newGenders = getGender(value);
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setFilter((prev: any) => {
			return {
				...prev,
				[key]: newGenders,
			};
		});
	};

	const getGender = (gender: string) => {
		switch (gender) {
			case 'female':
				return 'f';
			case 'male':
				return 'm';
			default:
				return '';
		}
	};

	const resetFilter = () => {
		setFilter((prev) => ({
			...prev,
			minAge: null,
			maxAge: null,
			genders: null,
			countries: null,
			minFollowers: null,
			maxFollowers: null,
			minEI: null,
			maxEI: null,
			brandAffiliations: null,
			excludedBrandAffiliations: null,
			hashtags: null,
			audienceAgeRanges: null,
			audienceGenders: null,
			audienceCountries: null,
		}));
	};

	const updateAudienceAgeRange = (value: string) => {
		let newAudienceAgeRange: string | null = null;
		if (filter) {
			if (filter.audienceAgeRanges) {
				const currentAgeRanges = filter.audienceAgeRanges.split(',');
				if (currentAgeRanges.some((range) => range === value)) {
					newAudienceAgeRange = currentAgeRanges.filter((range) => range !== value).join(',');
				} else {
					newAudienceAgeRange = currentAgeRanges.concat([value]).join(',');
				}
			} else {
				newAudienceAgeRange = value;
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setFilter((prev: any) => {
			return {
				...prev,
				audienceAgeRanges: newAudienceAgeRange,
			};
		});
	};

	const getReason = (url: string) => {
		return DiscoveryService.getInstagramPostAndDate(url);
	};

	const removeOneFilter = (filterKey: string) => {
		setFilter((prev: FilterType | null) => {
			if (prev !== null) {
				if (filterKey === 'minAge' || filterKey === 'maxAge') {
					return { ...prev, minAge: null, maxAge: null };
				} else if (filterKey === 'minFollowers' || filterKey === 'maxFollowers') {
					return { ...prev, minFollowers: null, maxFollowers: null };
				} else {
					return { ...prev, [filterKey]: null };
				}
			} else {
				return null;
			}
		});
	};

	const changeSortParameter = (sortingParameter: string, sortOrder: SORT_ORDER) => {
		const newSortBy = `${sortingParameter}.${sortOrder === SORT_ORDER.ASC ? 'asc' : 'desc'}` as SortByParameter;
		setSortBy(newSortBy);
	};

	const context = {
		loading: loading,
		isLoadingNext: isLoadingNext,
		filter: filter,
		page: page,
		setPage: setPage,
		isLastPage: isLastPage,
		setIsLastPage: setIsLastPage,
		searchHandler: searchHandler,
		searchResult: searchResult,
		nextSearchResult: nextSearchResult,
		changeSearchValueHandler: changeSearchValueHandler,
		addSearchCategoryHandler: addSearchCategoryHandler,
		searchText: searchText,
		setFilter: setFilter,
		resetFilter: resetFilter,
		changeBrandAffiliations: changeBrandAffiliations,
		removeBrandAffiliations: removeBrandAffiliations,
		changeHashtags: changeHashtags,
		removeHashtags: removeHashtags,
		updateGenderHandler: updateGenderHandler,
		updateAudienceAgeRange: updateAudienceAgeRange,
		updateNetworkHandler: updateNetworkHandler,
		searchTotalResult: searchTotalResult,
		getOneInfluencerExtraData: getOneInfluencerExtraData,
		getReason: getReason,
		removeOneFilter: removeOneFilter,
		changeSortParameter: changeSortParameter,
		sortByParameter: sortBy,
		isMessageModalOpen: isMessageModalOpen,
		messageTargetInfluencer: messageTargetInfluencer,
		openMessageModal: openMessageModalHandler,
		closeMessageModal: closeMessageModalHandler,
		setSelectedNetwork: setSelectedNetwork,
		selectedNetwork: selectedNetwork,
	};

	const DiscoveryContextProps = {
		value: context,
	};

	return <DiscoveryContext.Provider {...DiscoveryContextProps}>{children}</DiscoveryContext.Provider>;
};

export default DiscoveryContext;
