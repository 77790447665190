import { AxiosError } from 'axios';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import DashboardHelper from 'services/DataLibrary/DashboardHelper';
import toast from 'services/Toast';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilter } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { setFilterOnDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setDashboards } from 'views/DataLibrary/reducers/DashboardsSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

import AssignmentOptions from './AssignmentOptions/AssignmentOptions';
import AssignmentTypes from './AssignmentTypes/AssignmentTypes';
import BrandOptions from './BrandOptions/BrandOptions';
import CampaignOptions from './CampaignOptions/CampaignOptions';
import ClientOptions from './ClientOptions/ClientOptions';
import DateOptions from './DateOptions/DateOptions';
import Styled from './FilterSection.style';
import InfluencerCountryOptions from './InfluencerCountryOptions/InfluencerCountryOptions';
import InfluencerOptions from './InfluencerOptions/InfluencerOptions';
import { FilterOptionProps } from './types';

/**
 * @returns {JSX.Element}
 */
const FilterSection = (): JSX.Element => {
	const [clearFilter, setClearFilter] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filterHasChanged, setFilterHasChanged] = useState<boolean>(false);

	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const filter = useAppSelector((state) => state.filter);

	const manager = useInjection<DataLibraryManager>(DataLibraryManager);
	const dashboardHelper = useInjection<DashboardHelper>(DashboardHelper);

	const dispatch = useAppDispatch();
	const { dashboardId } = useParams();

	const saveFilter = () => {
		setIsLoading(true);
		// @ts-ignore
		manager
			.update(dashboard.id, { metaData: { dashboardFilter: dashboardFilter } })
			.then(() => {
				dispatch(setFilterOnDashboard({ dashboardFilter: dashboardFilter }));

				dashboardHelper.getDashboards().then((updatedDashboards) => {
					dispatch(setDashboards(updatedDashboards));
				});
				toast.success(`Filter saved`);
				setFilterHasChanged(false);
			})
			.catch((err: AxiosError) => {
				setFilterHasChanged(true);
				toast.error(getErrorMessageOnPost('saving the filter'));
				console.error(err.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getClearFilter = () => {
		setClearFilter(false);
	};

	const FilterOptiosProps: FilterOptionProps = {
		dashboardId,
		clearFilter,
		getClearFilter,
	};

	const hasFilterBeenChanged = () => {
		if (!isEqual(dashboardFilter.from, dashboard.metaData.dashboardFilter?.from)) return true;
		if (!isEqual(dashboardFilter.to, dashboard.metaData.dashboardFilter?.to)) return true;
		if (!isEqual(dashboardFilter.clients, dashboard.metaData.dashboardFilter?.clients)) return true;
		if (!isEqual(dashboardFilter.brands, dashboard.metaData.dashboardFilter?.brands)) return true;
		if (!isEqual(dashboardFilter.campaigns, dashboard.metaData.dashboardFilter?.campaigns)) return true;
		if (!isEqual(dashboardFilter.influencers, dashboard.metaData.dashboardFilter?.influencers)) return true;
		if (!isEqual(dashboardFilter.countries, dashboard.metaData.dashboardFilter?.countries)) return true;
		if (!isEqual(dashboardFilter.assignments, dashboard.metaData.dashboardFilter?.assignments)) return true;
		if (!isEqual(dashboardFilter.assignmentTypes, dashboard.metaData.dashboardFilter?.assignmentTypes)) return true;
		if (!isEqual(dashboardFilter.uploads, dashboard.metaData.dashboardFilter?.uploads)) return true;
		return false;
	};

	useEffect(() => {
		if (dashboardId === dashboard.id) {
			setFilterHasChanged(hasFilterBeenChanged());
		}
		if (dashboard.metaData?.dashboardFilter) {
			dispatch(setDashboardFilter(dashboard.metaData?.dashboardFilter));
		}
		return () => {
			setFilterHasChanged(false);
		};
	}, []);

	useEffect(() => {
		if (dashboardId === dashboard.id) {
			setFilterHasChanged(hasFilterBeenChanged());
		}
	}, [dashboardFilter, dashboardId]);

	useEffect(() => {
		if (dashboard.metaData?.dashboardFilter) {
			dispatch(setDashboardFilter(dashboard.metaData?.dashboardFilter));
		}
	}, [dashboardId]);

	return (
		<>
			<Styled.FiltersTitle>
				Filters
				{isLoading ? (
					<div className='saving-text'>Saving filters... </div>
				) : (
					<div className='link-text' onClick={() => setClearFilter(true)}>
						Reset all filters
					</div>
				)}
			</Styled.FiltersTitle>
			<Styled.FilterWrapper className={classNames({ visible: filter.isOpen })}>
				{dashboard.id && (
					<>
						<Styled.FilterSectionContainer>
							<DateOptions {...FilterOptiosProps} />
							<ClientOptions {...FilterOptiosProps} />
							<BrandOptions {...FilterOptiosProps} />
							<CampaignOptions {...FilterOptiosProps} />
							<InfluencerOptions {...FilterOptiosProps} />
							<InfluencerCountryOptions {...FilterOptiosProps} />
							<AssignmentOptions {...FilterOptiosProps} />
							<AssignmentTypes {...FilterOptiosProps} />
						</Styled.FilterSectionContainer>
					</>
				)}
			</Styled.FilterWrapper>
			<Styled.FilterButtonWrapper>
				<Styled.CustomButton
					className='save'
					disabled={!filterHasChanged}
					onClick={() => {
						saveFilter();
					}}
				>
					<span>Save Changes</span>
				</Styled.CustomButton>
			</Styled.FilterButtonWrapper>
		</>
	);
};

export default FilterSection;
