import styled, { DefaultThemeV2 } from 'styled-components';

import { StyledCard } from 'components/Settings/Components/ClientDetails/ClientDetails.style';
import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

type T = { theme: DefaultThemeV2 };

const TopSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const ClientCard = styled.div`
	display: flex;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
	cursor: pointer;
`;

const SearchInputWrapper = styled.div`
	float: right;
	width: 350px;
	margin-top: 16px;
	@media screen and (${devices.lg}) {
		margin-top: 0;
	}
`;

const ClientsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`;

const FlexDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	@media screen and (${devices.lg}) {
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
	}
`;

const AddIcon = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.ash};
	border-radius: ${(props: T) => props.theme.radius.small};
	.icon {
		margin-top: 4px;
	}
`;

const AddSection = styled.div`
	cursor: pointer;
	display: flex;
	gap: 8px;
	align-items: center;
	p {
		margin-bottom: 0;
		color: ${colors.smoke};
		font-weight: 600;
		margin-top: 3px;
	}
`;

const Text = styled.p`
	span {
		font-weight: 500;
	}
`;

const CreateContainer = styled.div`
	width: 100%;
	button {
		width: 100%;
	}
	@media screen and (${devices.lg}) {
		width: auto;
		button {
			width: fit-content;
			white-space: nowrap;
		}
	}
`;

const ClientContainer = styled.div`
	width: 100%;
	gap: 8px;
	display: flex;
	flex-wrap: wrap;
	&.searching {
		min-height: 600px;
		overflow-y: auto;
	}
`;

export const EmptyResultImgWrapper = styled.div`
	max-width: 500px;
	margin: 0 auto;

	p strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

const NoClients = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const IconContainer = styled.div`
	background-color: ${colors.white};
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
	.icon {
		margin-left: 3px;
	}
`;

const FieldWrapper = styled.div`
	margin-bottom: 40px;
	&.first {
		margin-top: 24px;
	}
`;

export default {
	FieldWrapper,
	IconContainer,
	NoClients,
	EmptyResultImgWrapper,
	ClientContainer,
	SearchInputWrapper,
	StyledCard,
	TopSection,
	ClientCard,
	ClientsWrapper,
	FlexDiv,
	AddIcon,
	AddSection,
	CreateContainer,
	Text,
};
