import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';

import Avatar from 'components/Avatar';

import Styled from './InfluencerItem.style';

type Props = {
	profileImage?: string;
	name: string;
	onClick: () => void;
	isSelected: boolean;
	conversation: Model | undefined;
	hasUnreadMessages: boolean;
};

const InfluencerItem = ({ profileImage, name, onClick, isSelected, conversation, hasUnreadMessages }: Props) => {
	const latestMessage = conversation ? new LatestMessage(conversation.latestMessage) : null;

	return (
		<Styled.ListItem onClick={onClick} data-testid='influencer-item'>
			<Styled.Wrapper className={classNames({ selected: isSelected })}>
				<Styled.ImageWrapper>
					<Avatar imageUrl={profileImage} name={name} />
					{hasUnreadMessages ? <Styled.UnreadMark /> : null}
				</Styled.ImageWrapper>
				{latestMessage ? (
					<Styled.LatestMessageWrapper>
						<Styled.LatestMessageHeader>
							<Styled.InfluencerName className='has-messages'>{name}</Styled.InfluencerName>
							<div className='date'>{latestMessage.createdAt}</div>
						</Styled.LatestMessageHeader>
						<Styled.LatestMessageContent>
							<span className='name'>{latestMessage.sender}:</span>
							<span data-testid='integrated-inbox-latest-message'>{latestMessage.message}</span>
						</Styled.LatestMessageContent>
					</Styled.LatestMessageWrapper>
				) : (
					<Styled.InfluencerName>{name}</Styled.InfluencerName>
				)}
			</Styled.Wrapper>
		</Styled.ListItem>
	);
};

export class LatestMessage {
	sender: string;
	message: string;
	createdAt: string;
	isRead: boolean;

	constructor(latestMessage: Model) {
		this.sender = this.isUser(latestMessage) ? latestMessage.user.initials.charAt(0) : latestMessage.influencer.username;
		this.message = latestMessage.message;
		this.createdAt = moment(latestMessage.createdAt).format('YY-MM-DD');
		this.isRead = !!latestMessage.readAt;
	}

	isUser(latestMessage: Model) {
		return !!latestMessage.user;
	}
}

export default InfluencerItem;
