import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PublisherModel } from 'api-models';
import { ListPublishersQuery } from 'api-queries';
import Navigation from 'components/Navigation/Navigation';
import PageHeader from 'components/PageHeader';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { UserType } from 'reducers/UserReducers/types';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Billing from './Billing/Billing';
import BrandManagement from './BrandManagement/BrandManagement';
import ClientManagement from './ClientManagement/ClientManagement';
import BrandDetails from './Components/BrandDetails/BrandDetails';
import ClientDetails from './Components/ClientDetails/ClientDetails';
import DeliveryInfoSection from './DeliveryInfo';
import MyAccountSection from './MyAccount/MyAccountSection';
import MyCompany from './MyCompany/MyCompany';
import ProfilesSection from './Profiles';
import Styled from './Settings.style';
import { OrganizationHierarchyRole } from './types';

const Titles = {
	ACCOUNT: 'My account',
	PROFILES: 'Social profiles',
	DELIVERYINFO: 'Delivery info',
	COMPANY: 'My company',
	BILLING: 'Billing',
	CLIENTS: 'Clients',
	INSTAGRAM: 'Instagram connection',
	TIKTOK: 'TikTok connection',
	BRANDS: 'Brands',
};

/**
 * SettingsContainer
 * @returns {JSX.Element}
 */
const SettingsContainer = (): JSX.Element => {
	const [publisher, setPublisher] = useState<PublisherModel | undefined>(undefined);

	const user = useAppSelector((state) => state.user);
	const { clientId, brandId } = useParams();

	const location = useLocation();

	const manager = useInjection<PublisherManager>(PublisherManager);
	const { repository } = manager.listPublishers(
		RequestQueryBuilder.create<ListPublishersQuery>(['logo', 'edit', 'logo', 'createInvitation', 'createClient', 'deleteLogo']),
	);

	const publishers = repository.findAll('publisher');

	const userHasRoleOnPublishers = publishers.filter((publisher) => {
		const role = user.permissions.entities && user.permissions.entities[publisher.id]?.role;
		return role !== undefined;
	});

	const isAdminOnPublisher = publishers.filter((publisher) => {
		const role = user.permissions.entities && user.permissions.entities[publisher.id]?.role;
		return role === OrganizationHierarchyRole.ADMINISTRATOR;
	});

	useEffect(() => {
		userHasRoleOnPublishers && setPublisher(userHasRoleOnPublishers[0] as PublisherModel);
	}, [userHasRoleOnPublishers.length, publishers]);

	const renderMenu = (user: UserType) => {
		if (user.permissions.isInfluencer) {
			return [
				{
					title: Titles.ACCOUNT,
					icon: 'user',
					url: '/influencer/settings/account',
					testId: 'account',
					disabled: false,
				},
				{
					title: Titles.DELIVERYINFO,
					icon: 'credit-card',
					url: '/influencer/settings/delivery-info',
					testId: 'delivery-info',
					disabled: false,
				},
				{
					title: Titles.INSTAGRAM,
					icon: 'instagram',
					url: '/influencer/settings/instagram',
					testId: 'instagram',
					disabled: false,
				},
				{
					title: Titles.TIKTOK,
					icon: 'tiktok',
					url: '/influencer/settings/tiktok',
					testId: 'tiktok',
					disabled: false,
				},
			];
		} else {
			return [
				{
					title: Titles.ACCOUNT,
					icon: 'user',
					url: '/settings/account',
					testId: 'account',
					disabled: false,
				},
				user.links?.publishers && {
					title: Titles.COMPANY,
					icon: 'store',
					url: '/settings/company',
					testId: 'company',
					disabled: false,
				},
				isAdminOnPublisher && {
					title: Titles.BILLING,
					icon: 'credit-card',
					url: '/settings/billing',
					testId: 'billing',
					disabled: false,
				},
				user.links?.clients && {
					title: Titles.CLIENTS,
					icon: 'influencer',
					url: '/settings/clients',
					testId: 'clients',
					disabled: false,
				},
				user.links?.brands && {
					title: Titles.BRANDS,
					icon: 'brand',
					url: '/settings/brands',
					testId: 'brands',
					disabled: false,
				},
			];
		}
	};

	const renderContent = () => {
		if (brandId) {
			return <BrandDetails />;
		} else if (clientId) {
			return <ClientDetails />;
		} else
			switch (location.pathname) {
				case '/settings/clients':
					return publisher && <ClientManagement publisher={publisher} />;
				case '/settings/brands':
					return <BrandManagement />;
				case '/settings/account':
				case '/influencer/settings/account':
					return <MyAccountSection />;
				case '/influencer/settings/instagram':
					return <ProfilesSection />;
				case '/influencer/settings/tiktok':
					return <ProfilesSection />;
				case '/influencer/settings/profiles':
					return <ProfilesSection />;
				case '/settings/delivery-info':
				case '/influencer/settings/delivery-info':
					return <DeliveryInfoSection />;
				case '/settings/company':
					return publisher && <MyCompany setPublisher={setPublisher} publisher={publisher} publishers={userHasRoleOnPublishers as PublisherModel[]} />;
				case '/settings/billing':
					return publisher && <Billing publisher={publisher} />;
				default:
					return <></>;
			}
	};

	return (
		<Styled.Wrapper>
			<Grid.Container>
				<Styled.HeaderColumn lg={12}>
					<PageHeader headline='Settings' showBreadcrumb={false} showCurrentDate={false} />
				</Styled.HeaderColumn>
			</Grid.Container>
			<Styled.StyledGridContainer>
				<Styled.FixedColumn lg={3} xs={12}>
					<Navigation Menu={renderMenu(user)} />
				</Styled.FixedColumn>
				<Styled.ScrollableColumn lg={9} xs={12}>
					{renderContent()}
				</Styled.ScrollableColumn>
			</Styled.StyledGridContainer>
		</Styled.Wrapper>
	);
};

export default SettingsContainer;
