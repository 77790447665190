import Styled from 'components/Settings/Settings.style';
import SocialProfileConnector from 'components/SocialProfileConnector';

/**
 * Add Account form
 * @returns {JSX.Element}
 */
const ProfilesSection = (): JSX.Element => {
	return (
		<Styled.Content>
			<h5>Link your social profiles</h5>
			<p>Boost your workflow and enhance your chances of getting custom and matched invitations by adding your social profiles.</p>
			<SocialProfileConnector isSignUp={false} />
		</Styled.Content>
	);
};

export default ProfilesSection;
