import styled from 'styled-components';

import { Button } from 'components/Button';
import Modal from 'components/Modals/Modal';
import { ModalContent } from 'components/Modals/Modal/Modal.style';
import boxShadow from 'styles/variables/box-shadows';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const dl = colors.dataLibrary;

const CustomModal = styled(Modal)`
	padding-right: 0;
	text-align: center;
	${ModalContent} {
		width: 100%;
		@media screen and (${devices.md}) {
			width: 616px;
		}

		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: none;
		border-radius: 29px;
		overflow: hidden;
	}
`;

const ModalInnerContainer = styled.div`
	width: 100%;
	height: 445px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	padding: ${spacing[3].rem} ${spacing[4].rem};
	background-color: ${colors.modalV2.background};

	@media screen and (${devices.sm}) {
		text-align: center;
		padding: ${spacing[4].rem};
	}
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: ${spacing[3].rem};
`;

const ModalContentWrapper = styled.div``;

const ModalButtonsContainer = styled.div`
	width: 100%;
	margin-top: ${spacing[4].px};

	display: flex;
	flex-direction: column-reverse;
	row-gap: ${spacing[2].px};
	justify-content: space-between;

	&.center {
		justify-content: center;
	}

	.cancel {
		height: 44px;
		width: 100%;
		font-size: 1rem !important;
		background-color: ${colors.modalV2.background};

		@media screen and (${devices.sm}) {
			width: 185px;
		}
	}

	@media screen and (${devices.sm}) {
		flex-direction: row;
	}
`;

const ConfirmButton = styled(Button)`
	font-size: 1rem;
	line-height: 1;
	width: 100%;
	@media screen and (${devices.sm}) {
		width: 220px;
	}

	height: 44px;
	border: none;
	cursor: pointer;
	&.isDangerButton {
		background: ${colors.button.danger.backgroundColor};
		color: ${colors.button.danger.color};
		&:hover {
			background: ${colors.button.danger.backgroundColor};
			border: 1px solid ${colors.button.danger.borderColor};
			color: ${colors.button.danger.color};
		}
	}
	background: ${colors.button.dark.background};
	color: ${colors.button.dark.color};
	.icon path {
		fill: ${colors.button.dark.color};
	}
	&:hover {
		background: ${colors.black};
		border: 2px solid ${colors.button.primary.borderColor};
		color: ${dl.white};
		box-shadow: ${boxShadow};
	}
`;

const Styled = {
	CustomModal,
	ModalInnerContainer,
	SpinnerWrapper,
	ModalContentWrapper,
	ModalButtonsContainer,
	ConfirmButton,
};

export default Styled;
