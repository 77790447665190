import { createClient } from 'shared/ApiClient/ApiClient';

const useBlastMessage = () => {
	const client = createClient();

	const createBlastDirectMessage = (influencerIds: string[], message: string) => {
		return client.post('/conversations/blasts', { message: message, influencers: influencerIds });
	};

	return {
		createBlastDirectMessage,
	};
};

export default useBlastMessage;
