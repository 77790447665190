import { Total, ByAssignmentType, OverTime, ByInfluencerContainer } from 'components/DataLibrary/Graphs/GraphComponents';
import ByCampaignContainer from 'components/DataLibrary/Graphs/GraphComponents/ByCampaign/ByCampaignContainer';
import { REACH } from 'constants/data-library';
import colors from 'styles/variables/colors';

/**
 * @returns object
 */
const ReachGraphs = (): object => {
	const graphItems = {
		category: 'Reach',
		items: [
			{
				title: REACH.TOTAL_TITLE,
				icon: REACH.TOTAL_ICON,
				id: REACH.TOTAL,
				component: <Total title={REACH.TOTAL_TITLE} chartType={REACH.TOTAL} />,
			},
			{
				title: REACH.BY_SOCIAL_MEDIA_TITLE,
				icon: REACH.BY_SOCIAL_MEDIA_ICON,
				id: REACH.BY_SOCIAL_MEDIA,
				component: <ByAssignmentType displayBar title={REACH.BY_SOCIAL_MEDIA_TITLE} chartType={REACH.BY_SOCIAL_MEDIA} />,
			},
			{
				title: REACH.OVERTIME_TITLE,
				icon: REACH.OVERTIME_ICON,
				id: REACH.OVERTIME,
				component: <OverTime title={REACH.OVERTIME_TITLE} color={colors.chartLineReach} isHoverable={true} chartType={REACH.OVERTIME} />,
			},
			{
				title: REACH.BY_INFLUENCER_TITLE,
				icon: REACH.BY_INFLUENCER_ICON,
				id: REACH.BY_INFLUENCER,
				component: <ByInfluencerContainer title={REACH.BY_INFLUENCER_TITLE} isTopThree={true} chartType={REACH.BY_INFLUENCER} valueType='reach' />,
			},
			{
				title: REACH.REACH_AND_CPR_BY_INFLUENCER_TITLE,
				icon: REACH.REACH_AND_CPR_BY_INFLUENCER_ICON,
				id: REACH.REACH_AND_CPR_BY_INFLUENCER,
				component: (
					<ByInfluencerContainer title={REACH.REACH_AND_CPR_BY_INFLUENCER_TITLE} chartType={REACH.REACH_AND_CPR_BY_INFLUENCER} valueType='reach-cpr' />
				),
			},
			{
				title: REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN_TITLE,
				icon: REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN_ICON,
				id: REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN,
				component: (
					<ByCampaignContainer
						title={REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN_TITLE}
						chartType={REACH.REACH_AND_ENGAGEMENT_PER_CAMPAIGN}
						displayCombinedBarWithLine
					/>
				),
			},
		],
	};

	return graphItems;
};
export default ReachGraphs;
