import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';

import Styled from './Login.style';
import Footer from './components/Footer';
import NotValidResetToken from './components/NotValidResetToken';
import ResetPasswordForm from './components/ResetPasswordForm';

/**
 * LoginResetView
 * @todo i18n
 * @returns {JSX.Element}
 */
export const LoginResetView = (): JSX.Element => {
	const { token } = useParams();
	return (
		<Styled.Wrapper>
			<Helmet>
				<title>Reset password | Collabs</title>
			</Helmet>
			<Styled.Inner>
				<Styled.Section>
					<img src={fallbackLogotype} alt='Collabs' width='110px' />
					{'expired' === token ? <NotValidResetToken /> : <ResetPasswordForm token={token || ''} />}
				</Styled.Section>
			</Styled.Inner>
			<Footer />
		</Styled.Wrapper>
	);
};

export default LoginResetView;
