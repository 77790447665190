import { isAxiosError } from 'axios';
import { Formik, Form } from 'formik';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import Styled from 'components/AffiliateMarketplaceCampaignForm/AffiliateMarketplaceCampaignFormContainer.style';
import CreationForm from 'components/AffiliateMarketplaceCampaignForm/CreationForm';
import useAffiliateMarketplaceData, { AM_BASE_URL } from 'components/AffiliateMarketplaceCampaignForm/hooks';
import { CreationFormData } from 'components/AffiliateMarketplaceCampaignForm/types';
import { CREATION_FORM_VALIDATION } from 'components/AffiliateMarketplaceCampaignForm/utils';
import { DarkButton } from 'components/Button';
import ConfirmModalV2 from 'components/Modals/ConfirmModalV2';
import { UPLOAD_COVER_IMAGE } from 'constants/hateoas-keys';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';
import errorHandler from 'utils/formik_error_handler';

/**
 * @returns {JSX.Element}
 */
const AMCampaignCreateContainer = () => {
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const [shouldValidate, setShouldValidate] = useState<boolean>(false);

	const { createProgram, saveCoverImage } = useAffiliateMarketplaceData();
	const { mutate } = useSWRConfig();

	const initialValues: CreationFormData = {
		name: '',
		adtractionId: '',
		storeLink: '',
		coverImage: undefined,
		ogMetaImage: undefined,
		coverImageFile: undefined,
		storeCommissionPercentage: 0,
		influencerCommissionPercentage: 0,
		background: '',
		guidelines: '',
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={CREATION_FORM_VALIDATION}
			validateOnBlur={shouldValidate}
			validateOnChange={shouldValidate}
			onSubmit={async (values) => {
				try {
					await createProgram(values).then(async (response) => {
						const { data } = response.data;
						if (values.coverImageFile && !isEmpty(data.links) && !isNil(data.links[UPLOAD_COVER_IMAGE])) {
							await saveCoverImage(data.links[UPLOAD_COVER_IMAGE], values.coverImageFile);
						}
						toast.success('Affiliate program is created');
						await mutate(
							(key: string) => {
								return key.includes(AM_BASE_URL);
							},
							undefined,
							{
								revalidate: true,
							},
						).then(() => {
							navigate('/affiliate/programs');
						});
					});
				} catch (err) {
					if (isAxiosError(err) && err.message) {
						toast.error(err.message);
					}
					console.error(err);
				}
			}}
		>
			{({ values, handleSubmit, validateForm, setErrors, isValid, isSubmitting, setFieldTouched }) => {
				return (
					<Form>
						<div>
							<Styled.TopBar>
								<Styled.ButtonsWrapper>
									<DarkButton
										data-testid='launch-button'
										disabled={!isValid}
										size='sm'
										type='button'
										onClick={() => {
											if (!shouldValidate) {
												setShouldValidate(true);
											}
											validateForm().then((res) => {
												if (isEmpty(res)) {
													setIsConfirmModalOpen(true);
												} else {
													for (const key in res) {
														setFieldTouched(key, true);
													}
													errorHandler(res, setErrors);
												}
											});
										}}
									>
										Launch affiliate
									</DarkButton>
								</Styled.ButtonsWrapper>
							</Styled.TopBar>
							<Grid.Container>
								<Grid.Column sm={12} lg={2} />
								<Grid.Column sm={12} lg={8}>
									<CreationForm />
								</Grid.Column>
							</Grid.Container>
						</div>
						<ConfirmModalV2
							isModalOpen={isConfirmModalOpen}
							toggleModal={() => setIsConfirmModalOpen(false)}
							action={() => {
								handleSubmit();
							}}
							buttonText='Yes, launch campaign'
							isFetching={isSubmitting}
						>
							<Styled.ComfirmModalInner>
								<div className='title'>Launch campaign</div>
								<p>You are about to launch </p>
								<p>{values.name}</p>
							</Styled.ComfirmModalInner>
						</ConfirmModalV2>
					</Form>
				);
			}}
		</Formik>
	);
};

export default AMCampaignCreateContainer;
