import { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { storeSelectedProfileId } from 'components/Header/Components/UserMenu/Utils';
import { getProperDataFromResponse } from 'components/InfluencerDashboard/Components/InvitationBriefPage/helper';
import LoadingSpinner from 'components/LoadingSpinner';
import ConfirmModal from 'components/Modals/ConfirmModal';
import JoinModal from 'components/NewBriefPage/Components/JoinModal/JoinModal';
import LoginModal from 'components/NewBriefPage/Components/LoginModal';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import { FEATURE_FLAG_MULTIPLE_ACCOUNTS } from 'constants/feature-flag-keys';
import { CLAIM, INFLUENCER_JOIN_CAMPAIGN } from 'constants/hateoas-keys';
import { SELECTED_PROFILE } from 'constants/localStorage-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import useFeaturePermissions from 'hooks/FeaturePermissions/useFeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import usePermissions from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { clearPermissions } from 'reducers/UserReducers/UserPermissionsSlice';
import { clearUser } from 'reducers/UserReducers/UserSlice';
import { isConnected } from 'reducers/UserReducers/helpers';
import { pathInfluencerCampaignJoined } from 'routing/PathLookup';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import TokenStorageRegistry from 'services/Authentication/TokenStorageRegistry';
import InfluencerService from 'services/Influencer';
import toast from 'services/Toast';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';
import colors from 'styles/variables/colors';

import Styled from './JoinButton.style';

import type GenericApiClientInterface from 'services/ApiClient/GenericApiClientInterface';

type JoinButtonProps = {
	campaignInstagramOwner: CampaignInstagramOwner;
	campaign: Campaign;
};
/**
 * @returns JSX.Element
 */
const JoinButton = ({ campaign, campaignInstagramOwner }: JoinButtonProps): JSX.Element => {
	const tokenStorage = useInjection<TokenStorageRegistry>(TokenStorageRegistry);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
	const userDispatch = useAppDispatch();

	const storage = new BrowserStorage(StorageType.LOCAL);

	const hasAlreadyJoined = campaignInstagramOwner.joined;
	const hasAccount = campaignInstagramOwner.influencer?.hasUser;

	const user = useAppSelector((state) => state.user);
	const { isInfluencer } = usePermissions();
	const { userCan } = useFeaturePermissions(campaignInstagramOwner.invite?.campaignInstagramOwner?.links);
	const inviteHateoas = useFeaturePermissions(campaignInstagramOwner.invite?.links);
	const params = useParams();
	const location = useLocation();

	const navigate = useNavigate();

	const isLoggedIn = useAppSelector(isConnected);
	const client = useInjection<GenericApiClientInterface>(ApiClientService.GenericApiClientInterface);

	const [isEnabled] = useFeatureToggle();

	const btnText = isLoggedIn && !hasAlreadyJoined ? 'Join now' : hasAlreadyJoined ? 'Already joined' : hasAccount ? 'Login to join' : 'Signup to join';

	const routeToSignupPage = () => {
		navigate(`/signup/${params.inviteToken}`);
	};

	const routeToLoginPage = () => {
		navigate('/login');
	};

	const logOut = () => {
		userDispatch(clearUser());
		userDispatch(clearPermissions());
		tokenStorage.clearAll();
	};

	const joinCampaign = async () => {
		const NEED_TO_CLAIM = inviteHateoas.userCan(CLAIM);
		const CAN_JOIN_CAMPAIGN = userCan(INFLUENCER_JOIN_CAMPAIGN);
		if (isEnabled(FEATURE_FLAG_MULTIPLE_ACCOUNTS) && NEED_TO_CLAIM) {
			setIsLoading(true);
			try {
				await client.post(campaignInstagramOwner?.invite.links[CLAIM]).then(() => {
					// After claim we need to get the invite again
					InfluencerService.getInvitedCampaign(params.inviteToken || '').then(async (result) => {
						if (result) {
							const newResult = getProperDataFromResponse(result);
							const CIO = newResult.campaignInstagramOwner as CampaignInstagramOwner;
							if (CIO.links) {
								const CIOPermissions = useFeaturePermissions(CIO?.links);
								if (CIOPermissions.userCan(INFLUENCER_JOIN_CAMPAIGN)) {
									// After the invite result we can get the join hateoas and join the campaign
									await client.post(CIO?.links[INFLUENCER_JOIN_CAMPAIGN]).then(() => {
										setIsLoading(false);
										if (CIO.influencer) {
											storeSelectedProfileId(CIO.influencer.id);
										}
										navigate(pathInfluencerCampaignJoined(campaignInstagramOwner?.campaign.shortId));
									});
								} else {
									toast.error('You dont have permission to join this campaign');
								}
							}
						}
					});
				});
			} catch (error) {
				setIsModalOpen(false);
				setIsLoading(false);
				toast.error(getErrorMessageOnPost('joining the campaign'));
				console.error('Unable to join campaign: %O', error);
			}
		}

		if (!NEED_TO_CLAIM && CAN_JOIN_CAMPAIGN) {
			setIsLoading(true);
			try {
				if (campaignInstagramOwner?.links) {
					await client.post(campaignInstagramOwner.links[INFLUENCER_JOIN_CAMPAIGN]);
					if (campaignInstagramOwner.influencer) {
						storage.setItem(SELECTED_PROFILE, campaignInstagramOwner.influencer.id);
						navigate(pathInfluencerCampaignJoined(campaignInstagramOwner?.campaign.shortId));
					}
				}
			} catch (e) {
				setIsModalOpen(false);
				toast.error(getErrorMessageOnPost('joining the campaign'));
				console.error('Unable to join campaign: %O', e);
			}
			setIsLoading(false);
		}

		if (!NEED_TO_CLAIM && !CAN_JOIN_CAMPAIGN) {
			toast.error('You dont have permission to join this campaign');
			setIsLoading(false);
			setIsModalOpen(false);
		}
	};

	const joinCampaignHandler = () => {
		if (isInfluencer()) {
			joinCampaign();
		}
	};

	const displayToastCampaignIsFull = () => {
		toast.error('This campaign is already full');
	};

	const getAction = () => {
		if (location.pathname.indexOf('preview') > -1) {
			return toast.error('Only preview');
		}

		if (!isLoggedIn) {
			return campaignInstagramOwner?.influencer?.hasUser ? routeToLoginPage() : routeToSignupPage();
		}

		if (campaignInstagramOwner.joined) {
			return toast.success('You have already joined this campaign');
		}

		if (campaignInstagramOwner.declined) {
			return toast.error('You have declined this invitation, please contact us for more info');
		}

		if (!isInfluencer()) {
			return toast.error('You are not an influencer');
		}

		if (campaignInstagramOwner?.campaign?.spotsLeft === 0) {
			return displayToastCampaignIsFull();
		}

		if (!inviteHateoas.userCan(CLAIM) && !userCan(INFLUENCER_JOIN_CAMPAIGN)) {
			setIsErrorModalOpen(true);
			return;
		}

		// Assert: The influencer is logged in
		return setIsModalOpen(true);
	};

	return (
		<>
			<Styled.CustomButton data-testid='join-campaign-button' onClick={() => getAction()} disabled={hasAlreadyJoined}>
				{isLoading ? <LoadingSpinner size='sm' /> : btnText}
			</Styled.CustomButton>
			<JoinModal
				campaign={campaign}
				campaignInstagramOwner={campaignInstagramOwner}
				isModalOpen={isModalOpen}
				toggleModal={() => setIsModalOpen(!isModalOpen)}
				join={joinCampaignHandler}
				isLoading={isLoading}
			/>

			<ConfirmModal
				icon='sign-in'
				iconSize='28'
				IconBackgroundColor={colors.modal.iconBackground.signIn}
				isModalOpen={isErrorModalOpen}
				title='Invitation for another account'
				buttonText={campaignInstagramOwner.influencer?.hasUser ? 'Sign in' : 'Sign up'}
				toggleModal={() => {
					setIsErrorModalOpen(false);
				}}
				action={() => {
					logOut();
					setIsErrorModalOpen(false);
					if (campaignInstagramOwner.influencer?.hasUser) {
						setIsLoginModalOpen(true);
					} else {
						routeToSignupPage();
					}
				}}
			>
				<Styled.ErrorModalInnerWrapper>
					<div data-testid='no-access-to-join-modal' aria-hidden={isErrorModalOpen ? 'false' : 'true'}>
						This invite is not for
						{user.influencers.length < 1 ? (
							' the current user'
						) : (
							<strong>
								{' '}
								@{user.influencer?.username} (<span>{user.influencer?.network}</span>)
							</strong>
						)}
						. Join by signing in to the account specified in the invitation.
					</div>
				</Styled.ErrorModalInnerWrapper>
			</ConfirmModal>
			<LoginModal
				isOpen={isLoginModalOpen}
				onClose={() => {
					setIsLoginModalOpen(false);
				}}
			/>
		</>
	);
};

export default JoinButton;
