import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const ListItem = styled.li`
	margin: 8px;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.m};

	width: 100%;
	height: 80px;

	padding-left: 0.5rem;
	border-radius: ${borderRadius.s};

	cursor: pointer;
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
	transition:
		background-color 200ms ease-in-out,
		color 200ms ease-in-out;

	&:hover {
		background-color: ${colors.integratedInbox.campaignSection.listItem.hover.background};
		color: ${colors.integratedInbox.campaignSection.listItem.hover.color};
	}

	&.selected {
		background-color: ${colors.integratedInbox.campaignSection.listItem.hover.background};
		color: ${colors.integratedInbox.campaignSection.listItem.hover.color};

		& > div:nth-child(2) {
			border-bottom-color: transparent;
		}
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 40px;
	height: 40px;

	& > figure {
		margin: 0;
	}
`;

const InfluencerName = styled.div`
	font-weight: 600;

	&.has-messages {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.no-message {
		flex: 1;
		height: 80px;
		display: flex;
		align-items: center;
		padding-right: ${guttersWithRem.m};
		border-bottom: 1px solid ${colors.lightGray};
	}
`;

const LatestMessageWrapper = styled.div`
	flex: 1;
	height: 80px;
	max-width: 85%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: ${guttersWithRem.m};
	padding-top: 4px;
`;

const LatestMessageHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 2px;
	& > div {
		&.date {
			font-size: 0.875rem;
			color: ${colors.integratedInbox.campaignSection.listItem.influencer.messageColor};
			white-space: nowrap;
		}
	}
`;

const LatestMessageContent = styled.div`
	font-size: 0.9375rem;
	line-height: 1.1;
	width: 220px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	& > span {
		color: ${colors.integratedInbox.campaignSection.listItem.influencer.messageColor};
		&.name {
			margin-right: ${guttersWithRem.xxs};
		}
	}

	&:hover {
		color: ${colors.integratedInbox.campaignSection.listItem.influencer.messageColor};
	}
`;

const UnreadMark = styled.div`
	position: absolute;
	right: 0;
	top: 0;

	width: 12px;
	height: 12px;
	background-color: ${colors.integratedInbox.campaignSection.listItem.unreadMark.background};
	border: 2px solid ${colors.integratedInbox.lightBlue};
	border-radius: 50%;
`;

const Styled = {
	ListItem,
	Wrapper,
	ImageWrapper,
	InfluencerName,
	LatestMessageWrapper,
	LatestMessageHeader,
	LatestMessageContent,
	UnreadMark,
};

export default Styled;
