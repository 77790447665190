import { useRef } from 'react';

import { Button } from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './ConfirmModalV2.style';

type ConfirmModalProps = {
	isModalOpen: boolean;
	toggleModal: () => void;
	isFetching?: boolean;
	action?: () => void;
	buttonText: string;
	children?: JSX.Element;
	cancelButtonText?: string;
};
/**
 * Gray color confirm modal
 * @returns {JSX.Element}
 */
const ConfirmModalV2 = ({ buttonText, isModalOpen, isFetching, toggleModal, action, children, cancelButtonText }: ConfirmModalProps): JSX.Element => {
	const cancelRef = useRef<HTMLButtonElement>(null);

	const onShown = () => {
		setTimeout(() => {
			cancelRef.current && cancelRef.current.focus();
		}, 0);
	};

	return (
		<Styled.CustomModal
			size='xs'
			open={isModalOpen}
			handleClose={() => {
				!isFetching && toggleModal();
			}}
			onShown={() => onShown()}
		>
			<Styled.ModalInnerContainer>
				<Styled.ModalContentWrapper>
					{isFetching ? (
						<Styled.SpinnerWrapper>
							<LoadingSpinner />
						</Styled.SpinnerWrapper>
					) : (
						children
					)}
				</Styled.ModalContentWrapper>

				<Styled.ModalButtonsContainer>
					<Button data-testid='modal-cancel-button' btnRef={cancelRef} className='cancel' onClick={() => toggleModal()} disabled={isFetching}>
						<div>{cancelButtonText ?? 'Cancel'}</div>
					</Button>
					<Styled.ConfirmButton type='button' onClick={action} disabled={isFetching} data-testid='custom-modal-confirm-button'>
						<div>{buttonText}</div>
					</Styled.ConfirmButton>
				</Styled.ModalButtonsContainer>
			</Styled.ModalInnerContainer>
		</Styled.CustomModal>
	);
};

export default ConfirmModalV2;
